import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";

const header_height = 150;
export default class TitleHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const title = this.props.title;
    const sub_title = this.props.sub_title;
    const width = _this.state.width;

    return (
      <ImageBackground
        style={styles.header_container}
        source={UI.HOTEL_RESORT}
        imageFit={ImageFit.COVER}
      >
        <View style={styles.header_text_container}>
          <Text style={styles.search_text}>
            {title}
          </Text>
          <Text style={styles.search_type}>{sub_title}</Text>
        </View>
        <View style={styles.header_tint}></View>
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  header_tint: {
    height: header_height,
    width: "100%",
    marginTop: -header_height,
    backgroundColor: "black",
    opacity: 0.4,
  },
  search_type: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  search_text: {
    fontSize: 26,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  header_text_container: {
    zIndex: 10,
    width: "100%",
    height: header_height,
    justifyContent: "center",
    alignItems: "center",
  },
  header_container: {
    width: "100%",
    height: header_height,
    backgroundColor: "red",
  },
});
  