import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import FilterInputs from "./FilterInputs";

// Context
import AllContext from "../../../shared/Context/AllContext";

export default class SearchContainer extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          ...styles.right_container,
          width: isMobile ? "100%" : 300 + paddingX * 2,
          margin: '0 auto',
        }}
      >
        <Text style={styles.primary_text}>Search</Text>
        {UI.box(30)}
        <FilterInputs _this={_this} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  primary_text: {
    fontWeight: "bold",
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: "flex-start",
  },
  right_container: {
    backgroundColor: "white",
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: "center",
    alignSelf: "flex-start"
  },
});
