import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

export default class Amenities extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          ...styles.main_container,
          alignItems: width <= 650 ? "center" : "flex-start",
        }}
      >
        <Header text={"PROMO AVAILABILITY"} />
        <UI.Row style = {{width: "100%"}} _this={_this} breakpoint={890}>
          <View style = {{flex: 1}}>
            <Text 
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "#434343",
                marginBottom: 15,
              }}
            >Flight Availability</Text>
            <AmenityItem icon = {UI.CAR} text = {"Free on-street parking"} />
            <AmenityItem icon = {UI.BALCONY} text = {"Patio or Balcony"} />
            <AmenityItem icon = {UI.LONG_TERM_STAY} text = {"Long-term stay allowed"} />
          </View>
          <View style = {{flex: 1}}>
            <AmenityItem icon = {UI.AIRCON} text = {"Airconditioning"} />
            <AmenityItem icon = {UI.CAMERA} text = {"Security cameras on property"} />
            <AmenityItem icon = {UI.TELEVISION} text = {"Television"} />
            <AmenityItem icon = {UI.PETS} text = {"Pets Allowed"} />
          </View>
        </UI.Row>
      </View>
    );
  }
}

const AmenityItem = (props = { icon: UI.BED, text: "" }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
      <Image imageFit = {ImageFit.CONTAIN} height = {30} width = {30} tintColor = {"#434343"} source={props.icon} />
      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: "#434343",
          marginLeft: 10,
        }}
      >
        {props.text}
      </Text>
    </View>
  );
};

const Header = (props = { text: "" }) => {
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={styles.header_text}>{props.text}</Text>
        <TouchableOpacity>
          <Text style={styles.header_right}>Show all amenities</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.line}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "#B3B3B3",
    marginTop: 5,
    marginBottom: 20,
  },
  header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
    flex: 1,
  },
  header_right: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#434343",
    textDecoration: "underline",
  },
  main_container: {
    width: "100%",
  },
});
