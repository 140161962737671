// Packages
import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import { v4 as uuidv4 } from 'uuid';

// Components
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SplashPage from './SplashPage';
import BigContainer from './BigContainer';
import SmallContainer from './SmallContainer';
import MediumContainer from './MediumContainer';
import Notification from '../../../shared/Components/Notification/Notification';
import { Modal, Button, Form } from 'react-bootstrap';
// Context
import AllContext from '../../../shared/Context/AllContext';

// CSS
import './MainLanding.scss';
import { emailupdate } from '../../../shared/API/request';

export default class MainLanding extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);

    // Initial State
    // This state is the root of all states in this Route /
    UI.initiateView(this, {
      active_link: 'HOME',
      selected_tab: 'HOTELS',
      type: 'HOTELS',
      active_input: '',
      booking_date: [
        {
          id: uuidv4(),
          check_in_date: '2021-10-14',
          check_in_time: '14:00:00',
          check_out_date: '2021-10-16',
          check_in_time: '11:00:00',
        },
      ],
      modalShow: false,
      emailInput: '',
      id_img_data: '',
      id_img_name: '',
      id_img_uploaded_at: '',
      passport_visa_data1: '',
      passport_visa_name1: '',
      passport_visa_uploaded_at1: '',
      passport_visa_data2: '',
      passport_visa_name2: '',
      passport_visa_uploaded_at2: '',
      passport_visa_data3: '',
      passport_visa_name3: '',
      passport_visa_uploaded_at3: '',
      passport_visa_data4: '',
      passport_visa_name4: '',
      passport_visa_uploaded_at4: '',
      passport_visa_data5: '',
      passport_visa_name5: '',
      passport_visa_uploaded_at5: '',
      search_guest: '1',
      search_room: '1',
    });
  }

  componentWillMount() {
    const { data } = this.context;
    if (data.user.email_verified === 1) {
      this.setState({ modalShow: true });
    }
  }

  // This will fire after clicking search button
  search = () => {
    // get/set data from Context (this data is from AppRouter)
    const { data, setData } = this.context;

    // Check all textbox if they dont have value
    if (
      !this.state.search_city ||
      !this.state.search_check_in ||
      !this.state.search_check_out ||
      !this.state.search_guest
    ) {
      Notification('Please fill in the required textbox', 'danger');
      return false;
    }

    console.log(this.state.search_guest);
    console.log(this.state);
    console.log(this.state.search_room);

    // check valid guest room number
    if (
      this.state.search_guest === '0' ||
      this.state.search_guest === '00' ||
      this.state.search_guest === '000'
    ) {
      Notification('Please input a valid input guess number', 'danger');
      return false;
    }

    if (
      this.state.search_room === '0' ||
      this.state.search_room === '00' ||
      this.state.search_room === '000'
    ) {
      Notification('Please input a valid input room number', 'danger');
      return false;
    }

    // Code will proceed here if the IF function result is false

    // Set State Data
    setData((prevData) => {
      return {
        ...prevData,
        search_results: {
          city: this.state.search_city,
          check_in: this.state.search_check_in,
          check_out: this.state.search_check_out,
          guest: this.state.search_guest,
          room: this.state.search_room,
          type: this.state.type,
        },
      };
    });

    // UI.search is like history.push
    // paramaters set in UI.search will be inserted into the URL
    UI.search('/search-results', {
      city: this.state?.search_city,
      check_in: this.state?.search_check_in,
      check_out: this.state?.search_check_out,
      guest: this.state?.search_guest,
      room: this.state?.search_room,
      type: this.state?.type,
      sort_by: 'price',
    });
  };

  emailSubmitHandler = (e) => {
    e.preventDefault();
    const { data, setData } = this.context;
    console.log(data);
    const params = { userId: data?.user?.id, email: this.state?.emailInput };
    console.log(params);
    emailupdate(params, this.emailupdatecallback);
  };

  emailupdatecallback = async (response) => {
    const { status, data } = await response;
    const { setData } = this.context;
    if (status === 201 || status === 200) {
      console.log(data);
      Notification('Login Successfully', 'success');
      localStorage.setItem('user', JSON.stringify(data.data));
      localStorage.setItem('isAuth', JSON.stringify(true));
      setData((prevState) => {
        return { ...prevState, user: data.data, isAuth: true };
      });

      this.setState({ modalShow: false });
    } else if (status === 400 || status === 401) {
      Notification('Submit Error', 'danger');
    } else {
      Notification('Submit Error', 'danger');
    }
  };

  // To render on screen
  render() {
    // for CSS/Responsiveness Functionality
    const width = this.state.width;
    const paddingX = width * 0.05;

    // set this as _this to be passed in child components
    const _this = this;

    // console.log('MAIN LANDING', _this);

    // To render on screen
    return (
      <>
        {' '}
        <View className='main_container'>
          <ScrollView>
            {/* Header Content */}
            <Header _this={this} />

            {/* Banner Content */}
            <SplashPage _this={this} />

            <View
              style={{
                width: '100%',
                paddingRight: paddingX,
                paddingLeft: paddingX,
              }}
            >
              {/* <BigContainer _this={_this} /> */}
              {/* <SmallContainer _this={_this} /> */}
              {/* <MediumContainer _this={_this} /> */}
            </View>

            <Footer _this={_this} />
          </ScrollView>
        </View>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: true })}
          size='lg'
          centered
        >
          <Form onSubmit={this.emailSubmitHandler}>
            <Modal.Header>
              <Modal.Title className='fw-bold'>Email Verification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='fw-bold mb-4'>Update Email before proceeding</h5>

              <Form.Group
                className='d-flex mt-2'
                style={{ alignItems: 'center' }}
              >
                <span className='me-2'>Email</span>
                <Form.Control
                  type='email'
                  placeholder='Input Email Here'
                  value={this.state.emailInput}
                  onChange={(e) =>
                    this.setState({ emailInput: e.target.value })
                  }
                  style={{ maxWidth: '400px' }}
                />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button className='modal-submit-button' type='submit'>
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
