// Packages
import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Components
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HotelInfo from './HotelInfo';
import HotelReserve from './HotelReserve';
import Availability from './Availability';
import Rooms from './Rooms';
import HotelDescription from './HotelDescription';
import HotelServices from './HotelServices';
import Information from './Information';
import HotelMap from './HotelMap';
import Amenities from './Amenities';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';

// API
import { hotelDetail, getBookmark } from '../../../shared/API/request';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

// Main Component
class HotelDetails extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props, context) {
    super(props);
    // Initial State
    UI.initiateView(this, {
      active_link: 'HOTELS',
      filteredList: [],
      loading: true,
      selected_room: null,
      all_selected_room: [],
      notFound: false,
    });

    // Get id from URL
    const hotelSlug = this.props.match.params.slug;

    // Run function to request api
    this.hotelDetailBySlug(hotelSlug);
    this.getBookmarkStatus(context.data.user.id, hotelSlug);
  }

  // Function to get Bookmark Status
  getBookmarkStatus = (id, slug) => {
    getBookmark(id, slug, this.bookmarkCallback);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_room !== this.state.selected_room) {
      // console.log(this.state.selected_room);
    }
  }

  // Function to run api request
  hotelDetailBySlug = (slug) => {
    hotelDetail(slug, this.callback);
  };

  // Callback after get api for searchhoteltours
  callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          filteredList: [data.data],
          loading: false,
        });
      }

      this.setState({
        meta: data.meta,
      });
    } else if (status === 404) {
      this.setState({
        loading: false,
        notFound: true,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  bookmarkCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      this.setState({
        bookmarkId: data.bookmarkId,
        isBookMarked: data.bookmarkId,
      });
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  gotoTop = () => {
    const el = document.createElement('a');
    el.href = '#top';

    el.click();
  };

  render() {
    // Set this as _this, to be send to child components
    const _this = this;

    // Variables for CSS / Responsive
    const width = this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    // Use Context
    const { data, setData } = this.context;

    const url_id = _this.props.match.params.id;

    // To render on screen
    return (
      <View style={{ ...styles.main_container, scrollBehavior: 'smooth' }}>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

          {/* Hotel Not Found */}
          {this.state.notFound && !this.state.loading && (
            <View style={{ width: width }}>
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingRight: paddingX,
                  marginTop: 50,
                  display: 'flex',
                  height: '15em',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <h2
                    style={{
                      fontSize: 36,
                      fontWeight: 'bold',
                      color: '#006fb9',
                    }}
                  >
                    Hotel Not Found
                  </h2>
                </div>
              </UI.Row>
            </View>
          )}

          {/* Loading... */}
          {this.state.loading && (
            <>
              <View style={{ width: width }}>
                <UI.Row
                  breakpoint={850}
                  _this={this}
                  style={{
                    width: '100%',
                    paddingLeft: paddingX,
                    paddingRight: paddingX,
                    marginTop: 50,
                    display: 'flex',
                    height: '15em',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <h2
                      style={{
                        fontSize: 36,
                        fontWeight: 'bold',
                        color: '#006fb9',
                      }}
                    >
                      Loading...
                    </h2>
                  </div>
                </UI.Row>
              </View>
            </>
          )}

          {/* Hotel Found */}
          {this.state.filteredList && this.state.filteredList.length > 0 && (
            <>
              {/* Banner Images */}
              <View>
                <ScrollView style={{ width: width }} horizontal={true}>
                  <View
                    style={{ flexDirection: 'row', width: width, height: 300 }}
                  >
                    {this.state.filteredList[0].images.map((image) => (
                      <img
                        src={image.file_url}
                        style={{
                          height: 300,
                          width: image_width,
                          objectFit: ImageFit.COVER,
                        }}
                        alt={image.filename}
                      />
                    ))}
                  </View>
                </ScrollView>
              </View>

              {/* Main Content */}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingRight: paddingX,
                  marginTop: 50,
                }}
              >
                {/* For Scrolling to Top */}
                <a id='top'></a>

                {/* Left Content */}
                <View style={{ flexDirection: 'column', flex: 1 }}>
                  <HotelInfo
                    _this={this}
                    filteredList={this.state.filteredList}
                    data={data}
                    hotelDetailBySlug={this.hotelDetailBySlug}
                  />

                  <HotelDescription
                    _this={_this}
                    filteredList={this.state.filteredList}
                  />

                  {/* {UI.box(width <= 830 ? 30 : 50)}
                  <Availability
                    _this={this}
                    filteredList={this.state.filteredList}
                  /> */}

                  {UI.box(width <= 830 ? 30 : 40)}
                  <Rooms _this={this} filteredList={this.state.filteredList} />

                  {UI.box(width <= 830 ? 30 : 40)}
                  <Amenities _this={_this} />
                  {UI.box(width <= 830 ? 30 : 40)}
                </View>
                {UI.box(width <= 830 ? 30 : 100)}

                {/* Right Content */}
                <View style={{ flexDirection: 'column' }}>
                  <HotelReserve
                    _this={this}
                    filteredList={this.state.filteredList}
                  />
                  {/* {UI.box(width <= 830 ? 30 : 50)} */}
                  {/* <Information _this={this} /> */}
                  {UI.box(width <= 830 ? 30 : 30)}
                  {/* <HotelServices _this={this} />
                  {UI.box(width <= 830 ? 30 : 50)} */}
                  <HotelMap
                    _this={this}
                    filteredList={this.state.filteredList}
                  />
                </View>
              </UI.Row>
            </>
          )}

          {/* End Contents Here */}
          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(HotelDetails);

// CSS Styles
const styles = StyleSheet.create({
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
});
