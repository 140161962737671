// Packages
import React, { Component } from 'react';
import IconTint from 'react-icon-tint';

// Components
import UI from '../../../shared/Components/UI/js';

// Assets / CSS
import './TourItems.scss';

export default class TourItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const tourList = this.props.tourList;

    return (
      <div className='tour-cont'>
        {tourList &&
          tourList.length > 0 &&
          tourList.map((destination) => {
            return (
              <TourItem
                _this={_this}
                destination={destination}
              />
            );
          })}
        {!tourList ||
          (!tourList.length > 0 && (
            <div>
              <h2 style={{ fontSize: 16 }}>
                No tours found. Try to search for something else.
              </h2>
            </div>
          ))}
      </div>
    );
  }
}

const TourItem = ({ _this, destination }) => {
  const onClick = () => {
    const search_params = {
      check_in: _this.state.search_check_in,
      check_out: _this.state.search_check_out,
      guest: _this.state.search_guest,
    };

    localStorage.setItem('search_params', JSON.stringify(search_params));
    _this.props.history.push(`/tour-details/${destination?.slug}`);
  };

  return (
    <div
      onClick={onClick}
      className='tour-item'
    >
      <img
        className='tour-image'
        src={destination?.primaryImage}
        alt={destination?.name}
      />

      <div className='tour-details'>
        <div className='first-row'>
          <h3 className='primary-text'>{destination?.name}</h3>

          <div className='icons'>
            <img
              width={20}
              src={UI.STAR}
              alt='star'
              className='star'
            />

            <p className='rating'>{destination?.rating}</p>

            <div
              className='best-value'
              style={{
                display: destination?.isBestValue ? 'flex' : 'none',
              }}
            >
              <IconTint
                src={UI.VERIFIED}
                maxWidth={20}
                maxHeight={20}
                color={'#006fb9'}
              />

              <p className='verified-text'>Best Value</p>
            </div>
          </div>
        </div>

        <div className='line'></div>

        <div className='second-row'>
          <div className='first-line'>
            <div className='location'>
              <IconTint
                src={UI.LOCATION}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-location'>{destination?.location}</p>
            </div>

            <div className='calendar'>
              <IconTint
                src={UI.CALENDAR}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-date'>{destination?.start_date}</p>
            </div>
          </div>

          <div className='second-line'>
            <IconTint
              src={UI.STAR}
              maxWidth={20}
              maxHeight={20}
              color={'#646464'}
            />

            <p className='hotel-address'>{destination?.landmarks}</p>
          </div>
        </div>

        <div className='third-row'>
          <div className='price'>
            <p className='text-header'>Php {destination?.price}</p>

            {destination?.isTaxInclusive && (
              <span className='tax-inclusive'>Inclusive of Taxes</span>
            )}
          </div>

          <p className={`pax-left`}>
            {parseInt(destination?.persons_left) > 0
              ? `${destination?.persons_left} Pax Left!`
              : `Fully Booked`}
          </p>
        </div>
      </div>
    </div>
  );
};
