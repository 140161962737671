import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';

export default class HotelDescription extends Component {
  constructor(props) {
    super(props);
  }

  renderHtmlDetails = (details) => {
    return { __html: details };
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    const { history_data } = _this.state;

    return (
      <View style={styles.main_container}>
        <View style={styles.header_container}>
          <UI.Row _this={_this} breakpoint={480}>
            <Text style={styles.header_text}>DESCRIPTION</Text>
            {UI.box(5)}
            {/* <Text style={styles.sub_text}>- Excellent Location</Text> */}
          </UI.Row>
        </View>
        <div
          style={{ ...styles.description, marginTop: 10 }}
          dangerouslySetInnerHTML={this.renderHtmlDetails(history_data?.historyDetails?.description)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  sub_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434343',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  main_container: {
    width: '100%',
    marginTop: 30,
  },
});
