// Packages
import React, { Component } from 'react';

// Components
import {
  View,
  Text,
  Image,
  ImageBackground,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Switch from 'react-switch';
import Notification from '../../../shared/Components/Notification/Notification';
import moment from 'moment';
// Main Components
export default class BookingDetailsInfoSecond extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    const datenow = new Date().toLocaleString();
    return (
      <View
        styles={{
          ...styles.main_container,
          width: width <= 830 ? '100%' : 400,
        }}
      >
        <UI.Row
          breakpoint={850}
          _this={_this}
        >
          <View
            style={{
              flexDirection: width <= 830.98 ? 'column' : 'row',
              width: width <= 830 ? '100%' : '50%',
            }}
          >
            <View
              style={{
                marginTop: 20,
                paddingRight: paddingX,
                width: width <= 830 ? '100%' : '40%',
              }}
            >
              <Text style={{ ...styles.h3 }}>Enter your Details</Text>

              <View
                style={{
                  flexDirection: width <= 768.98 ? 'column' : 'row',
                  width: width <= 768.98 ? 'auto' : width,
                  height: width <= 768.98 ? 'auto' : 50,
                  marginBottom: 30,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    First Name <Text style={{ color: '#FF0000' }}>*</Text>
                  </Text>
                  <input
                    type='text'
                    id='firstName'
                    value={_this.state.firstname}
                    onChange={(e) =>
                      _this.setState({ firstname: e.target.value })
                    }
                    placeholder='Enter First Name'
                    autoComplete='off'
                    style={{ ...styles.inputText }}
                  />
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    Last Name <Text style={{ color: '#FF0000' }}>*</Text>
                  </Text>
                  <input
                    type='text'
                    id='lastName'
                    value={_this.state.lastname}
                    onChange={(e) =>
                      _this.setState({ lastname: e.target.value })
                    }
                    placeholder='Enter Last Name'
                    autoComplete='off'
                    style={{ ...styles.inputText }}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: width <= 768.98 ? 'column' : 'row',
                  width: width <= 768.98 ? 'auto' : width,
                  height: width <= 768.98 ? 'auto' : 50,
                  marginBottom: 30,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    Email Address <Text style={{ color: '#FF0000' }}>*</Text>
                  </Text>
                  <input
                    type='email'
                    id='email'
                    value={_this.state.email}
                    onChange={(e) => _this.setState({ email: e.target.value })}
                    placeholder='Enter Email Address'
                    autoComplete='off'
                    style={{ ...styles.inputText }}
                  />
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    Confirm Email Address{' '}
                    <Text style={{ color: '#FF0000' }}>*</Text>
                  </Text>
                  <input
                    type='email'
                    id='confirmEmail'
                    value={_this.state.cEmail}
                    onChange={(e) => _this.setState({ cEmail: e.target.value })}
                    placeholder='Confirm Email Address'
                    autoComplete='off'
                    style={{ ...styles.inputText }}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    width: '38%',
                  }}
                >
                  <ColoredLine color='#D3D3D3' />
                </View>
              </View>

              {/* <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 5,
                    paddingRight: paddingX,
                    height: 50,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h3 }}>Is this a work trip? </Text>
                </View>
                <View
                  style={{
                    marginTop: 5,
                    paddingRight: paddingX,
                    height: 50,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Switch
                    onChange={() => _this.workTripHandler(_this.state.workTrip)}
                    checked={_this.state.workTrip}
                    style={{ width: width }}
                  />
                </View>
              </View> */}

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 5,
                  marginBottom: 0,
                }}
              >
                <View
                  style={{
                    marginTop: 5,
                    width: '38%',
                  }}
                >
                  <ColoredLine color='#D3D3D3' />
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginBottom: 30,
                }}
              >
                <View
                  style={{
                    marginTop: 0,
                    paddingRight: paddingX,
                    height: 110,
                    width: width <= 830 ? '100%' : '26%',
                  }}
                >
                  <Text style={{ ...styles.h3, marginBottom: 20 }}>
                    Your Arrival Time{' '}
                  </Text>
                  <Text style={{ ...styles.h5 }}>
                    Add your estimate arrival time. (Optional){' '}
                  </Text>
                  <input
                    min={moment(datenow).format('YYYY-MM-DDThh:mm')}
                    type='datetime-local'
                    onChange={(e) => _this.arrivalTimeHandler(e.target.value)}
                    value={_this.state.arrivalTime}
                    style={{
                      ...styles.inputText,
                      width: width <= 830 ? '80%' : '100%',
                    }}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: 20,
                paddingLeft: width <= 830 ? 0 : paddingX,
                width: width <= 830 ? '100%' : '50%',
              }}
            >
              <View
                style={{
                  flexDirection: width <= 830 ? 'column' : 'row',
                  width: 'auto',
                  minHeight: 200,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    width: width <= 830 ? '100%' : '20%',
                  }}
                >
                  <img
                    height={400}
                    width={300}
                    src={_this.state.booking.hotelArr.primaryImageURL}
                    alt='img'
                    style={{ borderRadius: '10px' }}
                  />
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '50%',
                    paddingLeft: 10,
                  }}
                >
                  <Text style={{ ...styles.h3 }}>
                    Room Hotel in {_this.state.booking.hotelArr.location}
                  </Text>
                  <Text style={{ ...styles.h3 }}>
                    {_this.state.booking.hotelArr.title}
                  </Text>
                  {/* <View style={{ flexDirection: "row" }}>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E', marginRight: 20 }}>Queen Bed: </Text>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E' }}>1 Bath</Text>
                                        </View> */}
                  <View style={{ flexDirection: 'row' }}>
                    <Image
                      height={20}
                      width={20}
                      tintColor={'#FF0000'}
                      source={UI.STAR}
                    />
                    <Text style={{ ...styles.h5, marginLeft: 20 }}>
                      {_this.state.booking.hotelArr.rating} (
                      {_this.state.booking.hotelArr.totalReviews} reviews)
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles.h5,
                      marginTop: '20px',
                      color: '#8E8E8E',
                    }}
                  >
                    This property is in {_this.state.booking.hotelArr.location}.
                  </Text>
                  <Text style={{ ...styles.h5, marginTop: 20 }}>
                    Hotel Amenities
                  </Text>
                  {_this.state.booking &&
                    _this.state.booking.hotelArr.aminities.map((item) => {
                      return (
                        <Text
                          style={{
                            ...styles.h5,
                            color: '#8E8E8E',
                            marginTop: 5,
                          }}
                        >
                          {item.desc}
                        </Text>
                      );
                    })}
                </View>
              </View>

              <View
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
              >
                <ColoredLine color='#D3D3D3' />
              </View>

              <Text style={{ ...styles.h3, marginTop: 30 }}>
                Special Request
              </Text>

              <View
                style={{
                  flexDirection: 'row',
                  width: width <= 830 ? 'auto' : width,
                  height: 50,
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 30,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '50%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    It can't be guaranteed, however the property will do it's
                    best to meet your needs. You can always make a special
                    request after your booking is complete.
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: width <= 830 ? 'auto' : width,
                  height: 50,
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 120,
                    width: width <= 830 ? '100%' : '50%',
                  }}
                >
                  <textarea
                    type='text'
                    id='cardNum'
                    onChange={(e) =>
                      _this.specialRequestHandler(e.target.value)
                    }
                    value={_this.state.specialRequest}
                    placeholder='Please write your request (Optional)'
                    autoComplete='off'
                    style={{ ...styles.inputText, padding: '1rem' }}
                  />
                </View>
              </View>
            </View>
          </View>
        </UI.Row>
      </View>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const styles = StyleSheet.create({
  sub_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434343',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  main_container: {
    width: '100%',
    marginTop: 30,
  },
  h3: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  h5: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  custom_btn: {
    height: 100,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  inputText: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 80,
    width: '100%',
    paddingLeft: 10,
  },
  textArea: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 150,
    width: '100%',
    paddingLeft: 10,
  },
  selectOption: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 80,
    width: 250,
    paddingLeft: 10,
  },
});
