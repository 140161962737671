// Packages
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

// CSS
import './ItemBooking.scss';

// Image
import locationLogo from '../../../shared/assets/images/icon/marker.png';
import StarLogo from '../../../shared/assets/images/icon/star.png';
import CalendarLogo from '../../../shared/assets/images/icon/calendar.png';

// Main Components
const ItemBooking = ({ data, setOpenModal, setModalData }) => {
  const history = useHistory();

  const gotoHotelDetails = () => {
    console.log(data.type);
    if (data.type === 'hotel') {
      history.push(`/hotel-details/${data.slug}`);
    } else if (data.type === 'ferry') {
      history.push(`/ferry-portal`);
    } else {
      history.push(`/tour-details/${data.slug}`);
    }
  };

  return (
    <div
      className='tour-hotel-booking-list'
      onClick={() => gotoHotelDetails()}
    >
      <div className='image'>
        <img
          src={data.primaryImageURL}
          alt={data.title}
        />
      </div>
      <div className='details'>
        <div className='header'>
          <div>
            <p className='title'>{data.title}</p>
          </div>
          <div className='asset'>
            {data.rating && (
              <div>
                {/* Star Logo */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 25.263 24'
                >
                  <path
                    id='icons8_star'
                    d='M16.638,5a.947.947,0,0,0-.854.524L12.53,12.033,4.8,13.222a.947.947,0,0,0-.525,1.606l5.345,5.345-1.19,7.736a.947.947,0,0,0,1.374.984l6.825-3.561,6.825,3.561a.947.947,0,0,0,1.374-.984l-1.19-7.736,5.345-5.345a.947.947,0,0,0-.525-1.606l-7.727-1.189L17.479,5.524A.947.947,0,0,0,16.638,5Z'
                    transform='translate(-4 -5)'
                    fill='#fcc203'
                  />
                </svg>

                <p className='rating'>{data.rating}</p>
              </div>
            )}
            {data.isVerified && (
              <div>
                {/* Verified Logo */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path
                    id='icons8_verified_account_2'
                    d='M16,4a4.454,4.454,0,0,0-3.925,2.414,4.392,4.392,0,0,0-5.66,5.66,4.4,4.4,0,0,0,0,7.85,4.392,4.392,0,0,0,5.66,5.66,4.4,4.4,0,0,0,7.85,0,4.392,4.392,0,0,0,5.66-5.66,4.4,4.4,0,0,0,0-7.85,4.392,4.392,0,0,0-5.66-5.66A4.456,4.456,0,0,0,16,4Zm0,1.8a2.692,2.692,0,0,1,2.6,2,.9.9,0,0,0,1.352.525,2.694,2.694,0,0,1,3.72,3.72A.9.9,0,0,0,24.2,13.4a2.7,2.7,0,0,1,0,5.208.9.9,0,0,0-.525,1.352,2.694,2.694,0,0,1-3.72,3.72A.9.9,0,0,0,18.6,24.2a2.7,2.7,0,0,1-5.208,0,.9.9,0,0,0-1.352-.525,2.694,2.694,0,0,1-3.72-3.72A.9.9,0,0,0,7.8,18.6a2.7,2.7,0,0,1,0-5.208.9.9,0,0,0,.525-1.352,2.694,2.694,0,0,1,3.72-3.72A.9.9,0,0,0,13.4,7.8,2.691,2.691,0,0,1,16,5.8Zm4.482,6.592a.9.9,0,0,0-.619.272L14.5,18.027l-2.364-2.364a.9.9,0,1,0-1.273,1.273l3,3a.9.9,0,0,0,1.273,0l6-6a.9.9,0,0,0-.654-1.545Z'
                    transform='translate(-4 -4)'
                    fill='#006fb9'
                  />
                </svg>

                <p className='verified'>Verified</p>
              </div>
            )}
          </div>
        </div>
        <div className='amenities'>
          <div className='list'>
            <div className='location'>
              <img
                src={locationLogo}
                alt='location logo'
              />
              <p>{data.location || data.location}</p>
            </div>
            {data.date_opened && (
              <div className='date'>
                <img
                  src={CalendarLogo}
                  alt='date logo'
                />
                <p>{data.date_opened}</p>
              </div>
            )}
          </div>
          <div className='list places'>
            <img
              src={StarLogo}
              alt='Places logo'
            />
            <p>{data.places || data.accomodationType}</p>
          </div>
        </div>
        <div className='green'>
          <div className='price'>
            Php {data.totalPrice}{' '}
            {data.isPaid && !data?.hasPendingPayment ? 'Paid' : ''}
            {!data.isPaid && !data?.hasPendingPayment ? 'Not Paid' : ''}
            {!data.isPaid && data?.hasPendingPayment ? 'Pending' : ''}{' '}
            {!data.isPaid && !data?.hasPendingPayment && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(true);

                  setModalData((prevState) => {
                    return {
                      ...prevState,
                      data: data,
                      attachment: '',
                      amount_credited: '',
                      amount: data?.totalPrice,
                      receipt_no: '',
                      transaction_refId: data?.bookingId,
                      transaction_type: data?.type,
                    };
                  });
                }}
                className='pay-now-button ml-3'
              >
                Pay Now
              </Button>
            )}
          </div>
          <div className='schedule'>
            Date: {data.checkIn} | {data.checkOut}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemBooking;
