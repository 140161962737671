import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ReactNotifications } from 'react-notifications-component'

import AppRouter from "./AppRouter";

// CSS
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  return (
    <>
      <ReactNotifications />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </>
  );
}
