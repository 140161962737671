import React from 'react';
import Images from '../Images/js';
import { View } from '../../custom-react-native';

class ui extends Images {
  search = async (url, parameters = {}) => {
    let add_to_link = await this.encodeParams(parameters);
    url = url + '?' + add_to_link;
    this.goTo(url);
  };

  encodeParams = (parameters = {}) => {
    return new Promise((resolve, reject) => {
      let new_query_string = '';
      let index = 0;
      let parameter_array = [];
      let param_keys = Object.keys(parameters);
      if (param_keys.length == 0) {
        resolve('');
      }
      param_keys.map((param_key) => {
        index++;
        let param_value = parameters[param_key];
        if (!param_value) {
          param_value = '';
        }
        parameter_array.push(param_key + '=' + param_value);
        if (index == param_keys.length) {
          new_query_string = parameter_array.join('&');
          resolve(new_query_string);
        }
      });
    });
  };

  // goTo = (url) => {
  //   window.location.href = url;
  // };

  goTo = (url) => {
    this._this.props.history.push(url);
  };

  titleCase = (string) => {
    if (string) {
      const mySentence = string.toLowerCase();

      return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase(),
      );
    }
  };

  longDatePattern = (date) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('en-us', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  findNumbers = (string) => {
    let r = /\d+/g;
    var s = string;
    var m;
    while ((m = r.exec(s)) != null) {
      return m[0];
    }
  };

  currencyFormat = (num) => {
    return '₱ ' + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  computeProduct = (num1, num2) => {
    let product = parseFloat(num1) * parseFloat(num2);

    return '₱ ' + product.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  computeProductWithServiceFee = (num1, num2, serviceFee) => {
    let product = parseFloat(num1) * parseFloat(num2);
    product = parseFloat(product) + parseFloat(serviceFee);
    return '₱ ' + product.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  time12hrPattern = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  };

  getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
  };
  getHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight,
    );
  };

  colors = {
    primary: 'rgba(0, 111, 185, 1)',
    secondary: '#0099FF',
    success: '#0BC175',
    black: '#3A3A3A',
    yellow: '#FCC203',
  };
  box = (size) => {
    return <div style={{ height: size, width: size }}></div>;
  };

  PadView = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View
        style={{
          width: '100%',
          paddingRight: paddingX,
          paddingLeft: paddingX,
          ...props.style,
        }}
      >
        {props.children}
      </View>
    );
  };

  resize(_this) {
    const width = this.getWidth();
    if (width !== _this.state.width) {
      _this.setState({
        width: width,
        height: this.getHeight(),
      });
      this.checkIfMobile(_this);
    }
  }

  checkIfMobile = (_this) => {
    let width = _this.state.width;
    if (width < 720) {
      _this.setState({
        isMobile: true,
      });
    } else {
      _this.setState({
        isMobile: false,
      });
    }
  };

  initiateView = (_this, initialState = {}) => {
    this._this = _this;

    _this.state = {
      width: this.getWidth(),
      height: this.getHeight(),
      isMobile: this.getWidth() < 720 ? true : false,
      ...initialState,
    };

    _this.componentDidMount = () => {
      window.addEventListener('resize', () => {
        this.resize(_this);
      });
    };

    _this.componentWillUnmount = () => {
      window.removeEventListener('resize', () => {
        this.resize(_this);
      });
    };
  };

  Row = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let flexDirection = 'row';
    if (isMobile) {
      flexDirection = 'column';
    } else {
      flexDirection = 'row';
    }
    if (props.breakpoint != undefined) {
      const width = _this.state.width;
      if (width <= props.breakpoint) {
        flexDirection = 'column';
      } else {
        flexDirection = 'row';
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };
}

const UI = new ui();
export default UI;
