import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import LoginForm from './LoginForm';


export default class LoginDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, { active_link: "" });
  }

  signup = () => {
    UI.goTo("/signup");
  }

  terms = () => {
    UI.goTo("/terms-of-use");
  }

  forgotpass = () => {
    UI.goTo("/forgotpass");
  }

  privacy_policy = () => {
    UI.goTo("/privacy-policy");
  }

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    const responseGoogle = response => {
      console.log(response);
    };

    const responseFacebook = (response) => {
      console.log(response);
    }

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

          <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              height: isMobile ? 1200 : 800,
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              padding: isMobile ? 20 : 60,
              justifyContent: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignSelf: isMobile ? "center" : "flex-start",
                width: 480,
                height: "100%",
              }}
            >
              <div style={{backgroundColor: "#F2F2F2", margin: "auto"}}>
                <h1 style={{...styles.hotel_title, alignSelf: "center"}}>404 Page Not Found</h1>
              </div>
            </View>
          </UI.Row>

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);

const styles = StyleSheet.create({
  header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: "1px solid #d9d9d9",
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: "1px solid #d9d9d9",
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f3f3f3",
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: "bold",
  },
  custom_btn_text: {
    color: "white",
    fontSize: 13,
    fontWeight: "bold",
  },
  main_text: {
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
  },
  submain_text: {
    color: "black",
    fontWeight: "bold",
  },
  label: {
    color: "black",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: "bold",
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: "bold",
  },
});
