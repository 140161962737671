// Packages
import React, { Component } from 'react';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { forgotpass, resetpassword } from '../../../shared/API/request';
import Notification from '../../../shared/Components/Notification/Notification';

// Main Component
export default class ResetPass extends Component {
  constructor(props) {
    super(props);
    // Initial State
    UI.initiateView(this, { active_link: '', email: '', emailCheck: true });
    // this.state = { active_link: '', email: '' };
  }
  getEmail = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');
    console.log(email);
    this.setState({
      email: email,
      emailCheck: false,
      password: '',
      confirm_password: '',
    });
  };
  // Will redirect to terms and conditions
  terms = () => {
    UI.goTo('/signup');
  };

  // Will redirect to privacy Policy
  privacy_policy = () => {
    UI.goTo('/signup');
  };

  onSubmitHandler(e) {
    e.preventDefault();

    const { password, confirm_password } = this.state;

    if (password !== confirm_password) {
      Notification('Password and confirm password are not the same.', 'danger');
      return;
    }

    if (password.length < 7) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger'
      );
      return;
    }

    if (!this.hasNumber(password)) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger'
      );
      return;
    }

    if (!this.hasSpecialCharacter(password)) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger'
      );
      return;
    }

    if (!this.stringCheck(password)) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger'
      );
      return;
    }

    const newformdata = new FormData();
    newformdata.append('email', this.state.email);
    newformdata.append('password', this.state.password);
    newformdata.append('password_confirmation', this.state.confirm_password);
    // const params = { email: this.state.email };

    resetpassword(newformdata, this.callback.bind(this));
  }

  hasNumber = (str) => {
    return /\d/.test(str);
  };

  hasSpecialCharacter = (str) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    return format.test(str);
  };

  stringCheck = (str) => {
    const upper = /[A-Z]/.test(str);
    const lower = /[a-z]/.test(str);

    return upper && lower;
  };

  async callback(res) {
    const { data, status } = await res;
    if (res) {
      console.log(status);
      if (status === 200 || status === 201 || status === 202) {
        Notification('Successfully submitted!', 'success');
        this.setState({ password: '', confirm_password: '' });
        this.props.hotelDetailBySlug(this.state.filteredList.slug);
      }

      if (status === 401 || status === 422) {
        Notification('An Error Occured', 'danger');
      }
    }
  }

  render() {
    // Set this as _this, to be send to child components
    const _this = this;

    // Variables for CSS / Responsive
    const width = this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    if (this.state.emailCheck) {
      this.getEmail();
    }
    // To render on screen
    return (
      <form onSubmit={this.onSubmitHandler.bind(this)}>
        <View style={styles.main_container}>
          <ScrollView>
            <Header _this={this} />
            {/* Contents Here */}

            <UI.Row
              breakpoint={850}
              _this={this}
              style={{
                height: isMobile ? 1200 : 990,
                width: '100%',
                flexDirection: isMobile ? 'column' : 'row',
                padding: isMobile ? 20 : 60,
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignSelf: isMobile ? 'center' : 'flex-start',
                  width: 480,
                  height: '100%',
                }}
              >
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'white',
                    borderRadius: 20,
                    padding: isMobile ? 15 : 40,
                    borderRadius: 0,
                    marginTop: isMobile ? '' : '5%',
                  }}
                >
                  <Text
                    style={{
                      ...styles.main_text,
                      textAlign: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    Password Recovery
                  </Text>
                  <Text
                    style={{
                      ...styles.submain_text,
                      textAlign: isMobile ? 'center' : 'flex-start',
                      fontSize: isMobile ? 11 : 16,
                      marginLeft: isMobile && '20%',
                      marginRight: isMobile && '20%',
                    }}
                  >
                    Please enter required details
                  </Text>
                  {UI.box(20)}
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    Email Address
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='email'
                      name='email'
                      readOnly
                      value={this.state.email}
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '60%' : '100%',
                      }}
                      placeholder={'Enter Email Address Here'}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </View>
                  {UI.box(20)}
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    Password
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='password'
                      name='password'
                      value={this.state.password}
                      required
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '60%' : '100%',
                        letterSpacing: '3.2px',
                      }}
                      // placeholder={'Enter Password Here'}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </View>
                  {UI.box(20)}
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    Confirm Password
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='password'
                      name='confirm_password'
                      value={this.state.confirm_password}
                      required
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '60%' : '100%',
                        letterSpacing: '3.2px',
                      }}
                      // placeholder={'Enter Confirm Password Here'}
                      onChange={(e) =>
                        this.setState({ confirm_password: e.target.value })
                      }
                    />
                  </View>
                  {UI.box(20)}
                  <button
                    type='submit'
                    style={{ backgroundColor: 'rgb(0, 111, 185)' }}
                    className=' text-white py-2 '
                  >
                    SEND
                  </button>
                </View>
              </View>
            </UI.Row>

            <Footer _this={_this} />
          </ScrollView>
        </View>
      </form>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const FilterCheckbox = (
  props = { _this: null, id: '', text: '', results: '' }
) => {
  const id = 'filter_' + props.id;
  let state_checked = props._this.state['checked_' + props.id];
  const onClick = () => {
    let value = document.getElementById(id).checked;
    document.getElementById(id).checked = !value;
    props._this.setState({
      ['checked_' + props.id]: !value,
    });
  };

  return (
    <View style={styles.filter_checkbox}>
      <TouchableOpacity onClick={onClick} style={styles.checkbox_container}>
        <input id={id} type={'checkbox'} style={styles.checkbox} />
        <View style={{ marginLeft: -17, marginTop: 0.5 }}>
          <Image height={14} width={14} tintColor='white' source={UI.CHECK} />
        </View>
        {UI.box(10)}
        <Text style={styles.filter_checkbox_text}>{props.text}</Text>
      </TouchableOpacity>
      <Text style={styles.filter_checkbox_text}>{props.results}</Text>
    </View>
  );
};

// CSS Styles
const styles = StyleSheet.create({
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 0,
    paddingRight: 30,
    marginBottom: 5,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  main_text: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
  },
  submain_text: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
});
