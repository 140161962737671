// Packages
import React, { Component } from "react";

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

// css
import './MediumContainer.scss';

// Sample API
const dataAPI = [
  {
    id: 1,
    title: "Hotels",
    subtitle: "800 Hotels",
    image: "/img/hotels.jpg",
    icon: "/img/icon_hotels.png",
  },
  {
    id: 2,
    title: "Apartments",
    subtitle: "800 Apartments",
    image: "/img/apartments.jpg",
    icon: "/img/icon_apartments.png",
  },
  {
    id: 3,
    title: "Resorts",
    subtitle: "800 Resorts",
    image: "/img/resorts.jpg",
    icon: "/img/icon_resorts.png",
  },
  {
    id: 4,
    title: "Villas",
    subtitle: "800 Villas",
    image: "/img/villas.jpg",
    icon: "/img/icon_villas.png",
  },
  {
    id: 5,
    title: "Cabins",
    subtitle: "800 Cabins",
    image: "/img/cabins.jpg",
    icon: "/img/icon_Cabins.png",
  },
];

// Main Component
export default class MediumContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // To render on screen
  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View style={{ marginTop: 30, width: "100%" }}>
        <span className="header-text">Browse by Property Type</span>
        <span className='sub-text'>
          These popular destinations have a lot to offer
        </span>
        {UI.box(30)}

        <View
          style={{
            flexDirection: _this.state.isMobile ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          {dataAPI &&
            dataAPI.map((location) => {
              return (
                <MediumItem
                  className="property-list"
                  key={location.id}
                  _this={_this}
                  main={location.title}
                  sub={location.subtitle}
                  image={location.image}
                  icon={location.icon}
                />
              );
            })}
        </View>
      </View>
    );
  }
}

// Medium Component
const MediumItem = (
  props = { _this: null, main: "", sub: "", image: "", icon: "" }
) => {
  const _this = props._this;
  const width = _this.state.width;

  const paddingX = width * 0.05;
  let container_width = width - paddingX * 2;
  let item_width = container_width / 5 - 30;
  let margin = 0;

  if (_this.state.isMobile) {
    item_width = container_width * 0.7;
    margin = paddingX * 1.2;
  }
  let icon_size = item_width * 0.5;

  return (
    <TouchableOpacity
      style={{ marginRight: 30, width: item_width, marginBottom: margin }}
      className={props.className}
    >
      <ImageBackground
        source={props.image}
        style={{
          height: item_width,
          width: item_width,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: item_width,
            width: item_width,
            backgroundColor: "black",
            opacity: 0.2,
          }}
        ></View>
        <View
          style={{
            height: item_width,
            width: item_width,
            marginTop: -item_width,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <ImageBackground
            imageFit={ImageFit.CONTAIN}
            source={props.icon}
            style={{ height: icon_size, width: icon_size }}
          />
        </View>
      </ImageBackground>
      {UI.box(30)}

      <Text style={{ fontSize: 20, fontWeight: "bold", color: "#707070" }}>
        {props.main}
      </Text>
      <Text style={{ fontSize: 17, fontWeight: "bold", color: "#B8B8B8" }}>
        {props.sub}
      </Text>
    </TouchableOpacity>
  );
};

