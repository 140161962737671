// Packages
import Item from 'antd/lib/list/Item';
import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Card,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
// Components
import ItemBooking from './ItemBooking';
import Notification from '../../../shared/Components/Notification/Notification';

// API
import { getCurrentBookings, submitPayment } from '../../../shared/API/request';

// Context
import AllContext from '../../../shared/Context/AllContext';

// CSS
import './Main.scss';

// /api/admin/upcoming-bookings/

// Main Component
const Main = () => {
  // State
  const [bookings, setBookings] = useState([]);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);

  // Context Values
  const { data, setData } = useContext(AllContext);
  const [disabledbtnPay, setdisabledbtnPay] = useState(false);
  const [disabledbtn, setdisabledbtn] = useState(false);
  const [modalData, setModalData] = useState({
    guestId: data?.user?.id,
    method: 'bank-deposit',
    transaction_type: '',
    transaction_refId: '',
    receipt_no: '',
    amount: '',
    amount_credited: '',
    attachment: '',
  });
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newformdata = new FormData();
    const {
      guestId,
      method,
      transaction_type,
      transaction_refId,
      receipt_no,
      amount,
      amount_credited,
      attachment,
    } = modalData;

    if (amount_credited === '' || receipt_no === '' || attachment === '') {
      Notification('Fill in the required fields', 'danger');
      return;
    }

    newformdata.append('guestId', guestId);
    newformdata.append('method', method);
    newformdata.append('transaction_type', transaction_type);
    newformdata.append('transaction_refId', transaction_refId);
    newformdata.append('receipt_no', receipt_no);
    newformdata.append('amount', amount);
    newformdata.append('amount_credited', amount_credited);
    newformdata.append('amount_credited', amount_credited);
    newformdata.append('attachment', attachment);
    setdisabledbtnPay(true);
    submitPayment(newformdata, paymentCallback);
  };

  useEffect(() => {
    getCurrentBookings(data.user.id, `?page=${page}`, callback);
  }, []);

  const callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      setdisabledbtn(false);
      if (data.data) {
        setBookings(data.data);
        setMeta(data.meta);
      }
    } else if (status === 400) {
      setdisabledbtn(false);
      Notification('An Error Occured', 'danger');
    } else {
      setdisabledbtn(false);
      Notification('An Error Occured', 'danger');
    }
  };

  const paymentCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200 || status === 202) {
      setdisabledbtnPay(false);
      Notification('Payment successfully submitted', 'success');
      setModalData({
        ...modalData,
        method: 'bank-deposit',
        transaction_type: '',
        transaction_refId: '',
        receipt_no: '',
        amount: '',
        amount_credited: '',
        attachment: '',
      });

      setOpenModal(false);
      getCurrentBookings(modalData.guestId, `?page=${page}`, callback);
    } else if (status === 400) {
      setdisabledbtnPay(false);
      Notification('An Error Occured', 'danger');
    } else {
      setdisabledbtnPay(false);
      Notification('An Error Occured', 'danger');
    }
  };

  const nextPage = () => {
    setdisabledbtn(true);
    const nxtPage = page + 1;
    getCurrentBookings(data.user.id, `?page=${nxtPage}`, callback);
    setPage(page + 1);
  };

  const prevPage = () => {
    setdisabledbtn(true);
    const prevPage = page - 1;
    getCurrentBookings(data.user.id, `?page=${prevPage}`, callback);
    setPage(page - 1);
  };

  return (
    <>
      <div className='my-booking'>
        <Container
          fluid
          style={{ padding: 0, margin: 0 }}
        >
          <Row>
            <Col>
              <div className='banner'>
                <div className='banner-text'>
                  <h1>UPCOMING BOOKINGS</h1>
                  <h6 className='text-white'>HOTEL / PROFILE / MY BOOKINGS</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className='main-page'
        >
          <Row className='mb-2'>
            <Col>
              <div className='item-container'>
                {!bookings && (
                  <div className='loading-scene'>
                    <p>Loading...</p>
                  </div>
                )}
                {bookings && bookings.length === 0 && (
                  <div className='loading-scene '>
                    <p className='no-booking'>
                      You currently have no upcoming bookings
                    </p>
                  </div>
                )}
                {bookings &&
                  bookings.length > 0 &&
                  bookings.map((api) => {
                    return (
                      <ItemBooking
                        data={api}
                        setOpenModal={setOpenModal}
                        setModalData={setModalData}
                      />
                    );
                  })}
              </div>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='text-center'>
              <View
                style={{
                  flexDirection: 'row',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                {meta && (
                  <>
                    {meta.current_page !== meta.from && (
                      <button
                        className='pagination_btn'
                        onClick={() => prevPage()}
                        disabled={disabledbtn}
                      >
                        <Image
                          height={15}
                          width={15}
                          tintColor={'#646464'}
                          source={UI.CHEVRON_LEFT}
                        />
                      </button>
                    )}
                    <View className='pagination_btn'>
                      <span className='pagination_btn_text'>
                        Showing Page {meta ? meta.current_page : '0'} of{' '}
                        {meta ? meta.last_page : '0'}
                      </span>
                    </View>
                    {meta.current_page !== meta.last_page && (
                      <button
                        className='pagination_btn'
                        onClick={() => nextPage()}
                        disabled={disabledbtn}
                      >
                        <Image
                          height={15}
                          width={15}
                          tintColor={'#646464'}
                          source={UI.CHEVRON_RIGHT}
                        />
                      </button>
                    )}
                  </>
                )}
              </View>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal */}
      <Modal
        className='form-modal payment-modal'
        size='lg'
        show={openModal}
        onHide={() => {
          setOpenModal(false);
        }}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Payment for{' '}
              {`${
                modalData?.data?.title
                  ? modalData?.data?.title
                  : modalData?.data?.historyDetails?.title
              } (P ${
                modalData?.data?.totalPrice
                  ? modalData?.data?.totalPrice
                  : modalData?.data?.paymentDetails?.grandtotal
              })`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='mb-3 mt-3'>
              <Col>
                <Form.Group controlId='formReceiptNo'>
                  <Form.Label className='label'>Receipt No</Form.Label>
                  <Form.Control
                    className='form_input'
                    type='text'
                    placeholder='Enter Receipt No'
                    value={modalData.receipt_no}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        receipt_no: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId='formAmount'>
                  <Form.Label className='label'>Amount Paid</Form.Label>
                  <Form.Control
                    className='form_input'
                    type='text'
                    placeholder='Enter Amount Paid'
                    value={modalData.amount_credited}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');

                      if (value > 10000000) {
                        return;
                      }

                      setModalData({
                        ...modalData,
                        amount_credited: value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Form.Group
                className='mb-3 d-flex align-items-center'
                controlId='formAmount'
              >
                <div className='upload-label'>
                  <label htmlFor='input_img'>
                    <i class='fas fa-upload'></i>&nbsp; Upload Attachment
                  </label>
                </div>

                <div className='ml-4 file-name'>
                  {modalData?.attachment && modalData?.attachment?.name}
                </div>

                <input
                  type='file'
                  className='attach-file'
                  id='input_img'
                  accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps'
                  onChange={(e) => {
                    let docuArr = [];
                    console.log(docuArr);
                    Array.from(e.target.files).forEach((file) => {
                      docuArr.push(file);
                    });
                    console.log(docuArr[0]);
                    setModalData({
                      ...modalData,
                      attachment: docuArr[0],
                    });
                  }}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='primary'
              disabled={disabledbtnPay}
            >
              Pay Now
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Main;
