import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';

export default class SearchItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDestination = (props = { _this: null, id: '' }) => {
    const _this = props._this;

    console.log('PROPSS', props);

    return (
      <>
        <SearchItem
          _this={_this}
          id={props.id ? props.id : ''}
          image={
            props.primaryImageURL
              ? props.primaryImageURL
              : props.primaryImage
              ? props.primaryImage
              : ''
          }
          title={props.title ? props.title : props.name ? props.name : ''}
          rating={props.rating ? props.rating : ''}
          isVerified={props.isVerified ? props.isVerified : false}
          isBestValue={props.isBestValue ? props.isBestValue : false}
          location={props.location ? props.location : ''}
          date_opened={
            props.date_opened
              ? props.date_opened
              : props.start_date
              ? props.start_date
              : ''
          }
          places={
            props.places ? props.places : props.landmarks ? props.landmarks : ''
          }
          price={props.price ? props.price : ''}
          isInclusive={props.isTaxInclusive ? props.isTaxInclusive : false}
          room_left={props.room_left ? props.room_left : ''}
          persons_left={props.persons_left ? props.persons_left : ''}
          slug={props.slug ? props.slug : ''}
        />
        {/* <h1>{UI.HOTELS_2}</h1> */}
      </>
    );
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    const filteredDestinations = _this.state.filteredDestinations;

    const sp = new URLSearchParams(window.location.search);
    const type = sp.get('type');

    return (
      <View style={{ flex: 1 }}>
        {filteredDestinations &&
          filteredDestinations.length > 0 &&
          filteredDestinations.map((destination) => {
            return this.renderDestination({
              ...destination,
              _this,
            });
          })}
        {(filteredDestinations === null ||
          filteredDestinations.length === 0) && (
          <div>
            <h2 style={{ fontSize: 16 }}>
              No {type?.slice(0, -1).toLowerCase()} found. Try to search for
              something else.
            </h2>
          </div>
        )}
      </View>
    );
  }
}

const SearchItem = (
  props = {
    _this: null,
    id: null,
    title: '',
    rating: '',
    isVerified: true,
    isBestValue: true,
    location: '',
    date_opened: '',
    places: '',
    price: '',
    isInclusive: false,
    room_left: '',
    persons_left: '',
    image: '',
    slug: '',
  }
) => {
  const _this = props._this;
  const width = _this.state.width;
  const isMobile = _this.state.isMobile;

  const id = props.id;
  const sp = new URLSearchParams(window.location.search);
  const search_type = sp.get('type');

  const onClick = () => {
    if (search_type === 'HOTELS') {
      UI.goTo(`/hotel-details/${props.slug}`);
    } else {
      UI.goTo(`/tour-details/${props.slug}`);
    }
  };

  return (
    <TouchableOpacity onClick={onClick} style={styles.hotel_item}>
      <ImageBackground
        imageFit={ImageFit.COVER}
        style={styles.hotel_image}
        source={props.image}
      />
      <View style={styles.flex}>
        <View
          style={{
            ...styles.hotel_top_container,
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Text style={styles.primary_text}>{props.title}</Text>
          <View style={styles.flex}></View>
          <View style={styles.fl_ai}>
            <Image
              height={20}
              width={20}
              tintColor={UI.colors.yellow}
              source={UI.STAR}
            />
            {UI.box(5)}
            <Text style={styles.rating_text}>{props.rating}</Text>

            {UI.box(15)}
            {/* Verified */}
            <View
              style={{
                flexDirection: 'row',
                display: props.isVerified ? 'flex' : 'none',
              }}
            >
              <Image
                height={20}
                width={20}
                tintColor={UI.colors.primary}
                source={UI.VERIFIED_ICON}
              />
              {UI.box(5)}
              <Text style={styles.verified_text}>Verified</Text>
            </View>

            {UI.box(15)}
            {/* Best Value */}
            <View
              style={{
                flexDirection: 'row',
                display: props.isBestValue ? 'flex' : 'none',
              }}
            >
              <Image
                height={20}
                width={20}
                tintColor={UI.colors.primary}
                source={UI.VERIFIED}
              />
              {UI.box(5)}
              <Text style={styles.verified_text}>Best Value</Text>
            </View>
          </View>
        </View>
        <View style={styles.hotel_line}></View>
        <View style={styles.hotel_center}>
          <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <View
              style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
            >
              <Image
                height={20}
                width={20}
                tintColor={'#646464'}
                source={UI.LOCATION}
              />
              {UI.box(5)}
              <Text style={styles.hotel_location}>{props.location}</Text>
            </View>
            <View style={styles.hotel_calendar}>
              <Image
                height={20}
                width={20}
                tintColor={'#646464'}
                source={UI.CALENDAR}
              />
              {UI.box(5)}
              <Text style={styles.hotel_date}>{props.date_opened}</Text>
            </View>
          </View>
          {UI.box(10)}
          <View style={styles.hotel_address_container}>
            <Image
              height={20}
              width={20}
              tintColor={'#646464'}
              source={UI.STAR}
            />
            {UI.box(5)}
            <Text style={styles.hotel_address}>{props.places}</Text>
          </View>
        </View>
        <View
          style={{
            ...styles.hotel_bottom,
            flexDirection: width < 540 ? 'column' : 'row',
          }}
        >
          <View
            style={{
              ...styles.hotel_bottom_content,
              flexDirection: width < 540 ? 'column' : 'row',
            }}
          >
            <Text style={styles.primary_white}>Php {props.price}</Text>
            {UI.box(10)}
            {props.isInclusive && (
              <Text style={styles.hotel_bottom_sub}>Inclusive of Taxes</Text>
            )}
          </View>

          <Text style={styles.primary_white}>
            {search_type === 'HOTELS' && (
              <>
                {parseInt(props?.room_left) > 0
                  ? `${props?.room_left} Room Left!`
                  : `Fully Booked`}
              </>
            )}
            {search_type === 'TOURS' && (
              <>
                {parseInt(props?.persons_left) > 0
                  ? `${props?.persons_left} Pax Left!`
                  : `Fully Booked`}
              </>
            )}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  hotel_bottom_sub: {
    color: 'white',
    fontSize: 12,
  },
  hotel_bottom_content: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  hotel_bottom: {
    backgroundColor: '#43980F',
    borderBottomRightRadius: 10,
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: 10,
  },
  hotel_address: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_address_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_calendar: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_location: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_center: {
    flex: 1,
    padding: 15,
  },
  hotel_line: {
    height: 1,
    width: '100%',
    backgroundColor: '#E8E8E8',
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
  rating_text: {
    fontSize: 14,
    color: UI.colors.yellow,
    fontWeight: 'bold',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  hotel_top_container: {
    alignSelf: 'stretch',
    padding: 15,
  },
  hotel_image: {
    height: '100%',
    width: 150,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    minWidth: 150,
  },
  hotel_item: {
    alignSelf: 'stretch',
    borderRadius: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    marginBottom: 20,
  },
  primary_white: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
  primary_text: {
    fontWeight: 'bold',
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: 'flex-start',
  },
});
