import React, { useEffect, useState } from 'react';
import { Form, Row, InputGroup, FormControl, Button } from 'react-bootstrap';
import { GrMapLocation } from 'react-icons/gr';
import { GoCalendar } from 'react-icons/go';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
// scss
//components
import onewayImage from './assets/Group341.png';
import { useForm } from 'react-hook-form';
import twoway from './assets/Group342.png';
import './InformationContent.scss';
import { ferryinquire } from '../../shared/API/request';
import Notification from '../../shared/Components/Notification/Notification';
import moment from 'moment';
function InformationContent({ paddingX }) {
  // Useform
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({});

  // number of passengers

  const [oneway, setOneway] = useState(false);
  const [roundtrip, setRoundtrip] = useState(false);
  const [mindate, setMindate] = useState('');
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [userSubmit, setUserSubmit] = useState({
    ferry_type: '',
    firstname: '',
    lastname: '',
    email: '',
    middlename: '',
    contact_no: '',
    gender: '',
    origin: '',
    destination: '',
    onward_date: '',
    return_date: '',
    total_children: 0,
    total_adult: 0,
    total_senior: 0,
    total_student: 0,
    total_disable: 0,
    additional_remarks: '',
    documents: '',
    trip_type: '',
  });
  useEffect(() => {
    const datenow = new Date().toLocaleString();

    const date = moment(datenow).format('YYYY-MM-DDThh:mm');

    setMindate(date);
  }, []);
  const submitHandler = () => {
    const api = '/api/submit-ferry-inquiry';
    const newformdata = new FormData();

    const {
      total_children,
      total_adult,
      total_senior,
      total_student,
      total_disable,
    } = userSubmit;

    if (
      total_children === 0 &&
      total_adult === 0 &&
      total_senior === 0 &&
      total_student === 0 &&
      total_disable === 0
    ) {
      Notification('Need atleast one passenger', 'danger');
      return;
    }

    if (oneway) {
      newformdata.append('trip_type', 'one-way');
    }
    if (roundtrip) {
      newformdata.append('trip_type', 'round-trip');
    }
    newformdata.append('ferry_type', 'ocean-jet');
    newformdata.append('firstname', userSubmit.firstname);
    newformdata.append('lastname', userSubmit.lastname);
    newformdata.append('email', userSubmit.email);
    newformdata.append('contact_no', userSubmit.contact_no);
    newformdata.append('gender', userSubmit.gender);
    newformdata.append('origin', userSubmit.origin);
    newformdata.append('destination', userSubmit.destination);
    newformdata.append('onward_date', userSubmit.onward_date);
    newformdata.append('return_date', userSubmit.return_date);
    newformdata.append('total_children', userSubmit.total_children);
    newformdata.append('total_adult', userSubmit.total_adult);
    newformdata.append('total_senior', userSubmit.total_senior);
    newformdata.append('total_student', userSubmit.total_student);
    newformdata.append('total_pwd', userSubmit.total_disable);
    newformdata.append('additional_remarks', userSubmit.additional_remarks);
    for (let i = 0; i < userSubmit.documents.length; i++) {
      newformdata.append(`documents[${i}]`, userSubmit.documents[i]);
    }
    setDisabledBtn(true);
    ferryinquire(newformdata, api, callback);
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setDisabledBtn(false);
        Notification(data.msg, 'success');
        setUserSubmit((prev) => {
          return {
            ...prev,
            onward_Date: '',
            return_date: '',
            contact_no: '',
            firstname: '',
            lastname: '',
            gender: '',
            birthdate: '',
            id_type: '',
            id_number: '',
            email: '',
            address_street: '',
            address_brgy: '',
            address_city: '',
            address_province: '',
            zipcode: '',
            plan_type: '',
            city: '',
            insurance_type: '',
            origin: '',
            destination: '',
            total_children: 0,
            total_adult: 0,
            total_senior: 0,
            total_disable: 0,
            total_student: 0,
            documents: '',
            additional_remarks: '',
          };
        });
        setValue('onward_date', '');
        setValue('return_date', '');
        setValue('contact_no', '');
        setValue('firstname', '');
        setValue('lastname', '');
        setValue('gender', '');
        setValue('birthdate', '');
        setValue('id_type', '');
        setValue('id_number', '');
        setValue('email', '');
        setValue('address_street', '');
        setValue('address_brgy', '');
        setValue('address_city', '');
        setValue('address_province', '');
        setValue('zipcode', '');
        setValue('plan_type', '');
        setValue('city', '');
        setValue('insurance_type', '');
        setValue('middlename', '');
        setValue('age', '');
        setValue('destination', '');
        setValue('origin', '');
        setValue('additional_remarks', '');
      }

      if (status === 401 || status === 422) {
        setDisabledBtn(false);
        if (data?.errors?.trip_type) {
          Notification(data?.errors?.trip_type, 'danger');
        }
        if (data?.errors['documents.1']) {
          Notification(
            'The document must not be greater than 1000 kilobytes.',
            'danger',
          );
        }
      }
    }
  };

  return (
    <div
      className='container '
      style={{ marginTop: '5rem', marginBottom: '5rem' }}
    >
      <Form onSubmit={handleSubmit(submitHandler)}>
        <div className='shadow-lg bg-white py-5'>
          <p className='px-5 titleHeader'>Fill up necessary informations </p>
          <hr />

          <div className='  ferryInformation'>
            {/*first number */}
            <Row className='mt-5'>
              <div className='col-lg-4'>
                <Form.Group
                  className='mb-5'
                  controlId='name'
                >
                  <Form.Label>
                    First Name
                    {errors.firstname ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.firstname.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='First Name'
                    {...register('firstname', {
                      required: '(Required)',
                      pattern: {
                        value: /^[a-zA-Z ]*$/g,
                        message: 'This field may only contain letters',
                      },
                      value: userSubmit.firstname,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            firstname: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
              <div className='col-lg-4'>
                <Form.Group
                  className='mb-5'
                  controlId='name'
                >
                  <Form.Label>
                    Middle Name
                    {errors.middlename ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.middlename.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Middle Name'
                    {...register('middlename', {
                      pattern: {
                        value: /^[a-zA-Z ]*$/g,
                        message: 'This field may only contain letters',
                      },
                      value: userSubmit.middlename,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            middlename: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
              <div className='col-lg-4'>
                <Form.Group
                  className='mb-5'
                  controlId='name'
                >
                  <Form.Label>
                    Last Name{' '}
                    {errors.lastname ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.lastname.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Last Name'
                    {...register('lastname', {
                      required: '(Required)',
                      pattern: {
                        value: /^[a-zA-Z ]*$/g,
                        message: 'This field may only contain letters',
                      },
                      value: userSubmit.lastname,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            lastname: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
            </Row>
            {/*contact  number */}
            <Row>
              <div className='col-lg-4'>
                <Form.Group
                  className='mb-5'
                  controlId='name'
                >
                  <Form.Label>
                    Contact Number{' '}
                    {errors.contact_no ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.contact_no.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    maxLength={11}
                    minLength={10}
                    placeholder='Enter Contact Number Here'
                    {...register('contact_no', {
                      required: '(Required)',
                      minLength: {
                        value: 11,
                        message: 'This field must be 11 digits', // JS only: <p>error message</p> TS only support string
                      },
                      value: userSubmit.contact_no,
                      onChange: (event) => {
                        const value = event.target.value.replace(/\D/g, '');

                        if (value.length <= 11) {
                          setUserSubmit((prevState) => {
                            return {
                              ...prevState,

                              contact_no: value,
                            };
                          });

                          setValue('contact_no', value);
                        } else {
                          setValue('contact_no', userSubmit.contact_no);
                        }
                      },
                    })}
                  />
                </Form.Group>
              </div>
              <div className='col-lg-4'>
                <Form.Group
                  className='mb-5'
                  controlId='name'
                >
                  <Form.Label>
                    Email Address{' '}
                    {errors.email ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.email.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter Email Address Here'
                    {...register('email', {
                      required: '(Required)',
                      value: userSubmit.email,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            email: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
              <div className='col-lg-4'>
                <Form.Group
                  className='mb-5'
                  controlId='formGender'
                  style={{ width: '150px' }}
                >
                  <Form.Label>
                    Gender{' '}
                    {errors.gender ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.gender.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Select
                    {...register('gender', {
                      required: '(Required)',
                      value: userSubmit.gender,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            gender: event.target.value,
                          };
                        });
                      },
                    })}
                  >
                    <option
                      hidden
                      value=''
                    >
                      Select Gender
                    </option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Row>
            {/*oneway to roundtrip */}
            <Row className='ferry-journey'>
              <div className='col-lg-3 col-12 mt-1'>
                <div
                  className={oneway ? 'active' : ''}
                  onClick={() => {
                    setRoundtrip(false);
                    setValue('onward_date', '');
                    setValue('return_date', '');
                    setUserSubmit((prev) => {
                      return { ...prev, onward_date: '', return_date: '' };
                    });
                    setOneway((prev) => !prev);
                  }}
                >
                  <img
                    className='img-fluid w-100 '
                    src={onewayImage}
                    alt='img'
                  />
                </div>
              </div>

              <div className='col-lg-3 col-12 mt-1'>
                <div
                  className={roundtrip ? 'active' : ''}
                  onClick={() => {
                    setOneway(false);
                    setValue('onward_date', '');
                    setValue('return_date', '');
                    setUserSubmit((prev) => {
                      return { ...prev, onward_date: '', return_date: '' };
                    });
                    setRoundtrip((prev) => !prev);
                  }}
                >
                  <img
                    className='img-fluid w-100'
                    src={twoway}
                    alt='img'
                  />
                </div>
              </div>
            </Row>
            {/*origin to Destination */}

            <Row className='mt-4'>
              <div className='col-lg-6'>
                <Form.Label>
                  Origin{' '}
                  {errors.origin ? (
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'normal',
                        fontSize: 14,
                      }}
                    >
                      {errors.origin.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>

                <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>
                    <GrMapLocation />
                  </InputGroup.Text>
                  <FormControl
                    placeholder='From'
                    {...register('origin', {
                      required: '(Required)',
                      value: userSubmit.origin,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            origin: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </InputGroup>
              </div>
              <div className='col-lg-6'>
                <Form.Label>
                  Destination{' '}
                  {errors.destination ? (
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'normal',
                        fontSize: 14,
                      }}
                    >
                      {errors.destination.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>

                <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>
                    <GrMapLocation color='#8E8E8E' />
                  </InputGroup.Text>
                  <FormControl
                    placeholder='To'
                    {...register('destination', {
                      required: '(Required)',
                      value: userSubmit.destination,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            destination: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </InputGroup>
              </div>
            </Row>
            {/*Onward to Return Date */}

            <Row className='mt-4'>
              <div className='col-lg-3'>
                <Form.Label>
                  Onward Date{' '}
                  {errors.onward_date ? (
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'normal',
                        fontSize: 14,
                      }}
                    >
                      {errors.onward_date.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>
                    <GoCalendar />
                  </InputGroup.Text>
                  <FormControl
                    type='datetime-local'
                    min={mindate}
                    max={userSubmit.return_date}
                    placeholder='Apr 8, 2021, Wed'
                    {...register('onward_date', {
                      required: '(Required)',
                      value: userSubmit.onward_date,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            onward_date: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </InputGroup>
              </div>
              {roundtrip && (
                <div className='col-lg-3'>
                  <Form.Label>
                    Return Date
                    {errors.return_date ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.return_date.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>

                  <InputGroup className='mb-3'>
                    <InputGroup.Text id='basic-addon1'>
                      <GoCalendar />
                    </InputGroup.Text>
                    <FormControl
                      type='datetime-local'
                      min={userSubmit.onward_date}
                      max='2922-08-20T22:56'
                      placeholder='Apr 8, 2021, Wed'
                      {...register('return_date', {
                        required: '(Required)',
                        value: userSubmit.return_date,
                        onChange: (event) => {
                          setUserSubmit((prevState) => {
                            return {
                              ...prevState,
                              return_date: event.target.value,
                            };
                          });
                        },
                      })}
                    />
                  </InputGroup>
                </div>
              )}
            </Row>

            {/*Number of Passengers */}
            <p className='fw-bold h5 mt-4'>Number of Passengers</p>
            <Row>
              <div className='col text-center mt-3 '>
                <p className='form-label'>Children (Age 0 - 11)</p>
                <div className='d-flex justify-content-center'>
                  <Button
                    className='btn '
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_children > 0) {
                          return {
                            ...prev,
                            total_children: +prev.total_children - 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_children: +prev.total_children,
                          };
                        }
                      })
                    }
                  >
                    -
                  </Button>
                  <Form.Control
                    style={{
                      backgroundColor: '#fff',
                      width: '60px',
                      textAlign: 'center',
                    }}
                    type='number'
                    value={userSubmit.total_children}
                    className='text-primary fw-bold'
                    readOnly
                  />
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_children < 20) {
                          return {
                            ...prev,
                            total_children: +prev.total_children + 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_children: +prev.total_children,
                          };
                        }
                      })
                    }
                  >
                    +
                  </Button>
                </div>
              </div>
              <div className='col  text-center mt-3'>
                <p className='form-label'>Adults (Age 12 - 59)</p>
                <div className='d-flex justify-content-center'>
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_adult > 0) {
                          return {
                            ...prev,
                            total_adult: +prev.total_adult - 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_adult: +prev.total_adult,
                          };
                        }
                      })
                    }
                  >
                    -
                  </Button>
                  <Form.Control
                    style={{
                      backgroundColor: '#fff',
                      width: '60px',
                      textAlign: 'center',
                    }}
                    type='number'
                    value={userSubmit.total_adult}
                    className='text-primary fw-bold'
                    readOnly
                  />
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_adult < 20) {
                          return {
                            ...prev,
                            total_adult: +prev.total_adult + 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_adult: +prev.total_adult,
                          };
                        }
                      })
                    }
                  >
                    +
                  </Button>
                </div>
              </div>
              <div className='col text-center mt-3'>
                <p className='form-label'>Senior (Age 60 - 80)</p>
                <div className='d-flex justify-content-center'>
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_senior > 0) {
                          return {
                            ...prev,
                            total_senior: +prev.total_senior - 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_senior: +prev.total_senior,
                          };
                        }
                      })
                    }
                  >
                    -
                  </Button>
                  <Form.Control
                    style={{
                      backgroundColor: '#fff',
                      width: '60px',
                      textAlign: 'center',
                    }}
                    type='number'
                    value={userSubmit.total_senior}
                    className='text-primary fw-bold'
                    readOnly
                  />
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_senior < 20) {
                          return {
                            ...prev,
                            total_senior: +prev.total_senior + 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_senior: +prev.total_senior,
                          };
                        }
                      })
                    }
                  >
                    +
                  </Button>
                </div>
              </div>
              <div className='col text-center mt-3'>
                <p className='form-label'>Student</p>
                <div className='d-flex justify-content-center'>
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_student > 0) {
                          return {
                            ...prev,
                            total_student: +prev.total_student - 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_student: +prev.total_student,
                          };
                        }
                      })
                    }
                  >
                    -
                  </Button>
                  <Form.Control
                    style={{
                      backgroundColor: '#fff',
                      width: '60px',
                      textAlign: 'center',
                    }}
                    type='number'
                    className='text-primary fw-bold'
                    value={userSubmit.total_student}
                    readOnly
                  />
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_student < 20) {
                          return {
                            ...prev,
                            total_student: +prev.total_student + 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_student: +prev.total_student,
                          };
                        }
                      })
                    }
                  >
                    +
                  </Button>
                </div>
              </div>
              <div className='col text-center mt-3 '>
                <p className='form-label'>Disabled</p>
                <div className='d-flex justify-content-center'>
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_disable > 0) {
                          return {
                            ...prev,
                            total_disable: +prev.total_disable - 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_disable: +prev.total_disable,
                          };
                        }
                      })
                    }
                  >
                    -
                  </Button>
                  <Form.Control
                    style={{
                      backgroundColor: '#fff',
                      width: '60px',
                      textAlign: 'center',
                    }}
                    type='number'
                    className='text-primary fw-bold'
                    value={userSubmit.total_disable}
                    readOnly
                  />
                  <Button
                    className='btn'
                    onClick={() =>
                      setUserSubmit((prev) => {
                        if (+prev.total_disable < 20) {
                          return {
                            ...prev,
                            total_disable: +prev.total_disable + 1,
                          };
                        } else {
                          return {
                            ...prev,
                            total_disable: +prev.total_disable,
                          };
                        }
                      })
                    }
                  >
                    +
                  </Button>
                </div>
              </div>
            </Row>
            <Row>
              <div className='col-7 mt-5'>
                {' '}
                <Form.Group
                  className='mb-5'
                  controlId='name'
                >
                  <Form.Label>Remarks</Form.Label>
                  <textarea
                    type='textarea'
                    placeholder='Enter Remark Here (Optional)'
                    {...register('additional_remarks', {
                      value: userSubmit.additional_remarks,
                      onChange: (event) => {
                        setUserSubmit((prevState) => {
                          return {
                            ...prevState,
                            additional_remarks: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
            </Row>
            <p className='mt-5 h5 fw-bold'>
              Upload Identity Document (ID)
              {errors.documents ? (
                <span
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    fontSize: 14,
                  }}
                >
                  {errors.documents.message}
                </span>
              ) : (
                ''
              )}
            </p>
            <div>
              <label htmlFor='formId'>
                <input
                  accept='image/*'
                  type='file'
                  id='formId'
                  hidden
                  {...register('documents', {
                    required: '(Required)',
                    value: userSubmit.documents,
                    onChange: (e) => {
                      // eslint-disable-next-line prefer-const
                      let docuArr = [];

                      Array.from(e.target.files).forEach((file) => {
                        docuArr.push(file);
                      });

                      setUserSubmit((prevState) => {
                        return { ...prevState, documents: docuArr };
                      });
                    },
                  })}
                  multiple
                />
                <InputGroup className='mb-3 widthFile'>
                  <InputGroup.Text id='add-file'>
                    <AiOutlinePlus />
                  </InputGroup.Text>
                  <FormControl
                    readOnly
                    placeholder={
                      userSubmit.documents
                        ? `${userSubmit.documents.length} File`
                        : 'Upload file'
                    }
                    className='p-2'
                  />
                </InputGroup>
                <h6
                  className='text-danger'
                  style={{ fontSize: '12px' }}
                >
                  Note: Multiple attachments can be uploaded
                </h6>
              </label>
            </div>
            <div className='row'>
              <div className='col'></div>
              <div className='col float-right'>
                <div className=' text-center'>
                  <button
                    className='btn btn-submit m-1 float-right'
                    type='submit'
                    disabled={disabledBtn}
                  >
                    Submit
                  </button>
                </div>
                <div className=' text-center'>
                  <Link
                    className='btn btn-cancel m-1 float-right'
                    to={'/inquiries'}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default InformationContent;
