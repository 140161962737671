// Packages
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// Components
import UI from "../../shared/Components/UI/js";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import { updateUserPass } from "../../shared/API/request";
import Notification from "../../shared/Components/Notification/Notification";

// Main Component
const AccountSecurity = ({
  _this,
  styles,
  isMobile,
  width,
  state,
  onStateUpdate,
  ...rest
}) => {
  // useForm
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // State
  const [userData, setUserData] = useState();
  const [birthdate, setBirthdate] = useState({
    month: "",
    day: "",
    year: "",
  });

  useEffect(() => {
    // console.log(errors);
  }, [errors]);

  // useEffect will fire on every state props update
  useEffect(() => {
    const { user } = state;
    setUserData(user);
  }, [state]);

  // Will fire on Submit Button
  const onSubmit = (values) => {
    values.userID = userData.id;

    updateUserPass(values, callback);
  };

  // Runs after onSubmit
  const callback = async (response) => {
    const { status, data } = await response;

    // console.log("STATUS", status);

    if (status === 201) {
      Notification(data.msg, "success");
      reset();
    } else if (status === 400 || status === 422) {
      if(data.errors) {
        if(data.errors.currentpassword) {
          Notification(data.errors.currentpassword, "danger");
        }
        
        if(data.errors.newpassword) {
          data.errors.newpassword.map(err => {
            if(err == "The newpassword format is invalid.") {
              Notification("Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.", "danger");

            } else {
              Notification(err, "danger");
            }
          })
        }

        if(data.errors.confirmnewpassword) {
          Notification(data.errors.confirmnewpassword, "danger");
        }
      }
    } else {
      Notification("An Error Occured", "danger");
    }
  };

  // To render on screen
  return (
    <>
      {userData && (
        <form
          className="form-profile"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            flexDirection: isMobile ? "column" : "column",
            display: "flex",
          }}
        >
          <UI.PadView
            breakpoint={850}
            _this={_this}
            style={{ ...styles.SegoeUI }}
          >
            {UI.box(50)}
            <View
              style={{
                backgroundColor: "#fff",
                padding: isMobile ? 15 : 30,
                boxShadow: "0 2px 3px #3444",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  fontSize: isMobile ? 20 : 25,
                  fontWeight: "bold",
                }}
              >
                <Text>Password</Text>
                {/* <TouchableOpacity>
                  <Text style={{ color: "#006FB9" }}>Edit</Text>
                </TouchableOpacity> */}
              </View>

              {UI.box(20)}
              <View style={styles.line}></View>
              {UI.box(20)}

              <View
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  gap: 20,
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flex: "1",
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? "flex-start" : "flex-start",
                    }}
                  >
                    Current Password
                  </Text>
                  <View
                    style={{
                      alignSelf: "stretch",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <input
                      {...register("currentpassword", {
                        required: "Please enter your current password",
                        value: userData.currentpassword,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              currentpassword: e.target.value,
                            };
                          });
                        },
                      })}
                      type="password"
                      name="currentpassword"
                      style={{
                        ...styles.form_input,
                        width: isMobile ? "100%" : "100%",
                      }}
                      className={errors.currentpassword ? "red" : ""}
                      placeholder={
                        errors.currentpassword
                          ? errors.currentpassword.message
                          : "Enter your current password"
                      }
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: width > 1000 ? "0.5" : "0",
                    position: "relative",
                    ...styles.SegoeUI,
                  }}
                ></View>

                <View
                  style={{
                    flex: "1",
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? "flex-start" : "flex-start",
                    }}
                  >
                    New password
                  </Text>
                  <View
                    style={{
                      alignSelf: "stretch",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <input
                      {...register("newpassword", {
                        required: "Please enter your new password",
                        value: userData.newpassword,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              newpassword: e.target.value,
                            };
                          });
                        },
                      })}
                      type="password"
                      name="newpassword"
                      style={{
                        ...styles.form_input,
                        width: isMobile ? "100%" : "100%",
                      }}
                      className={errors.newpassword ? "red" : ""}
                      placeholder={
                        errors.newpassword
                          ? errors.newpassword.message
                          : "Enter your new password"
                      }
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: "1",
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? "flex-start" : "flex-start",
                    }}
                  >
                    Re-enter password
                  </Text>
                  <View
                    style={{
                      alignSelf: "stretch",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <input
                      {...register("confirmnewpassword", {
                        required: "Please re-enter your new password",
                        value: userData.confirmnewpassword,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              confirmnewpassword: e.target.value,
                            };
                          });
                        },
                      })}
                      type="password"
                      name="confirmnewpassword"
                      style={{
                        ...styles.form_input,
                        width: isMobile ? "100%" : "100%",
                      }}
                      className={errors.confirmnewpassword ? "red" : ""}
                      placeholder={
                        errors.confirmnewpassword
                          ? errors.confirmnewpassword.message
                          : "Re-enter password"
                      }
                    />
                  </View>
                </View>
              </View>

              {UI.box(40)}

              <View
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  gap: 20,
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flex: "1",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      display: "block",
                      textAlign: "center",
                      width: "min-content",
                      margin: "0 auto",
                    }}
                  >
                    <input
                      type="submit"
                      name="submit"
                      style={{
                        ...styles.form_input,
                        cursor: "pointer",
                        backgroundColor: "#0BC175",
                        color: "#fff",
                        fontWeight: "bold",
                        width: isMobile ? "auto" : 150,
                        padding: isMobile ? "0 8px" : "",
                        height: isMobile ? 37 : 50,
                      }}
                      value={"Change Password"}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </UI.PadView>
        </form>
      )}
    </>
  );
};

export default AccountSecurity;
