import React from "react";
import { Row, Card, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import {useHistory} from 'react-router-dom'
//components
import img from "../../shared/assets/images/bg/Group349.png";
import { GoLocation, GoCalendar } from "react-icons/go";
import { HiOutlineMap } from "react-icons/hi";
import { Splide, SplideSlide } from "@splidejs/react-splide";

// css
import "./FerryContent.scss";
import "@splidejs/react-splide/css";
import { color } from "@chakra-ui/react";

// data api sample

const API = [
  {
    id: 1,
    title: "Coron, Palawan",
    img: "./img/Group371.png",
    rate: "4.8",
    location: "Coron, Palawan",
    date: "July 22, 2022",
    exactloc:
      "Kayangan Lake, Malcapuya Island, Twin Lagoon, Sunken Japanese...",
    pax: "8",
    amount: "14,988",
  },
  {
    id: 2,
    title: "Coron, Palawan",
    img: "./img/Group370.png",
    rate: "4.8",
    location: "Coron, Palawan",
    date: "July 22, 2022",
    exactloc:
      "Kayangan Lake, Malcapuya Island, Twin Lagoon, Sunken Japanese...",
    pax: "8",
    amount: "14,988",
  },
  {
    id: 3,
    title: "Coron, Palawan",
    img: "./img/Group372.png",
    rate: "4.8",
    location: "Coron, Palawan",
    date: "July 22, 2022",
    exactloc:
      "Kayangan Lake, Malcapuya Island, Twin Lagoon, Sunken Japanese...",
    pax: "8",
    amount: "6,000",
  },
];
const API2 = [
  {
    id: "1",
    img: "./img/Resorts.png",
    properrtyType: "Ferry",
    numberofProperty: "20 Ferries",
  },
  {
    id: "2",
    img: "./img/Villas.png",
    properrtyType: "Hotels",
    numberofProperty: "800 Apartment",
  },
  {
    id: "3",
    img: "./img/Resorts.png",
    properrtyType: "Resorts",
    numberofProperty: "800 Resorts",
  },
  {
    id: "4",
    img: "./img/Villas.png",
    properrtyType: "Villas",
    numberofProperty: "800 Villas",
  },
  {
    id: "5",
    img: "./img/Resorts.png",
    properrtyType: "Cabins",
    numberofProperty: "800 Cabins",
  },
  {
    id: "6",
    img: "./img/Resorts.png",
    properrtyType: "Ferry",
    numberofProperty: "20 Ferries",
  },
];

function FerryContent({paddingX}) {

  const history =useHistory();
  return (
    <>
      <section className="favorite-property">
        <div style={{padding: `3rem ${paddingX}px`}}>
          <p className="h5">My Favorite Property</p>
          <p className="h5-sub">
            Enjoy and relax while traveling by ferry with Traverse
          </p>
          <Row className="mt-5">
            <div className="col-lg-6">
              <Card onClick={()=>history.push('/ferry-portal-2go')}>
                <Card.Img variant="top" src="./img/Group349.png" />
                <Card.ImgOverlay>
                  <Card.Img variant="center" src="./img/2go-logo-white.png" />
                </Card.ImgOverlay>
                <Card.Body>
                  <Card.Title>2GO Travel booking with Traverse</Card.Title>
                  <Card.Text>
                    <span className="fw-bold" style={{ color: "#006FB9" }}>
                      2GO Travel,
                    </span>{" "}
                    is a passenger ferry company based in Manila, Philippines,
                    the shipping and passenger ferry arm of 2GO Group, and the
                    only remaining Manila-based major interisland passenger
                    ferry company, with its main hub located in Pier 4 at the
                    Manila North Harbor.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-6">
              <Card onClick={()=>history.push('/ferry-portal-OceanJet')}>
                <Card.Img variant="top" src="./img/Group350.png" />
                <Card.Body>
                  <Card.ImgOverlay>
                    <Card.Img variant="center" src="./img/OceanJet_logo.png" />
                  </Card.ImgOverlay>
                  <Card.Title>OCEANJET Travel booking with Traverse</Card.Title>
                  <Card.Text>
                    <span className="fw-bold" style={{ color: "#006FB9" }}>
                      Ocean Fast Ferries Inc.,
                    </span>{" "}
                    is a wholly owned Filipino corporation that operates
                    high-speed crafts commonly known as OceanJet, serving
                    destinations in the Visayas, Luzon region in the
                    Philippines.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Row>
        </div>
      </section>
      {/* <section className="explore">
        <div  style={{padding: `0 ${paddingX}px`}}>
          <p className="title mt-3">EXPLORE PHILIPPINES</p>
          <p className="sub-title">
            These popular destinations have a lot to offer
          </p>

          <Row>
            <div className="col-lg-8">
              {API.map((sampleAPI) => {
                return (
                  <Card className="mt-3" key={sampleAPI.id}>
                    <Row>
                      <div className="col-lg-4 nopadding">
                        <Card.Img variant="top" src={sampleAPI.img} />
                      </div>
                      <div className="col-lg-8 nopadding">
                        <ListGroup className="list-group-flush">
                          <ListGroupItem className="mt-5">
                            <Row>
                              <div className="title col-lg-6">
                                {sampleAPI.title}
                              </div>
                              <div className="d-flex col-lg-6">
                                <svg
                                  className="mx-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26.001"
                                  height="25"
                                  viewBox="0 0 26.001 25"
                                >
                                  <path
                                    id="icons8_christmas_star_1"
                                    d="M25.97,9.481a.653.653,0,0,0-.551-.449l-8.391-.895L13.594.355a.675.675,0,0,0-1.188,0L8.973,8.137.582,9.032a.648.648,0,0,0-.551.449.665.665,0,0,0,.184.691l6.266,5.7-1.75,8.332a.666.666,0,0,0,.254.668A.65.65,0,0,0,5.367,25a.617.617,0,0,0,.324-.09L13,20.657l7.309,4.254a.646.646,0,0,0,.707-.035.666.666,0,0,0,.254-.668l-1.75-8.336,6.266-5.7A.656.656,0,0,0,25.97,9.481Z"
                                    transform="translate(0 -0.001)"
                                    fill="#fcc203"
                                  />
                                </svg>
                                <span className="mr-5 rate">{sampleAPI.rate}</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25.173"
                                  height="25"
                                  viewBox="0 0 25.173 25"
                                >
                                  <path
                                    className="mx-1"
                                    id="icons8_approval"
                                    d="M25.163,13.541,23.679,11.2l.877-2.625a.793.793,0,0,0-.355-.942L21.8,6.254,21.36,3.519a.8.8,0,0,0-.752-.668l-2.77-.107L16.175.524A.794.794,0,0,0,15.2.283l-2.5,1.19L10.2.283a.8.8,0,0,0-.977.24L7.56,2.744l-2.77.107a.8.8,0,0,0-.752.668L3.6,6.25,1.2,7.635a.793.793,0,0,0-.359.939L1.719,11.2.235,13.541a.8.8,0,0,0,.122,1l2,1.919-.221,2.758a.794.794,0,0,0,.568.828l2.663.767L6.45,23.361a.79.79,0,0,0,.889.469l2.713-.557L12.2,25.028a.8.8,0,0,0,1.007,0l2.144-1.755,2.713.557a.784.784,0,0,0,.889-.469l1.084-2.549,2.663-.767a.791.791,0,0,0,.568-.828l-.221-2.758,2-1.919A.8.8,0,0,0,25.163,13.541ZM18.441,9.722,12.882,18.05a1.1,1.1,0,0,1-.858.519,1.41,1.41,0,0,1-.916-.412L7.03,14.01a.735.735,0,0,1,0-1.026l1.007-1.022a.7.7,0,0,1,1.007,0l2.655,2.7L16.079,8.1a.7.7,0,0,1,.988-.191l1.183.813A.733.733,0,0,1,18.441,9.722Z"
                                    transform="translate(-0.113 -0.207)"
                                    fill="#006fb9"
                                  />
                                </svg>
                                <span className="mx-1"> Best Value</span>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Row>
                              <div className="d-flex col-lg-6">
                                <GoLocation /> <span>{sampleAPI.location}</span>
                              </div>
                              <div className="d-flex d-flex col-lg-6 px-3">
                                <GoCalendar />
                                <span>{sampleAPI.date}</span>
                              </div>
                              <div className="col-12">
                                <span>{sampleAPI.exactloc}</span>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{
                              backgroundColor: "#43980F",
                              color: "white",
                            }}
                          >
                            <Row className="text-lg-start text-center">
                              <div className="col-lg-6">
                                Good for {sampleAPI.pax} PAX
                              </div>
                              <div className="col-lg-6">
                                <span
                                  style={{ fontSize: "12px" }}
                                  className="text-white"
                                >
                                  Amount
                                </span>
                                <span className="ml-3 text-white">
                                  Php {sampleAPI.amount}
                                </span>
                              </div>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    </Row>
                  </Card>
                );
              })}
            </div>
            <div className="col-lg-4 mt-3">
              <div className="search">
                <p className="search-tag">Search</p>
                <div className="input-group mb-3">
                  <label>Location</label>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <HiOutlineMap />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tokyo, Japan"
                  />
                </div>
                <div className="input-group mb-3">
                  <label>Date of booking</label>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <GoCalendar />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tokyo, Japan"
                  />
                </div>
                <button className="btn-search d-flex justify-content-center py-3">
                  <svg
                    id="Group_289"
                    data-name="Group 289"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="29.972"
                    viewBox="0 0 33.376 29.972"
                  >
                    <path
                      id="Path_33"
                      data-name="Path 33"
                      d="M257.961,701.142a13.211,13.211,0,1,0,13.211,13.211A13.211,13.211,0,0,0,257.961,701.142Zm0,24.081a10.87,10.87,0,1,1,10.869-10.87A10.87,10.87,0,0,1,257.961,725.223Z"
                      transform="translate(-244.75 -701.142)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_120"
                      data-name="Rectangle 120"
                      width="14.06"
                      height="2.358"
                      transform="translate(22.556 19.178) rotate(39.688)"
                      fill="#fff"
                    />
                  </svg>
                  <span className="mt-1 text-white">Search</span>
                </button>
              </div>
            </div>
          </Row>
          <div className="text-center my-5">
            <Button className="btn fw-bold bg-white border-white">
              Showing 3 of 20 {`>`}{" "}
            </Button>
          </div>
        </div>
      </section>
      <section>
        <div className=" Splide" style={{padding: `0 ${paddingX}px 3rem`}}>
          <p className="title">Browse by Property Type</p>
          <p className="sub-title">
            These popular destinations have a lot to offer
          </p>
          <Splide
            options={{
              rewind: true,

              gap: "1rem",
            }}
            aria-label="My Favorite Images"
            data-splide='{"type":"loop","perPage":5}'
          >
            {API2.map((item) => {
              return (
                <SplideSlide>
                  <img src={item.img} alt="Image1" />
                  <p className="title">{item.properrtyType}</p>
                  <p className="subtitle">{item.numberofProperty}</p>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </section> */}
    </>
  );
}

export default FerryContent;
