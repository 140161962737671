
class Images {
  ARROW_DOWN = "/img/arrow-down.png";
  CHEVRON_DOWN = "/img/chevron_down.png";
  PH_LOGO = "/img/ph_logo.png";
  CIRCLE_FACEBOOK = "/img/circle_facebook.png";
  CIRCLE_TWITTER = "/img/circle_twitter.png";
  CIRCLE_INSTAGRAM = "/img/circle_instagram.png";
  CIRCLE_LINKEDIN = "/img/circle_linkedin.png";
  SIGN_IN = "/img/sign_in.png";
  LOGO = "/img/logo.png";
  MAGNIFYING = "/img/magnifying.png";
  MAGNIFYING_2 = "/img/magnifying_2.png";
  HOTEL = "/img/hotel.jpg";
  HOTEL_2 = "/img/hotel.png";
  AIRPLANE = "/img/airplane.svg"
  SUITCASE = "/img/suitcase.svg";
  DESTINATION = "/img/destination.png";
  CALENDAR = "/img/calendar.png";
  PROFILE = "/img/profile.png";
  SAMPLE_PLACE_1 = "/img/sample_place_1.jpg";
  SAMPLE_PLACE_2 = "/img/sample_place_2.jpg";
  SAMPLE_PLACE_3 = "/img/sample_place_3.jpg";
  ITEM_OVERLAY = "/img/item_overlay.png";
  ARROW_LEFT = "/img/arrow_left.png";
  ARROW_RIGHT = "/img/arrow_right.png";
  BANNER = "/img/banner.png";
  MANILA = "/img/manila.png";
  BATANGAS = "/img/batangas.png";
  BORACAY = "/img/boracay.png";
  CEBU = "/img/cebu.png";
  BOHOL = "/img/bohol.png";
  SIARGAO = "/img/siargao.png";
  CORON = "/img/coron.png";
  QUEZON = "/img/quezon.jpg";
  TAGAYTAY = "/img/tagaytay.jpg";
  GOOGLE = "/img/google.png";
  FACEBOOK = "/img/facebook.png";
  GREEN_CHECK = "/img/green-check.png";
  SETTINGS = "/img/settings.png";
  SETTINGS_WHITE = "/img/settings-white.png";
  SIGNOUT_WHITE = "/img/logout-white.png";
  SIGNOUT = "/img/logout.png";
  CAVITE = "/img/cavite.jpg";
  PANGASINAN = "/img/pangasinan.jpg";
  BAGUIO = "/img/baguio.jpg";
  ILOCOS = "/img/ilocos.jpg";
  HOTELS = "/img/hotels.jpg";
  APARTMENTS = "/img/apartments.jpg";
  RESORTS = "/img/resorts.jpg";
  VILLAS = "/img/villas.jpg";
  CABINS = "/img/cabins.jpg";
  ICON_HOTELS = "/img/icon_hotels.png";
  ICON_APARTMENTS = "/img/icon_apartments.png";
  ICON_RESORTS = "/img/icon_resorts.png";
  ICON_VILLAS = "/img/icon_villas.png";
  ICON_CABINS = "/img/icon_cabins.png";
  HOTEL_RESORT = "/img/hotel_resort.jpg";
  HOTEL_SEARCH_BANNER = "/img/Hotel_Search_Banner.png";
  HOT_AIR_BALLOON = "/img/hot-air-balloon.png";
  CALENDAR_BG = "/img/calender-bg.png";
  JUAN_CAMARING = "/img/Juan-Camaring.png";
  PROFILE_PICTURE = "/img/user-image.png";
  CHEVRON_GRAY = "/img/user-image.png";
  HOTELS_1 = "/img/hotel_1.jpg";
  HOTELS_2 = "/img/hotel_2.jpg";
  HOTELS_3 = "/img/hotel_3.jpg";
  HOTELS_4 = "/img/hotel_4.jpg";
  STAR = "/img/star.png";
  VERIFIED = "/img/verified.png";
  VERIFIED_ICON = "/img/verified_icon.png";
  LOCATION = "/img/location.png";
  CHECK = "/img/check.png";
  CHEVRON_RIGHT = "/img/chevron_right.png";
  CHEVRON_LEFT = "/img/chevron_left.png";
  MAPS = "/img/maps.jpg";
  TIMER = "/img/timer.png";
  AMENITIES = "/img/amenities.png";
  MAP = "/img/map.png";
  WEB = "/img/web.png";
  GMAIL = "/img/gmail.svg";
  BED = "/img/bed.png";
  BED_TOURS = "/img/bed.svg";
  BREAKFAST = "/img/breakfast.svg"
  WIFI = "/img/wifi.png";
  QUEUE = "/img/queue.svg";
  RECYCLE = "/img/recycle.svg";
  CAR = "/img/car.png";
  CAR_ROUNDTRIP = "/img/car_roundtrip.svg";
  BEACH = "/img/beach.svg";
  BOODLE = "/img/boodle.svg";
  DOCUMENT = "/img/document.svg";
  BOATING = "/img/boating.svg";
  LICENSED = "/img/licensed.svg";
  BUILDING = "/img/building.svg";
  BILL = "/img/bill.svg";
  BALCONY = "/img/balcony.png";
  LONG_TERM_STAY = "/img/long_term_stay.png";
  AIRCON = "/img/aircon.png";
  ROOM_USER = "/img/room-user.png";
  BEDROOMS = "/img/bedrooms.png";
  BATHROOMS = "/img/bath.png";
  CAMERA = "/img/camera.png";
  TELEVISION = "/img/television.png";
  PETS = "/img/pets.png";
  SHINJUKU_HOTEL = "/img/shinjuku_hotel.png";
  MOUNT_FUJI = "/img/mountfuji.png";
  BGC = "/img/bgc.png";
  SMART = "/img/smart.png";
  TOKYO_JAPAN = "/img/tokyo_japan.png";
  ICONS8_MASTERCARD1 = "/img/icons8_mastercard_480px_1.png";
  ICONS8_MASTERCARD2 = "/img/icons8_mastercard2.png";
  ICONS8_MASTERCARD3 = "/img/icons8_american_express.png";
  ICONS8_MASTERCARD4 = "/img/icons8_mastercard4.png";
  MASKGROUP = "/img/Mask Group 15.png";
  MASKGROUP20 = "/img/Mask Group 20.png";
  PERSON = "/img/person.png"
}

export default Images;
