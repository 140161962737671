import api from './api';

const getFeaturedProducts = () => {
  return api
    .get('featured-products')
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const authHeader = () => {
  let token = localStorage.getItem('token');
  if (token) {
    return `Bearer ${token}`;
  }
};

const userRegister = async (params, callback = null) => {
  return api
    .post(
      'api/register',
      {
        firstname: params.firstname,
        lastname: params.lastname,
        password: params.password,
        confirmpassword: params.confirmpassword,
        email: params.email,
        emailpromotions: params.emailpromotions,
        termsandconditions: params.termsandconditions,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: false,
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
};

const userLogin = (params, callback = null) => {
  return api
    .post('api/login', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      console.log(error);
      if (error) {
        callback(error.response);
      }
    });
};

const updateUserPass = (params, callback = null) => {
  const header = authHeader();

  return api
    .put(
      `api/admin/change-password/${params.userID}`,
      {
        currentpassword: params.currentpassword,
        newpassword: params.newpassword,
        confirmnewpassword: params.confirmnewpassword,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': header,
        },
        withCredentials: false,
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const updateUserDetails = (params, callback = null) => {
  const header = authHeader();

  return api
    .patch(
      `api/admin/profile-update/${params.userID}`,
      {
        firstname: params.firstname,
        middlename: params.middlename,
        lastname: params.lastname,
        gender: params.gender,
        birthdate: params.birthdate,
        email: params.email,
        mobile: params.mobile,
        address: params.address,
        city: params.city,
        province: params.city,
        country: params.country,
        postalcode: params.postalcode,
        language: params.language,
        biography: params.biography,
        telephone: params.telephone,
        profile: params.profile,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': header,
        },
        withCredentials: false,
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const hotelOrTourSearch = (params, callback = null) => {
  return api
    .get(`api/search-results`, { params: params })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const allTours = (params, callback = null) => {
  return api
    .get(`api/tours`, { params: params })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const allHotels = (params, callback = null) => {
  return api
    .get(`api/hotels`, { params: params })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const hotelDetail = (slug, callback = null) => {
  return api
    .get(`api/hotels/${slug}`)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        callback(error.response);
      }
    });
};

const tourDetail = (slug, callback = null) => {
  return api
    .get(`api/tours/${slug}`)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        callback(error.response);
      }
    });
};

const submitVisaPassport = (params, callback = null) => {
  return api
    .post(`/api/submit-visa-assistance`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const updateVisaPassport = (params, callback = null) => {
  return api
    .post(`/api/update-visa-assistance`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (
          error.response.status === 400 ||
          error.response.status === 422 ||
          error.response.status === 404
        ) {
          callback(error.response);
        }
      }
    });
};

const submitHotelBooking = (params, callback = null) => {
  return api
    .post(`/api/submit-hotel-booking`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const submitTourBooking = (params, callback = null) => {
  return api
    .post(`/api/submit-tour-booking`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const getCurrentBookings = (id, params, callback = null) => {
  return api
    .get(`/api/upcoming-bookings/${id}${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getBankAccounts = (callback = null) => {
  return api
    .get(`/api/bank-accounts`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getVisaPassport = (params, callback = null) => {
  return api
    .get(`/api/visa-assistance-list/${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      console.log(error);
    });
};

const getVisaPassportUser = (params, callback = null) => {
  return api
    .get(`/api/visa-assistance-details/${params}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const submitFlightInquiry = (params, callback = null) => {
  return api
    .post(`/api/submit-flight-inquiry`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const submitContact = (params, callback = null) => {
  return api
    .post(`/api/submit-inquiry`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const getPastBooking = (id, params, callback = null) => {
  return api
    .get(`/api/booking-history/${id}`, { params: params })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getTourPastBooking = (id, params, callback = null) => {
  return api
    .get(`/api/tour-booking-history/${id}${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getHotelPastBooking = (id, params, callback = null) => {
  return api
    .get(`/api/hotel-booking-history/${id}${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getFerryPastBooking = (id, params, callback = null) => {
  return api
    .get(`/api/ferry-booking-history/${id}${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const submitBookingReview = (params, callback = null) => {
  return api
    .post(`/api/submit-review`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const getFerry = (type, params, callback = null) => {
  return api
    .get(`/api/ferry-type/${type}${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getFerryDestinations = (id, origin, callback = null) => {
  return api
    .get(`/api/ferry-destinations?ferryId=${id}&origin=${origin}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getFerrySchedules = (id, origin, destination, callback = null) => {
  return api
    .get(
      `/api/ferry-schedules?ferryId=${id}&origin=${origin}&destination=${destination}`,
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getSinglePastBooking = (type, id, callback = null) => {
  return api
    .get(`/api/booking-details/${type}/${id}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const insuranceSubmitForm = (params, callback = null) => {
  return api
    .post(`/api/insurance-inquiry`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const ferryinquire = (params, apiurl, callback = null) => {
  return api
    .post(`${apiurl}`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const subscribe = (params, callback = null) => {
  return api
    .post(`/api/store-subscription`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const submitFerryBooking = (params, callback = null) => {
  return api
    .post(`/api/submit-ferry-booking`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const toursBookmarksubmit = (params, callback = null) => {
  return api
    .post(`/api/bookmarks`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const getBookmarkList = (id, params, callback = null) => {
  return api
    .get(`/api/bookmarks?guestId=${id}${params}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const removeSelectedBookmark = (params, callback = null) => {
  return api
    .post(`/api/delete-bookmark`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};
const forgotpass = (params, callback = null) => {
  return api
    .post(`/api/forgot-password`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};
const resetpassword = (params, callback = null) => {
  return api
    .post(`/api/reset-password`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const getCurrentFerryBookings = (id, callback = null) => {
  return api
    .get(`/api/upcoming-ferry-bookings/${id}`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
const aboutUsSettings = (callback = null) => {
  return api
    .get(`api/about-us-setting`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const emailupdate = (params, callback = null) => {
  return api
    .post(`/api/update-email`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const submitPayment = (params, callback = null) => {
  return api
    .post(`/api/store-payment`, params)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        if (error.response.status === 400 || error.response.status === 422) {
          callback(error.response);
        }
      }
    });
};

const getInsurancePDF = (callback = null) => {
  return api
    .get(`/api/insurance-request-pdf`)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

const getBookmark = (userId, slug, callback = null) => {
  return api
    .get(`/api/is-bookmark/${userId}/${slug}`)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        callback(error.response);
      }
    });
};
const getFooter = (callback = null) => {
  return api
    .get(`/api/footer-pages`)
    .then((res) => {
      callback(res);
    })
    .catch((error) => {
      if (error) {
        callback(error.response);
      }
    });
};
export {
  getFeaturedProducts,
  authHeader,
  userRegister,
  userLogin,
  updateUserPass,
  updateUserDetails,
  hotelOrTourSearch,
  hotelDetail,
  tourDetail,
  allTours,
  getVisaPassport,
  getVisaPassportUser,
  submitVisaPassport,
  submitHotelBooking,
  submitTourBooking,
  getCurrentBookings,
  submitFlightInquiry,
  submitContact,
  allHotels,
  getPastBooking,
  submitBookingReview,
  getFerry,
  getSinglePastBooking,
  insuranceSubmitForm,
  getFerryDestinations,
  getFerrySchedules,
  ferryinquire,
  subscribe,
  submitFerryBooking,
  toursBookmarksubmit,
  getBookmarkList,
  removeSelectedBookmark,
  forgotpass,
  getCurrentFerryBookings,
  getTourPastBooking,
  getHotelPastBooking,
  getFerryPastBooking,
  resetpassword,
  aboutUsSettings,
  emailupdate,
  getBankAccounts,
  submitPayment,
  getInsurancePDF,
  getBookmark,
  updateVisaPassport,
  getFooter,
};
