import React, { useState } from 'react';
import { Row, Form, InputGroup, FormControl } from 'react-bootstrap';
//components

import { Switch } from 'antd';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControls from '@mui/material/FormControl';
import DatePicker from 'react-datepicker';
//react-icons
import { BsFillPersonFill } from 'react-icons/bs';
import { AiFillStar, AiOutlineCreditCard } from 'react-icons/ai';
import { BsCreditCard } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
//css
import 'react-datepicker/dist/react-datepicker.css';

import './FinalStep.scss';
function FinalStep({ _this }) {
  const [checked, setChecked] = useState(false);
  const { booking, ferry } = _this.state;

  return (
    <div className='final-steps '>
      <hr />
      <Row className='mt-5'>
        <div className='col-lg-6'>
          <p className='fw-bold h5'>Enter your Information</p>
          <Row>
            <div className='col-lg-6'>
              <Form.Group>
                <Form.Label className='mb-2'>
                  Country/ Region <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Select
                  value={_this.state.country}
                  onChange={(e) => _this.setState({ country: e.target.value })}
                >
                  <option value='philippines'>Philippines</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className='col-lg-6'>
              <Form.Group>
                <Form.Label className='mb-2'>
                  Phone Number <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  value={_this.state.mobile}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');

                    if (value.length <= 11) {
                      _this.setState({ mobile: value });
                    }
                  }}
                  placeholder='Enter Phone No. Here'
                />
              </Form.Group>
            </div>
          </Row>
          {/* Switch
          <Row className="mt-5">
            <div className="col-lg-6">
              <p className="fw-bold h5">You want paperless confirmation?</p>
              <p className="p1-highlight">
                We'll text and email your booking confirmation with travel
                requirements.
              </p>
            </div>
            <div className="col-lg-6 text-end">
              <Switch onChange={toogler} />
            </div>
          </Row>
          <hr />
          {/* When to pay
          <p>When do you want to pay?</p>
          <div>
            <FormControls>
              <RadioGroup name="radio-buttons-group">
                <FormControlLabel
                  value="paylater"
                  control={<Radio />}
                  label="Pay Later"
                />
                <p className="ml-5 p1-highlight">
                  The property will handle the payment. The day they charge you
                  will depend on your booking conditions
                </p>
                <FormControlLabel
                  value="payBooking"
                  control={<Radio />}
                  label="Pay your Booking on Oct. 8, 2021"
                />
                <p className="ml-5 p1-highlight">
                  We'll only validate your card today, then charge it for the
                  full amount on Oct. 8, 2021.
                </p>
                <FormControlLabel
                  value="paynow"
                  control={<Radio />}
                  label="Pay now"
                />{" "}
                <p className="ml-5 p1-highlight">
                  Traverse will facilitate your payment. You'll pay today after
                  complete your booking.
                </p>
              </RadioGroup>
            </FormControls>
          </div> */}
        </div>
        <div className='col-lg-6'>
          <Row>
            <div className='col-lg-6 mb-5'>
              <img
                src={ferry.images[0].image_url}
                alt='img'
              />
            </div>
            <div className='col-lg-6'>
              <p className='h5 fw-bold'>Travel to {booking.destination}</p>
              <p className='h5 fw-bold'>{`${ferry.name}`}</p>
              <p className='p1-highlight'>
                {booking.accomodation} <span>&#8226;</span>
                {` ${booking.passenger}`} Passengers
              </p>
              <span
                className='d-flex fw-bold'
                style={{ alignItems: 'center' }}
              >
                <AiFillStar color='red' /> {ferry?.others?.rating}{' '}
                {`(${ferry?.others?.totalReviews} Review${
                  ferry?.others?.totalReviews === 0 ? '' : 's'
                })`}
              </span>

              <p className='p1-highlight'>{ferry.amenities}</p>
            </div>
          </Row>

          <Row>
            <div className='payment-selection'>
              <h3>Pay with</h3>

              <Form>
                <div className='mb-3'>
                  <Form.Check
                    type='radio'
                    label={`Credit Card`}
                    id={`creditCard`}
                    value='creditcard'
                    name='paymentType'
                    onChange={(e) => {
                      if (e.target.checked) {
                        _this.setState({ paymentType: e.target.value });
                      }
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <Form.Check
                    type='radio'
                    label={`Cash on deposit`}
                    value='bank-deposit'
                    id={`cash`}
                    name='paymentType'
                    onChange={(e) => {
                      if (e.target.checked) {
                        _this.setState({ paymentType: e.target.value });
                      }
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <Form.Check
                    type='radio'
                    label={`Paymaya `}
                    id={`paymaya`}
                    value='paymaya'
                    name='paymentType'
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.checked) {
                        _this.setState({ paymentType: e.target.value });
                      }
                    }}
                  />
                </div>
              </Form>
            </div>

            {_this.state.paymentType === 'bank-deposit' && (
              <div className='mt-4 bank-details'>
                {_this.state.bankAccounts &&
                  _this.state.bankAccounts.length > 0 &&
                  _this.state.bankAccounts.map((data, idx) => {
                    return (
                      <div
                        className='d-flex mb-4'
                        style={{ alignItems: 'center' }}
                      >
                        <img
                          alt='img'
                          src={data?.image_url}
                          height={50}
                          width={50}
                          className='mr-2'
                        />
                        <p
                          key={idx}
                          style={{ margin: '0' }}
                        >
                          {data?.bank_name}
                          <span className='ml-4 '>
                            ( {data?.account_name} -{' '}
                          </span>
                          {data?.account_number} )
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </Row>

          {/* pay with */}
          {/* <Row className="mt-5">
            <div className="col-lg-6 d-flex" style={{ alignItems: "center" }}>
              <p className="p1 fw-bold ">Pay with</p>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <img src="/img/mastercard.png" alt="img" />
              <img src="/img/visa.png" alt="img" />
              <img src="/img/amex.png" alt="img" />
              <img src="/img/jcb.png" alt="img" />
            </div>
          </Row> */}
          {/* payment method */}
          {/* <Row className="mt-5">
            <div className="col-lg-6">
              <p className="p1">Cardholder's Name <span className="text-danger">*</span></p>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <BsFillPersonFill />
                </InputGroup.Text>
                <FormControl
                  placeholder="Enter Name Here"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="col-lg-6">
              <p className="p1">Expiration Date <span className="text-danger">*</span></p>
              <div className="d-flex calendar-container">
                <BiCalendar size={17} className='mx-2' />
                <DatePicker
                  icons={{ checked: <BiCalendar /> }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <p className="p1">Card Number <span className="text-danger">*</span></p>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineCreditCard />
                </InputGroup.Text>
                <FormControl placeholder="Enter Number Here" />
              </InputGroup>
            </div>
            <div className="col-lg-6">
              <p className="p1">CVC <span className="text-danger">*</span></p>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <BsCreditCard />
                </InputGroup.Text>
                <FormControl placeholder="" />
              </InputGroup>
            </div>
          </Row> */}
          {/* <div className="pay-with-maya">
            <p>
              <span className="p1-highlight">Or pay with Paymaya</span>
            </p>
          </div>
          <p>Pay with Paymaya Account</p>
          <p className="p1-highlight">Log-in to your account to complete the purchase</p>
          <img src="/img/payamayalogo.png" alt="img" /> */}
        </div>
      </Row>
    </div>
  );
}

export default FinalStep;
