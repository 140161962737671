import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

const header_height = 200;

export default class TitleHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;

    // Search type Props
    const search_type = this.props.search_type;
    const search_city = this.props.search_city;

    return (
      <ImageBackground
        style={styles.header_container}
        source={UI.HOTEL_SEARCH_BANNER}
        imageFit={ImageFit.COVER}
      >
        <View style={styles.header_text_container}>
          <Text style={styles.search_text}>
            SEARCH RESULTS FROM: { UI.titleCase(search_city) }
          </Text>
          <Text style={styles.search_type}>{ search_type === "HOTELS" ? "HOTEL / HOTEL LIST" : "HOTEL / TOURS" }</Text>
        </View>
        <View style={styles.header_tint}></View>
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  header_tint: {
    height: header_height,
    width: "100%",
    marginTop: -header_height,
    backgroundColor: "black",
    opacity: 0.4,
  },
  search_type: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  search_text: {
    fontSize: 26,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  header_text_container: {
    zIndex: 10,
    width: "100%",
    height: header_height,
    justifyContent: "center",
    alignItems: "center",
  },
  header_container: {
    width: "100%",
    height: header_height,
    backgroundColor: "grey",
  },
});
