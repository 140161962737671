// Packages
import React, { Component } from 'react';
import { m } from 'framer-motion';
// import { withRouter } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TitleHeader from './TitleHeader';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

import './Inquiries.scss';

export default class Inquiries extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);

    UI.initiateView(this, {
      active_link: '',
      user: {},
    });
  }

  redirectURL = (url) => {
    UI.goTo(url)
  }

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    // Use Context
    const { data, setData } = this.context;

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          <TitleHeader _this={_this} />

          <section
            className='inquiries-container'
            style={{ paddingLeft: paddingX, paddingRight: paddingX }}
          >
            <Container fluid style={{ padding: 0 }}>
              <Row>
                <Col xl={3} lg={6} md={6} sm={12}>
                  <section className='flight-inquiry' onClick={() => this.redirectURL('flight-inquiry')}>
                    <div className='image'>
                      <span>Flight</span>
                    </div>
                  </section>
                </Col>
                <Col xl={3} lg={6} md={6} sm={12}>
                  <section className='ferry-inquiry ferry-inquiry-2go' onClick={() => this.redirectURL('ferry-portal/2go-information')}>
                    <div className='image'>
                      <span>Ferry / 2GO</span>
                    </div>
                  </section>
                </Col>
                <Col xl={3} lg={6} md={6} sm={12}>
                  <section className='ferry-inquiry ferry-inquiry-ocean-jet' onClick={() => this.redirectURL('/ferry-portal/Oceanjet-information')}>
                    <div className='image'>
                      <span>Ferry / Ocean Jet</span>
                    </div>
                  </section>
                </Col>
                <Col xl={3} lg={6} md={6} sm={12}>
                  <section className='insurance-inquiry' onClick={() => this.redirectURL('ferry-portal/insurance')}>
                    <div className='image'>
                      <span>Insurance</span>
                    </div>
                  </section>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: '100%',
    backgroundColor: '#B3B3B3',
    marginTop: 5,
    marginBottom: 10,
  },
  SegoeUI: {
    fontFamily: 'Segoe UI',
  },
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 0,
    paddingRight: 30,
    marginBottom: 5,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_header: {
    padding: 12,
    // backgroundColor: UI.colors.primary,
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  main_text: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
  },
  submain_text: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
  sort_select: {
    width: 200,
    borderWidth: 0.5,
    borderColor: '#c0c0c0',
    borderRadius: 10,
    borderStyle: 'solid',
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 10,
    backgroundColor: 'transparent',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sort_text: {
    alignSelf: 'stretch',
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 5,
  },
});
