// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// Components
import UI from '../../../shared/Components/UI/js';
import { userRegister } from '../../../shared/API/request';
import Notification from '../../../shared/Components/Notification/Notification';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

// CSS
import './css.css';

// Main Component
const SignUpForm = ({ props, width, isMobile, styles, _this }) => {
  // States
  const [cBTermsAndCondition, setCBTermsAndCondition] = useState(false);
  const [cBEmailPromotions, setCBEmailPromotions] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  // UseHistory
  const history = useHistory();

  // useForm
  const {
    setValue,
    register,
    trigger,
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Context Values
  const { data, setData } = useContext(AllContext);

  // Will run everytime
  useEffect(() => {
    setValue('termsandconditions', cBTermsAndCondition);
    setValue('emailpromotions', cBEmailPromotions);
  }, [cBTermsAndCondition, cBEmailPromotions, watch]);

  // Toogle Terms and Condition
  const toggleTermsAndConditions = () =>
    setCBTermsAndCondition(!cBTermsAndCondition);
  const toggleEmailPromotions = () => setCBEmailPromotions(!cBEmailPromotions);

  // Will fire on submit
  const onSubmit = (data) => {
    console.log(data);

    const { password, confirmpassword } = data;

    if (password !== confirmpassword) {
      Notification('Password and confirm password are not the same.', 'danger');
      return;
    }

    if (password.length < 7) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger',
      );
      return;
    }

    if (!hasNumber(password)) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger',
      );
      return;
    }

    if (!hasSpecialCharacter(password)) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger',
      );
      return;
    }

    if (!stringCheck(password)) {
      Notification(
        'Minimum of eight (8) characters (combination of letters and numbers) with at least one (1) upper case and one (1) special character.',
        'danger',
      );
      return;
    }
    setDisabledBtn(true);
    userRegister(data, callback);
  };

  const hasNumber = (str) => {
    return /\d/.test(str);
  };

  const hasSpecialCharacter = (str) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    return format.test(str);
  };

  const stringCheck = (str) => {
    const upper = /[A-Z]/.test(str);
    const lower = /[a-z]/.test(str);

    return upper && lower;
  };

  // Callback fcuntion for onSubmit
  const callback = async (response) => {
    const { status, data } = await response;

    if (status === 200 || status === 201) {
      setDisabledBtn(false);
      Notification(data.message, 'success');
      setCBTermsAndCondition(false);
      setCBEmailPromotions(false);
      reset();
      history.push('/login');
    } else if (status === 400 || status === 401) {
      setDisabledBtn(false);
      const serverData = JSON.parse(data);

      if (serverData.email) {
        Notification('The email has already been taken.', 'danger');
      }

      if (serverData.confirmpassword) {
        Notification('The confirmpassword and password must match.', 'danger');
      }
    } else {
      setDisabledBtn(false);
      Notification('An Error Occured', 'danger');
    }
  };

  // To render on screen
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          flexDirection: isMobile ? 'column' : 'column',
          display: 'flex',
        }}
      >
        {UI.box(20)}
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          First Name{' '}
          {errors.firstname ? (
            <span style={{ color: '#ff5c5c' }}>{errors.firstname.message}</span>
          ) : (
            ''
          )}
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='text'
            name='firstname'
            style={{
              ...styles.form_input,
              width: '100%',
              borderColor: errors.firstname ? 'red' : '',
            }}
            // className={errors.firstname ? "red" : ""}
            placeholder={
              errors.firstname
                ? errors.firstname.message
                : 'Enter First Name Here'
            }
            {...register('firstname', {
              required: 'Please Enter First Name',
              pattern: {
                value: /^[a-zA-Z ]*$/g,
                message: 'Special Characters not Allowed',
              },
            })}
          />
        </View>
        {UI.box(20)}
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          Last Name{' '}
          {errors.lastname ? (
            <span style={{ color: '#ff5c5c' }}>{errors.lastname.message}</span>
          ) : (
            ''
          )}
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='text'
            name='lastname'
            style={{
              ...styles.form_input,
              width: '100%',
              borderColor: errors.lastname ? 'red' : '',
            }}
            // className={errors.lastname ? "red" : ""}
            placeholder={
              errors.lastname ? errors.lastname.message : 'Enter Last Name Here'
            }
            {...register('lastname', {
              required: 'Please Enter Last Name',
              pattern: {
                value: /^[a-zA-Z ]*$/g,
                message: 'Special Characters not Allowed',
              },
            })}
          />
        </View>
        {UI.box(20)}
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          Email Address
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='email'
            style={{
              ...styles.form_input,
              width: '100%',
              borderColor: errors.email ? 'red' : '',
            }}
            // className={errors.email ? "red" : ""}
            placeholder={
              errors.email ? errors.email.message : 'Enter Email Address Here'
            }
            {...register('email', {
              required: 'Please Enter Email',
            })}
          />
        </View>
        {UI.box(20)}
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          Password
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='password'
            style={{
              ...styles.form_input_password,
              width: '100%',
              borderColor: errors.password ? 'red' : '',
            }}
            placeholder={
              errors.password ? errors.password.message : 'Enter Password Here'
            }
            {...register('password', {
              required: 'Please Enter Password',
            })}
          />
        </View>
        {UI.box(20)}
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          Confirm Password
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='password'
            name='confirmpassword'
            style={{
              ...styles.form_input_password,
              width: '100%',
              borderColor: errors.confirmpassword ? 'red' : '',
            }}
            placeholder={
              errors.confirmpassword
                ? errors.confirmpassword.message
                : 'Enter Confirm Password Here'
            }
            {...register('confirmpassword', {
              required: 'Please Enter Confirm Password',
            })}
          />
        </View>
        {UI.box(25)}
        <TouchableOpacity
          style={{
            width: '100%',
            padding: '0',
          }}
          onClick={() => {
            trigger();
          }}
        >
          {/* <input
            type='submit'
            placeholder={`SIGN UP`}
            value={'SIGN UP'}
            style={{
              ...styles.custom_btn,
              color: '#fff',
              fontWeight: '800',
              width: isMobile ? '60%' : '100%',
              cursor: 'pointer',
            }}
          /> */}
          <button
            type='submit'
            style={{
              ...styles.custom_btn,
              color: '#fff',
              fontWeight: '800',
              width: isMobile ? '60%' : '100%',
              cursor: 'pointer',
            }}
            disabled={disabledBtn}
          >
            SIGN UP
          </button>
        </TouchableOpacity>
        {UI.box(20)}
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'flex-start',
          }}
        >
          <View style={styles.filter_checkbox}>
            <TouchableOpacity
              onClick={toggleTermsAndConditions}
              style={styles.checkbox_container}
            >
              <input
                type={'checkbox'}
                style={styles.checkbox}
                value={cBTermsAndCondition}
                required
                checked={cBTermsAndCondition ? 'checked' : ''}
                {...register('termsandconditions', {
                  required: 'Please Check this Checkbox',
                })}
              />
              <View style={{ marginLeft: -17, marginTop: 0.5 }}>
                <Image
                  height={14}
                  width={14}
                  tintColor='white'
                  source={UI.CHECK}
                />
              </View>
              {UI.box(10)}
              <Text style={styles.filter_checkbox_text}>{props.text}</Text>
            </TouchableOpacity>
            <Text style={styles.filter_checkbox_text}>{props.results}</Text>
          </View>
          <Text
            style={{
              ...styles.submain_text,
              marginLeft: isMobile ? 0 : -26,
              fontSize: isMobile ? 11 : 12,
              color: '#8f8f8f',
            }}
          >
            I agree with <a onClick={terms}>Terms &amp; Condition</a> and{' '}
            <a onClick={privacy_policy}>Privacy Policy</a> of Traverse
          </Text>
        </View>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'flex-start',
          }}
        >
          <View style={styles.filter_checkbox}>
            <TouchableOpacity
              onClick={toggleEmailPromotions}
              style={styles.checkbox_container}
            >
              <input
                type={'checkbox'}
                style={styles.checkbox}
                value={cBEmailPromotions}
                checked={cBEmailPromotions ? 'checked' : ''}
                {...register('emailpromotions')}
              />
              <View style={{ marginLeft: -17, marginTop: 0.5 }}>
                <Image
                  height={14}
                  width={14}
                  tintColor='white'
                  source={UI.CHECK}
                />
              </View>
              {UI.box(10)}
              <Text style={styles.filter_checkbox_text}>{props.text}</Text>
            </TouchableOpacity>
            <Text style={styles.filter_checkbox_text}>{props.results}</Text>
          </View>
          <Text
            style={{
              ...styles.submain_text,
              marginLeft: isMobile ? 0 : -26,
              fontSize: isMobile ? 11 : 12,
              color: '#8f8f8f',
            }}
          >
            Email me exclusive Traverse promotions.
          </Text>
        </View>
      </form>
    </>
  );
};

// Will redirect to terms and conditions
const terms = () => {
  UI.goTo('/terms-of-use');
};

// Will redirect to privacy and policy
const privacy_policy = () => {
  UI.goTo('/privacy-policy');
};

export default SignUpForm;
