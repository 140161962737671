import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

export default class SortComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeSortBy = (e) => {
    const _this = this.props._this;

    _this.setState({
      search_sortby: e
    })
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    const { meta, search_sortby } = _this.state;

    return (
      <UI.PadView _this={_this}>
        {UI.box(20)}
        <View
          style={{
            ...styles.sort_text,
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          {!meta && (
            <Text style={{ fontSize: 14, color: "black", flex: 1 }}>
              Showing Page 0 of Page 0
            </Text>
          )}
          {meta && (
            <Text style={{ fontSize: 14, color: "black", flex: 1 }}>
              Showing Page {meta.current_page} of Page {meta.last_page}
            </Text>
          )}
          <View
            style={{
              ...styles.fl_ai,
              marginTop: isMobile ? 20 : 0,
            }}
          >
            <Text style={{ fontSize: 14, color: "black", flex: 1 }}>
              Sort By
            </Text>
            {UI.box(10)}
            <select style={styles.sort_select} defaultValue="price" value={search_sortby ? search_sortby : "price"} onChange={e => this.changeSortBy(e.target.value)}>
              <option value="price">Price</option>
              <option value="rating">Rating</option>
            </select>
            <View style={{ marginLeft: -30 }}>
              <Image
                width={22}
                height={22}
                source={UI.CHEVRON_DOWN}
                tintColor={"#3b3b3b"}
              />
            </View>
          </View>
        </View>
      </UI.PadView>
    );
  }
}

const styles = StyleSheet.create({
  sort_select: {
    width: 200,
    borderWidth: 0.5,
    borderColor: "#c0c0c0",
    borderRadius: 10,
    borderStyle: "solid",
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 10,
    backgroundColor: "transparent",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  sort_text: {
    alignSelf: "stretch",
    padding: 15,
    backgroundColor: "white",
    borderRadius: 5,
  },
});
