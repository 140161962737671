// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import countryList from 'country-list';

// Components
import UI from '../../shared/Components/UI/js';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';
import { updateUserDetails } from '../../shared/API/request';
import Notification from '../../shared/Components/Notification/Notification';

// Context
import AllContext from '../../shared/Context/AllContext';

// Main Component
const AccountInformation = ({
  _this,
  styles,
  isMobile,
  width,
  state,
  onStateUpdate,
  ...rest
}) => {
  // useForm
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // Context Values
  const { data, setData } = useContext(AllContext);

  // State
  const [userData, setUserData] = useState();
  const [profileImage, setProfileImage] = useState(null);
  const [birthdate, setBirthdate] = useState({
    month: '',
    day: '',
    year: '',
  });

  useEffect(() => {
    if (state.user.birthdate) {
      const dataDate = new Date(state.user.birthdate);
      const month = dataDate.toLocaleString('en-US', { month: 'short' });
      const day = dataDate.getDate();
      const year = dataDate.getFullYear();

      setBirthdate({
        month: month,
        day: parseInt(day) + 1,
        year: year,
      });
    }
  }, [state]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  // useEffect will fire on every state props update
  useEffect(() => {
    const { user } = state;
    setUserData(user);
  }, [state]);

  // useEffect will fire on every userData state update
  useEffect(() => {
    if (userData && userData.profile) {
      setValue('profile', userData.profile);
    }

    if (userData && userData.birthdate) {
      setValue('birthdate', userData.birthdate);
    }

    reset({}, { keepDirty: true });
  }, [userData, profileImage]);

  // useEffect will fire on every birthdate state update
  useEffect(() => {
    const { month, day, year } = birthdate;

    if (month && day && year) {
      const newDate = new Date(`${month} ${day}, ${year}`)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');

      // If new selected date is not the same  as current selected date
      if (newDate.toString() != userData.birthdate) {
        setUserData((prevState) => {
          return { ...prevState, birthdate: newDate.toString() };
        });
      }
    }
  }, [birthdate]);

  // Will fire on Submit Button
  const onSubmit = (values) => {
    values.userID = userData.id;
    updateUserDetails(values, submitCallback);
  };

  // Runs after onSubmit
  const submitCallback = async (response) => {
    const { data, status } = await response;

    if (status === 201) {
      Notification(data.msg, 'success');

      localStorage.setItem('user', JSON.stringify(response.data.data));

      setData((prevState) => {
        return { ...prevState, user: response.data.data };
      });

      _this.setState({
        user: {
          ..._this.state.user,
          firstname: userData.firstname,
          middlename: userData.middlename,
          lastname: userData.lastname,
        },
      });

      reset();
    } else if (status === 400 || status === 422) {
      if (data.errors) {
        console.log(data.errors);
      }
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  // Get File Image
  const getFileImage = async (e, callback = null) => {
    const [file] = e.target.files;

    if (file) {
      const { name: fileName, size } = file;
      const fileSize = (size / 1000).toFixed(2);
      const fileNameAndSize = `${fileName} - ${fileSize}KB`;
      document.getElementById('labelProfile').textContent = fileNameAndSize;
    }

    const filetoConvert = e.target.files[0];
    const response = await convertBase64(filetoConvert);

    if (response) {
      callback(response);
    }
  };

  // Callback for Get File Image
  const callback = (response) => {
    if (response) {
      setProfileImage(response);
    }
  };

  // To render on screen
  return (
    <>
      {userData && (
        <form
          className='form-profile'
          onSubmit={handleSubmit(onSubmit)}
          style={{
            flexDirection: isMobile ? 'column' : 'column',
            display: 'flex',
          }}
        >
          <input
            type='hidden'
            {...register('birthdate', {
              required: '(Required)',
              value: userData.birthdate,
              onChange: (e) => {
                setUserData((prevState) => {
                  return { ...prevState, birthdate: e.target.value };
                });
              },
            })}
          />

          <input
            type='hidden'
            {...register('profile', {
              value: profileImage,
              onChange: (e) => {
                setProfileImage(e.target.value);
              },
            })}
          />

          <UI.PadView
            breakpoint={850}
            _this={_this}
            style={{ ...styles.SegoeUI }}
          >
            {UI.box(50)}
            <View
              style={{
                backgroundColor: '#fff',
                padding: isMobile ? 15 : 30,
                boxShadow: '0 2px 3px #3444',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                  fontSize: isMobile ? 20 : 25,
                  fontWeight: 'bold',
                }}
              >
                <Text>Account information</Text>
                {/* <TouchableOpacity>
                  <Text style={{ color: "#006FB9" }}>Edit</Text>
                </TouchableOpacity> */}
              </View>

              {UI.box(20)}
              <View style={styles.line}></View>
              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    First Name{' '}
                    {errors.firstname ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.firstname.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='text'
                      placeholder='Enter First Name'
                      {...register('firstname', {
                        required: '(Required)',
                        value: userData.firstname,
                        pattern: {
                          value: /^[a-zA-Z ]*$/g,
                          message: 'Special Characters not Allowed',
                        },
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, firstname: e.target.value };
                          });
                        },
                      })}
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Middle Name{' '}
                    {errors.middlename ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.middlename.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='text'
                      placeholder='Enter Middle Name'
                      {...register('middlename', {
                        value: userData.middlename,
                        pattern: {
                          value: /^[a-zA-Z ]*$/g,
                          message: 'Special Characters not Allowed',
                        },
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, middlename: e.target.value };
                          });
                        },
                      })}
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Last Name{' '}
                    {errors.lastname ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.lastname.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='text'
                      placeholder='Enter Last Name'
                      {...register('lastname', {
                        required: '(Required)',
                        value: userData.lastname,
                        pattern: {
                          value: /^[a-zA-Z ]*$/g,
                          message: 'Special Characters not Allowed',
                        },
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, lastname: e.target.value };
                          });
                        },
                      })}
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: '0.5',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Gender{' '}
                    {errors.gender ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.gender.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <select
                      {...register('gender', {
                        required: '(Required)',
                        value: userData.gender,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, gender: e.target.value };
                          });
                        },
                      })}
                      name='gender'
                      id='gender'
                      style={{
                        ...styles.selectOption,
                        height: isMobile ? 37 : 50,
                        padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                        color: '#8E8E8E',
                      }}
                    >
                      <option
                        value=''
                        hidden
                      >
                        Select Gender
                      </option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                    </select>
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: width > 900 ? '1' : '1.5',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Birthdate{' '}
                    {errors.birthdate ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.birthdate.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'stretch',
                      alignItems: isMobile ? 'center' : 'flex-start',
                      gap: width > 900 ? 20 : 10,
                    }}
                  >
                    <select
                      className='option-select'
                      id='month'
                      style={{
                        ...styles.selectOption,
                        height: isMobile ? 37 : 50,
                        padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                        color: '#8E8E8E',
                      }}
                      value={birthdate.month ? birthdate.month : ''}
                      onChange={(e) => {
                        setBirthdate((prevState) => {
                          return { ...prevState, month: e.target.value };
                        });
                      }}
                    >
                      <option
                        value=''
                        hidden
                      >
                        Select Month
                      </option>
                      <option value='Jan'>Jan</option>
                      <option value='Feb'>Feb</option>
                      <option value='Mar'>Mar</option>
                      <option value='Apr'>Apr</option>
                      <option value='May'>May</option>
                      <option value='Jun'>Jun</option>
                      <option value='Jul'>Jul</option>
                      <option value='Aug'>Aug</option>
                      <option value='Sep'>Sep</option>
                      <option value='Oct'>Oct</option>
                      <option value='Nov'>Nov</option>
                      <option value='Dec'>Dec</option>
                    </select>

                    <select
                      className='option-select'
                      id='day'
                      style={{
                        ...styles.selectOption,
                        height: isMobile ? 37 : 50,
                        padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                        color: '#8E8E8E',
                      }}
                      value={birthdate.day ? birthdate.day : ''}
                      onChange={(e) => {
                        setBirthdate((prevState) => {
                          return { ...prevState, day: e.target.value };
                        });
                      }}
                    >
                      <option
                        value=''
                        hidden
                      >
                        Select Day
                      </option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                      <option value='12'>12</option>
                      <option value='13'>13</option>
                      <option value='14'>14</option>
                      <option value='15'>15</option>
                      <option value='16'>16</option>
                      <option value='17'>17</option>
                      <option value='18'>18</option>
                      <option value='19'>19</option>
                      <option value='20'>20</option>
                      <option value='21'>21</option>
                      <option value='22'>22</option>
                      <option value='23'>23</option>
                      <option value='24'>24</option>
                      <option value='25'>25</option>
                      <option value='26'>26</option>
                      <option value='27'>27</option>
                      <option value='28'>28</option>
                      <option value='29'>29</option>
                      <option value='30'>30</option>
                      <option value='31'>31</option>
                    </select>

                    <select
                      className='option-select'
                      id='year'
                      style={{
                        ...styles.selectOption,
                        height: isMobile ? 37 : 50,
                        padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                        color: '#8E8E8E',
                      }}
                      value={birthdate.year ? birthdate.year : ''}
                      onChange={(e) => {
                        setBirthdate((prevState) => {
                          return { ...prevState, year: e.target.value };
                        });
                      }}
                    >
                      <option
                        value=''
                        hidden
                      >
                        Select Year
                      </option>
                      {years()}
                    </select>
                  </View>
                </View>

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Email Address{' '}
                    {errors.email ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.email.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      {...register('email', {
                        required: '(Required)',
                        value: userData.email,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, email: e.target.value };
                          });
                        },
                      })}
                      type='email'
                      name='email'
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                      placeholder={'Enter Email'}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Contact Number{' '}
                    {errors.mobile && (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.mobile.message}
                      </span>
                    )}
                    {errors.mobile && errors.mobile.type === 'mininumLength' && (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        Minimum length of contact number is 11
                      </span>
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                      placeholder='Enter Number'
                      type='text'
                      {...register('mobile', {
                        required: '(Required)',
                        validate: {
                          mininumLength: (value) => value.length > 10,
                        },
                        value: userData.mobile,
                        onChange: (event) => {
                          const value = event.target.value.replace(/\D/g, '');

                          if (value.length <= 11) {
                            setUserData((prevState) => {
                              return {
                                ...prevState,
                                mobile: value,
                              };
                            });

                            setValue('mobile', value);
                          } else {
                            setValue('mobile', userData.mobile);
                          }
                        },
                      })}
                    />
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Address{' '}
                    {errors.address ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.address.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      {...register('address', {
                        required: '(Required)',
                        value: userData.address,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, address: e.target.value };
                          });
                        },
                      })}
                      type='text'
                      name='address'
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                      placeholder={
                        'Please enter your House No. Street, Barangay'
                      }
                    />
                  </View>
                </View>
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Telephone
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='text'
                      {...register('telephone', {
                        minLength: {
                          value: 10,
                          message: 'The field must be 10 digits', // JS only: <p>error message</p> TS only support string
                        },
                        value: userData.telephone,

                        onChange: (event) => {
                          const value = event.target.value.replace(/\D/g, '');

                          if (value.length <= 11) {
                            setUserData((prevState) => {
                              return {
                                ...prevState,
                                telephone: event.target.value,
                              };
                            });

                            setValue('telephone', value);
                          } else {
                            setValue('telephone', userData.telephone);
                          }
                        },
                      })}
                      name='telephone'
                      maxLength={11}
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                      placeholder={'Please enter your Tel No. '}
                    />
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    City{' '}
                    {errors.city ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.city.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      {...register('city', {
                        required: '(Required)',
                        value: userData.city,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, city: e.target.value };
                          });
                        },
                      })}
                      type='text'
                      name='city'
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                      placeholder='City'
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Postal Code{' '}
                    {errors.postalcode ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.postalcode.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <input
                      type='text'
                      placeholder='Zip Code'
                      {...register('postalcode', {
                        required: '(Required)',
                        value: userData.postalcode,
                        onChange: (event) => {
                          const value = event.target.value.replace(/\D/g, '');

                          if (value.length <= 4) {
                            setUserData((prevState) => {
                              return {
                                ...prevState,
                                postalcode: value,
                              };
                            });

                            setValue('postalcode', value);
                          } else {
                            setValue('postalcode', userData.postalcode);
                          }
                        },
                      })}
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Country{' '}
                    {errors.postalcode ? (
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {errors.postalcode.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Text>
                  <View
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: isMobile ? 'center' : 'flex-start',
                    }}
                  >
                    <select
                      {...register('country', {
                        required: '(Required)',
                        value: userData.country,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, country: e.target.value };
                          });
                        },
                      })}
                      type='text'
                      name='country'
                      className={'option-select'}
                      id='country'
                      style={{
                        ...styles.selectOption,
                        height: isMobile ? 37 : 50,
                        padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                        color: '#8E8E8E',
                      }}
                    >
                      <option
                        value=''
                        hidden
                        selected
                      >
                        Select Country
                      </option>
                      {allCountry()}
                    </select>
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                {/* <View
                  style={{
                    flex: "0.5",
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? "flex-start" : "flex-start",
                    }}
                  >
                    Linked Accounts
                  </Text>
                  <View
                    style={{
                      alignSelf: "stretch",
                      flexDirection: isMobile ? "column" : "column",
                      alignItems: isMobile ? "flex-start" : "flex-start",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "row" : "row",
                        alignItems: isMobile ? "center" : "flex-start",
                        marginBottom: 10,
                      }}
                    >
                      <Image width={22} height={22} source={UI.FACEBOOK} />

                      <Text style={{ marginLeft: 5, flex: "0 0 10em" }}>
                        Facebook
                      </Text>
                      <View style={{ height: 0, width: 50 }}></View>

                      <View>
                        <TouchableOpacity>
                          <Text style={{ color: "#006FB9" }}>Connect</Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "row" : "row",
                        alignItems: isMobile ? "center" : "flex-start",
                      }}
                    >
                      <Image width={22} height={22} source={UI.GOOGLE} />

                      <Text style={{ marginLeft: 5, flex: "0 0 10em" }}>
                        Google
                      </Text>
                      <View style={{ height: 0, width: 50 }}></View>

                      <View>
                        <Text>
                          <Image
                            width={22}
                            height={22}
                            source={UI.GREEN_CHECK}
                          />
                        </Text>
                      </View>
                    </View>
                  </View>
                </View> */}

                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Language
                  </Text>
                  <View
                    style={{
                      display: 'block',
                    }}
                  >
                    <input
                      {...register('language', {
                        pattern: {
                          value: /^[a-zA-Z ]*$/g,
                          message: 'Numbers and Special Characters not Allowed',
                        },
                        value: userData.language,
                        onChange: (e) => {
                          setUserData((prevState) => {
                            return { ...prevState, language: e.target.value };
                          });
                        },
                      })}
                      type='text'
                      name='language'
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '50%',
                        height: isMobile ? 37 : 50,
                      }}
                      placeholder={'English, Tagalog, etc..'}
                    />
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    About me
                  </Text>
                  <View
                    style={{
                      display: 'block',
                    }}
                  >
                    <textarea
                      {...register('biography')}
                      value={userData.biography}
                      onChange={(e) => {
                        setUserData((prevState) => {
                          return { ...prevState, biography: e.target.value };
                        });
                      }}
                      type='text'
                      name='about'
                      style={{
                        ...styles.form_input,
                        width: isMobile ? '100%' : '100%',
                        height: 150,
                      }}
                      className={errors.biography ? 'red' : ''}
                      placeholder={
                        errors.biography
                          ? errors.biography.message
                          : 'Tell us about yourself'
                      }
                    />
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      textAlign: isMobile ? 'flex-start' : 'flex-start',
                    }}
                  >
                    Upload Photo
                  </Text>
                  <View
                    style={{
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    <div class='file-input'>
                      <input
                        onChange={(e) => {
                          getFileImage(e, callback);
                        }}
                        type='file'
                        id='profile'
                        class='upload-file'
                        accept='image/x-png,image/gif,image/jpeg'
                      />
                      <label
                        id='labelProfile'
                        for='file'
                        onClick={getProfileImage}
                      >
                        Upload Profile Picture
                      </label>
                    </div>
                  </View>
                </View>
              </View>

              {UI.box(40)}

              <View
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 20,
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flex: '1',
                  }}
                >
                  <TouchableOpacity
                    style={{
                      display: 'block',
                      textAlign: 'center',
                      width: 'min-content',
                      margin: '0 auto',
                    }}
                  >
                    <input
                      // onClick={e=> {console.log('Clicked')}}
                      type='submit'
                      name='submit'
                      style={{
                        ...styles.form_input,
                        cursor: 'pointer',
                        backgroundColor: '#0BC175',
                        color: '#fff',
                        fontWeight: 'bold',
                        width: isMobile ? 'auto' : 150,
                        padding: isMobile ? '0 8px' : '',
                        height: isMobile ? 37 : 50,
                      }}
                      value={'Update Profile'}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </UI.PadView>
        </form>
      )}
    </>
  );
};

export default AccountInformation;

const getProfileImage = () => {
  const file = document.getElementById('profile');
  file.click();
};

const convertBase64 = (filetoConvert) => {
  if (filetoConvert) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(filetoConvert);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
};

const years = () => {
  let selectYears = [];

  let endDate = 1950;
  let currentYear = new Date().getFullYear();

  while (currentYear >= endDate) {
    selectYears.push(setOption(currentYear));
    currentYear--;
  }

  return <>{selectYears}</>;
};

const setOption = (param) => {
  return <option value={`${param}`}>{param}</option>;
};

const allCountry = () => {
  const allCountries = countryList.getNameList();
  let arrAllCountries = [];
  const sortedCountries = sortObject(allCountries);

  Object.keys(sortedCountries).forEach(function (key) {
    arrAllCountries.push(setOption(key));
  });

  return <>{arrAllCountries}</>;
};

const sortObject = (o) => {
  var sorted = {},
    key,
    a = [];

  for (key in o) {
    if (o.hasOwnProperty(key)) {
      a.push(key);
    }
  }

  a.sort();

  for (key = 0; key < a.length; key++) {
    sorted[a[key]] = o[a[key]];
  }
  return sorted;
};
