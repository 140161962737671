// Packages
import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import { gapi } from 'gapi-script';

import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SignUpForm from './SignUpForm';
import { userLogin } from '../../../shared/API/request';
import Notification from '../../../shared/Components/Notification/Notification';

// Main Component
export default class Signup extends Component {
  constructor(props) {
    super(props);
    // Initial State
    UI.initiateView(this, { active_link: '' });
  }

  clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  componentDidMount() {
    const initClient = () => {
      gapi.client.init({
        clientId: this.clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }

  // Will redirect to terms page
  terms = () => {
    UI.goTo('/terms-of-use');
  };

  // Will redirect to privacy_policy page
  privacy_policy = () => {
    UI.goTo('/privacy-policy');
  };

  // Will redirect to google_register page
  google_register = () => {
    window.open(
      'https://accounts.google.com/signup/v2/webcreateaccount?hl=en&flowName=GlifWebSignIn&flowEntry=SignUp',
      '_blank'
    );
  };

  // Will redirect to facebook_register page
  facebook_register = () => {
    window.open('https://www.facebook.com/reg/', '_blank');
  };

  // To render on screen
  render() {
    // Setting this as _this to be passed down in child components
    const _this = this;

    // Variables for CSS / Responsive
    const width = this.state.width;
    const isMobile = _this.state.isMobile;

    const successGoogle = (response) => {
      const profile = response?.profileObj;
      
      const user = {
        logintype: 'google',
        socmedID: response.googleId,
        firstname: profile?.givenName,
        lastname: profile?.familyName,
        photo: profile?.imageUrl,
        email: profile?.email,
        password: '',
      };

      userLogin(user, userlogincallback);
    };

    // Runs after onSubmit
    const userlogincallback = async (response) => {
      const { status, data: datares } = await response;
      const { setData } = this.context;

      if (status === 201 || status === 200) {
        localStorage.setItem('token', `Bearer ${datares.access_token}`);
        localStorage.setItem('user', JSON.stringify(datares.user));
        localStorage.setItem('isAuth', JSON.stringify(true));

        setData((prevState) => {
          return { ...prevState, user: datares.user, isAuth: true };
        });

        this.props.history.push('/');

        Notification('Login Successfully', 'success');
      } else if (status === 400 || status === 401) {
        Notification(datares.msg, 'danger');
      } else if (status === 422) {
        Notification(datares.email, 'danger');
        this.props.history.push('/login');
      } else {
        Notification(datares.msg, 'danger');
      }
    };

    const failureGoogle = () => {
      console.log('Facebook Login Error');
    };

    // Fill log the response of facebook
    const successFacebook = (response) => {
      console.log(response);

      const user = {
        logintype: 'facebook',
        socmedID: response?.id,
        firstname: response?.first_name,
        lastname: response?.last_name,
        email: response?.email,
        password: '',
      };
      console.log(user);
      userLogin(user, facebookcallback);
    };

    const facebookcallback = async (response) => {
      const { status, data } = await response;
      const { setData } = this.context;
      if (status === 201 || status === 200) {
        localStorage.setItem('token', `Bearer ${data.access_token}`);
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('isAuth', JSON.stringify(true));

        setData((prevState) => {
          return { ...prevState, user: data.user, isAuth: true };
        });

        this.props.history.push('/');

        Notification('Login Successfully', 'success');
      } else if (status === 400 || status === 401) {
        Notification('Submit Error', 'danger');
      } else {
        Notification('Submit Error', 'danger');
      }
    };

    // To render on screen
    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

          <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              height: isMobile ? 1200 : 990,
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              padding: isMobile ? 20 : 60,
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                alignSelf: isMobile ? 'center' : 'flex-start',
                width: isMobile ? '100%' : 480,
                height: '100%',
                alignSelf: 'center',
              }}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: 'white',
                  borderRadius: 20,
                  padding: isMobile ? 15 : 40,
                  borderRadius: 0,
                  marginTop: isMobile ? '' : '5%',
                }}
              >
                <Text
                  style={{
                    ...styles.main_text,
                    textAlign: isMobile ? 'center' : 'flex-start',
                  }}
                >
                  Sign up
                </Text>
                <Text
                  style={{
                    ...styles.submain_text,
                    textAlign: isMobile ? 'center' : 'flex-start',
                    fontSize: isMobile ? 11 : 16,
                  }}
                >
                  Please enter required details
                </Text>
                {/**  Sign Up Form **************************************************************************************************************************************************************/}

                <SignUpForm
                  props={this.props}
                  width={this.state.width}
                  isMobile={isMobile}
                  styles={styles}
                  _this={_this}
                />

                {UI.box(10)}
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      width: isMobile ? '24%' : '35%',
                      paddingTop: 14,
                      marginLeft: isMobile && '20%',
                    }}
                  >
                    <ColoredLine color='#D3D3D3' />
                  </View>

                  <View
                    style={{
                      width: isMobile ? '30%' : '30%',
                      textAlign: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.label,
                        textAlign: isMobile ? 'center' : 'flex-start',
                      }}
                    >
                      or sign in with
                    </Text>
                  </View>

                  <View
                    style={{
                      width: isMobile ? '24%' : '35%',
                      paddingTop: 14,
                      marginRight: isMobile && '20%',
                    }}
                  >
                    <ColoredLine color='#D3D3D3' />
                  </View>
                </View>
                <View
                  style={{
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <GoogleLogin
                    clientId={this.clientId}
                    buttonText='GOOGLE'
                    onSuccess={successGoogle}
                    onFailure={failureGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                  {UI.box(20)}
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                    fields='first_name,last_name,email,picture'
                    scope='public_profile,user_friends'
                    callback={successFacebook}
                    size='medium'
                    textButton='Facebook'
                    icon='fa-facebook'
                  />
                </View>
                {UI.box(20)}
                {UI.box(20)}
                <Text
                  style={{
                    ...styles.submain_text,
                    textAlign: isMobile ? 'center' : 'flex-start',
                    fontSize: isMobile ? 11 : 12,
                    marginLeft: isMobile && '20%',
                    marginRight: isMobile && '20%',
                  }}
                >
                  By signing in, I agree with{' '}
                  <a onClick={this.terms}>Terms of use</a> and{' '}
                  <a onClick={this.privacy_policy}>Privacy Policy</a> of
                  Traverse
                </Text>
                {UI.box(20)}
              </View>
            </View>
          </UI.Row>

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const styles = StyleSheet.create({
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 0,
    paddingRight: 30,
    marginBottom: 5,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  main_text: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
  },
  submain_text: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
});
