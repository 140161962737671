import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import { Button } from 'react-bootstrap';
import IconTint from 'react-icon-tint';

// CSS
import './FerryList.scss';

export default class FerryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ferryItems: this.props._this.state.ferryList,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        ferryItems: this.props._this.state.ferryList,
      });
    }
  }

  render() {
    const _this = this.props._this;
    const type = this.props.type;
    const { ferryItems } = this.state;

    return (
      <div className='list-cont'>
        {type === 'ferry' && (
          <>
            {ferryItems &&
              ferryItems.length > 0 &&
              ferryItems.map((destination) => {
                return (
                  <Item
                    _this={_this}
                    destination={destination}
                  />
                );
              })}

            {!ferryItems ||
              (!ferryItems.length > 0 && (
                <div>
                  <h2 style={{ fontSize: 16 }}>
                    No Past Booking, Book more and enjoy.
                  </h2>
                </div>
              ))}
          </>
        )}
      </div>
    );
  }
}

const Item = ({ _this, destination }) => {
  const onClick = () => {
    UI.goTo(`/booking-history/${destination.bookingId}?type=ferry`);
  };

  console.log('DESTINATION', destination);

  return (
    <div
      onClick={onClick}
      className='list-item'
    >
      <img
        className='list-image'
        src={destination?.historyDetails?.primaryImageURL}
        alt={destination?.historyDetails?.title}
      />

      <div className='list-details'>
        <div className='first-row'>
          <h3 className='primary-text'>{destination?.historyDetails?.title}</h3>

          <div className='icons'>
            <img
              width={20}
              src={UI.STAR}
              alt='star'
              className='star'
            />

            <p className='rating'>{destination?.reviews?.overAllRating}</p>

            <div
              className='best-value'
              style={{
                display: destination?.isBestValue ? 'flex' : 'none',
              }}
            >
              <IconTint
                src={UI.VERIFIED}
                maxWidth={20}
                maxHeight={20}
                color={'#006fb9'}
              />

              <p className='verified-text'>Best Value</p>
            </div>
          </div>
        </div>

        <div className='line'></div>

        <div className='second-row'>
          <div className='first-line'>
            <div className='location'>
              <IconTint
                src={UI.LOCATION}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-location'>{destination?.origin}</p>
            </div>

            <div className='calendar'>
              <IconTint
                src={UI.CALENDAR}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-date'>{destination?.departureDate}</p>
            </div>
          </div>

          <div className='second-line'>
            <IconTint
              src={UI.STAR}
              maxWidth={20}
              maxHeight={20}
              color={'#646464'}
            />

            <p className='hotel-address'>{destination?.destination}</p>
          </div>
        </div>

        <div className='third-row'>
          <p className='rating'>
            Your Rating:{' '}
            {userRatingFn(
               destination?.reviews?.customerRating,
               destination?.reviews?.hasRating,
              _this,
              destination.bookingId,
              'ferry',
            )}
          </p>

          <div className='price'>
            <p className='text-header'>
              <span>Amount Paid Php</span>{' '}
              {destination?.paymentDetails?.grandtotal.toLocaleString()}
            </p>

            {destination?.isInclusive && (
              <span className='tax-inclusive'>Inclusive of Taxes</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const userRatingFn = (userRating, rating, _this, id, type) => {
  if (!rating) {
    return (
      <Button
        className='rate-now-button'
        onClick={(e) => {
          e.stopPropagation();
          _this.openReviewModal(id, type);
        }}
      >
        Rate Now
      </Button>
    );
  }

  return <>{userRating}</>;
};
