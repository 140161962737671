import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

export default class Beds extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View style={{...styles.main_container, alignItems: width <= 650 ? "center" : "flex-start"}}>
        <Header text={"WHERE YOU'LL SLEEP"} />
        <UI.Row _this={_this} breakpoint={650}>
          <UI.Row _this={_this} breakpoint={950}>
            <BedItem _this={_this} main={"Bedroom Area"} sub={"1 Queen Bed"} />
            <BedItem _this={_this} main={"Bedroom Area"} sub={"1 Single Bed"} />
          </UI.Row>
          <BedItem _this={_this} main={"Common Area"} sub={"1 Queen Bed"} />
        </UI.Row>
      </View>
    );
  }
}

const BedItem = (props = { _this: null, main: "", sub: "" }) => {
  return (
    <TouchableOpacity>
      <View style={styles.bed_item}>
        <Image height={100} width={100} tintColor={"#787878"} source={UI.BED} />
        <Text style={styles.header_bed}>{props.main}</Text>
        <Text style={styles.header_sub}>{props.sub}</Text>
      </View>
    </TouchableOpacity>
  );
};

const Header = (props = { text: "" }) => {
  return (
    <View style={{ width: "100%" }}>
      <Text style={styles.header_text}>{props.text}</Text>
      <View style={styles.line}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  bed_item: {
    height: 180,
    width: 180,
    backgroundColor: "white",
    borderRadius: 5,
    marginRight: 30,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "#B3B3B3",
    marginTop: 5,
    marginBottom: 20,
  },
  header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
    marginTop: 20,
  },
  header_bed: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
  },
  header_sub: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#7E7E7E",
  },
  main_container: {
    width: "100%",
  },
});
