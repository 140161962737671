import React from 'react';
import { useTable, usePagination } from 'react-table';
//css
import './SailingScheduleTable.scss';
function SailingScheduleTable({ schedules }) {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Origin',
        accessor: 'origin',
      },
      {
        Header: 'Day',
        accessor: 'dayDeparture',
      },

      {
        Header: ' Departure',
        accessor: 'departure',
      },
      {
        Header: 'Destination',
        accessor: 'destination',
      },
      {
        Header: 'Day',
        accessor: 'dayDestination',
      },
      {
        Header: 'Arrival',
        accessor: 'arrival',
      },
    ],
    [],
  );

  const getDayFromDate = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const day = new Date(date);

    return days[day.getDay()];
  };

  // Data of the table
  const data = React.useMemo(() => {
    // Let's assume this data is from API
    if (!schedules) {
      return [];
    }

    return schedules.map((data) => {
      return {
        origin: data?.origin,
        dayDeparture: getDayFromDate(data?.departure_date),
        departure: data?.departure_time,
        destination: data.destination,
        dayDestination: getDayFromDate(data?.arrival_date),
        arrival: data.arrival_time,
      };
    });
  }, [schedules]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  return (
    <div
      className='sailSchedule-container-table'
      style={{ overflowX: 'scroll' }}
    >
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SailingScheduleTable;
