import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';
import UI from '../../shared/Components/UI/js';

export default class FilterInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    const _this = props._this;
    const isActive = _this.state.active_input === props.title ? true : false;
    const { value, disabled } = props;

    return (
      <View
        style={{ height: 45, width: '100%' }}
        className={this.props.className}
      >
        <View
          style={{
            ...styles.filter_input,
            borderColor: isActive ? UI.colors.primary : '#c7c7c7',
          }}
        >
          <Text style={styles.filter_title}>{props.title}</Text>
          <View style={styles.filter_input_container}>
            {UI.box(8)}
            <View style={styles.filter_icon}>
              <View
                style={{
                  alignSelf: 'center',
                  display: isActive ? 'flex' : 'none',
                }}
              >
                <Image
                  width={22}
                  height={22}
                  source={props.icon}
                  tintColor={UI.colors.primary}
                />
              </View>
              <View
                style={{
                  alignSelf: 'center',
                  display: !isActive ? 'flex' : 'none',
                }}
              >
                <Image
                  width={22}
                  height={22}
                  source={props.icon}
                  tintColor={value ? '#646464' : '#c7c7c7'}
                />
              </View>
            </View>
            <input
              onChange={props.onChange}
              onFocus={() => {
                _this.setState({
                  active_input: props.title,
                });
              }}
              onBlur={() => {
                _this.setState({
                  active_input: '',
                });
              }}
              style={{
                ...styles.filter_input_tag,
                pointerEvents: disabled ? 'none' : 'all',
              }}
              placeholder={props.placeholder}
              defaultValue={value}
              type={props.type}
              min={props.min}
              max={props.max}
              readOnly={props.readOnly}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  filter_input_tag: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 10,
    fontSize: 12,
    backgroundColor: 'transparent',
  },
  filter_icon: {
    alignSelf: 'center',
  },
  filter_input_container: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    marginTop: -3,
  },
  filter_title: {
    backgroundColor: 'white',
    height: 14,
    fontSize: 12,
    fontWeight: 'bolder',
    color: UI.colors.primary,
    paddingLeft: 3,
    paddingRight: 3,
    display: 'flex',
    marginLeft: 7,
    marginTop: -10,
    whiteSpace: 'nowrap',
  },
  filter_input: {
    flex: 1,
    height: 45,
    borderStyle: 'solid',
    borderWidth: 1.5,
    borderRadius: 5,
    alignItems: 'flex-start',
  },
});
