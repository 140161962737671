import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';

import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BookingInfo from './BookingInfo';
import HotelReserve from './HotelReserve';
import Availability from './Availability';
import Information from './Information';

// API
import { getSinglePastBooking } from '../../../shared/API/request';

class HistoryDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      active_link: 'HOME',
      history_data: null,
      loading: true,
      notFound: false,
    });

    // Get ID from URL
    const historyID = this.props.match.params.id;
    const type = new URLSearchParams(window.location.search).get('type');

    // console.log(historyID);
    // console.log(params);

    getSinglePastBooking(type, historyID, this.callback);
  }

  // Callback after get api for searchhoteltours
  callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          history_data: data.data,
          loading: false,
        });
      }

      this.setState({
        meta: data.meta,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else if (status === 404) {
      this.setState({
        loading: false,
        notFound: true,
      });
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.history_data);
  }

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;

    const image_width = width <= 830 ? width : width / 3;

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

          {/* Booking Not Found */}
          {this.state.notFound && !this.state.loading && (
            <View style={{ width: width }}>
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingRight: paddingX,
                  marginTop: 50,
                  display: 'flex',
                  height: '15em',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <h2
                    style={{
                      fontSize: 36,
                      fontWeight: 'bold',
                      color: '#006fb9',
                    }}
                  >
                    Booking Not Found
                  </h2>
                </div>
              </UI.Row>
            </View>
          )}

          {/* Loading... */}
          {this.state.loading && (
            <>
              <View style={{ width: width }}>
                <UI.Row
                  breakpoint={850}
                  _this={this}
                  style={{
                    width: '100%',
                    paddingLeft: paddingX,
                    paddingRight: paddingX,
                    marginTop: 50,
                    display: 'flex',
                    height: '15em',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <h2
                      style={{
                        fontSize: 36,
                        fontWeight: 'bold',
                        color: '#006fb9',
                      }}
                    >
                      Loading...
                    </h2>
                  </div>
                </UI.Row>
              </View>
            </>
          )}

          {this.state.history_data && this.state.loading === false && (
            <>
              <View>
                <ScrollView style={{ width: width }} horizontal={true}>
                  <View
                    style={{ flexDirection: 'row', width: width, height: 300 }}
                  >
                    {this.state.history_data.historyDetails.photos.map((image) => (
                      <img
                        src={image.file_url || image.image_url}
                        style={{
                          height: 300,
                          width: image_width,
                          objectFit: ImageFit.COVER,
                        }}
                        alt={image.filename}
                      />
                    ))}
                  </View>
                </ScrollView>
              </View>

              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingRight: paddingX,
                  marginTop: 50,
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    width: width <= 830 ? '100%' : '70%',
                    paddingRight: paddingX,
                  }}
                >
                  <BookingInfo _this={this} />
                </div>

                <div
                  style={{
                    flexDirection: 'column',
                    width: width <= 830 ? '100%' : '30%',
                  }}
                >
                  {/* {UI.box(width <= 830 ? 30 : 100)} */}
                  <HotelReserve _this={this} />

                  <div
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center',
                      flexDirection: 'row',
                      flex: 1,
                    }}
                  >
                    {/* <Information _this={this} /> */}
                  </div>
                </div>
              </UI.Row>
            </>
          )}

          {/* End Contents Here */}
          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(HistoryDetails);

const styles = StyleSheet.create({
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
});
