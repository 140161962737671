import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';
import UI from '../../shared/Components/UI/js';
import { Button } from 'react-bootstrap';

import bookingHistoryList from './booking_history.json';

// CSS
import './ItemList.scss';
import { height } from '@mui/system';

export default class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    const filteredDestinations = _this.state.filteredDestinations;

    return (
      <View
        style={{ flex: 1 }}
        className='history-item-container'
      >
        {filteredDestinations &&
          filteredDestinations.length > 0 &&
          filteredDestinations.map((destination) => {
            console.log(destination);
            return (
              <HotelItem
                key={destination.bookmarkDetails?.id}
                _this={_this}
                id={destination.bookmarkId}
                image={
                  destination.bookmarkDetails?.primaryImage ||
                  destination.bookmarkDetails?.primaryImageURL
                }
                title={
                  destination.bookmarkDetails?.name ||
                  destination.bookmarkDetails?.title
                }
                rating={destination.bookmarkDetails?.rating}
                location={destination.bookmarkDetails?.location}
                date={
                  destination.bookmarkDetails?.start_date ||
                  destination.bookmarkDetails?.checkin_date
                }
                address={
                  destination.bookmarkDetails?.places ||
                  destination.bookmarkDetails?.landmarks
                }
                persons={destination.bookmarkDetails?.persons_left}
                price={destination.bookmarkDetails?.price}
                userRating={
                  destination.bookmarkDetails?.rating
                    ? destination.bookmarkDetails?.rating
                    : null
                }
                slug={destination?.bookmarkDetails?.slug}
                type={
                  destination?.bookmarkDetails?.rooms
                    ? 'hotel-details'
                    : 'tour-details'
                }
              />
            );
          })}
      </View>
    );
  }
}

const HotelItem = (
  props = {
    key: null,
    _this: null,
    id: null,
    title: '',
    rating: '',
    isVerified: false,
    location: '',
    date: '',
    address: '',
    price: '',
    isInclusive: false,
    rooms: '',
    image: '',
    isBestValue: false,
    guest: 0,
    userRating: null,
    slug: '',
    type: '',
  },
) => {
  const _this = props._this;
  const width = _this.state.width;
  const isMobile = _this.state.isMobile;

  const onClick = () => {
    UI.goTo(`/${props.type}/${props.slug}`);
  };

  return (
    <div
      className='history-item-list'
      onClick={onClick}
      key={props.id}
    >
      <ImageBackground
        imageFit={ImageFit.COVER}
        style={{
          ...styles.hotel_image,
          height: width <= 425.98 ? '200px' : '100%',
          width: width <= 425.98 ? '100%' : 'auto',
        }}
        source={props.image}
      />
      <View style={styles.flex}>
        <View
          style={{
            ...styles.hotel_top_container,
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Text style={styles.primary_text}>{props.title}</Text>
          <View style={styles.flex}></View>
          <View style={styles.fl_ai}>
            <Image
              height={20}
              width={20}
              tintColor={UI.colors.yellow}
              source={UI.STAR}
            />
            {UI.box(5)}
            <Text style={styles.rating_text}>{props.rating}</Text>
            {UI.box(15)}
            <View
              style={{
                flexDirection: 'row',
                display: props.isBestValue ? 'flex' : 'none',
              }}
            >
              <Image
                height={20}
                width={20}
                tintColor={UI.colors.primary}
                source={UI.VERIFIED}
              />
              {UI.box(5)}
              <Text style={styles.verified_text}>Best Value</Text>
            </View>
          </View>
        </View>
        <View style={styles.hotel_line}></View>
        <View style={styles.hotel_center}>
          <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <View
              style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
            >
              <Image
                height={20}
                width={20}
                tintColor={'#646464'}
                source={UI.LOCATION}
              />
              {UI.box(5)}
              <Text style={styles.hotel_location}>{props.location}</Text>
            </View>
            <View style={styles.hotel_calendar}>
              <Image
                height={20}
                width={20}
                tintColor={'#646464'}
                source={UI.CALENDAR}
              />
              {UI.box(5)}
              <Text style={styles.hotel_date}>{props.date}</Text>
            </View>
          </View>
          {UI.box(10)}
          <View style={styles.hotel_address_container}>
            <Image
              height={20}
              width={20}
              tintColor={'#646464'}
              source={UI.STAR}
            />
            {UI.box(5)}
            <Text style={styles.hotel_address}>{props.address}</Text>
          </View>
          {UI.box(10)}
        </View>
        <View
          style={{
            ...styles.hotel_bottom,
            flexDirection: width < 540 ? 'column' : 'row',
          }}
        >
          <View
            style={{
              ...styles.hotel_bottom_content,
              flexDirection: width < 540 ? 'column' : 'row',
            }}
          >
            <Text style={styles.primary_white}>
              {userRatingFn(props.userRating, _this, props.id)}
            </Text>
            {UI.box(10)}
            {/* {props.isInclusive && (
              <Text style={styles.hotel_bottom_sub}>Inclusive of Taxes</Text>
            )} */}
          </View>

          <span style={styles.primary_white}>Php {props.price}</span>
        </View>
      </View>
    </div>
  );
};

const userRatingFn = (rating, _this, id) => {
  return (
    <Button
      className='rate-now-button'
      onClick={(e) => {
        e.stopPropagation();
        _this.openReviewModal(id);
      }}
    >
      Remove Bookmark
    </Button>
  );

  return rating;
};

const styles = StyleSheet.create({
  hotel_bottom_sub: {
    color: 'white',
    fontSize: 12,
  },
  hotel_bottom_content: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  hotel_bottom: {
    backgroundColor: '#43980F',
    borderBottomRightRadius: 10,
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: 10,
  },
  hotel_address: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_address_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_calendar: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_location: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_center: {
    flex: 1,
    padding: 15,
  },
  hotel_line: {
    height: 1,
    width: '100%',
    backgroundColor: '#E8E8E8',
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
  rating_text: {
    fontSize: 14,
    color: UI.colors.yellow,
    fontWeight: 'bold',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  hotel_top_container: {
    alignSelf: 'stretch',
    padding: 15,
  },
  hotel_image: {
    height: '100%',
    width: 150,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    minWidth: 150,
  },
  primary_white: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
  primary_text: {
    fontWeight: 'bold',
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: 'flex-start',
  },
});
