// Packages
import React, { Component } from 'react';

// Components
import UI from '../../shared/Components/UI/js';
import MainHeaderWeb, { MainHeaderMobile } from './MainHeader';
import SubHeaderWeb, { SubHeaderMobile } from './SubHeader';
import { View, StyleSheet } from '../../shared/custom-react-native';

// Context
import './Header.scss';

// Main Component
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
    };
  }

  // Will run if component is unmounting
  componentWillUnmount = () => {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  };

  // To render on screen
  render() {
    // Get this value from Mainlanding as _this
    const _this = this.props._this;

    // Variable for getting current screen width
    const width = _this.state.width;

    // if (!_this.state.isMobile) {
    return (
      <View className="header-web">
        <MainHeaderWeb _this={_this} headerThis={this}/>
        <SubHeaderWeb _this={_this} headerThis={this}/>
      </View>
    );
  }
}
