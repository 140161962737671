import React, { useState, useEffect } from 'react';

import './StarRating.scss';

const StarRating = ({_this}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

    useEffect(() => {
        _this.setState({rating: rating})
    },[rating])

  return (
    <div className='star-rating'>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type='button'
            key={index}
            className={index <= (hover || rating) ? 'on' : 'off'}
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            onDoubleClick={() => {
              setRating(0);
              setHover(0);
            }}
          >
            <span className='star'>&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
