import React, { useState, useEffect } from 'react';
import { ReactPhotoCollage } from 'react-photo-collage';
import ConditionalRender from '../components/ConditionalRender';

function Collage({ gallerySettings }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [gallerySettings]);

  const settings = {
    width: '100%',
    height: ['400px', '170px'],
    layout: [1, 4],
    photos: gallerySettings.photos,
    showNumOfRemainingPhotos: true,
  };

  const isNotLoading = loading === false;

  return (
    <>
      <ConditionalRender
        condition={isNotLoading}
        renderIf={<ReactPhotoCollage {...settings} />}
      />
    </>
  );
}

export default Collage;
