import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import FilterInput from '../../components/FilterInput';
import NumberInput from '../../components/NumberInput';

export default class FilterInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;

    return (
      <View style={{ ...styles.filter_inputs_container }}>
        <FilterInput
          onChange={(e) => {
            _this.setState({
              search_city: e.target.value,
            });
          }}
          _this={_this}
          title={'Tour Name, Destination, Location'}
          placeholder={'Tour Name Here'}
          icon={UI.DESTINATION}
          value={_this.state.search_city}
        />
        {UI.box(15)}
        <FilterInput
          onChange={(e) => {
            _this.setState({
              search_check_in: e.target.value,
            });
          }}
          value={_this.state.search_check_in}
          _this={_this}
          title={'Date Start'}
          icon={UI.CALENDAR}
          className='checkIn'
          type='date'
          min={new Date().toISOString().split('T')[0]}
          max={_this.state.search_check_out}
        />
        {UI.box(15)}
        <FilterInput
          onChange={(e) => {
            _this.setState({
              search_check_out: e.target.value,
            });
          }}
          value={_this.state.search_check_out}
          _this={_this}
          title={'Date End'}
          icon={UI.CALENDAR}
          className='checkOut'
          type='date'
          min={_this.state.search_check_in}
          max={'2999-08-25'}
        />
        {UI.box(15)}
        <NumberInput
          onChange={(e) => {
            _this.setState({
              search_guest: e,
            });
          }}
          _this={_this}
          title={'Guests'}
          placeholder={'Guest Number Here'}
          icon={UI.PROFILE}
          min='1'
          className='guest'
          type='text'
          value={_this.state.search_guest}
        />
        <TouchableOpacity
          onClick={_this.search}
          style={styles.search_hotels_btn}
        >
          <View style={{ alignSelf: 'center' }}>
            <Image
              width={22}
              height={22}
              source={UI.MAGNIFYING_2}
              tintColor={'white'}
            />
          </View>
          {UI.box(5)}
          <Text style={styles.search_hotel_text}>SEARCH</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const TabButton = (
  props = { _this: null, text: '', icon: UI.HOTEL_2, type: '' },
) => {
  const _this = props._this;
  let isActive = _this.state.selected_tab === props.text ? true : false;
  let search = _this.state.selected_tab === '';

  return (
    <TouchableOpacity
      onClick={(e) => {
        _this.setState({
          selected_tab: props.text,
          search_type: props.text,
        });
      }}
      style={{
        ...styles.tab_button_container,
        backgroundColor: isActive ? UI.colors.primary : '#C4C4C4',
      }}
    >
      <View
        style={{ alignSelf: 'center', display: isActive ? 'flex' : 'none' }}
      >
        <Image
          source={props.icon}
          tintColor={'white'}
          height={24}
          width={24}
        />
      </View>
      <View
        style={{ alignSelf: 'center', display: !isActive ? 'flex' : 'none' }}
      >
        <Image
          source={props.icon}
          tintColor={'#888888'}
          height={24}
          width={24}
        />
      </View>
      {UI.box(10)}
      <Text
        style={{
          color: isActive ? 'white' : '#888888',
          fontSize: 13,
          fontWeight: 'bold',
        }}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  filter_inputs_container: {
    borderRadius: 10,
    backgroundColor: 'white',
    alignSelf: 'stretch',
  },
  filter_input: {
    flex: 1,
    height: 45,
    borderStyle: 'solid',
    borderWidth: 1.5,
    borderRadius: 5,
    alignItems: 'flex-start',
  },
  search_hotel_text: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  },
  search_hotels_btn: {
    height: 45,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    width: '100%',
    justifyContent: 'center',
    marginTop: 20,
  },
  tab_button_container: {
    height: 40,
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'row',
    marginBottom: 30,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
});
