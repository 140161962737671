import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import { Button } from 'react-bootstrap';
import IconTint from 'react-icon-tint';

// CSS
import './ItemList.scss';

export default class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    const filteredDestinations = _this.state.filteredDestinations;

    console.log('asdsadsad', filteredDestinations);

    return (
      <div className='list-cont'>
        {filteredDestinations &&
          filteredDestinations.length > 0 &&
          filteredDestinations.map((destination) => {
            return <Item _this={_this} destination={destination} />;
          })}

        {!filteredDestinations ||
          (!filteredDestinations.length > 0 && (
            <div>
              <h2 style={{ fontSize: 16 }}>
                No Past Booking, Book more and enjoy.
              </h2>
            </div>
          ))}
      </div>
    );
  }
}

const Item = ({ _this, destination }) => {
  const onClick = () => {
    UI.goTo(`/booking-history/${destination.id}?type=${destination.type}`);
  };

  return (
    <div onClick={onClick} className='list-item'>
      <img
        className='list-image'
        src={destination?.primaryImageURL}
        alt={destination?.name}
      />

      <div className='list-details'>
        <div className='first-row'>
          <h3 className='primary-text'>{destination?.title}</h3>

          <div className='icons'>
            <img width={20} src={UI.STAR} alt='star' className='star' />

            <p className='rating'>{destination?.overAllRating}</p>

            <div
              className='best-value'
              style={{
                display: destination?.isBestValue ? 'flex' : 'none',
              }}
            >
              <IconTint
                src={UI.VERIFIED}
                maxWidth={20}
                maxHeight={20}
                color={'#006fb9'}
              />

              <p className='verified-text'>Best Value</p>
            </div>
          </div>
        </div>

        <div className='line'></div>

        <div className='second-row'>
          <div className='first-line'>
            <div className='location'>
              <IconTint
                src={UI.LOCATION}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-location'>{destination?.location}</p>
            </div>

            <div className='calendar'>
              <IconTint
                src={UI.CALENDAR}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-date'>{destination?.checkIn}</p>
            </div>
          </div>

          <div className='second-line'>
            <IconTint
              src={UI.STAR}
              maxWidth={20}
              maxHeight={20}
              color={'#646464'}
            />

            <p className='hotel-address'>{destination?.places}</p>
          </div>
        </div>

        <div className='third-row'>
          <p className='rating'>
            Your Rating:{' '}
            {userRatingFn(
              destination.customerRating,
              destination.hasRating,
              _this,
              destination.id,
              destination.type,
            )}
          </p>

          <div className='price'>
            <p className='text-header'>
              <span>Amount Paid Php</span> {destination?.totalPrice}
            </p>

            {destination?.isInclusive && (
              <span className='tax-inclusive'>Inclusive of Taxes</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const userRatingFn = (userRating, rating, _this, id, type) => {
  if (!rating) {
    return (
      <Button
        className='rate-now-button'
        onClick={(e) => {
          e.stopPropagation();
          _this.openReviewModal(id , type);
        }}
      >
        Rate Now
      </Button>
    );
  }

  return <>{userRating}</>;
};

const styles = StyleSheet.create({
  hotel_bottom_sub: {
    color: 'white',
    fontSize: 12,
  },
  hotel_bottom_content: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  hotel_bottom: {
    backgroundColor: '#43980F',
    borderBottomRightRadius: 10,
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: 10,
  },
  hotel_address: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_address_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_calendar: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_location: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_center: {
    flex: 1,
    padding: 15,
  },
  hotel_line: {
    height: 1,
    width: '100%',
    backgroundColor: '#E8E8E8',
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
  rating_text: {
    fontSize: 14,
    color: UI.colors.yellow,
    fontWeight: 'bold',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  hotel_top_container: {
    alignSelf: 'stretch',
    padding: 15,
  },
  hotel_image: {
    height: '100%',
    width: 150,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    minWidth: 150,
  },
  primary_white: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
  primary_text: {
    fontWeight: 'bold',
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: 'flex-start',
  },
});
