import React, { Component } from 'react';
import { ReactPhotoCollage } from 'react-photo-collage';
import { Row, Col } from 'react-bootstrap';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import { NumberInputStepper } from '@chakra-ui/react';
import {
  removeSelectedBookmark,
  toursBookmarksubmit,
} from '../../../shared/API/request';
import Notification from '../../../shared/Components/Notification/Notification';

export default class HotelInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collageSettings: {},
      filteredList: {},
      isBookmarked: null,
      bookmarkId: null,
      disabledbtn: false,
    };
  }

  getDirection = (url) => {
    if (url.get_directions) {
      window.open(url.get_directions, '_blank');
    }
  };

  gotoWebsite = (url) => {
    if (url.website_url) {
      window.open(url.website_url, '_blank');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      const images = this.props.filteredList[0].images.map((data) => {
        return {
          source: data.file_url,
        };
      });

      this.setState({
        filteredList: this.props.filteredList[0],
        collageSettings: {
          width: '100%',
          height: ['400px', '170px'],
          layout: [1, 4],
          photos: images,
          showNumOfRemainingPhotos: true,
        },
      });
    }
  }

  bookmarkThis = (stateData, userData) => {
    const { isBookMarked, bookmarkId } = stateData;
    const { slug, id } = stateData.filteredList[0];
    const { user } = userData;

    if (isBookMarked) {
      const params = {
        bookmarkId: bookmarkId,
      };
      this.setState({ disabledbtn: true });
      removeSelectedBookmark(params, this.removebookmarkcallback);
    } else {
      const params = {
        guest_id: user.id,
        slug: slug,
        url: this.props._this.props.location.pathname,
        type: 'hotel',
        refId: id,
      };
      this.setState({ disabledbtn: true });
      toursBookmarksubmit(params, this.callback);
    }
  };

  removebookmarkcallback = async (res) => {
    const { status } = await res;
    const _this = this.props._this;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        this.setState({ disabledbtn: false });
        _this.getBookmarkStatus(
          this.props.data.user.id,
          _this.state.filteredList[0].slug,
        );
        Notification('Successfully removed from Bookmark.', 'success');
      }

      if (status === 401 || status === 422) {
        this.setState({ disabledbtn: false });
        Notification('An Error Occured', 'danger');
      }
    }
  };

  callback = async (res) => {
    const { status } = await res;
    const _this = this.props._this;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        this.setState({ disabledbtn: false });
        _this.getBookmarkStatus(
          this.props.data.user.id,
          _this.state.filteredList[0].slug,
        );
        Notification('Successfully added to Bookmark.', 'success');
      }

      if (status === 401 || status === 422) {
        this.setState({ disabledbtn: false });
        Notification('An Error Occured', 'danger');
      }
    }
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const isBookMarked = _this.state.isBookMarked;
    const { isAuth } = this.props.data;

    return (
      <View style={{ width: '100%' }}>
        {Object.getOwnPropertyNames(this.state.filteredList).length > 0 ? (
          <>
            <Text
              style={{ fontSize: 16, fontWeight: 'bold', color: '#ADADAD' }}
            >
              HOME / HOTELS / HOTEL LIST /{' '}
              {this.state.filteredList.title.toUpperCase()}
            </Text>

            <UI.Row
              breakpoint={850}
              _this={_this}
            >
              <Text style={styles.hotel_title}>
                {UI.titleCase(this.state.filteredList.title)}
              </Text>
              {UI.box(width <= 850 ? 0 : 20)}
              <Verified width={width} />
            </UI.Row>

            <UI.Row
              breakpoint={850}
              _this={_this}
              style={{ width: 600 }}
            >
              <Location text={UI.titleCase(this.state.filteredList.location)} />
              <OpenedDate
                text={UI.titleCase(this.state.filteredList.date_opened)}
              />
              <Rating
                text={UI.titleCase(`${this.state.filteredList.rating}`)}
              />
            </UI.Row>

            <Places text={UI.titleCase(this.state.filteredList.places)} />

            <UI.Row
              breakpoint={850}
              _this={_this}
              style={{ width: '100%' }}
            >
              <DeskHours
                text={UI.titleCase(this.state.filteredList.reception_hours)}
              />

              {this.state.filteredList.aminities &&
                this.state.filteredList.aminities.length > 3 && (
                  <Amenities
                    text={this.state.filteredList.aminities
                      .slice(0, 4)
                      .map((value, index) =>
                        index === 4 - 1
                          ? UI.titleCase(value.desc) + '...'
                          : UI.titleCase(value.desc) + ', ',
                      )}
                  />
                )}
            </UI.Row>

            {UI.box(20)}
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                style={styles.custom_btn}
                onClick={() => this.getDirection(this.state.filteredList)}
              >
                <Image
                  height={25}
                  width={25}
                  tintColor={'white'}
                  source={UI.MAP}
                />
                {UI.box(10)}
                <Text style={styles.custom_btn_text}>GET DIRECTIONS</Text>
              </TouchableOpacity>
              {UI.box(20)}
              <TouchableOpacity
                style={styles.custom_btn}
                onClick={() => this.gotoWebsite(this.state.filteredList)}
              >
                <Image
                  height={25}
                  width={25}
                  tintColor={'white'}
                  source={UI.WEB}
                />
                {UI.box(10)}
                <Text style={styles.custom_btn_text}>HOTEL WEBSITE</Text>
              </TouchableOpacity>
              {UI.box(20)}
              {isAuth && (
                <button
                  style={styles.custom_btn}
                  disabled={this.state.disabledbtn}
                  onClick={() =>
                    this.bookmarkThis(_this.state, this.props.data)
                  }
                >
                  {isBookMarked && (
                    <Text style={styles.custom_btn_text}>REMOVE BOOKMARK</Text>
                  )}

                  {!isBookMarked && (
                    <Text style={styles.custom_btn_text}>ADD TO BOOKMARK</Text>
                  )}
                </button>
              )}

              {/* {UI.box(20)}
              <TouchableOpacity style={styles.custom_btn}>
                <Image
                  height={25}
                  width={25}
                  tintColor={"white"}
                  source={UI.GMAIL}
                />
                {UI.box(10)}
                <Text style={styles.custom_btn_text}>MESSAGE</Text>
              </TouchableOpacity> */}
            </View>

            <Row>
              <Col>
                <div className='gallery mt-4'>
                  {Object.getOwnPropertyNames(this.state.collageSettings)
                    .length > 0 && (
                    <ReactPhotoCollage {...this.state.collageSettings} />
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div>
            <p>Loading</p>
          </div>
        )}
      </View>
    );
  }
}

const Amenities = (props = { text: '' }) => {
  return (
    <View
      style={{ ...styles.hotel_address_container, justifyContent: 'flex-end' }}
    >
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.AMENITIES}
      />
      {UI.box(5)}
      <Text style={styles.hotel_location}>{props.text}</Text>
    </View>
  );
};

const DeskHours = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.TIMER}
      />
      {UI.box(5)}
      <Text style={styles.hotel_location}>
        Reception Desk Hours: {props.text}
      </Text>
    </View>
  );
};

const DurationHours = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.TIMER}
      />
      {UI.box(5)}
      <Text style={styles.hotel_location}>Duration Hours: {props.text}</Text>
    </View>
  );
};

const Places = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.STAR}
      />
      {UI.box(5)}
      <Text style={styles.hotel_location}>Landmarks: {props.text}</Text>
    </View>
  );
};

const Rating = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={UI.colors.yellow}
        source={UI.STAR}
      />
      {UI.box(5)}
      <Text style={styles.rating_text}>
        {props.text && props.text + ' Magnificent'}
      </Text>
      {UI.box(15)}
    </View>
  );
};

const OpenedDate = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.CALENDAR}
      />
      {UI.box(5)}
      <Text style={styles.hotel_date}>Opened {props.text}</Text>
    </View>
  );
};

const Location = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.LOCATION}
      />
      {UI.box(5)}
      <Text style={styles.hotel_location}> {props.text}</Text>
    </View>
  );
};

const Verified = (props = { width: 0 }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: props.width <= 850 ? 'flex-start' : 'center',
      }}
    >
      <Image
        height={20}
        width={20}
        tintColor={UI.colors.primary}
        source={UI.VERIFIED}
      />
      {UI.box(5)}
      <Text style={styles.verified_text}>Verified Provider</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn: {
    height: 40,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  hotel_address_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
  },
  rating_text: {
    fontSize: 14,
    color: UI.colors.yellow,
    fontWeight: 'bold',
  },
  hotel_date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_calendar: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
  hotel_location: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
});
