// Packages
import Switch from 'react-switch';
import React, { Component } from 'react';

// Components
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
} from '../../../shared/custom-react-native';
import { Col, Row, Form } from 'react-bootstrap';
import UI from '../../../shared/Components/UI/js';
import PaymentSelection from './PaymentSelection/PaymentSelection';

// Main Component
export default class BookingDetailsInfoThird extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    const { user, booking } = this.props;

    return (
      <View
        styles={{
          ...styles.main_container,
          width: width <= 830 ? '100%' : 400,
        }}
      >
        <UI.Row
          breakpoint={850}
          _this={_this}
        >
          <View
            style={{
              flexDirection: width <= 768.98 ? 'column' : 'row',
              width: width,
            }}
          >
            {/* Left Content */}
            <View
              style={{
                marginTop: 20,
                paddingRight: paddingX,
                width: width <= 830 ? '100%' : '40%',
              }}
            >
              <Text style={{ ...styles.h3 }}>Enter your Information</Text>

              <View
                style={{
                  flexDirection: width <= 768.98 ? 'column' : 'row',
                  width: width <= 768.98 ? 'auto' : width,
                  height: width <= 768.98 ? 'auto' : 50,
                  marginBottom: 30,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    Country/ Region <Text style={{ color: '#FF0000' }}>*</Text>
                  </Text>
                  <select
                    id='country'
                    style={{
                      fontSize: '13px',
                      border: '1px solid rgb(225, 225, 225)',
                      borderRadius: '5px',
                      height: '80px',
                      width: '100%',
                      paddingLeft: '10px',
                    }}
                    value={_this.state.country}
                    onChange={(e) =>
                      _this.setState({ country: e.target.value })
                    }
                  >
                    <option value='philippines'>Philippines</option>
                  </select>
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '22%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>
                    Phone Number <Text style={{ color: '#FF0000' }}>*</Text>
                  </Text>
                  <input
                    type='text'
                    maxLength={11}
                    id='phoneNum'
                    value={_this.state.mobile}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        _this.setState({ mobile: e.target.value });
                      }
                    }}
                    style={{ ...styles.inputText }}
                  />
                </View>
              </View>

              {/* <View style={{ flexDirection: "row", width: width, height: 50, marginTop: 20, marginBottom: 0 }}>
                <View style={{
                  marginTop: 20,
                  paddingRight: paddingX,
                  height: 60,
                  width: width <= 830 ? '100%' : '30%'
                }}>
                  <Text style={{ ...styles.h3 }}>You want paperless confirmation? </Text>
                </View>

                <View style={{
                  marginTop: 20,
                  paddingRight: paddingX,
                  transform: 'translateX(20%)',
                  height: 60,
                  width: width <= 830 ? '100%' : '22%'
                }}>
                  <Switch onChange={() => _this.paperlessConfirmationHandler(_this.state.paperlessConfirmation)} checked={_this.state.paperlessConfirmation} style={{ width: width }} />
                </View>
              </View> */}

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 20,
                  marginBottom: 0,
                }}
              >
                <View
                  style={{
                    marginTop: 5,
                    paddingRight: paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '35%',
                  }}
                >
                  <Text style={{ ...styles.h5, color: '#8E8E8E' }}>
                    We'll text and email your booking confirmation with travel
                    requirements.{' '}
                  </Text>
                </View>
              </View>

              {/* <View style={{ flexDirection: "row", width: width, height: 50, marginTop: 5 }}>
                            <View style={{
                                marginTop: 20,
                                width: '38%',
                            }}>
                                <ColoredLine color="#D3D3D3" />
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 30 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                width: width <= 830 ? '100%' : '35%'
                            }}>
                                <Text style={{...styles.h3, marginBottom: 20}}>When do you want to pay? </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 0 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                height: 110,
                                width: width <= 830 ? '100%' : '35%'
                            }}>
                              {UI.box(10)}
                              <FilterRadio
                                _this={_this}
                                text={"Pay Later"}
                                value = {"1"}
                                state_name = {"selected_radio"}
                              />
                              {UI.box(10)}
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 0 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                height: 110,
                                width: width <= 830 ? '100%' : '45%'
                            }}>
                              <Text style={{...styles.h5, color:'#8E8E8E', marginLeft: 55}}>The property will handle the payment. The day they charge you will depend on your booking conditions</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 0 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                height: 110,
                                width: width <= 830 ? '100%' : '45%'
                            }}>
                              {UI.box(10)}
                                <FilterRadio
                                  _this={_this}
                                  text={"Pay your Booking on Oct. 8, 2021"}
                                  value = {"2"}
                                  state_name = {"selected_radio"}
                                />
                              {UI.box(10)}
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 0 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                height: 110,
                                width: width <= 830 ? '100%' : '45%'
                            }}>
                              <Text style={{...styles.h5, color:'#8E8E8E', marginLeft: 55}}>We'll only validate your card today, then charge it for the full amount on Oct. 8, 2021.</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 0 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                height: 110,
                                width: width <= 830 ? '100%' : '45%'
                            }}>
                              {UI.box(10)}
                                <FilterRadio
                                  _this={_this}
                                  text={"Pay Now"}
                                  value = {"3"}
                                  state_name = {"selected_radio"}
                                />
                              {UI.box(10)}
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 0 }}>
                            <View style={{
                                marginTop: 0,
                                paddingRight:paddingX,
                                height: 110,
                                width: width <= 830 ? '100%' : '45%'
                            }}>
                              <Text style={{...styles.h5, color:'#8E8E8E', marginLeft: 55}}>Traverse will facilitate your payment. You'll pay today after complete your _this.state.booking.</Text>
                            </View>
                        </View> */}
            </View>

            {/* Right Content               */}
            <View
              style={{
                marginTop: 20,
                paddingLeft: paddingX,
                width: width <= 830 ? '100%' : '50%',
              }}
            >
              <View
                style={{
                  flexDirection: width <= 830 ? 'column' : 'row',
                  width: width <= 830 ? 'auto' : 'auto',
                  minHeight: 200,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    width: width <= 830 ? '100%' : '20%',
                  }}
                >
                  <img
                    height={300}
                    width={300}
                    src={_this.state.booking.hotelArr.primaryImageURL}
                    alt='img'
                    style={{ borderRadius: '10px' }}
                  />
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '50%',
                    paddingLeft: 10,
                  }}
                >
                  <Text style={{ ...styles.h3 }}>
                    Room Hotel in {_this.state.booking.hotelArr.location}
                  </Text>
                  <Text style={{ ...styles.h3 }}>
                    {_this.state.booking.hotelArr.title}
                  </Text>
                  {/* <View style={{ flexDirection: "row" }}>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E', marginRight: 20 }}>Queen Bed: </Text>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E' }}>1 Bath</Text>
                                        </View> */}
                  <View style={{ flexDirection: 'row' }}>
                    <Image
                      height={20}
                      width={20}
                      tintColor={'#FF0000'}
                      source={UI.STAR}
                    />
                    <Text style={{ ...styles.h5, marginLeft: 20 }}>
                      {_this.state.booking.hotelArr.rating} (
                      {_this.state.booking.hotelArr.totalReviews} reviews)
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles.h5,
                      marginTop: '20px',
                      color: '#8E8E8E',
                    }}
                  >
                    This property is in {_this.state.booking.hotelArr.location}.
                  </Text>
                  <Text style={{ ...styles.h5, marginTop: 20 }}>
                    Hotel Amenities
                  </Text>
                  {_this.state.booking &&
                    _this.state.booking.hotelArr.aminities.map((item) => {
                      return (
                        <Text
                          style={{
                            ...styles.h5,
                            color: '#8E8E8E',
                            marginTop: 5,
                          }}
                        >
                          {item.desc}
                        </Text>
                      );
                    })}
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '5%',
                    alignItems: 'right',
                  }}
                ></View>
              </View>

              <View
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
              >
                <ColoredLine color='#D3D3D3' />
              </View>

              <div className='payment-selection'>
                <h3>Pay with</h3>

                <Form>
                  <div className='mb-3'>
                    <Form.Check
                      type='radio'
                      label={`Credit Card `}
                      id={`creditCard`}
                      value='creditcard'
                      name='paymentType'
                      onChange={(e) => {
                        if (e.target.checked) {
                          _this.setState({ paymentType: e.target.value });
                        }
                      }}
                    />
                  </div>

                  <div className='mb-3'>
                    <Form.Check
                      type='radio'
                      label={`Cash on deposit`}
                      value='bank-deposit'
                      id={`cash`}
                      name='paymentType'
                      onChange={(e) => {
                        if (e.target.checked) {
                          _this.setState({ paymentType: e.target.value });
                        }
                      }}
                    />
                  </div>
                  <div className='mb-3'>
                    <Form.Check
                      type='radio'
                      label={`Paymaya`}
                      id={`paymaya`}
                      value='paymaya'
                      name='paymentType'
                      onChange={(e) => {
                        if (e.target.checked) {
                          _this.setState({ paymentType: e.target.value });
                        }
                      }}
                    />
                  </div>
                </Form>
              </div>

              {_this.state.paymentType === 'bank-deposit' && (
                <div className='mt-4 bank-details'>
                  {_this.state.bankAccounts &&
                    _this.state.bankAccounts.length > 0 &&
                    _this.state.bankAccounts.map((data, idx) => {
                      console.log(data);
                      return (
                        <div
                          className='d-flex mb-4'
                          style={{ alignItems: 'center' }}
                        >
                          <img
                            alt='img'
                            src={data?.image_url}
                            height={50}
                            width={50}
                            className='mr-2'
                          />
                          <p
                            key={idx}
                            style={{ margin: '0' }}
                          >
                            {data?.bank_name}
                            <span className='ml-4 '>
                              ( {data?.account_name} -{' '}
                            </span>
                            {data?.account_number} )
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}

              {/* <View style={{ flexDirection: "row", width: width, height: 50, marginTop: 10, marginBottom: 20 }}>
                <View style={{
                    marginTop: 20,
                    paddingRight:paddingX,
                    width: width <= 830 ? '100%' : '22%'
                }}>
                    <Text style={{...styles.h3}}>Pay with</Text>
                </View>

                <View style={{
                    marginTop: 20,
                    paddingRight:paddingX,
                    width: width <= 830 ? '100%' : '5%',
                    alignItems: 'right'
                }}>

                </View>

                <View style={{
                    flexDirection: "row",
                    marginTop: 20,
                    paddingRight:paddingX,
                    width: width <= 830 ? '100%' : '40%'
                }}>
                    <View style={{ marginLeft: 5, transform: 'translateY(-10%)' }}>
                      <Image height={100} width={50} tintColor="" source={UI.ICONS8_MASTERCARD1} />
                    </View>
                    <View style={{ marginLeft: 5, transform: 'translateY(-10%)' }}>
                      <Image height={100} width={50} tintColor="" source={UI.ICONS8_MASTERCARD2} />
                    </View>
                    <View style={{ marginLeft: 5, transform: 'translateY(-10%)' }}>
                      <Image height={100} width={50} tintColor="" source={UI.ICONS8_MASTERCARD3} />
                    </View>
                    <View style={{ marginLeft: 5, transform: 'translateY(-10%)' }}>
                      <Image height={100} width={50} tintColor="" source={UI.ICONS8_MASTERCARD4} />
                    </View>
                </View>
              </View>

              <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 30 }}>
                <View style={{
                    marginTop: 20,
                    paddingRight:paddingX,
                    marginRight: 40,
                    height: 60,
                    width: width <= 830 ? '100%' : '25%'
                }}>
                    <Text style={{...styles.h5}}>Cardholder's Name <Text style={{color:'#FF0000'}}>*</Text></Text>
                    <input type="text" id="cardHolderName" placeholder="Enter Name Here" autoComplete="off" style={{...styles.inputText}} />
                </View>

                <View style={{
                    marginTop: 20,
                    paddingRight:paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '20%'
                }}>
                    <Text style={{...styles.h5}}>Expiration Date <Text style={{color:'#FF0000'}}>*</Text></Text>
                    <input type="date" id="phoneNum" placeholder="+63 | Enter Phone No. Here" autoComplete="off" style={{...styles.inputText}}/>
                </View>
              </View>

              <View style={{ flexDirection: "row", width: width, height: 50, marginBottom: 30 }}>
                <View style={{
                    marginTop: 20,
                    paddingRight:paddingX,
                    marginRight: 40,
                    height: 60,
                    width: width <= 830 ? '100%' : '25%'
                }}>
                    <Text style={{...styles.h5}}>Card Number <Text style={{color:'#FF0000'}}>*</Text></Text>
                    <input type="text" id="cardNum" placeholder="Enter Number Here" autoComplete="off" style={{...styles.inputText}} />
                </View>

                <View style={{
                    marginTop: 20,
                    paddingRight:paddingX,
                    height: 60,
                    width: width <= 830 ? '100%' : '20%'
                }}>
                    <Text style={{...styles.h5}}>CVC <Text style={{color:'#FF0000'}}>*</Text></Text>
                    <input type="text" id="cvc" placeholder="Enter CVC Here" autoComplete="off" style={{...styles.inputText}}/>
                </View>
              </View>

              <View style={{
                  marginTop: 30,
                  width: '100%',
              }}>
                  <ColoredLine color="#D3D3D3" />
              </View> */}
            </View>
          </View>
        </UI.Row>
      </View>
    );
  }
}

const FilterRadio = (
  props = { _this: null, text: '', value: '', state_name: '' },
) => {
  const onClick = () => {
    props._this.setState({
      [props.state_name]: props.value,
    });
  };

  const is_active =
    props._this.state[props.state_name] == props.value ? true : false;

  return (
    <View style={styles.filter_checkbox}>
      <TouchableOpacity
        onClick={onClick}
        style={styles.checkbox_container}
      >
        <input
          type={'button'}
          style={{
            ...styles.checkbox,
            borderRadius: 100,
            backgroundColor: is_active ? UI.colors.primary : 'transparent',
          }}
        />
        {UI.box(10)}
        <Text style={styles.filter_checkbox_text}>{props.text}</Text>
      </TouchableOpacity>
    </View>
  );
};

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const styles = StyleSheet.create({
  sub_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434343',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  main_container: {
    width: '100%',
    marginTop: 30,
  },
  h3: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  h5: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  custom_btn: {
    height: 100,
    width: '100%',
    // backgroundColor: UI.colors.primary,
    backgroundColor: '#F2F2F2',
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_text: {
    color: '#006FB9',
    fontSize: 13,
    fontWeight: 'bold',
  },
  inputText: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 80,
    width: '100%',
    paddingLeft: 10,
  },
  textArea: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 150,
    width: '100%',
    paddingLeft: 10,
  },
  selectOption: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 80,
    width: 250,
    paddingLeft: 10,
  },
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    border: '1px solid #8E8E8E',
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 5,
  },
  filter_text_right: {
    fontWeight: 'bold',
    fontSize: 16,
    color: UI.colors.primary,
    alignSelf: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
  },
  filter_text_left: {
    fontWeight: 'bold',
    fontSize: 16,
    color: UI.colors.primary,
    alignSelf: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
    flex: 1,
  },
  filter_text_container: {
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  line_divider: {
    height: 0.5,
    alignSelf: 'stretch',
    backgroundColor: '#c0c0c0',
  },
  filter_title: {
    fontWeight: 'bold',
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 15,
  },
  right_container: {
    backgroundColor: 'white',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
});
