import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

export default class HotelDescription extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    const filteredList = this.props.filteredList;

    return (
      <View style={styles.main_container}>
        {filteredList.map(fl => (
        <>
          <View style={styles.header_container}>
            <UI.Row _this={_this} breakpoint={480}>
              <Text style={styles.header_text}>Description</Text>
              {UI.box(5)}
              {/* <Text style={styles.sub_text}>- { fl.isExcellentLocation === 1 && "Excellent Location" }</Text> */}
            </UI.Row>
          </View>
          <Text style={styles.description}>
          { fl.description }
          </Text>
        </>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  sub_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
  },
  description: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#434343",
  },
  header_container: {
    flexDirection: "row",
    alignItems: "center",
  },
  main_container: {
    width: "100%",
    marginTop: 30,
  },
});
