// Packages
import React, { useContext, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import { useHistory } from 'react-router-dom';

// Components
import UI from '../../shared/Components/UI/js';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';

// Contexts
import AllContext from '../../shared/Context/AllContext';

// CSS
import 'react-dropdown/style.css';
import './Profile.scss';

const Profile = ({ login }) => {
  // History
  const history = useHistory();

  // Contexts
  const { data, setData } = useContext(AllContext);
  const { user } = data;

  // LocalStorage Data
  const localUser = JSON.parse(localStorage.getItem('user'));

  // Dropdown Options
  const options = [
    { value: 'My Account', label: 'My Account' },
    {
      value: 'Past Bookings',
      label: 'Past Bookings',
      className: 'past-booking',
    },
    {
      value: 'Inquiries',
      label: 'Inquiries',
      className: 'inquiries',
    },
    { value: 'Settings', label: 'Settings', className: 'settings' },
    { value: 'Sign Out', label: 'Sign Out', className: 'signout' },
  ];

  // Default Option == Firstname
  const defaultOption = {
    value: user.firstname,
    label: user.firstname,
  };

  // Will Fire on Dropdown Option Select
  const onSelect = (val) => {
    if (val.value === 'My Account') {
      history.push('/account');
    } else if (val.value === 'Past Bookings') {
      history.push('/booking-history');
    } else if (val.value === 'Settings') {
      history.push('/account-settings');
    } else if (val.value === 'Inquiries') {
      history.push('/inquiries');
    } else if (val.value === 'Sign Out') {
      localStorage.clear();
      setData((prevState) => {
        return { ...prevState, isAuth: false, user: {} };
      });

      history.push('/login');
    }
  };

  // Will render on screen
  return (
    <>
      {!user ||
        (!localUser && (
          <TouchableOpacity
            onClick={login}
            className='signin-btn'
            
          >
            <Image
              width={18}
              height={18}
              source={UI.SIGN_IN}
              tintColor={'white'}
            />
            {UI.box(10)}
            <span className='header-text'>Log In</span>
          </TouchableOpacity>
        ))}

      {user && localUser && (
        <Dropdown
          className='account-settings'
          options={options}
          onChange={onSelect}
          value={defaultOption}
          placeholder={data.firstname}
        />
      )}
    </>
  );
};

export default Profile;
