// Packages
import React, { Component } from "react";

// Components
import Main from "../Components/Main";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StepperUI from "../../components/Stepper";

// Main Component
export default class MyBooking extends Component {
  constructor(props) {
    super(props);

    UI.initiateView(this, { active_link: "MY BOOKINGS" });
  }

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    // console.log("MY BOOKING", _this);

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

            {/* <Text>My Booking</Text> */}
            <Main />
        
          {/* End Contents Here */}
          <Footer _this={this} />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f3f3f3",
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: "bold",
  },
  circle_stepper: {
    height: 25,
    width: 25,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
  },
  stepper_active: {
    borderColor: "dodgerblue",
    backgroundColor: "dodgerblue"
  },
  custom_btn: {
    height: 100,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  custom_btn_text: {
    color: "white",
    fontSize: 13,
    fontWeight: "bold",
  },
});
