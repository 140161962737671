// Packages
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import { ScrollView, View, StyleSheet } from '../../shared/custom-react-native';
import UI from '../../shared/Components/UI/js';
import Header from '../../modules/components/Header';
import Footer from '../../modules/components/Footer';
import TitleHeader from './TitleHeader';

// Contexts

import AllContext from '../../shared/Context/AllContext';
import { getVisaPassport } from '../../shared/API/request';

// CSS
import '../../modules/accountsettings/container/css.css';

import InformationContent from './InformationContent';

// Class
class FerryPortal_OceanJetInformation extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props, context) {
    super(props);

    UI.initiateView(this, {
      active_link: '',
      user: {},
      pass: {},
    });
  }

  // Redirect to Account Profile
  accountProfile = () => {
    UI.goTo('/account');
  };

  // Sign Out Function
  signOut = (setData) => {
    localStorage.clear();
    setData((prevState) => {
      return { ...prevState, isAuth: false, user: {} };
    });
    UI.goTo('/');
  };

  handlePassportVisa = () => {
    UI.goTo('/passport-visa');
  };

  visaPassportData = (id) => {
    getVisaPassport(id, this.callbackVisaPassport);
  };

  callbackVisaPassport = async (response) => {
    const { data } = await response;

    console.log(data);

    this.setState({
      VisaPassportList: data.data,
    });
  };

  // Will re-render everytime a state gets updated
  // componentDidUpdate() {
  //   console.log(this.state.user);
  // }

  // Callback for converted Image to Base64
  callback = (response) => {
    if (response) {
      this.setState({
        user: {
          ...this.state.user,
          profile: response,
        },
      });
    }
  };

  onStateUpdate = (e) => {
    console.log(e);
  };

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;

    // if (Object.keys(this.state.user).length === 0) {
    //   this.setState({
    //     user: user,
    //   });

    //   this.visaPassportData(user.id);
    // }

    // console.log(`USER PROFILE`, user.profile);

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          <TitleHeader _this={_this} />
          <InformationContent
            paddingX={paddingX}
            _this={_this}
          />

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(FerryPortal_OceanJetInformation);

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: '1011',
    backgroundColor: '#B3B3B3',
    marginTop: 5,
    marginBottom: 10,
  },
  SegoeUI: {
    fontFamily: 'Segoe UI',
  },
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 0,
    paddingRight: 30,
    marginBottom: 5,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_header: {
    padding: 12,
    // backgroundColor: UI.colors.primary,
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  main_text: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
  },
  submain_text: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
  sort_select: {
    width: 200,
    borderWidth: 0.5,
    borderColor: '#c0c0c0',
    borderRadius: 10,
    borderStyle: 'solid',
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 10,
    backgroundColor: 'transparent',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sort_text: {
    alignSelf: 'stretch',
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  selectOption: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 50,
    width: '100%',
    paddingLeft: 10,
  },
});
