import React, { useState, useEffect, useContext } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
//components
import { GoLocation } from 'react-icons/go';
import { FaShip } from 'react-icons/fa';
import { BsCalendar3 } from 'react-icons/bs';
import { FcAlarmClock } from 'react-icons/fc';
import { FiMap } from 'react-icons/fi';
import { BsPerson } from 'react-icons/bs';
import { AiFillStar } from 'react-icons/ai';
import { ReactPhotoCollage } from 'react-photo-collage';
import SailingScheduleTable from './SailingScheduleTable';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ScrollView, View, ImageFit } from '../../shared/custom-react-native';
import Notification from '../../shared/Components/Notification/Notification';
import Collage from './Collage';
import moment from 'moment';
// css
import './FerryContent_Maligaya.scss';
import '@splidejs/react-splide/css';
import AllContext from '../../shared/Context/AllContext';
import { deprecatedPropType } from '@mui/material';

function FerryContent_Maligaya({ paddingX, _this }) {
  const context = useContext(AllContext);
  const { data: contextData } = context;
  const [travelType, setTravelType] = useState(null);
  const [gallerySettings, setGallerySettings] = useState({});
  const [data, setData] = useState({});
  const [bookingData, setBookingData] = useState({
    origin: '',
    destination: '',
    departureDate: '',
    returnDate: '',
    passenger: '',
    accomodationFull: '',
    accomodation: '',
    price: '',
    serviceFee: '',
    withInsurance: null,
    insuranceFee: '',
    insuranceTotal: '',
    subTotal: '',
    total: '',
  });

  const [bookingForm, setBookingForm] = useState({
    origins: null,
    destinations: null,
    departureDates: null,
    accomodations: null,
    returnDates: null,
    originalReturnDate: null,
  });

  const history = useHistory();
  const padding_X = `0 ${paddingX}px`;

  const travelTypehandle = (e) => {
    setTravelType(e.target.value);

    setBookingData((prevState) => {
      return { ...prevState, tripType: e.target.value };
    });
  };

  const renderHtmlDetails = (details) => {
    return { __html: details };
  };

  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  const getOrigins = (schedules) => {
    const filtered = schedules.filter(
      (v, i, a) => a.findIndex((v2) => v2.origin === v.origin) === i,
    );

    return filtered.map((data) => {
      return <span>{`${data.origin} - `}</span>;
    });
  };

  const getCurrentMonth = () => {
    const date = new Date();

    let shortMonthName = new Intl.DateTimeFormat('en-US', { month: 'short' })
      .format;

    return `${shortMonthName(date)} ${date.getFullYear()}`;
  };

  const gotoTop = () => {
    const el = document.createElement('a');
    el.href = '#top';

    el.click();
  };

  const handleReserve = () => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      passenger,
      accomodationFull,
      accomodation,
      price,
      insuranceFee,
      insuranceTotal,
      subTotal,
      total,
    } = bookingData;

    const newdepartureDate = departureDate.split('|');
    const newreturnDate = returnDate.split('|');

    console.log(moment(newdepartureDate[1]).format('YYYY-MM-DD'));
    console.log(moment(newreturnDate[1]).format('YYYY-MM-DD'));

    console.log(moment(newdepartureDate[1]).isAfter(newreturnDate[1]));
    if (
      travelType === 'twoway' &&
      moment(newdepartureDate[1]).isAfter(newreturnDate[1])
    ) {
      console.log('cant prooceed');
      Notification('Departure date is ahead of return date', 'danger');
      return;
    }

    if (travelType === 'twoway' && returnDate === '') {
      Notification('Please select a return date', 'danger');
      return;
    }

    if (passenger === 0 || passenger === '0') {
      Notification('Minimum Passenger allowed is 1', 'danger');
      return;
    }

    if (
      origin === '' ||
      destination === '' ||
      departureDate === '' ||
      passenger === '' ||
      accomodationFull === '' ||
      accomodation === '' ||
      price === '' ||
      insuranceFee === '' ||
      insuranceTotal === '' ||
      subTotal === '' ||
      total === ''
    ) {
      Notification('Please fill in the empty fields', 'danger');
      return;
    } else {
      localStorage.setItem(
        'ferry-booking-details',
        JSON.stringify(bookingData),
      );
      localStorage.setItem(
        'ferry-details',
        JSON.stringify(_this.state.selectedFerry),
      );
      history.push('/ferry-portal/ferry-booking-details');
    }
  };

  const arraysEqual = (a1, a2 = []) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

  const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  useEffect(() => {
    if (_this.state.selectedFerry) {
      const { selectedFerry } = _this.state;

      const images = selectedFerry.images.map((image) => {
        return {
          source: image.image_url,
        };
      });

      setData(selectedFerry);

      setBookingForm((prevState) => {
        const { form_attribute, others } = selectedFerry;

        if (form_attribute) {
          return {
            ...prevState,
            origins: form_attribute?.origins,
            accomodations: form_attribute?.accomodationType,
          };
        }

        return {
          ...prevState,
        };
      });

      const newImages = {
        width: '100%',
        height: ['400px', '170px'],
        layout: [1, 4],
        photos: images,
        showNumOfRemainingPhotos: true,
      };

      const equal = arraysEqual(images, gallerySettings.photos);

      if (!equal) {
        setGallerySettings(newImages);
      }
    }

    if (_this.state.selectedFerry) {
      const { selectedFerry } = _this.state;

      setBookingData((prevState) => {
        const { others } = selectedFerry;

        return {
          ...prevState,
          serviceFee: others?.serviceFee,
          insuranceFee: others?.insuranceFee,
        };
      });
    }

    if (_this.state.destinations) {
      setBookingForm((prevState) => {
        return { ...prevState, destinations: _this.state.destinations };
      });
    }

    if (_this.state.departureDates) {
      setBookingForm((prevState) => {
        return { ...prevState, departureDates: _this.state.departureDates };
      });
    }

    if (_this.state.returnDates) {
      setBookingForm((prevState) => {
        return {
          ...prevState,
          returnDates: _this.state.returnDates,
          originalReturnDate: _this.state.returnDates,
        };
      });
    }
  }, [_this.state]);

  useEffect(() => {
    if (bookingData.passenger && bookingData.price) {
      const subTotal = parseFloat(bookingData.passenger * bookingData.price);

      if (subTotal !== bookingData.subTotal) {
        setBookingData((prevState) => {
          return { ...prevState, subTotal: subTotal };
        });
      }
    }

    if (bookingData.subTotal && bookingData.insuranceFee !== '') {
      const totalInsurance = parseFloat(
        (bookingData.withInsurance ? bookingData.insuranceFee : 0) *
          bookingData.passenger,
      );

      const total =
        totalInsurance +
        parseFloat(bookingData.subTotal) +
        parseFloat(bookingData.serviceFee);

      if (total !== bookingData.total) {
        setBookingData((prevState) => {
          return { ...prevState, total: total, insuranceTotal: totalInsurance };
        });
      }
    }
  }, [bookingData]);

  const departureDateValidation = (departureDate) => {
    let newreturnDates = [];
    const newdepartdate = departureDate.split('|');
    const selectedDepartureDate = new Date(newdepartdate[1]);

    bookingForm.originalReturnDate.forEach((element) => {
      const newelement = element.split('|');
      const foreachreturndate = new Date(newelement[1]);
      // console.log(selectedDepartureDate.getHours());
      // console.log(foreachreturndate.getHours());
      if (
        selectedDepartureDate.setHours(0, 0, 0, 0) <
        foreachreturndate.setHours(0, 0, 0, 0)
      ) {
        newreturnDates.push(element);
      }
    });
    console.log(newreturnDates);

    setBookingForm((prev) => {
      return { ...prev, returnDates: newreturnDates };
    });
  };
  return (
    <>
      {data && Object.keys(data).length > 0 ? (
        <>
          {/* For Scrolling to Top */}
          <a id='top'></a>

          {/* Banner Images */}
          {_this.state && _this.state.width && (
            <View>
              <ScrollView
                style={{ width: _this.state.width }}
                horizontal={true}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: _this.state.width,
                    height: 300,
                  }}
                >
                  {data.images &&
                    data.images.length > 0 &&
                    data.images.map((image, idx) => {
                      return (
                        <img
                          key={idx}
                          src={image.image_url}
                          style={{
                            height: 300,
                            width:
                              _this.state.width <= 830
                                ? _this.state.width
                                : _this.state.width / 3,
                            objectFit: ImageFit.COVER,
                          }}
                          alt={image.filename}
                        />
                      );
                    })}
                </View>
              </ScrollView>
            </View>
          )}

          <section className='content  mt-5'>
            <div style={{ padding: padding_X }}>
              <p className='title'>{data?.name}</p>
              <Row>
                <div className='col-lg-6 col-12'>
                  <span className='d-flex centered-label'>
                    <GoLocation className='mr-2' />
                    {` `}
                    {data?.location}
                  </span>
                </div>
                <div className='col-lg-6 col-12'>
                  <span className='d-flex centered-label'>
                    <BsCalendar3 className='mr-2' /> Opened {data?.date_opened}
                  </span>
                </div>
              </Row>

              <Row>
                <div className='col-lg-6 col-12'>
                  <span className='d-flex centered-label'>
                    <FaShip className='mr-2' /> {data?.amenities}
                  </span>
                </div>
                <div className='col-lg-6 col-12'>
                  <span className='d-flex centered-label'>
                    <FcAlarmClock className='mr-2' />
                    Reception Desk Hours: {data?.reception_hours}
                  </span>
                </div>
              </Row>

              <Row>
                {/* Left Content */}
                <Col lg={8}>
                  <div className='mt-4 d-flex flex-sm-row  flex-column  my-2 buttons-cont'>
                    <div>
                      {' '}
                      <button
                        className='btn'
                        onClick={() => openNewTab(data.directions)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='23.063'
                          viewBox='0 0 24 23.063'
                          className='mr-2'
                        >
                          <path
                            id='icons8_map_5'
                            d='M28,4.469l-1.406.625L19.969,7.938,12.344,5.063l-.375-.125-.375.156-7,3L4,8.344V27.531l1.406-.625,6.625-2.844,7.625,2.875.375.125.375-.156,7-3,.594-.25ZM13,7.438l6,2.25V24.563l-6-2.25ZM11,7.5V22.344L6,24.5V9.656Zm15,0V22.344L21,24.5V9.656Z'
                            transform='translate(-4 -4.469)'
                            fill='#fff'
                          />
                        </svg>
                        GET DIRECTIONS
                      </button>
                    </div>

                    <div>
                      {' '}
                      <button
                        className='btn'
                        onClick={() => openNewTab(data.website_url)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='mr-2'
                        >
                          <path
                            id='icons8_globe_earth_1'
                            d='M16,4A12,12,0,1,0,28,16,12.013,12.013,0,0,0,16,4Zm0,2c.18,0,.453.109.813.531a6.9,6.9,0,0,1,1.063,2.031A14.68,14.68,0,0,1,18.313,10H13.688a14.707,14.707,0,0,1,.438-1.437,6.9,6.9,0,0,1,1.063-2.031C15.547,6.109,15.82,6,16,6Zm-3.187.5a10.606,10.606,0,0,0-.594,1.375A17.688,17.688,0,0,0,11.625,10H8A10.021,10.021,0,0,1,12.813,6.5Zm6.375,0A10.02,10.02,0,0,1,24,10H20.375a17.707,17.707,0,0,0-.594-2.125A10.619,10.619,0,0,0,19.188,6.5ZM6.813,12h4.469a28.708,28.708,0,0,0-.25,3H6.063A9.984,9.984,0,0,1,6.813,12Zm6.5,0h5.375a27.023,27.023,0,0,1,.281,3H13.031A27,27,0,0,1,13.313,12Zm7.406,0h4.469a9.986,9.986,0,0,1,.75,3H20.969A28.762,28.762,0,0,0,20.719,12ZM6.063,17h4.969a28.708,28.708,0,0,0,.25,3H6.813A9.984,9.984,0,0,1,6.063,17Zm6.969,0h5.938a27.023,27.023,0,0,1-.281,3H13.313A27,27,0,0,1,13.031,17Zm7.938,0h4.969a9.986,9.986,0,0,1-.75,3H20.719A28.762,28.762,0,0,0,20.969,17ZM8,22h3.625a17.688,17.688,0,0,0,.594,2.125,10.607,10.607,0,0,0,.594,1.375A10.02,10.02,0,0,1,8,22Zm5.688,0h4.625a14.682,14.682,0,0,1-.437,1.438,6.9,6.9,0,0,1-1.062,2.031c-.359.422-.633.531-.812.531s-.453-.109-.812-.531a6.9,6.9,0,0,1-1.062-2.031A14.709,14.709,0,0,1,13.688,22Zm6.688,0H24a10.02,10.02,0,0,1-4.812,3.5,10.62,10.62,0,0,0,.594-1.375A17.707,17.707,0,0,0,20.375,22Z'
                            transform='translate(-4 -4)'
                            fill='#fff'
                          />
                        </svg>
                        VISIT WEBSITE
                      </button>
                    </div>

                    {/* <div>
                      <button className='btn'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='27.368'
                          height='20'
                          viewBox='0 0 27.368 20'
                          className='mr-2'
                        >
                          <path
                            id='icons8_Gmail_logo'
                            d='M6.158,7.031a3.087,3.087,0,0,0-.921.152L8.3,9.137h16.76l3.067-1.953a3.087,3.087,0,0,0-.921-.152Zm-1.986.724A3.135,3.135,0,0,0,3,10.189V23.873a3.164,3.164,0,0,0,3.158,3.158H27.21a3.164,3.164,0,0,0,3.158-3.158V10.189A3.135,3.135,0,0,0,29.2,7.755L16.684,15.715ZM6.158,12.73l10.526,6.7,10.526-6.7v12.2H6.158Z'
                            transform='translate(-3 -7.031)'
                            fill='#fff'
                          />
                        </svg>
                        MESSAGE
                      </button>
                    </div> */}
                  </div>

                  <Row>
                    <Col>
                      <div className='gallery mt-4'>
                        {gallerySettings &&
                          Object.getOwnPropertyNames(gallerySettings).length >
                            0 && (
                            <>
                              <Collage gallerySettings={gallerySettings} />
                            </>
                          )}
                      </div>
                    </Col>
                  </Row>

                  <div className='description'>
                    <p className='description-text'>Description</p>
                    <div
                      dangerouslySetInnerHTML={renderHtmlDetails(data.details)}
                    />
                    ;
                  </div>
                </Col>

                {/* Right Content */}
                <Col
                  lg={4}
                  className='mt-4'
                >
                  <div className='search'>
                    <Row className='mb-4'>
                      <div className='col-6'>
                        <p className='amount'>{`${
                          bookingData.price
                            ? `₱${bookingData.price.toLocaleString()}`
                            : '-'
                        }`}</p>
                        <p className='businessClass'>{`${
                          bookingData.accomodation
                            ? bookingData.accomodation
                            : 'Select Accomodation'
                        }`}</p>
                      </div>
                      <div className='col-6 text-end'>
                        <span className='d-flex centered-label rating justify-content-end'>
                          <AiFillStar color='red' /> {data.others.rating}
                        </span>
                        <p className='review'>{`(${data.others.totalReviews} Reviews)`}</p>
                      </div>
                    </Row>

                    <div className='input-group mb-4'>
                      <label>Origin</label>
                      <div className='input-group-prepend'>
                        <span
                          className='input-group-text'
                          id='basic-addon1'
                        >
                          <GoLocation />
                        </span>
                      </div>
                      <Form.Select
                        value={bookingData.origin}
                        onChange={(e) => {
                          setBookingData((prevState) => {
                            return {
                              ...prevState,
                              origin: e.target.value,
                              destination: '',
                            };
                          });

                          _this.getDestinations(e.target.value);
                        }}
                      >
                        <option
                          value=''
                          hidden
                        >
                          Select Origin
                        </option>
                        {bookingForm.origins &&
                          bookingForm.origins.length > 0 &&
                          bookingForm.origins.map((data, idx) => {
                            return (
                              <option
                                key={idx}
                                value={data}
                              >
                                {data}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>

                    <div className='input-group mb-4'>
                      <label>Destination</label>
                      <div className='input-group-prepend'>
                        <span
                          className='input-group-text'
                          id='basic-addon1'
                        >
                          <FiMap />
                        </span>
                      </div>
                      <Form.Select
                        value={bookingData.destination}
                        onChange={(e) => {
                          setBookingData((prevState) => {
                            return {
                              ...prevState,
                              destination: e.target.value,
                            };
                          });

                          _this.getDepartureDates(
                            bookingData.origin,
                            e.target.value,
                            bookingForm.departureDates,
                          );
                        }}
                      >
                        <option
                          value=''
                          hidden
                        >
                          Select Destination
                        </option>
                        {bookingForm.destinations &&
                          bookingForm.destinations.length > 0 &&
                          bookingForm.destinations.map((data, idx) => {
                            return (
                              <option
                                key={idx}
                                value={data}
                              >
                                {data}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>

                    <Form.Group
                      className='mb-4 form-group'
                      controlId='formTrip'
                    >
                      <Form.Label>Trip</Form.Label>
                      <Form.Select onChange={travelTypehandle}>
                        <option
                          value=''
                          hidden
                        >
                          Select Trip Type
                        </option>
                        <option value='oneway'>One Way</option>
                        <option value='twoway'>Two Way</option>
                      </Form.Select>
                    </Form.Group>

                    {travelType && (
                      <>
                        <div className='input-group mb-4'>
                          <label>Departure Date</label>
                          <div className='input-group-prepend'>
                            <span
                              className='input-group-text'
                              id='basic-addon1'
                            >
                              <BsCalendar3 />
                            </span>
                          </div>
                          <Form.Select
                            value={bookingData.departureDate}
                            onChange={(e) => {
                              setBookingData((prevState) => {
                                departureDateValidation(e.target.value);
                                return {
                                  ...prevState,
                                  departureDate: e.target.value,
                                };
                              });
                            }}
                          >
                            <option
                              value=''
                              hidden
                            >
                              - - -
                            </option>
                            {bookingForm.departureDates &&
                              bookingForm.departureDates.length > 0 &&
                              bookingForm.departureDates.map((data, idx) => {
                                const newelement = data.split('|');

                                return (
                                  <option
                                    key={idx}
                                    value={data}
                                  >
                                    {newelement[0]}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </div>

                        {travelType === 'twoway' && (
                          <div className='input-group mb-4'>
                            <label>Return Date</label>
                            <div className='input-group-prepend'>
                              <span
                                className='input-group-text'
                                id='basic-addon1'
                              >
                                <BsCalendar3 />
                              </span>
                            </div>
                            <Form.Select
                              value={bookingData.returnDate}
                              onChange={(e) => {
                                setBookingData((prevState) => {
                                  return {
                                    ...prevState,
                                    returnDate: e.target.value,
                                  };
                                });
                              }}
                            >
                              <option
                                value=''
                                hidden
                              >
                                - - -
                              </option>
                              {bookingForm.returnDates &&
                                bookingForm.returnDates.length > 0 &&
                                bookingForm.returnDates.map((data, idx) => {
                                  const newelement = data.split('|');
                                  return (
                                    <option
                                      key={idx}
                                      value={data}
                                    >
                                      {newelement[0]}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </div>
                        )}
                      </>
                    )}

                    <div className='input-group mb-4'>
                      <label>Passengers</label>
                      <div className='input-group-prepend'>
                        <span
                          className='input-group-text'
                          id='basic-addon1'
                        >
                          <BsPerson />
                        </span>
                      </div>
                      <input
                        className='form-control'
                        placeholder='Enter Passenger'
                        min='1'
                        max='8'
                        value={bookingData.passenger}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '');

                          if (value === '00' || value === '0') {
                            return;
                          }

                          if (value <= 100) {
                            setBookingData((prevState) => {
                              return {
                                ...prevState,
                                passenger: value,
                                insurance: '',
                                total: '',
                              };
                            });
                          }
                        }}
                      />
                    </div>

                    <Form.Group
                      className='mb-4 form-group'
                      controlId='formGender'
                    >
                      <Form.Label>Accomodation Type</Form.Label>
                      <Form.Select
                        value={bookingData.accomodationFull}
                        onChange={(e) => {
                          setBookingData((prevState) => {
                            const accomodationArr = e.target.value.split('|');
                            return {
                              ...prevState,
                              accomodationFull: e.target.value,
                              accomodation: accomodationArr[0],
                              price: parseFloat(accomodationArr[1]),
                              insurance: '',
                              total: '',
                            };
                          });
                        }}
                      >
                        <option
                          value=''
                          hidden
                        >
                          Select Accomodation
                        </option>
                        {bookingForm.accomodations &&
                          bookingForm.accomodations.length > 0 &&
                          bookingForm.accomodations.map((data, idx) => {
                            return (
                              <option
                                key={idx}
                                value={data}
                              >
                                {data}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className='mb-4 form-group'
                      controlId='formInsurance'
                    >
                      <Form.Label>With Insurance?</Form.Label>
                      <Form.Select
                        value={bookingData.withInsurance}
                        onChange={(e) => {
                          setBookingData((prevState) => {
                            return {
                              ...prevState,
                              withInsurance:
                                e.target.value === 'true' ? true : false,
                            };
                          });
                        }}
                      >
                        <option
                          value=''
                          hidden
                        >
                          ---
                        </option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>

                    <div className='text-center'>
                      {contextData.isAuth && (
                        <button
                          className='btn-reserve  py-3'
                          onClick={() => {
                            handleReserve();
                          }}
                        >
                          <span className='mt-1 text-white'>Reserve</span>
                        </button>
                      )}
                      <p className='wont-charge'>You won't be charge yet</p>
                    </div>
                    <Row>
                      <Col>
                        <p className='brown'>
                          {bookingData.passenger && bookingData.price ? (
                            <>{`₱${bookingData.price} X ${bookingData.passenger}`}</>
                          ) : (
                            <>Price X Passenger</>
                          )}
                        </p>
                      </Col>
                      <Col>
                        <p className='brown'>
                          {bookingData.subTotal ? (
                            <>{`₱${bookingData.subTotal.toLocaleString()}`}</>
                          ) : (
                            <>-</>
                          )}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <p className='brown'>Insurance Fee (per guest)</p>
                      </Col>
                      <Col>
                        <p className='brown'>
                          {bookingData.insuranceFee &&
                            bookingData.passenger &&
                            bookingData.withInsurance && (
                              <>{`₱${bookingData.insuranceFee.toLocaleString()} (₱${parseFloat(
                                bookingData.insuranceFee *
                                  bookingData.passenger,
                              ).toLocaleString()} All)`}</>
                            )}

                          {bookingData.insuranceFee &&
                            bookingData.passenger &&
                            !bookingData.withInsurance && <>₱0</>}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <p className='brown'>Service Fee</p>
                      </Col>
                      <Col>
                        <p className='brown'>
                          ₱
                          {bookingData.serviceFee ? bookingData.serviceFee : ''}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <p className='fw-bold'>Total</p>
                      </Col>
                      <Col>
                        <p className='fw-bold'>
                          {bookingData.total ? (
                            <>{`₱${bookingData.total.toLocaleString()}`}</>
                          ) : (
                            <>-</>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          {/* Sailing Schedule */}
          <section style={{ padding: padding_X }}>
            <Row>
              <Col>
                <Row>
                  <div className='col-6'>
                    <p
                      className='fw-bold mt-4'
                      style={{ color: '#006FB9', margin: '0' }}
                    >
                      Sailing Schedule
                    </p>
                  </div>
                  <div className='col-6 text-end'>
                    <p
                      className='fw-bold mt-4'
                      style={{ color: '#006FB9', margin: '0' }}
                    >
                      {getCurrentMonth()}
                    </p>
                  </div>
                </Row>
                <p
                  className='fw-bold'
                  style={{ color: '#646464' }}
                >
                  {getOrigins(data.schedules)}
                </p>

                <SailingScheduleTable schedules={data.schedules} />
              </Col>
            </Row>
          </section>

          {/* Footer Image Slider */}
          <section>
            <div
              className=' Splide-maligaya my-5'
              style={{ padding: padding_X }}
            >
              <Splide
                options={{
                  rewind: false,
                  gap: '1rem',
                  perPage: 3,
                  loop: false,
                }}
              >
                {data.related_ferries.length > 0 &&
                  data.related_ferries.map((item) => {
                    return (
                      <SplideSlide
                        key={item.id}
                        onClick={() => {
                          setBookingData({
                            origin: '',
                            destination: '',
                            departureDate: '',
                            returnDate: '',
                            passenger: '',
                            accomodationFull: '',
                            accomodation: '',
                            price: '',
                            insurance: '',
                            subTotal: '',
                            total: '',
                          });

                          _this.changeFerry(item.slug);

                          gotoTop();
                        }}
                      >
                        <div>
                          <img
                            className='w-100'
                            src={item.photos[0].image_url}
                            alt='Image1'
                          />
                          <p className='text-center mt-2'>{item.name}</p>
                        </div>
                      </SplideSlide>
                    );
                  })}
              </Splide>
            </div>
          </section>
        </>
      ) : (
        <section style={{ padding: padding_X }}>
          <div className='text-center'>
            <h3>Loading</h3>
          </div>
        </section>
      )}
    </>
  );
}

export default FerryContent_Maligaya;
