import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import FilterInput from "../../components/FilterInput";

export default class Information extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{ ...styles.main_container, width: width <= 830 ? "100%" : 400 }}
      >
        {/* <InfoButton text={"Information & Prices"} is_active={true} />
        <InfoButton text={"Facilities"} is_active={false} />
        <InfoButton text={"House Rules"} is_active={false} />
        <InfoButton text={"Guest Reviews"} is_active={false} /> */}

        {UI.box(20)}

        <Text style={styles.stay_text}>Tours Inclusions</Text>
        {UI.box(20)}

        <StayItem
          image={UI.TAGAYTAY}
          main={"Massage Full Body"}
          sub={"Already included in package"}
        />
        <StayItem
          image={UI.ILOCOS}
          main={"Airport Transfers"}
          sub={"Already included in package"}
        />
        <StayItem
          image={UI.PANGASINAN}
          main={"Tour Guide"}
          sub={"Already included in package"}
        />

        {/* <View style={styles.line}></View>
        <Text style={styles.stay_text}>Where you'll be</Text>
        {UI.box(10)}
        <Text style={{ ...styles.stay_text, fontSize: 12 }}>Tokyo, Japan</Text>
        {UI.box(5)}

        <ImageBackground
          style={styles.maps}
          source={UI.MAPS}
        >
          <TouchableOpacity style={styles.custom_btn}>
            <Text style={styles.custom_btn_text}>Show Map</Text>
          </TouchableOpacity>
        </ImageBackground> */}
      </View>
    );
  }
}

const StayItem = (props = { image: UI.TAGAYTAY, main: "", sub: "" }) => {
  return (
    <View style={styles.stay_item}>
      <ImageBackground style={styles.stay_image} source={props.image} />
      {UI.box(15)}
      <View style={{ flex: 1 }}>
        <Text style={styles.stay_item_text}>{props.main}</Text>
        <Text style={styles.included_text}>{props.sub}</Text>
        <View style={{ flex: 1 }}></View>
        <TouchableOpacity>
          <Text style={styles.details_text}>View Details</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const InfoButton = (props = { text: "", is_active: false }) => {
  return (
    <TouchableOpacity>
      <View style={styles.info_btn}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 14,
            color: props.is_active ? UI.colors.primary : "#959595",
          }}
        >
          {props.text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  maps: {
    height: 150,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  custom_btn_text: {
    color: "white",
    fontSize: 13,
    fontWeight: "bold",
  },
  custom_btn: {
    height: 40,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  stay_image: {
    height: 100,
    width: 100,
    borderRadius: 5,
  },
  stay_item: {
    flexDirection: "row",
    height: 100,
    width: "100%",
    marginBottom: 25,
  },
  stay_item_text: {
    fontSize: 12,
    color: "black",
    fontWeight: "bold",
  },
  included_text: {
    fontSize: 12,
    color: "#707070",
    fontWeight: "bold",
  },
  details_text: {
    fontSize: 12,
    color: "black",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  stay_text: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#3D3D3D",
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "#B3B3B3",
    marginTop: 15,
    marginBottom: 15,
  },
  info_btn: {
    width: "100%",
    height: 50,
    borderBottomStyle: "solid",
    borderBottomColor: "#c5c5c5",
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  main_container: {
    borderRadius: 10,
    marginTop: -10,
  },
});
