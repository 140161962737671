// Packages
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Container, Col, Form, Button } from 'react-bootstrap';
import {
  regions,
  provinces,
  cities,
  barangays,
} from 'select-philippines-address';
import { useForm } from 'react-hook-form';

// Components
import { CityList } from '../dropdown_static_data/cities';
import { plantype } from '../dropdown_static_data/plantype';
import { idtype } from '../dropdown_static_data/idtype';
import Notification from '../../../shared/Components/Notification/Notification';

// API
import {
  insuranceSubmitForm,
  getInsurancePDF,
} from '../../../shared/API/request';

// CSS
import './InsuranceForm.scss';

// Main Component
const InsuranceForm = () => {
  // Useform
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const [regionSelect, setRegionSelect] = useState([]);
  const [insurancePDF, setInsurancePDF] = useState({
    premium_rates: null,
    medical_brochure: null,
    policy_benefits: null,
  });
  const [provinceSelect, setProvinceSelect] = useState([]);
  const [citiesSelect, setCities] = useState([]);
  const [brgySelect, setBrgy] = useState([]);
  const [mindate, setMindate] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [userSubmit, setUserSubmit] = useState({
    onward_Date: '',
    return_date: '',
    contact_no: '',
    firstname: '',
    middlename: '',
    lastname: '',
    gender: '',
    birthdate: '',
    id_type: '',
    id_number: '',
    email: '',
    address_street: '',
    address_brgy: '',
    address_city: '',
    address_province: '',
    zipcode: '',
    plan_type: '',
    city: 'N/A',
    region: '',
  });

  const RegionSelectHandler = (e) => {
    setProvinceSelect([]);
    setBrgy([]);
    setCities([]);
    console.log(e.target.value);
    setUserSubmit((prev) => {
      return { ...prev, region: e.target.value };
    });
    const provinceselect = e.target.value;
    provinces(provinceselect).then((province) => setProvinceSelect(province));
    // get province name
  };

  const ProvinceSelectHandler = (e) => {
    e.preventDefault();
    const provincevalue = e.target.value;

    let index = e.nativeEvent.target.selectedIndex;
    const provinceName = e.nativeEvent.target[index].text;
    setUserSubmit((prev) => {
      return { ...prev, address_province: provinceName };
    });
    cities(provincevalue).then((city) => setCities(city));
  };

  const CitiesSelectHandler = (e) => {
    const citiesValue = e.target.value;
    setBrgy([]);
    let index = e.nativeEvent.target.selectedIndex;
    const address_city = e.nativeEvent.target[index].text;
    setUserSubmit((prev) => {
      return { ...prev, address_city: address_city };
    });
    barangays(citiesValue).then((barangays) => setBrgy(barangays));
  };

  const BrgySelectHandler = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    const address_brgy = e.nativeEvent.target[index].text;
    setUserSubmit((prev) => {
      return { ...prev, address_brgy: address_brgy };
    });
  };

  const submitHandler = () => {
    const newformdata = new FormData();

    const birthDate = new Date(userSubmit.birthdate);
    const todayDate = new Date();

    if (userSubmit.region === '' || userSubmit.region === null) {
      Notification('The Region field is required', 'danger');
      return;
    }

    if (birthDate.getFullYear() < 1900) {
      Notification('Birthdate must be higher than 1900', 'danger');
      return;
    }

    if (birthDate.setHours(0, 0, 0, 0) > todayDate.setHours(0, 0, 0, 0)) {
      Notification('Birthdate Year Invalid', 'danger');
      return;
    }

    newformdata.append('onward_date', userSubmit.onward_Date);
    newformdata.append('return_date', userSubmit.return_date);
    newformdata.append('contact_no', userSubmit.contact_no);
    newformdata.append('firstname', userSubmit.firstname);
    newformdata.append('middlename', userSubmit.middlename);
    newformdata.append('lastname', userSubmit.lastname);
    newformdata.append('gender', userSubmit.gender);
    newformdata.append('birthdate', userSubmit.birthdate);
    newformdata.append('id_type', userSubmit.id_type);
    newformdata.append('id_number', userSubmit.id_number);
    newformdata.append('email', userSubmit.email);
    newformdata.append('address_street', userSubmit.address_street);
    newformdata.append('address_brgy', userSubmit.address_brgy);
    newformdata.append('address_city', userSubmit.address_city);
    newformdata.append('address_province', userSubmit.address_province);
    newformdata.append('zipcode', userSubmit.zipcode);
    newformdata.append('onward_Date', userSubmit.onward_Date);
    newformdata.append('plan_type', userSubmit.plan_type);
    newformdata.append('city', userSubmit.city);
    setDisabledBtn(true);
    insuranceSubmitForm(newformdata, callback);
  };

  const handleRedirect = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setDisabledBtn(false);
        Notification('Successfully submitted!', 'success');
        setUserSubmit((prev) => {
          return {
            ...prev,
            onward_Date: '',
            return_date: '',
            contact_no: '',
            firstname: '',
            lastname: '',
            gender: '',
            birthdate: '',
            id_type: '',
            id_number: '',
            email: '',
            address_street: '',
            address_brgy: '',
            address_city: '',
            address_province: '',
            zipcode: '',
            plan_type: '',
            city: 'N/A',
            insurance_type: '',
            region: '',
          };
        });
        setProvinceSelect([]);
        setBrgy([]);
        setCities([]);
        setValue('onward_Date', '');
        setValue('return_date', '');
        setValue('contact_no', '');
        setValue('firstname', '');
        setValue('middlename', '');
        setValue('lastname', '');
        setValue('gender', '');
        setValue('birthdate', '');
        setValue('id_type', '');
        setValue('id_number', '');
        setValue('email', '');
        setValue('address_street', '');
        setValue('address_brgy', '');
        setValue('address_city', '');
        setValue('address_province', '');
        setValue('zipcode', '');
        setValue('plan_type', '');
        setValue('city', 'N/A');
        setValue('insurance_type', '');
        setValue('middlename', '');
        setValue('age', '');
        setValue('region', '');
      }

      if (status === 401 || status === 422) {
        setDisabledBtn(false);
        Notification(data.errors?.address_brgy[0], 'danger');
        Notification(data.errors?.address_city[0], 'danger');
        Notification(data.errors?.address_province[0], 'danger');
      }
    }
  };

  const pdfCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setInsurancePDF(data.data);
      }

      if (status === 401 || status === 422) {
        Notification('Something went wrong in retrieving the PDF', 'danger');
      }
    }
  };

  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    setMindate(`${yyyy}-${mm}-${dd}`);

    regions().then((region) => setRegionSelect(region));

    getInsurancePDF(pdfCallback);
  }, []);

  useEffect(() => {
    console.log(insurancePDF);
  }, [insurancePDF]);

  return (
    <Card className='card-insurance'>
      <Container fluid>
        <Row>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
          >
            <div className='insurance-form-title'>
              <p>Insurance</p>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
          >
            <div className='insurance-form-icons'>
              <Row>
                {insurancePDF.premium_rates && (
                  <Col>
                    <p
                      onClick={() => {
                        handleRedirect(insurancePDF.premium_rates);
                      }}
                      className='premium-rates'
                      style={{ cursor: 'pointer' }}
                    >
                      SAFE TRIP PREMIUM RATES
                    </p>
                  </Col>
                )}
              </Row>
              <Row>
                {insurancePDF.policy_benefits && (
                  <Col xxl={6}>
                    <p
                      onClick={() => {
                        handleRedirect(insurancePDF.policy_benefits);
                      }}
                      className='view-policy'
                      style={{ cursor: 'pointer' }}
                    >
                      VIEW POLICY BENEFITS
                    </p>
                  </Col>
                )}
                {insurancePDF.medical_brochure && (
                  <Col xxl={6}>
                    <p
                      onClick={() => {
                        handleRedirect(insurancePDF.medical_brochure);
                      }}
                      className='medical-brochure'
                      style={{ cursor: 'pointer' }}
                    >
                      COVID 19 MEDICAL BROCHURE
                    </p>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <hr className='horizontal-line' />

      <Container fluid>
        <Row>
          <Col>
            <Form
              className='insurance-form'
              onSubmit={handleSubmit(submitHandler)}
            >
              <h4>Insurance Request</h4>
              {/* First Row */}
              <div className='rows'>
                <Row>
                  <Col md={7}>
                    <Row>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                      ></Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* Second Row */}
              <div className='rows'>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formPlanType'
                        >
                          <Form.Label>
                            Plan Type{' '}
                            {errors.plan_type ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.plan_type.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Select
                            {...register('plan_type', {
                              required: '(Required)',
                              value: userSubmit.plan_type,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    plan_type: event.target.value,
                                  };
                                });
                              },
                            })}
                          >
                            <option
                              hidden
                              value=''
                            >
                              Select Plan type
                            </option>
                            {plantype.map((plan, id) => {
                              return (
                                <option
                                  key={id}
                                  value={plan}
                                >
                                  {plan}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formCity'
                        >
                          <Form.Label>
                            City{' '}
                            {errors.city ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.city.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Select
                            {...register('city', {
                              required: '(Required)',
                              value: userSubmit.city,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    city: event.target.value,
                                  };
                                });
                              },
                            })}
                          >
                            <option
                              hidden
                              value=''
                            >
                              Select City type
                            </option>
                            {CityList.map((city) => city)}
                          </Form.Select>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formOnWardDate'
                        >
                          <Form.Label>
                            Onward Date{' '}
                            {errors.onward_Date ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.onward_Date.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='date'
                            min={mindate}
                            max={userSubmit.return_date}
                            placeholder='Select Date'
                            {...register('onward_Date', {
                              required: '(Required)',
                              value: userSubmit.onward_Date,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  console.log(event.target.value);
                                  return {
                                    ...prevState,
                                    onward_Date: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formReturnDate'
                        >
                          <Form.Label>
                            Return Date{' '}
                            {errors.return_date ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.return_date.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='date'
                            placeholder='Select Date'
                            min={userSubmit.onward_Date}
                            max='2999-12-31'
                            {...register('return_date', {
                              required: '(Required)',
                              value: userSubmit.return_date,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    return_date: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formContactNumber'
                        >
                          <Form.Label>
                            Contact Number{' '}
                            {errors.contact_no ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.contact_no.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Number'
                            {...register('contact_no', {
                              required: '(Required)',
                              minLength: {
                                value: 11,
                                message: 'This field must be 11 digits',
                              },
                              value: userSubmit.contact_no,
                              onChange: (event) => {
                                const value = event.target.value.replace(
                                  /\D/g,
                                  '',
                                );
                                if (value.length <= 11) {
                                  setUserSubmit((prevState) => {
                                    return {
                                      ...prevState,
                                      contact_no: value,
                                    };
                                  });

                                  setValue('contact_no', value);
                                } else {
                                  setValue('contact_no', userSubmit.contact_no);
                                }
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col md={5}>
                    <Row>
                     
                    </Row>
                  </Col> */}
                </Row>
              </div>

              {/* Third Row */}
              <div className='rows'>
                <Row>
                  <Col md={7}>
                    <Row>
                      <Col
                        md={4}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formFirstName'
                        >
                          <Form.Label>
                            First Name
                            {errors.firstname ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.firstname.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='First Name'
                            {...register('firstname', {
                              required: '(Required)',
                              pattern: {
                                value: /^[a-zA-Z ]*$/g,
                                message:
                                  'Numbers and Special Characters not Allowed',
                              },
                              value: userSubmit.firstname,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    firstname: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        md={4}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formMiddleName'
                        >
                          <Form.Label>
                            Middle Name{' '}
                            {errors.middlename ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.middlename.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Middle Name'
                            {...register('middlename', {
                              pattern: {
                                value: /^[a-zA-Z ]*$/g,
                                message:
                                  'Numbers and Special Characters not Allowed',
                              },
                              value: userSubmit.middlename,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    middlename: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        md={4}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formLastName'
                        >
                          <Form.Label>
                            Last Name{' '}
                            {errors.lastname ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.lastname.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Last Name'
                            {...register('lastname', {
                              required: '(Required)',
                              pattern: {
                                value: /^[a-zA-Z ]*$/g,
                                message:
                                  'Numbers and Special Characters not Allowed',
                              },
                              value: userSubmit.lastname,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    lastname: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formGender'
                        >
                          <Form.Label>
                            Gender{' '}
                            {errors.gender ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.gender.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Select
                            {...register('gender', {
                              required: '(Required)',
                              value: userSubmit.gender,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    gender: event.target.value,
                                  };
                                });
                              },
                            })}
                          >
                            <option
                              hidden
                              value=''
                            >
                              Select Gender
                            </option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className='mb-3'
                          controlId='formDateOfBirth'
                        >
                          <Form.Label>
                            Date of Birth
                            {errors.birthdate ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.birthdate.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='date'
                            placeholder='Select Date'
                            {...register('birthdate', {
                              required: '(Required)',
                              value: userSubmit.birthdate,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    birthdate: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className='mb-3' controlId='formAge'>
                          <Form.Label>
                            Age{' '}
                            {errors.age ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.age.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Age'
                            {...register('age', {
                              required: '(Required)',
                              value: userSubmit.age,
                              onChange: (event) => {
                                const value = event.target.value.replace(
                                  /\D/g,
                                  ''
                                );
                                if (value.length <= 3 && value <= 200) {
                                  setUserSubmit((prevState) => {
                                    return {
                                      ...prevState,
                                      age: value,
                                    };
                                  });

                                  setValue('age', value);
                                } else {
                                  setValue('age', userSubmit.age);
                                }
                              },
                            })}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </div>

              {/* Fourth Row */}
              <div className='rows'>
                <Row>
                  <Col md={7}>
                    <Row>
                      <Col
                        md={4}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formIDType'
                        >
                          <Form.Label>
                            ID Type{' '}
                            {errors.id_type ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.id_type.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Select
                            {...register('id_type', {
                              required: '(Required)',
                              value: userSubmit.id_type,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    id_type: event.target.value,
                                  };
                                });
                              },
                            })}
                          >
                            <option
                              hidden
                              value=''
                            >
                              Select ID Type
                            </option>
                            {idtype.map((data) => data)}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col
                        md={4}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formIDNumber'
                        >
                          <Form.Label>
                            ID Number{' '}
                            {errors.id_number ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.id_number.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter ID Number'
                            {...register('id_number', {
                              required: '(Required)',
                              value: userSubmit.id_number,
                              onChange: (event) => {
                                if (event.target.value.length <= 20) {
                                  setUserSubmit((prevState) => {
                                    return {
                                      ...prevState,
                                      id_number: event.target.value,
                                    };
                                  });

                                  setValue('id_number', event.target.value);
                                } else {
                                  setValue('id_number', userSubmit.id_number);
                                }
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        md={4}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formEmail'
                        >
                          <Form.Label>
                            Email Address{' '}
                            {errors.email ? (
                              <span style={{ color: '#ff5c5c' }}>
                                {errors.email.message}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            type='email'
                            placeholder='Enter Email'
                            {...register('email', {
                              required: '(Required)',
                              value: userSubmit.email,
                              onChange: (event) => {
                                setUserSubmit((prevState) => {
                                  return {
                                    ...prevState,
                                    email: event.target.value,
                                  };
                                });
                              },
                            })}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              {/* Fifth Row */}
              <div className='rows'>
                <Row>
                  <Col md={9}>
                    <Row>
                      <Col
                        md={3}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formProvince'
                        >
                          <Form.Label>Region</Form.Label>
                          <Form.Select onChange={RegionSelectHandler}>
                            <option
                              hidden
                              value=''
                            >
                              Select Region
                            </option>
                            {regionSelect.map((data, idx) => {
                              return (
                                <option
                                  key={idx}
                                  label={data.region_name}
                                  value={data.region_code}
                                >
                                  {data.region_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col
                        md={3}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formProvince'
                        >
                          <Form.Label>Province </Form.Label>
                          <Form.Select onChange={ProvinceSelectHandler}>
                            <option
                              hidden
                              value=''
                            >
                              Select Province
                            </option>
                            {provinceSelect.map((data, id) => {
                              return (
                                <option
                                  key={id}
                                  value={data.province_code}
                                >
                                  {data.province_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col
                        md={3}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formCity'
                        >
                          <Form.Label>City</Form.Label>
                          <Form.Select onChange={CitiesSelectHandler}>
                            <option
                              hidden
                              value=''
                            >
                              Select City
                            </option>
                            {citiesSelect.map((data, id) => {
                              return (
                                <option
                                  key={id}
                                  value={data.city_code}
                                >
                                  {data.city_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col
                        md={3}
                        sm={12}
                      >
                        <Form.Group
                          className='mb-3'
                          controlId='formBarangay'
                        >
                          <Form.Label>Barangay</Form.Label>
                          <Form.Select onChange={BrgySelectHandler}>
                            <option
                              hidden
                              value=''
                            >
                              Select Barangay
                            </option>
                            {brgySelect.map((data, id) => {
                              return <option key={id}>{data.brgy_name}</option>;
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group
                      className='mb-3'
                      controlId='formZipCode'
                    >
                      <Form.Label>
                        Zip Code{' '}
                        {errors.zipcode ? (
                          <span style={{ color: '#ff5c5c' }}>
                            {errors.zipcode.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Zip Code'
                        {...register('zipcode', {
                          required: '(Required)',
                          minLength: {
                            value: 4,
                            message: 'The field must be 4 digits', // JS only: <p>error message</p> TS only support string
                          },
                          value: userSubmit.zipcode,
                          onChange: (event) => {
                            const value = event.target.value.replace(/\D/g, '');

                            if (value.length <= 4) {
                              setUserSubmit((prevState) => {
                                return {
                                  ...prevState,
                                  zipcode: value,
                                };
                              });

                              setValue('zipcode', value);
                            } else {
                              setValue('zipcode', userSubmit.zipcode);
                            }
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className='mb-3'
                      controlId='formAddress'
                    >
                      <Form.Label>
                        Address{' '}
                        {errors.address_street ? (
                          <span style={{ color: '#ff5c5c' }}>
                            {errors.address_street.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Address'
                        {...register('address_street', {
                          required: '(Required)',
                          value: userSubmit.address_street,
                          onChange: (event) => {
                            setUserSubmit((prevState) => {
                              return {
                                ...prevState,
                                address_street: event.target.value,
                              };
                            });
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='d-flex justify-content-between'>
                <span
                  style={{
                    color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                    display: 'block',
                  }}
                ></span>
                <div className='buttons'>
                  <Link
                    to={'/inquiries'}
                    className='btn btn-primary cancel'
                    type='cancel'
                  >
                    Cancel
                  </Link>

                  <Button
                    className='submit'
                    type='submit'
                    disabled={disabledBtn}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default InsuranceForm;
