// Packages
import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import StepperUI from '../../components/Stepper';
import UI from '../../../shared/Components/UI/js';
import BookingDetailsInfo from './BookingDetailsInfo';
import BookingDetailsInfoThird from './BookingDetailsInfoThird';
import BookingDetailsInfoSecond from './BookingDetailsInfoSecond';
import Notification from '../../../shared/Components/Notification/Notification';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

// css
import './TourBookingDetails.scss';

// API
import {
  submitTourBooking,
  getBankAccounts,
} from '../../../shared/API/request';

export default class HotelBookingDetails extends Component {
  static contextType = AllContext;

  constructor(props, context) {
    super(props);

    UI.initiateView(this, {
      user: context.data.user,
      booking: JSON.parse(localStorage.getItem('booking-details')),
      active_link: 'MY BOOKINGS',
      workTrip: false,
      arrivalTime: '',
      paperlessConfirmation: false,
      promoCode: '',
      specialRequest: '',
      firstname: context?.data?.user?.firstname,
      lastname: context?.data?.user?.lastname,
      email: context?.data?.user?.email,
      cEmail: '',
      address: context?.data?.user?.full_address,
      mobile: context?.data?.user?.mobile || '',
      country: 'philippines',
      paymentType: '',
      step: 0,
      bankAccounts: [],
      disabledBtn: false,
    });

    getBankAccounts(this.bankCallback);
  }

  bankCallback = async (response) => {
    const { data, status } = await response;

    if (status === 200 || status === 201) {
      this.setState({
        bankAccounts: data.data,
      });
    }
  };

  arrivalTimeHandler(arrivalTime) {
    this.setState({ arrivalTime });
  }

  workTripHandler(workTrip) {
    this.setState({ workTrip: !workTrip });
  }

  paperlessConfirmationHandler(paperlessConfirmation) {
    this.setState({ paperlessConfirmation: !paperlessConfirmation });
  }

  promoCodeHandler(promoCode) {
    this.setState({ promoCode });
  }

  specialRequestHandler(specialRequest) {
    this.setState({ specialRequest });
  }

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    let step = this.state.step;

    const containsSpecialChars = (str) => {
      const specialChars = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    };

    const handleNext = () => {
      // For Step 2
      if (this.state.step === 1) {
        if (this.state.email !== this.state.cEmail) {
          Notification('Email and Confirm Email are not the same', 'danger');
          return;
        }
        if (
          containsSpecialChars(this.state.firstname) ||
          containsSpecialChars(this.state.lastname)
        ) {
          Notification(
            'Numbers and special characters are not allowed in names',
            'danger',
          );
          return;
        }
        if (
          this.state.firstname === '' ||
          this.state.lastname === '' ||
          this.state.email === ''
        ) {
          Notification('Please input fields required', 'danger');
          return;
        }
        this.setState({
          step: this.state.step + 1,
        });
      }
      // For Other Steps
      if (this.state.step === undefined || this.state.step < 2) {
        this.setState({
          step: this.state.step + 1,
        });
      }
    };

    const handleBack = () => {
      if (this.state.step >= 1) {
        _this.setState({
          step: this.state.step - 1,
        });
      } else {
        _this.props.history.goBack();
      }
    };

    const submitHandler = () => {
      if (this.state.paymentType === '') {
        Notification('Select Payment Type', 'danger');
        return;
      }
      if (this.state.paymentType === 'paymaya') {
        console.log(this.state.paymentType);
        return;
      }
      if (this.state.mobile === '') {
        Notification('Please input fields required', 'danger');
        return;
      }

      if (this.state.mobile.length < 11) {
        Notification('Must be 11 digit required', 'danger');
        return;
      }

      const bookingForm = {
        userId: this.state.user.id,
        tourId: this.state.booking.tourArr.id,
        checkInDate: this.state.booking.check_in,
        checkOutDate: this.state.booking.check_out,
        guest: this.state.booking.guest,
        workTrip: this.state.workTrip,
        arrivalTime: this.state.arrivalTime,
        specialRequest: this.state.specialRequest,
        totalPrice: this.state.booking.total,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        contact: this.state.mobile,
        country: this.state.country,
        address: this.state.address,
        paymentType: this.state.paymentType,
      };
      this.setState({ disabledBtn: true });
      console.log('test');
      submitTourBooking(bookingForm, callback);
    };

    // Callback after get api for searchhoteltours
    const callback = async (response) => {
      const { status, data } = await response;

      if (status === 201 || status === 200) {
        this.setState({ disabledBtn: false });
        localStorage.removeItem('booking-details');
        localStorage.removeItem('search_params');

        if (this.state.paymentType === 'paymaya') {
          window.location.replace(data.data.redirectUrl);
        } else {
          Notification('Book successfully', 'success');
          this.props.history.push('/my-booking');
        }
      } else if (status === 400) {
        this.setState({ disabledBtn: false });
        Notification('An Error Occured', 'danger');
      } else if (status === 422) {
        this.setState({ disabledBtn: false });
        if (data.errors) {
          const { errors } = data;

          if (errors.contact) {
            Notification(errors.contact, 'danger');
          }

          if (errors.checkOutDate) {
            errors.checkOutDate.forEach((msg) => {
              console.log(msg);
              Notification(msg, 'danger');
            });
          }
        }
      } else {
        Notification('An Error Occured', 'danger');
        this.setState({ disabledBtn: false });
      }
    };

    return (
      <View className='main_container'>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

          <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              width: '100%',
              paddingRight: paddingX,
            }}
          >
            <StepperUI
              _this={this}
              step={step}
            />
          </UI.Row>

          <View
            style={{
              marginTop: 20,
              paddingLeft: paddingX,
              paddingRight: paddingX,
              width: '100%',
            }}
          >
            <ColoredLine color='#D3D3D3' />
          </View>

          <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              width: '100%',
              paddingLeft: paddingX,
              paddingRight: paddingX,
              marginTop: 0,
              marginBottom: 50,
            }}
          >
            {step === 0 ? (
              <BookingDetailsInfo _this={this} />
            ) : step === 1 ? (
              <BookingDetailsInfoSecond _this={this} />
            ) : (
              step === 2 && <BookingDetailsInfoThird _this={this} />
            )}
          </UI.Row>

          <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              width: '100%',
              paddingLeft: paddingX,
              paddingRight: paddingX,
              marginTop: 0,
              marginBottom: 50,
            }}
          >
            <View
              style={{
                flexDirection: width < 425.98 ? 'column' : 'row',
                width: width < 768.98 ? 'auto' : width,
                marginTop: 20,
                marginBottom: 20,
                marginTop: 60,
                justifyContent: 'space-between',
              }}
            >
              <TouchableOpacity
                onClick={() => handleBack()}
                className='custom_btn'
                style={{ height: 50 }}
              >
                <span className='custom_btn_text'>Back</span>
              </TouchableOpacity>

              {step !== 2 && (
                <TouchableOpacity
                  onClick={() => handleNext()}
                  className='custom_btn'
                  style={{ height: 50, marginTop: 10 }}
                >
                  <span className='custom_btn_text'>Next</span>
                </TouchableOpacity>
              )}

              {step === 2 && (
                <button
                  style={{ height: 50, marginTop: 10 }}
                  className='custom_btn custom_btn_text'
                  disabled={this.state.disabledBtn}
                  onClick={submitHandler}
                >
                  Submit
                </button>
              )}
            </View>
          </UI.Row>

          {/* <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              width: "100%",
              paddingLeft: paddingX,
              paddingRight: paddingX,
              marginTop: 50,
            }}
          >
            <HotelInfo _this={this} />
            {UI.box(width <= 830 ? 30 : 100)}
            <HotelReserve _this={this} />
          </UI.Row> */}

          {/* <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              width: "100%",
              paddingLeft: paddingX,
              paddingRight: paddingX,
              marginTop: 30,
            }}
          >
            <Availability _this={this} />
            {UI.box(width <= 830 ? 30 : 100)}
            <Information _this={this} />
          </UI.Row> */}

          {/* End Contents Here */}
          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);
