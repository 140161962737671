import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './css.css';
import SortComponent from './SortComponent';
import TitleHeader from './TitleHeader';
import HotelItems from './HotelItems';
import SearchContainer from './SearchContainer';
import Checkboxes from './Checkboxes';
import Notification from '../../../shared/Components/Notification/Notification';

// Context
import AllContext from '../../../shared/Context/AllContext';
// scss
import './HotelContainer.scss';
// API
import { allHotels } from '../../../shared/API/request';

export default class HotelContainer extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);

    const dateToday = new Date();
    let tomorrow = new Date(dateToday);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const hotelsSearch = JSON.parse(localStorage.getItem('hotelsSearch'));

    // States
    if (!hotelsSearch) {
      UI.initiateView(this, {
        active_link: 'HOTELS',
        search_city: '',
        search_check_in: dateToday.toISOString().split('T')[0],
        search_check_out: tomorrow.toISOString().split('T')[0],
        search_guest: '1',
        search_room: '1',
        search_results: null,
        search_sortby: 'price',
        filteredDestinations: null,
        filter_by_price: '0|999999',
        meta: null,
        checked_9: false,
        checked_10: false,
        checked_11: false,
        checked_12: false,
        checked_13: false,
        checked_14: false,
        checked_15: false,
      });
    } else {
      const { search_check_in } = hotelsSearch;

      const todayDate = new Date();
      const tomorrowDate = new Date();
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);

      if (this.isInThePast(new Date(search_check_in))) {
        UI.initiateView(this, {
          ...hotelsSearch,
          active_link: 'HOTELS',
          filter_by_price: '0|999999',
          checked_9: false,
          checked_10: false,
          checked_11: false,
          checked_12: false,
          checked_13: false,
          checked_14: false,
          checked_15: false,
          search_check_in: todayDate.toISOString().split('T')[0],
          search_check_out: tomorrowDate.toISOString().split('T')[0],
        });
      } else {
        UI.initiateView(this, {
          ...hotelsSearch,
          active_link: 'HOTELS',
          filter_by_price: '0|999999',
          checked_9: false,
          checked_10: false,
          checked_11: false,
          checked_12: false,
          checked_13: false,
          checked_14: false,
          checked_15: false,
        });
      }
    }

    // Fire api query on first render
    this.allHotels(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_sortby,
      this.state.filter_by_price,
    );
  }

  isInThePast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  }

  // API get query for search results
  allHotels = (
    city,
    checkIn,
    checkOut,
    guest,
    room,
    sortBy,
    filterByPrice,
    page = 1,
  ) => {
    const params = {
      city: city,
      check_in: checkIn,
      check_out: checkOut,
      guest: guest,
      room: room,
      sortBy: sortBy,
      pricerange: filterByPrice,
      page: page,
    };

    this.setState({
      destinations: null,
    });

    allHotels(params, this.callback);
  };

  // Callback after get api for searchhoteltours
  callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          filteredDestinations: data.data,
        });
      }

      this.setState({
        meta: data.meta,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  // Sidebar Search Function
  search = () => {
    if (
      !this.state.search_check_in ||
      !this.state.search_check_out ||
      !this.state.search_guest
    ) {
      Notification('Please fill in the required textbox', 'danger');
      return false;
    }
    if (this.state.search_guest <= 0) {
      Notification('Guess should not be empty', 'danger');
      return false;
    }
    if (this.state.room <= 0) {
      Notification('Room should not be empty', 'danger');
      return false;
    }

    this.allHotels(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_sortby,
      this.state.filter_by_price,
    );
  };

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.search_city !== this.state.search_city) {
    //   console.log('test');
    //   this.search();
    // }
    if (prevState.search_city !== this.state.search_city) {
      if (this.state.search_city === '') {
        console.log('test2');
        this.allHotels(
          this.state.search_city,
          this.state.search_check_in,
          this.state.search_check_out,
          this.state.search_guest,
          this.state.search_room,
          this.state.search_sortby,
          this.state.filter_by_price,
        );
      }
    }
    if (prevState !== this.state) {
      localStorage.setItem('hotelsSearch', JSON.stringify(this.state));
    }

    // Function for Filter By Price
    if (
      prevState.checked_9 !== this.state.checked_9 ||
      prevState.checked_10 !== this.state.checked_10 ||
      prevState.checked_11 !== this.state.checked_11 ||
      prevState.checked_12 !== this.state.checked_12 ||
      prevState.checked_13 !== this.state.checked_13 ||
      prevState.checked_14 !== this.state.checked_14 ||
      prevState.checked_15 !== this.state.checked_15
    ) {
      let ids = [9, 10, 11, 12, 13, 14, 15];
      let reverseIds = [15, 14, 13, 12, 11, 10, 9];
      let lowestPrice = '0';
      let highestPrice = '999999';

      for (let i = 0; i < ids.length; i++) {
        if (this.state[`checked_${ids[i]}`]) {
          switch (ids[i]) {
            case 9:
              lowestPrice = '0';
              break;
            case 10:
              lowestPrice = '3001';
              break;
            case 11:
              lowestPrice = '6001';
              break;
            case 12:
              lowestPrice = '9001';
              break;
            case 13:
              lowestPrice = '12001';
              break;
            case 14:
              lowestPrice = '15001';
              break;
            case 15:
              lowestPrice = '18001';
              break;
            default:
              lowestPrice = '0';
              break;
          }

          break;
        }
      }

      for (let i = 0; i < reverseIds.length; i++) {
        if (this.state[`checked_${reverseIds[i]}`]) {
          switch (reverseIds[i]) {
            case 15:
              highestPrice = '999999';
              break;
            case 14:
              highestPrice = '18000';
              break;
            case 13:
              highestPrice = '15000';
              break;
            case 12:
              highestPrice = '12000';
              break;
            case 11:
              highestPrice = '9000';
              break;
            case 10:
              highestPrice = '6000';
              break;
            case 9:
              highestPrice = '3000';
              break;
            default:
              highestPrice = '999999';
              break;
          }
          break;
        }
      }

      this.setState({
        filter_by_price: `${lowestPrice}|${highestPrice}`,
      });
    }

    // Function for Filter By Price API
    if (prevState.filter_by_price !== this.state.filter_by_price) {
      this.allHotels(
        this.state.search_city,
        this.state.search_check_in,
        this.state.search_check_out,
        this.state.search_guest,
        this.state.search_room,
        this.state.search_sortby,
        this.state.filter_by_price,
      );
    }
  }

  prevPage = () => {
    const prevPage = parseInt(this.state.meta.current_page) - 1;

    this.allHotels(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_sortby,
      this.state.filter_by_price,
      prevPage,
    );
  };

  nextPage = () => {
    const nextPage = parseInt(this.state.meta.current_page) + 1;

    this.allHotels(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_sortby,
      this.state.filter_by_price,
      nextPage,
    );
  };

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;

    // Use Context
    const { data, setData } = this.context;

    // Pass State to Props
    const hotelList = this.state.filteredDestinations;

    return (
      <View className='hotel_main_container'>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}
          <TitleHeader _this={_this} />
          <SortComponent _this={_this} />

          {/* Main Content */}
          <UI.PadView _this={_this}>
            {UI.box(30)}
            <div className='main-content'>
              {/* Left Content */}
              <HotelItems
                _this={_this}
                hotelList={hotelList}
              />
              {UI.box(30)}

              {/* Right Content */}
              <View>
                {/* Search Right Sidebar */}
                <SearchContainer _this={_this} />

                {UI.box(30)}

                {/* Filter By Price */}
                <Checkboxes _this={_this} />

                {/* <ImageBackground
                  source={UI.MAPS}
                  className='maps_bg'
                  style={{
                    width: isMobile ? '100%' : 300 + paddingX * 2,
                    margin: '0 auto',
                  }}
                >
                  <TouchableOpacity className='show_map_btn'>
                    <span className='show_map_btn_text'>Show Map</span>
                  </TouchableOpacity>
                </ImageBackground> */}
              </View>
            </div>

            <View
              style={{
                flexDirection: 'row',
                gap: '5px',
                justifyContent: 'center',
              }}
            >
              {this.state.meta && (
                <>
                  {this.state.meta.current_page !== this.state.meta.from && (
                    <TouchableOpacity
                      className='pagination_btn'
                      onClick={() => this.prevPage()}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={'#646464'}
                        source={UI.CHEVRON_LEFT}
                      />
                    </TouchableOpacity>
                  )}
                  <View className='pagination_btn'>
                    <span className='pagination_btn_text'>
                      Showing Page{' '}
                      {this.state.meta ? this.state.meta.current_page : '0'} of{' '}
                      {this.state.meta ? this.state.meta.last_page : '0'}
                    </span>
                  </View>
                  {this.state.meta.current_page !==
                    this.state.meta.last_page && (
                    <TouchableOpacity
                      className='pagination_btn'
                      onClick={() => this.nextPage()}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={'#646464'}
                        source={UI.CHEVRON_RIGHT}
                      />
                    </TouchableOpacity>
                  )}
                </>
              )}
            </View>
          </UI.PadView>

          {/* End Contents Here */}

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}
