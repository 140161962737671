import React, { Component } from 'react';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import FilterInput from '../../components/FilterInput';
import NumberInput from '../../components/NumberInput';
import Notification from '../../../shared/Components/Notification/Notification';

import './TourReserve.scss';

export default class TourReserve extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    tourArr: '',
    hotel: '',
    check_in: '',
    check_out: '',
    guest: '',
    service_fee: 0,
    total: 0,
    disabled: false,
  };

  // Will run on every first render
  componentDidMount() {
    // Props data
    const _this = this.props._this;
    const filteredList = _this.state.filteredList[0];

    // get search params to localStorage
    const search_params = JSON.parse(localStorage.getItem('search_params'));

    if (search_params) {
      this.setState({
        tourArr: filteredList,
        hotel: filteredList.title,
        check_in: search_params.check_in,
        check_out: search_params.check_out,
        guest: search_params.guest,
        service_fee: filteredList.serviceFee,
      });

      this.calculateNightDuration(
        search_params.check_in,
        search_params.check_out
      );
    } else {
      this.setState({
        disabled: true,
      });
    }

    this.computeTotal(filteredList.price);
  }

  componentDidUpdate(prevProps, prevState) {
    const _this = this.props._this;
    const filteredList = _this.state.filteredList[0];

    if (prevState !== this.state) {
      this.computeTotal(filteredList.price);
    }
  }

  calculateNightDuration = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      let check_in_date = new Date(checkIn);
      let check_out_date = new Date(checkOut);

      const nights = Math.floor(
        (Date.UTC(
          check_out_date.getFullYear(),
          check_out_date.getMonth(),
          check_out_date.getDate()
        ) -
          Date.UTC(
            check_in_date.getFullYear(),
            check_in_date.getMonth(),
            check_in_date.getDate()
          )) /
          (1000 * 60 * 60 * 24)
      );

      // set state of nights
      this.setState({
        night_duration: nights,
      });
    }
  };

  handleSubmit = () => {
    if (this.state.guest === '') {
      Notification('Minimum number of guest is 1', 'danger');
      return;
    }

    if (this.state.tourArr) {
      localStorage.setItem('booking-details', JSON.stringify(this.state));
      UI.goTo('/tour-booking-details');
    } else {
      Notification('Please select a room first', 'danger');
    }
  };

  computeTourPrice = (price) => {
    return parseFloat(price / 1.12).toFixed(2);
  };

  computeTourVat = (price) => {
    return parseFloat((price / 1.12) * 0.12).toFixed(2);
  };

  computeSubTotal = (price) => {
    return parseFloat(
      parseInt(price) + parseInt(this.state.service_fee)
    ).toFixed(2);
  };

  computeTotal = (price) => {
    const subTotal = parseFloat(
      parseInt(price) + parseInt(this.state.service_fee)
    ).toFixed(2);

    const total = parseFloat(subTotal * parseInt(this.state.guest)).toFixed(2);

    if (this.state.total !== total) {
      this.setState({
        total: total,
      });
    }
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;

    const filteredList = _this.state.filteredList[0];
    const { data } = _this.context;
    const user_islogin = data.isAuth;

    const paxLeft = filteredList.persons_left;

    return (
      <>
        {this.state.disabled && <></>}
        {!this.state.disabled && paxLeft !== 0 && (
          <>
            <View
              style={{
                ...styles.main_container,
                width: width <= 830 ? '100%' : 400,
                marginLeft: width <= 830 ? 0 : 20,
              }}
            >
              <View style={styles.row}>
                <View style={styles.header_container}>
                  <Text style={styles.header_price}>Book this Tour</Text>
                </View>
              </View>
              {UI.box(20)}
              <FilterInput
                _this={_this}
                title={'Tour Name'}
                placeholder={'Tour Name'}
                icon={UI.DESTINATION}
                value={UI.titleCase(filteredList.name)}
                disabled={true}
              />
              {UI.box(20)}
              <FilterInput
                _this={_this}
                title={'Date Start'}
                placeholder={'Date Start'}
                icon={UI.DESTINATION}
                value={this.state.check_in}
                disabled={true}
              />
              {UI.box(20)}
              <FilterInput
                onChange={(e) => {
                  _this.setState({
                    reserve_check_out: e.target.value,
                  });
                }}
                _this={_this}
                title={'Date End'}
                placeholder={'Date End'}
                icon={UI.DESTINATION}
                value={this.state.check_out}
                disabled={true}
              />
              {UI.box(20)}
              <NumberInput
                onChange={(e) => {
                  this.setState({
                    guest: e,
                  });
                }}
                _this={_this}
                title={'Guests'}
                placeholder={'Enter Number of Guest'}
                icon={UI.PROFILE}
                className='guest'
                type='text'
                value={this.state.guest}
              />
              {UI.box(20)}

              <Text style={styles.normal_text}>You won't be charged yet</Text>

              <View style={styles.price_container}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.price_left}>Tour Price</Text>
                </View>
                <Text style={styles.price_right}>P {filteredList.price}</Text>
              </View>

              <View style={styles.price_container}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.price_left}>Service Fee</Text>
                </View>
                <Text style={styles.price_right}>
                  P {parseFloat(this.state.service_fee).toFixed(2)}
                </Text>
              </View>

              <View style={styles.price_container}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.price_left}>Sub Total</Text>
                </View>
                <Text style={styles.price_right}>
                  P {this.computeSubTotal(filteredList.price)}
                </Text>
              </View>

              <View style={styles.price_container}>
                <View style={{ flex: 1 }}>
                  <Text style={{ ...styles.price_left, color: 'black' }}>
                    Total
                  </Text>
                  <Text style={{ ...styles.price_left }}>
                    Sub Total x Guest
                  </Text>
                </View>
                <Text
                  style={{
                    ...styles.price_right,
                    color: 'black',
                    alignSelf: 'flex-end',
                  }}
                >
                  P {isNaN(this.state.total) ? '-' : this.state.total}
                </Text>
              </View>

              {UI.box(20)}
              {user_islogin && (
                <TouchableOpacity
                  style={styles.custom_btn}
                  onClick={this.handleSubmit}
                >
                  <Text style={styles.custom_btn_text}>Book Now</Text>
                </TouchableOpacity>
              )}
            </View>
          </>
        )}
        { paxLeft === 0 && (
          <h2 className='fully-booked-text'>FULLY BOOKED</h2>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  price_right: {
    fontSize: 14,
    color: '#8E8E8E',
    fontWeight: 'bold',
  },
  price_left: {
    fontSize: 14,
    color: '#8E8E8E',
    fontWeight: 'bold',
  },
  price_container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  normal_text: {
    fontSize: 12,
    color: '#8E8E8E',
    alignSelf: 'center',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn: {
    height: 40,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  review_text: {
    fontSize: 12,
    color: '#8E8E8E',
  },
  rating_text: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 20,
    marginLeft: 10,
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  end: {
    alignItems: 'flex-end',
  },
  header_night: {
    fontSize: 16,
    color: UI.colors.primary,
    marginLeft: 10,
  },
  header_price: {
    fontSize: 20,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  row: {
    flexDirection: 'row',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flex: 1,
    alignSelf: 'flex-start',
  },

  main_container: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
  },
});
