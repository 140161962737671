// Packages
import React, { Component } from "react";

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

// CSS
import "./SmallContainer.css";
import "./SmallContainer.scss";

// Sample API
const dataAPI = [
  {
    id: 1,
    title: "Quezon City",
    subtitle: "60 minute drive",
    image: "/img/quezon.jpg",
  },
  {
    id: 2,
    title: "Tagaytay",
    subtitle: "60 minute drive",
    image: "/img/tagaytay.jpg",
  },
  {
    id: 3,
    title: "Cavite",
    subtitle: "60 minute drive",
    image: "/img/cavite.jpg",
  },
  {
    id: 4,
    title: "Pangasinan",
    subtitle: "60 minute drive",
    image: "/img/pangasinan.jpg",
  },
  {
    id: 5,
    title: "Baguio",
    subtitle: "60 minute drive",
    image: "/img/baguio.jpg",
  },
  {
    id: 6,
    title: "Ilocos",
    subtitle: "60 minute drive",
    image: "/img/ilocos.jpg",
  },
];

// Main Component
export default class SmallContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View style={{ marginTop: 30, width: "100%" }}>
        <span className="header-text">EXPLORE NEARBY</span>
        <span className="sub-text">
          These popular destinations have a lot to offer
        </span>
        {UI.box(30)}

        <View
          style={{
            flexDirection: _this.state.isMobile ? "column" : "row",
            justifyContent: "space-between",
            marginBottom: _this.state.isMobile ? 0 : 30,
          }}
          className="nearby-locations"
        >
          {dataAPI &&
            dataAPI.map((location) => {
              return (
                <SmallItem
                  className="nearby-list"
                  key={location.id}
                  _this={_this}
                  main={location.title}
                  sub={location.subtitle}
                  image={location.image}
                />
              );
            })}
        </View>
      </View>
    );
  }
}

const SmallItem = (
  props = { _this: null, main: "", sub: "", image: "", className: "" }
) => {
  const _this = props._this;
  const width = _this.state.width;

  const paddingX = width * 0.05;
  let item_width = (width - paddingX * 2) / 3 - paddingX / 2;
  let margin = 0;

  if (_this.state.isMobile) {
    item_width = width - paddingX * 2;
    margin = paddingX * 1.2;
  }

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        width: item_width,
      }}
      className={props.className}
    >
      <ImageBackground
        source={props.image}
        style={{ height: 160, width: 160, borderRadius: 10 }}
      />
      {UI.box(20)}
      <View>
        <Text style={{ fontSize: 20, fontWeight: "bold", color: "#707070" }}>
          {props.main}
        </Text>
        <Text style={{ fontSize: 17, fontWeight: "bold", color: "#B8B8B8" }}>
          {props.sub}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
