// Packages
import React, { Component } from 'react';
import { m } from 'framer-motion';
import { withRouter } from 'react-router-dom';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TitleHeader from './TitleHeader';
import Notification from '../../../shared/Components/Notification/Notification';
// Contexts
import AllContext from '../../../shared/Context/AllContext';
import {
  getFerryPastBooking,
  getHotelPastBooking,
  getPastBooking,
} from '../../../shared/API/request';
const past_booking = [
  {
    type: 'tour',
    id: 3,
    referenceId: 1,
    title: 'Cebu to Bohol Tour',
    date_opened: '',
    isVerified: false,
    isTaxInclusive: true,
    location: 'cebu',
    places: 'cebu',
    totalPrice: '12000.00',
    isPaid: false,
    primaryImageURL: 'http://localhost:8000/storage/images/tours/1/bohol2.jpg',
    checkIn: '2022-05-20',
    checkOut: '2022-05-21',
    hasRating: true,
    customerRating: 5,
    overAllRating: '5.00',
    rooms: null,
  },
  {
    type: 'tour',
    id: 4,
    referenceId: 1,
    title: 'Cebu to Bohol Tour',
    date_opened: '',
    isVerified: false,
    isTaxInclusive: true,
    location: 'cebu',
    places: 'cebu',
    totalPrice: '6000.00',
    isPaid: false,
    primaryImageURL: 'http://localhost:8000/storage/images/tours/1/bohol2.jpg',
    checkIn: '2022-05-16',
    checkOut: '2022-05-17',
    hasRating: false,
    customerRating: 0,
    overAllRating: '5.00',
    rooms: null,
  },
  {
    type: 'tour',
    id: 5,
    referenceId: 1,
    title: 'Cebu to Bohol Tour',
    date_opened: '',
    isVerified: false,
    isTaxInclusive: true,
    location: 'cebu',
    places: 'cebu',
    totalPrice: '6000.00',
    isPaid: false,
    primaryImageURL: 'http://localhost:8000/storage/images/tours/1/bohol2.jpg',
    checkIn: '2022-05-16',
    checkOut: '2022-05-17',
    hasRating: false,
    customerRating: 0,
    overAllRating: '5.00',
    rooms: null,
  },
];
class MyAccount extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);

    UI.initiateView(this, {
      active_link: '',
      user: {},
      origin: '',
      departure_date: '',
      sortBy: '',
    });
  }

  accountSettings = () => {
    UI.goTo('/account-settings');
  };

  signOut = (setData) => {
    localStorage.clear();
    setData((prevState) => {
      return { ...prevState, isAuth: false, user: {} };
    });
    UI.goTo('/');
  };
  pastbooking = (id) => {
    const tourHotelParams = `?origin=${this.state.origin}&departure_date=${this.state.departure_date}&sortBy=${this.state.sortBy}`;
    getFerryPastBooking(id, tourHotelParams, this.callback);
  };

  callback = async (response) => {
    const { status, data } = await response;
    const { setData } = this.context;
    if (status === 201 || status === 200) {
      console.log(data);
    } else if (status === 400 || status === 401) {
      Notification(status, 'danger');
    } else {
      Notification(status, 'danger');
    }
  };
  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    // Use Context
    const { data, setData } = this.context;
    const { user } = data;
    this.pastbooking(data.user.id);

    console.log('USSSSSERRRRRRRRRR', user);

    if (Object.keys(this.state.user).length === 0) {
      this.setState({
        user: user,
      });
    }

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          <TitleHeader _this={_this} />

          {this.state.user && (
            <>
              <UI.PadView
                breakpoint={850}
                _this={_this}
              >
                {UI.box(150)}
                <View
                  style={{
                    ...styles.sort_text,
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'center' : 'center',
                    padding: 0,
                    boxShadow: '0 2px 3px #3444',
                  }}
                >
                  <View
                    style={{
                      flex: isMobile ? 1 : '0 0 14em',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={user.profile ? user.profile : user.photo ? user.photo : UI.PROFILE_PICTURE}
                      style={{
                        height: isMobile ? 'auto' : 154,
                        width: 154,
                        objectFit: ImageFit.COVER,
                        position: 'absolute',
                        border: 'solid 5px #fff',
                        borderRadius: '50%',
                        top: -75,
                        left: isMobile ? 0 : 25,
                      }}
                      alt='User Profile'
                    />
                    {isMobile ? (
                      <div style={{ width: '140px', height: '80px' }} />
                    ) : (
                      UI.box(100)
                    )}
                  </View>

                  <View
                    style={{
                      flex: width > 1000 ? '1' : '2',
                      position: 'relative',
                      alignItems: isMobile ? 'center' : 'flex-start',
                      ...styles.SegoeUI,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 25,
                      }}
                    >
                      {`${this.state.user.firstname} ${this.state.user?.middlename || ''} ${this.state.user.lastname}`}
                    </Text>
                    <Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                      <Image
                        width={22}
                        height={22}
                        source={UI.GOOGLE}
                      />
                      {UI.box(5)}
                      Google
                    </Text>
                  </View>
                  {isMobile ? UI.box(30) : ''}
                  <View
                    style={{
                      flex: width > 1000 ? '1' : '0',
                      position: 'relative',
                      ...styles.SegoeUI,
                    }}
                  ></View>

                  <View
                    style={{
                      flex: 1,
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      height: '100%',
                      ...styles.SegoeUI,
                      width: isMobile ? '100%' : '',
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        ...styles.custom_header,
                        width: isMobile ? '60%' : '100%',
                        height: '100%',
                        backgroundColor: UI.colors.primary,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: isMobile ? 14 : 18,
                          color: '#fff',
                          textAlign: 'center',
                        }}
                      >
                        My Profile
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onClick={this.accountSettings}
                      style={{
                        ...styles.custom_header,
                        width: isMobile ? '60%' : '100%',
                        height: '100%',
                      }}
                    >
                      <Text
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: isMobile ? 14 : 18,
                          fontWeight: 400,
                          color: '#434343',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Image
                          width={22}
                          height={22}
                          source={UI.SETTINGS}
                        />
                        {UI.box(5)}
                        Settings
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onClick={() => this.signOut(setData)}
                      style={{
                        ...styles.custom_header,
                        width: isMobile ? '60%' : '100%',
                        height: '100%',
                        color: '#434343',
                      }}
                    >
                      <Text
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: isMobile ? 14 : 18,
                          fontWeight: 400,
                          color: '#434343',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        Sign Out
                        {UI.box(5)}
                        <Image
                          width={22}
                          height={22}
                          source={UI.SIGNOUT}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </UI.PadView>

              <UI.PadView
                _this={_this}
                style={styles.SegoeUI}
              >
                {isMobile ? UI.box(50) : UI.box(100)}
                <View>
                  <Text style={{ fontSize: 20, fontWeight: 600 }}>
                    Personal Details
                  </Text>
                  {UI.box(20)}
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-around',
                    margin: '10px 0',
                  }}
                >
                  <Text
                    style={{
                      flex: 1,
                      // marginLeft: 30
                    }}
                  >
                    <b>Full Name: </b>
                    {`${this.state.user.firstname} ${this.state.user?.middlename || ''}  ${this.state.user.lastname}`}
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      // marginLeft: 30
                    }}
                  >
                    <b>Location: </b>
                    {`${this.state.user.address ? this.state.user.address : ''} ${this.state.user.city ? this.state.user.city : ''} ${this.state.user.postalcode ? this.state.user.postalcode : ''}`}
                  </Text>
                </View>

                <View style={styles.line}></View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-around',
                    margin: '10px 0',
                  }}
                >
                  <Text
                    style={{
                      flex: 1,
                      // marginLeft: 30
                    }}
                  >
                    <b>Language:</b>{' '}
                    {this.state.user.language ? this.state.user.language : ''}
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      // marginLeft: 30
                    }}
                  >
                    <b>Gender:</b>{' '}
                    {this.state.user.gender ? this.state.user.gender : ''}
                  </Text>
                </View>

                <View style={styles.line}></View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-around',
                    margin: '10px 0',
                  }}
                >
                  <Text
                    style={{
                      flex: 1,
                      // marginLeft: 30
                    }}
                  >
                    <b>Email:</b>{' '}
                    {this.state.user.email ? this.state.user.email : ''}
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      // marginLeft: 30
                    }}
                  >
                    <b>Phone Number:</b>{' '}
                    {this.state.user.mobile ? this.state.user.mobile : ''}
                  </Text>
                </View>

                <View style={styles.line}></View>

                {UI.box(50)}

                <View>
                  <Text style={{ fontSize: 20, fontWeight: 600 }}>
                    Biography
                  </Text>
                  {UI.box(20)}
                </View>

                <View>
                  <Text>
                    {this.state.user.biography ? this.state.user.biography : ''}
                  </Text>
                </View>

                {UI.box(50)}

                {/* <View>
                  <Text style={{ fontSize: 20, fontWeight: 600 }}>
                    Past Booking
                  </Text>
                  {UI.box(20)}
                </View> */}

                {/* <View
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    // justifyContent: "space-around",
                    justifyContent: 'start',
                  }}
                >
                  {past_booking.map((booking) => {
                    return (
                      <>
                        {' '}
                        <TouchableOpacity
                          style={{
                            flex: '0 0 200px',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            width: isMobile ? 300 : '',
                            margin: isMobile ? '0 auto' : '',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                            }}
                          >
                            <img
                              src={booking.primaryImageURL}
                              style={{
                                height: 154,
                                width: '100%',
                                objectFit: ImageFit.COVER,
                              }}
                              alt={booking.title}
                            />

                            <Text
                              style={{
                                backgroundColor: '#fff',
                                textAlign: 'center',
                                padding: '10px 0',
                              }}
                            >
                              {booking.title}
                            </Text>
                          </View>
                        </TouchableOpacity>
                        {UI.box(20)}
                      </>
                    );
                  })}

                  {UI.box(20)}

                  <TouchableOpacity
                    style={{
                      flex: '0 0 100px',
                      backgroundColor: '#E4E4E4',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    onClick={() => this.props.history.push('/booking-history')}
                  >
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      View more
                    </Text>
                  </TouchableOpacity>
                </View> */}
              </UI.PadView>
            </>
          )}
          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(MyAccount);

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: '100%',
    backgroundColor: '#B3B3B3',
    marginTop: 5,
    marginBottom: 10,
  },
  SegoeUI: {
    fontFamily: 'Segoe UI',
  },
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 0,
    paddingRight: 30,
    marginBottom: 5,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_header: {
    padding: 12,
    // backgroundColor: UI.colors.primary,
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  main_text: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
  },
  submain_text: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
  sort_select: {
    width: 200,
    borderWidth: 0.5,
    borderColor: '#c0c0c0',
    borderRadius: 10,
    borderStyle: 'solid',
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 10,
    backgroundColor: 'transparent',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sort_text: {
    alignSelf: 'stretch',
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 5,
  },
});
