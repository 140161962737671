import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ReactPhotoCollage } from 'react-photo-collage';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import HotelDescription from './HotelDescription';

export default class BookingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = { collageSettings: {}, filteredList: {} };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      const { historyDetails } = this.props._this.state.history_data;

      const images = historyDetails.photos.map((data) => {
        return {
          source: data.file_url || data.image_url,
        };
      });

      this.setState({
        collageSettings: {
          width: '100%',
          height: ['400px', '170px'],
          layout: [1, 4],
          photos: images,
          showNumOfRemainingPhotos: true,
        },
      });
    }
  }

  render() {
    const _this = this.props._this;
    const { history_data } = _this.state;
    const { historyDetails } = history_data;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    console.log(history_data);

    return (
      <View style={{ width: '100%' }}>
        <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#ADADAD' }}>
          HOME / PROFILE / BOOKING HISTORY /{' '}
          {historyDetails?.title.toUpperCase()}
        </Text>

        <UI.Row breakpoint={850} _this={_this} style={{ width: '100%' }}>
          <Text style={styles.hotel_title}>{historyDetails?.title}</Text>
          {UI.box(width <= 850 ? 0 : 20)}
          {/* <Verified width={width} /> */}
          <div style={{ alignSelf: 'flex-end', marginLeft: 'auto' }}>
            <Rating text={`${history_data?.reviews?.overAllRating}`} />
          </div>
        </UI.Row>

        <UI.Row breakpoint={850} _this={_this} style={{ width: '100%' }}>
          <Places
            text={`${historyDetails?.places || historyDetails?.location}`}
          />
          {UI.box(width <= 850 ? 0 : 20)}
          {/* <Verified width={width} /> */}
          <div style={{ alignSelf: 'flex-end', marginLeft: 'auto' }}>
            <Location text={`${historyDetails?.location}`} />
          </div>
        </UI.Row>

        <UI.Row breakpoint={850} _this={_this} style={{ width: '100%' }}>
          <Schedule
            departDate={
              history_data?.checkInDate || history_data?.departureDate
            }
            departTime={history_data?.arrivalTime || 'N/A'}
            returnDate={
              history_data?.checkOutDate || history_data?.returnDate || 'N/A'
            }
            returnTime={history_data?.checkOutTime || 'N/A'}
          />

          <div style={{ marginLeft: 'auto', marginLeft: 'auto' }}>
            <Persons
              text={`${
                history_data?.guest
                  ? `${history_data?.guest} Guest`
                  : `${history_data?.totalPassenger} Passenger`
              }`}
            />
          </div>
        </UI.Row>

        {UI.box(20)}

        <Row>
          <Col>
            <div className='gallery mt-4'>
              {Object.getOwnPropertyNames(this.state.collageSettings).length >
                0 && <ReactPhotoCollage {...this.state.collageSettings} />}
            </div>
          </Col>
        </Row>

        <HotelDescription _this={_this} />
      </View>
    );
  }
}

const Address = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image height={20} width={20} tintColor={'#646464'} source={UI.STAR} />
      {UI.box(5)}
      <Text style={styles.hotel_location}>{props.text}</Text>
    </View>
  );
};

const Persons = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image height={20} width={20} tintColor={'#646464'} source={UI.PROFILE} />
      {UI.box(5)}
      <Text style={styles.hotel_location}>{props.text}</Text>
    </View>
  );
};

const Rating = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={UI.colors.yellow}
        source={UI.STAR}
      />
      {UI.box(5)}
      <Text style={styles.rating_text}>{props.text}</Text>
      {UI.box(15)}
    </View>
  );
};

const Schedule = (
  props = { departDate: '', departTime: '', returnDate: '', returnTime: '' }
) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.CALENDAR}
      />
      {UI.box(5)}

      <Text style={{ ...styles.hotel_date, marginRight: 5 }}>
        {props.departDate}
      </Text>
      <Image height={20} width={20} tintColor={'#646464'} source={UI.TIMER} />
      <Text style={{ ...styles.hotel_date, marginLeft: 5 }}>
        {props.departTime}
      </Text>

      <Text style={{ ...styles.hotel_date, marginLeft: 5, marginRight: 5 }}>
        -
      </Text>

      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.CALENDAR}
      />
      {UI.box(5)}

      <Text style={{ ...styles.hotel_date, marginRight: 5 }}>
        {props.returnDate}
      </Text>
      <Image height={20} width={20} tintColor={'#646464'} source={UI.TIMER} />
      <Text style={{ ...styles.hotel_date, marginLeft: 5 }}>
        {props.returnTime}
      </Text>
    </View>
  );
};

const Location = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image
        height={20}
        width={20}
        tintColor={'#646464'}
        source={UI.LOCATION}
      />
      {UI.box(5)}
      <Text style={styles.hotel_location}>{props.text}</Text>
    </View>
  );
};

const Places = (props = { text: '' }) => {
  return (
    <View style={styles.hotel_address_container}>
      <Image height={20} width={20} tintColor={'#646464'} source={UI.STAR} />
      {UI.box(5)}
      <Text style={styles.hotel_location}>{props.text}</Text>
    </View>
  );
};

const Verified = (props = { width: 0 }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: props.width <= 850 ? 'flex-start' : 'center',
      }}
    >
      <Image
        height={20}
        width={20}
        tintColor={UI.colors.primary}
        source={UI.VERIFIED}
      />
      {UI.box(5)}
      <Text style={styles.verified_text}>Verified Provider</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn: {
    height: 40,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  hotel_address_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
  },
  rating_text: {
    fontSize: 14,
    color: UI.colors.yellow,
    fontWeight: 'bold',
  },
  hotel_date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
  hotel_calendar: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  verified_text: {
    fontSize: 14,
    color: UI.colors.primary,
    fontWeight: 'bold',
  },
  hotel_location: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 'bold',
  },
});
