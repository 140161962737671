// Packages
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// Components
import UI from '../../../shared/Components/UI/js';
import { userLogin } from '../../../shared/API/request';
import Notification from '../../../shared/Components/Notification/Notification';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

// CSS
import './css.css';

// Main Component
const LoginForm = ({ props, width, isMobile, styles }) => {
  // States

  // Context Values
  const { data, setData } = useContext(AllContext);

  // React Router History
  let history = useHistory();

  // useForm
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  // On login button click
  const onSubmit = (data) => {
    const newData = { ...data, logintype: 'normal' };

    userLogin(newData, callback);
  };

  // Runs after onSubmit
  const callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      localStorage.setItem('token', `Bearer ${data.access_token}`);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('isAuth', JSON.stringify(true));
      console.log('test')
      setData((prevState) => {
        return { ...prevState, user: data.user, isAuth: true };
      });

      history.push('/');

      Notification('Login Successfully', 'success');
      reset();
    } else if (status === 400 || status === 401) {
      Notification('Invalid Username/Password', 'danger');
      console.log('s');
    } else if (status === 422) {
      if (data.email) {
        Notification(data.email, 'danger');
        console.log('s');
      }

      if (data.message) {
        Notification(data.message, 'danger');
        console.log('s');
      }
    } else {
      Notification('An Error Occured', 'danger');
      console.log('s');
    }
  };

  // To render on screen
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          flexDirection: isMobile ? 'column' : 'column',
          display: 'flex',
        }}
      >
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          Email Address
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='email'
            style={{
              ...styles.form_input,
              width: isMobile ? '60%' : '100%',
            }}
            className={errors.email ? 'red' : ''}
            placeholder={
              errors.email ? errors.email.message : 'Enter Email Address Here'
            }
            {...register('email', {
              required: 'Please Enter Email',
            })}
          />
        </View>
        {UI.box(20)}
        <Text
          style={{
            ...styles.label,
            textAlign: isMobile ? 'center' : 'flex-start',
          }}
        >
          Password
        </Text>
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <input
            type='password'
            minLength={8}
            style={{
              ...styles.form_input_password,
              width: isMobile ? '60%' : '100%',
            }}
            className={errors.password ? 'red' : ''}
            placeholder={
              errors.password ? errors.password.message : 'Enter Password Here'
            }
            {...register('password', {
              required: 'Please Enter Password',
            })}
          />
        </View>
        {UI.box(25)}
        <TouchableOpacity
          style={{
            width: '100%',
            padding: '0',
          }}
          onClick={() => {
            trigger();
          }}
        >
          <input
            type='submit'
            placeholder={`SIGN IN`}
            value={'SIGN IN'}
            style={{
              ...styles.custom_btn,
              color: '#fff',
              width: isMobile ? '60%' : '100%',
              cursor: 'pointer',
            }}
          />
        </TouchableOpacity>
      </form>
    </>
  );
};

export default LoginForm;
