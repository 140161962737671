// Packages
import React, { Component } from "react";

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

// css
import './BigContainer.scss';
// Sample API
const dataAPI = [
  {
    id: 1,
    title: "Manila",
    subtitle: "800 Properties",
    image: "/img/sample_place_1.jpg",
  },
  {
    id: 2,
    title: "Batangas",
    subtitle: "800 Properties",
    image: "/img/sample_place_2.jpg",
  },
  {
    id: 3,
    title: "Boracay",
    subtitle: "800 Properties",
    image: "/img/sample_place_3.jpg",
  },
];

// Main Component
export default class BigContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    // To render on screen
    return (
      <View style={{ marginTop: 30, width: "100%" }}>
        <span className='header-text'>EXPLORE PHILIPPINES</span>

        <span className='sub-text'>
          These popular destinations have a lot to offer
        </span>

        {UI.box(20)}

        <View
          style={{
            flexDirection: _this.state.isMobile ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          {dataAPI &&
            dataAPI.map((location) => {
              return (
                <BigItem
                  key={location.id}
                  _this={_this}
                  main={location.title}
                  sub={location.subtitle}
                  image={location.image}
                />
              );
            })}
        </View>
      </View>
    );
  }
}

// Component
const BigItem = (props = { _this: null, main: "", sub: "", image: "" }) => {
  const _this = props._this;
  const width = _this.state.width;

  const paddingX = width * 0.05;
  let item_width = (width - paddingX * 2) / 3 - paddingX / 2;
  let additional_height = paddingX;
  let item_height = 240 + additional_height;
  let margin = 0;

  if (_this.state.isMobile) {
    item_width = width - paddingX * 2;
    item_height = 200;
    item_height += paddingX * 2;
    margin = paddingX * 1.2;
  }

  if (item_height <= 130) {
    item_height = 130;
  }

  return (
    <TouchableOpacity style={{ marginBottom: margin }}>
      <ImageBackground
        source={props.image}
        className='big-item-container'
        style={{
        
          width: item_width,
          height: item_height,
        }}
      >
        <View
        className='big-item-header'
          style={{
            
            width: item_width,
            minHeight: item_height,
            zIndex: 2,
          }}
        >
          <span className='big-item-main'>{props.main}</span>
          <span className='big-item-sub'>{props.sub}</span>
        </View>

        <View
          style={{
            width: item_width,
            height: item_height,
            marginTop: -item_height,
          }}
        >
          <ImageBackground
            imageFit={ImageFit.COVER}
            style={{
              height: item_height,
              width: item_width,
              zIndex: 1,
              borderRadius: 10,
            }}
            source={UI.ITEM_OVERLAY}
          ></ImageBackground>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

