const mydata = [
  'Barangay Certification',
  'Certification from NCWDP',
  'Company Identification',
  'Consular ID',
  'DSWD Certificaiton',
  ` Driver's License`,
  'GSIS e-Card',
  'GovtOffice/GOCC ID',
  'IBP ID',
  'NBI Clearance',
  'NSO Authenticated Birth Certification',
  'OFW ID',
  'OWWA',
  'POEA ID',
  'PRA Special Resident Retiree Visa ID',
  'PRC ID',
  'Passport',
  'Permit to Carry Firearms',
  'PhilHealth Card',
  'Police Clearance',
  'Postal ID',
  'SSS',
  'Seamans Book',
  'Senior Citizen Card',
  'Student ID',
  'TIN',
  'Visa',
  `Voter's ID`,
];

const idtype = mydata
  .sort((a, b) => a.localeCompare(b))
  .map((item, i) => {
    return (
      <option
        key={i}
        value={item}
      >
        {item}
      </option>
    );
  });

export { idtype };
