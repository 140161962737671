import React, { Component } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import FilterInput from '../../components/FilterInput';
import NumberInput from '../../components/NumberInput';

// Components
import Notification from '../../../shared/Components/Notification/Notification';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

export default class HotelReserve extends Component {
  constructor(props) {
    super(props);
  }

  // Static Context
  static contextType = AllContext;

  // State
  state = {
    hotelArr: '',
    hotel: '',
    check_in: '',
    check_out: '',
    guest: '',
    all_selected_room: [],
    room_vats: [],
    night_duration: 1,
    service_fee: 0,
    hotelVat: 0,
    totalServiceFee: 0,
    total: 0,
    disabled: false,
  };

  // Will run on every first render
  componentDidMount() {
    // Use Context
    // const { data, setData } = this.context;

    // Props data
    const _this = this.props._this;
    const filteredList = _this.state.filteredList[0];

    // get search params to localStorage
    const search_params = JSON.parse(localStorage.getItem('search_params'));

    if (search_params) {
      this.setState({
        hotelArr: filteredList,
        hotel: filteredList.title,
        check_in: search_params.check_in,
        check_out: search_params.check_out,
        guest: search_params.guest,
        service_fee: filteredList.serviceFee,
      });

      this.calculateNightDuration(
        search_params.check_in,
        search_params.check_out
      );
    } else {
      this.setState({
        disabled: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const _this = this.props._this;

    if (prevState.all_selected_room !== this.state.all_selected_room) {
      const vats = this.state.all_selected_room.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.room_type === value.room_type && t.id === value.id
          )
      );

      // Component State
      this.setState({
        room_vats: vats,
      });
    }

    if (_this.state.selected_room) {
      let new_all_selected_room = this.state.all_selected_room.concat(
        _this.state.selected_room
      );

      // Component State
      this.setState({
        all_selected_room: new_all_selected_room,
      });

      // Parent State
      _this.setState({
        selected_room: null,
        all_selected_room: new_all_selected_room,
      });
    }
  }

  calculateNightDuration = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      let check_in_date = new Date(checkIn);
      let check_out_date = new Date(checkOut);

      const nights = Math.floor(
        (Date.UTC(
          check_out_date.getFullYear(),
          check_out_date.getMonth(),
          check_out_date.getDate()
        ) -
          Date.UTC(
            check_in_date.getFullYear(),
            check_in_date.getMonth(),
            check_in_date.getDate()
          )) /
          (1000 * 60 * 60 * 24)
      );

      // set state of nights
      this.setState({
        night_duration: nights,
      });
    }
  };

  handleSubmit = () => {
    if (this.state.guest === '') {
      Notification('Minimum number of guest is 1', 'danger');
      return;
    }

    if (this.state.hotelArr && this.state.all_selected_room.length > 0) {
      localStorage.setItem('booking-details', JSON.stringify(this.state));
      UI.goTo('/hotel-booking-details');
    } else {
      Notification('Please select a room first', 'danger');
    }
  };

  removeRoom = (arrIndex) => {
    let allRooms = this.state.all_selected_room;

    if (arrIndex > -1) {
      allRooms.splice(arrIndex, 1);
    }

    this.setState({
      all_selected_room: allRooms,
    });
  };

  computeTotal = () => {
    const pricesArr = this.state.all_selected_room.map((room) => {
      return parseInt(room.price * this.state.night_duration);
    });

    const subtotal = pricesArr.reduce((partialSum, a) => partialSum + a, 0);

    const total = parseInt(subtotal + this.state.totalServiceFee);

    if (this.state.total !== total) {
      this.setState({
        total: total,
      });
    }

    return total;
  };

  computeServiceFee = () => {
    const perRoom = parseInt(
      this.state.service_fee * this.state.all_selected_room.length
    );
    const totalFee = parseInt(perRoom * this.state.night_duration);

    if (this.state.totalServiceFee !== totalFee) {
      this.setState({
        totalServiceFee: totalFee,
      });
    }

    return totalFee;
  };

  computeVat = (vats) => {
    const vat = parseFloat((vats.price / 1.12) * 0.12).toFixed(2);

    return vat;
  };

  roomPriceNoVat = (vats) => {
    const roomPrice = parseFloat(vats.price / 1.12).toFixed(2);

    return roomPrice;
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const { data } = _this.context;
    const user_islogin = data.isAuth;
    const filteredList = _this.state.filteredList;
    const roomLeft = filteredList[0].room_left;

    return (
      <>
        {this.state.disabled && <></>}
        {!this.state.disabled && roomLeft !== 0 && (
          <>
            <View
              style={{
                ...styles.main_container,
                width: width <= 830 ? '100%' : 400,
                marginLeft: width <= 830 ? 0 : 20,
              }}
            >
              <View style={styles.row}>
                <View style={styles.header_container}>
                  <Text style={styles.header_price}>Book this Hotel</Text>
                </View>
              </View>

              {/* Hotel Name */}
              {UI.box(20)}
              <FilterInput
                onChange={(e) => {
                  this.setState({
                    hotel: e.target.value,
                  });
                }}
                _this={_this}
                title={'City, Destination or Hotel Name'}
                placeholder={'Destination Name'}
                icon={UI.DESTINATION}
                value={this.state.hotel}
                disabled={true}
              />

              {/* Check In */}
              {UI.box(20)}
              <FilterInput
                onChange={(e) => {
                  this.setState({
                    check_in: e.target.value,
                  });
                }}
                _this={_this}
                title={'Check In'}
                placeholder={'Check In'}
                icon={UI.CALENDAR}
                value={this.state.check_in}
                disabled={true}
              />

              {/* Check Out */}
              {UI.box(20)}
              <FilterInput
                onChange={(e) => {
                  this.setState({
                    check_out: e.target.value,
                  });
                }}
                _this={_this}
                title={'Check Out'}
                placeholder={'Check Out'}
                icon={UI.CALENDAR}
                value={this.state.check_out}
                disabled={true}
              />

              {/* Guest */}
              {UI.box(20)}
              <NumberInput
                onChange={(e) => {
                  this.setState({
                    guest: e,
                  });
                }}
                _this={_this}
                title={'Guests'}
                placeholder={'Enter Number of Guest'}
                icon={UI.PROFILE}
                className='guest'
                type='text'
                value={this.state.guest}
              />

              {/* Room */}

              <View>
                {(!this.state.all_selected_room ||
                  this.state.all_selected_room.length === 0) && (
                  <>
                    {UI.box(20)}
                    <Text>Select Room to proceed</Text>
                  </>
                )}
                {this.state.all_selected_room &&
                  this.state.all_selected_room.length > 0 && (
                    <>
                      {this.state.all_selected_room.map((room, index) => {
                        return (
                          <div
                            key={room.id}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {UI.box(20)}
                            <View style={{ position: 'relative' }}>
                              <FilterInput
                                key={room.id}
                                _this={_this}
                                title={`Room/s`}
                                placeholder={'Please select a room first'}
                                icon={UI.BEDROOMS}
                                value={
                                  room.value !== null
                                    ? `${room.room_type} Room - PHP ${room.price} / per night`
                                    : ''
                                }
                                disabled={true}
                              />

                              <TouchableOpacity
                                onClick={() => this.removeRoom(index)}
                                style={{
                                  position: 'absolute',
                                  width: '2em',
                                  height: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  right: 0,
                                  color: 'red',
                                }}
                              >
                                <Text>X</Text>
                              </TouchableOpacity>
                            </View>
                          </div>
                        );
                      })}
                    </>
                  )}
              </View>

              {UI.box(20)}
              {this.state.all_selected_room &&
                this.state.all_selected_room.length > 0 && (
                  <>
                    <Text style={styles.normal_text}>
                      You won't be charged yet
                    </Text>
                    <View style={styles.price_container}>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.price_left}>
                          Room price/s (vat included)
                        </Text>
                      </View>
                      <Text
                        style={{ ...styles.price_right, alignSelf: 'flex-end' }}
                      ></Text>
                    </View>

                    {/* For all Room vat */}
                    {this.state.room_vats.map((vats) => {
                      return (
                        <>
                          <View style={styles.price_container}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.price_left}>
                                {vats.room_type} room
                              </Text>
                              <Text
                                style={{ ...styles.price_left, fontSize: 12 }}
                              >
                                room price = {this.roomPriceNoVat(vats)}
                              </Text>
                              <Text
                                style={{ ...styles.price_left, fontSize: 12 }}
                              >
                                vat (12%) = {this.computeVat(vats)}
                              </Text>
                            </View>
                            <Text
                              style={{
                                ...styles.price_right,
                                alignSelf: 'flex-end',
                              }}
                            >
                              P {vats.price}
                            </Text>
                          </View>
                        </>
                      );
                    })}

                    <hr
                      style={{
                        borderBottom: '1px solid rgb(197, 197, 197)',
                        margin: '1em 0',
                      }}
                    />

                    {this.state.all_selected_room.map((room) => {
                      return (
                        <>
                          <View style={styles.price_container}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.price_left}>
                                P {room.price} x{' '}
                                {this.state.night_duration > 1
                                  ? `${this.state.night_duration} Nights`
                                  : '1 Night'}
                              </Text>
                            </View>
                            <Text style={styles.price_right}>
                              P{' '}
                              {parseInt(room.price * this.state.night_duration)}
                            </Text>
                          </View>
                        </>
                      );
                    })}

                    <View style={styles.price_container}>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.price_left}>
                          {`Service fee (P${this.state.service_fee} per room/per night)`}
                        </Text>
                        <Text style={{ ...styles.price_left, fontSize: 12 }}>
                          Number of room ={' '}
                          {this.state.all_selected_room
                            ? this.state.all_selected_room.length
                            : '1'}
                        </Text>
                        <Text style={{ ...styles.price_left, fontSize: 12 }}>
                          Number of night ={' '}
                          {this.state.night_duration
                            ? this.state.night_duration
                            : '1'}
                        </Text>
                      </View>
                      <Text
                        style={{ ...styles.price_right, alignSelf: 'flex-end' }}
                      >
                        P {this.computeServiceFee()}
                      </Text>
                    </View>

                    <View style={styles.price_container}>
                      <View style={{ flex: 1 }}>
                        <Text style={{ ...styles.price_left, color: 'black' }}>
                          Total
                        </Text>
                      </View>
                      <Text style={{ ...styles.price_right, color: 'black' }}>
                        P {this.computeTotal()}
                      </Text>
                    </View>
                  </>
                )}

              {UI.box(20)}
              {user_islogin && (
                <TouchableOpacity
                  style={styles.custom_btn}
                  onClick={(e) => this.handleSubmit()}
                >
                  <Text style={styles.custom_btn_text}>Reserve </Text>
                </TouchableOpacity>
              )}
            </View>
          </>
        )}

        {roomLeft === 0 && <h2 className='fully-booked-text'>FULLY BOOKED</h2>}
      </>
    );
  }
}

const styles = StyleSheet.create({
  price_right: {
    fontSize: 14,
    color: '#8E8E8E',
    fontWeight: 'bold',
  },
  price_left: {
    fontSize: 14,
    color: '#8E8E8E',
    fontWeight: 'bold',
  },
  price_container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  normal_text: {
    fontSize: 12,
    color: '#8E8E8E',
    alignSelf: 'center',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn: {
    height: 40,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  review_text: {
    fontSize: 12,
    color: '#8E8E8E',
  },
  rating_text: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 20,
    marginLeft: 10,
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  end: {
    alignItems: 'flex-end',
  },
  header_night: {
    fontSize: 16,
    color: UI.colors.primary,
    marginLeft: 10,
  },
  header_price: {
    fontSize: 20,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  row: {
    flexDirection: 'row',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flex: 1,
    alignSelf: 'flex-start',
  },

  main_container: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
  },
});
