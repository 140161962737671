import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import HotelDescription from "./HotelDescription";
import Beds from "./Beds";
import Amenities from "./Amenities";

export default class Availability extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          width: "100%",
          alignItems: width <= 830 ? "flex-start" : "center",
        }}
      >
        {/* <Beds _this={_this} /> */}
        <Amenities _this={_this} />
        <Header text={"PROMO AVAILABILITY"} />
        {UI.box(20)}
        <UI.Row _this={_this} breakpoint={415}>
          <View style={{ flexDirection: "row" }}>
            <View>
              <Text style={styles.header_sub}>Check-in Date</Text>
              <Text style={styles.header_date}>Thu, Oct 14, 2021</Text>
              <Text style={styles.header_small}>From 2:00 PM</Text>
            </View>
            {UI.box(width <= 1000 ? 30 : width <= 1200 ? 60 : 90)}
            <View>
              <Text style={styles.header_sub}>Check-out Date</Text>
              <Text style={styles.header_date}>Thu, Oct 16, 2021</Text>
              <Text style={styles.header_small}>2-night stay</Text>
            </View>
          </View>
          {UI.box(width <= 1000 ? 30 : width <= 1200 ? 60 : 90)}
          <View style={{ flexDirection: "row" }}>
            <View>
              <Text style={styles.header_sub}>Guests</Text>
              <Text style={styles.header_date}>2 Adults</Text>
            </View>
            {UI.box(width <= 1000 ? 30 : width <= 1200 ? 60 : 90)}
            <View style={{ alignSelf: "center" }}>
              <TouchableOpacity style={styles.custom_btn}>
                <Text style={styles.custom_btn_text}>Change Search</Text>
              </TouchableOpacity>
            </View>
          </View>
        </UI.Row>
      </View>
    );
  }
}

const Header = (props = { text: "" }) => {
  return (
    <View style={{ width: "100%" }}>
      <Text style={styles.header_text}>{props.text}</Text>
      <View style={styles.line}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  custom_btn_text: {
    color: "white",
    fontSize: 13,
    fontWeight: "bold",
  },
  custom_btn: {
    height: 40,
    width: 130,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "#B3B3B3",
    marginTop: 5,
    marginBottom: 10,
  },

  header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#434343",
  },
  header_sub: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#434343",
  },
  header_small: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#434343",
  },
  header_date: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.primary,
    marginTop: 10,
  },
});
