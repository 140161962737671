import React, { Component } from 'react';
import { View, Text, StyleSheet } from '../../shared/custom-react-native';
import UI from '../../shared/Components/UI/js';
import { Stepper, Step } from 'react-form-stepper';

import './Stepper.scss';

export default class StepperUI extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      step: this.props._this.state.step,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps !== this.props) {
      this.setState({
        step: this.props._this.state.step
      })
    }
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;

    return (
      <View className='stepperUI' style={styles.main_container}>
        <View style={styles.header_container}>
          <View style={{ flexDirection: 'row', width: width, height: 100 }}>
            <Stepper
              activeStep={this.state.step}
              style={{ width: width }}
            >
              <Step label='Your Selections' />
              <Step label='Your Details' />
              <Step label='Final Step' />
            </Stepper>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  sub_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434343',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  main_container: {
    width: '100%',
    marginTop: 30,
  },
});
