import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';
import { Modal, Button, Form } from 'react-bootstrap';
import Notification from '../../shared/Components/Notification/Notification';

import UI from '../../shared/Components/UI/js';
import Header from '../../modules/components/Header';
import Footer from '../../modules/components/Footer';

import './css.css';
import SortComponent from './SortComponent';
import TitleHeader from '../../modules/components/TitleHeader';
import ItemList from './ItemList';
import SearchContainer from './SearchContainer';
import Recommendations from '../../modules/components/Recommendations';
import StarRating from './StarRating';

// Context
import AllContext from '../../shared/Context/AllContext';

// API
import {
  getBookmarkList,
  removeSelectedBookmark,
} from '../../shared/API/request';

class bookmarks extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);

    UI.initiateView(this, {
      active_link: 'BOOKMARKS',
      filteredDestinations: null,
      meta: null,
      search_sortby: null,
      location: '',
      sortBy: 'price',
      dateOfBooking: '',
      show: false,
      historyID: '',
      comment: '',
      rating: 0,
      bookmarkList: null,
      bookmarkId: null,
      disabledbtn: false,
    });
  }

  componentWillMount() {
    // Use Context
    const { data, setData } = this.context;

    this.fnGetBookmarks(data.user.id, '');
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, setData } = this.context;

    if (prevState !== this.state) {
      console.log(this.state);
    }
  }

  fnGetBookmarks = (id, page) => {
    const params = `&page=${page}`;

    console.log('yawas');

    getBookmarkList(id, params, this.callbackBookmarkList);
  };

  prevPage = () => {
    const { data } = this.context;
    const prevPage = parseInt(this.state.meta.current_page) - 1;

    this.fnGetBookmarks(data.user.id, prevPage, this.callbackBookmarkList);
  };

  nextPage = () => {
    const { data } = this.context;
    const nextPage = parseInt(this.state.meta.current_page) + 1;
    this.setState({ disabledbtn: true });
    this.fnGetBookmarks(data.user.id, nextPage, this.callbackBookmarkList);
  };

  searchFromBookmark = (id) => {
    const params = {
      location: this.state.location,
      sortBy: this.state.sortBy,
    };
  };

  // Sidebar Search Function
  search = () => {
    const { data } = this.context;
    this.searchFromBookmark(data.user.id);
  };

  // Callback after get api for searchhoteltours
  callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          filteredDestinations: data.data,
        });
      }

      this.setState({
        meta: data.meta,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  callbackBookmarkList = async (response) => {
    const { status, data } = await response;

    if (response) {
      if (status === 200 || status === 201 || status === 202) {
        this.setState({ disabledbtn: false });
        this.setState({ filteredDestinations: [] });
        this.setState({
          filteredDestinations: data.data,
        });

        this.setState({
          meta: data.meta,
        });
      }

      if (status === 401 || status === 422) {
        this.setState({ disabledbtn: false });
        Notification('An Error Occured', 'danger');
      }
    }
  };

  openReviewModal = (data) => {
    console.log(data);
    this.setState({
      show: true,
      historyID: data,
      comment: '',
      rating: 0,
      bookmarkId: data,
    });
  };

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;

    // Use Context
    const { data, setData } = this.context;
    const userID = data?.user?.id;

    const submitReview = () => {
      this.setState({ show: false });
      const params = {
        bookmarkId: this.state.bookmarkId,
      };
      console.log(params);
      removeSelectedBookmark(params, removebookmarkcallback);
    };

    const removebookmarkcallback = async (response) => {
      const { status, data } = await response;

      if (status === 201 || status === 200 || status === 202) {
        this.fnGetBookmarks(userID, '');
        Notification('Bookmark successfully removed', 'success');
      } else if (status === 400) {
        Notification('An Error Occured', 'danger');
      } else {
        Notification('An Error Occured', 'danger');
      }
    };

    return (
      <>
        {/* Main Content */}
        <View style={styles.main_container}>
          <ScrollView>
            <Header _this={this} />
            {/* Contents Here */}
            <TitleHeader
              _this={_this}
              title={'BOOKMARKS'}
              sub_title={'HOTEL / PROFILE / BOOKMARKS'}
            />
            <SortComponent _this={_this} />

            <UI.PadView _this={_this}>
              {UI.box(30)}
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                <ItemList
                  _this={_this}
                  openReviewModal={this.openReviewModal}
                />

                {/*   {UI.box(30)}
                <View>
                  <SearchContainer _this={_this} />
                  {UI.box(30)}
                 <Recommendations _this={_this} />
                  {UI.box(30)} 
                </View>*/}
              </View>

              {/* Pagination */}
              <View
                style={{
                  flexDirection: 'row',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                {this.state.meta && (
                  <>
                    {this.state.meta.current_page !== this.state.meta.from && (
                      <TouchableOpacity
                        style={styles.pagination_btn}
                        onClick={() => this.prevPage()}
                      >
                        <Image
                          height={15}
                          width={15}
                          tintColor={'#646464'}
                          source={UI.CHEVRON_LEFT}
                        />
                      </TouchableOpacity>
                    )}
                    <View style={styles.pagination_btn}>
                      <Text style={styles.pagination_btn_text}>
                        Showing Page{' '}
                        {this.state.meta ? this.state.meta.current_page : '0'}{' '}
                        of {this.state.meta ? this.state.meta.last_page : '0'}
                      </Text>
                      {UI.box(10)}
                    </View>
                    {this.state.meta.current_page !==
                      this.state.meta.last_page && (
                      <button
                        style={styles.pagination_btn}
                        onClick={() => this.nextPage()}
                        disabled={this.state.disabledbtn}
                      >
                        <Image
                          height={15}
                          width={15}
                          tintColor={'#646464'}
                          source={UI.CHEVRON_RIGHT}
                        />
                      </button>
                    )}
                  </>
                )}
              </View>
            </UI.PadView>

            {/* End Contents Here */}

            <Footer _this={_this} />
          </ScrollView>
        </View>

        {/* Modal */}
        <Modal
          className='form-modal'
          size='lg'
          show={this.state.show}
          onHide={() => {
            this.setState({ show: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation Delete Bookmark</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5> Are you sure you want to Remove this Bookmark ?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                this.setState({ show: false });
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitReview();
              }}
            >
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(bookmarks);

const styles = StyleSheet.create({
  maps_bg: {
    height: 250,
    justifyContent: 'center',
    alignItems: 'center',
  },
  show_map_btn_text: {
    color: 'white',
    fontSize: 14,
  },
  show_map_btn: {
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: UI.colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  pagination_btn_text: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 'bold',
  },
  pagination_btn: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    alignSelf: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
});
