// Packages
import React, { Component, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TitleHeader from './TitleHeader';
import AccountInformation from '../AccountInformation';
import AccountSecurity from '../AccountSecurity';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

import {
  getVisaPassport,
  getViewVisaPassport,
} from '../../../shared/API/request';

// CSS
import './css.css';
import Item from 'antd/lib/list/Item';

// Class
class AccountSettings extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props, context) {
    super(props);

    UI.initiateView(this, {
      active_link: '',
      user: {},
      pass: {},
    });
  }

  // Redirect to Account Profile
  accountProfile = () => {
    UI.goTo('/account');
  };

  // Sign Out Function
  signOut = (setData) => {
    localStorage.clear();
    setData((prevState) => {
      return { ...prevState, isAuth: false, user: {} };
    });
    UI.goTo('/');
  };

  handlePassportVisa = () => {
    UI.goTo('/passport-visa');
  };

  visaPassportData = (id) => {
    getVisaPassport(id, this.callbackVisaPassport);
  };

  callbackVisaPassport = async (response) => {
    const { data } = await response;

    this.setState({
      VisaPassportList: data,
    });
  };

  // Will re-render everytime a state gets updated
  componentDidUpdate() {
    console.log(this.state.user);
  }

  // Callback for converted Image to Base64
  callback = (response) => {
    if (response) {
      this.setState({
        user: {
          ...this.state.user,
          profile: response,
        },
      });
    }
  };

  render() {
    const width = this.state.width;
    const _this = this;
    const isMobile = _this.state.isMobile;

    const { data, setData } = this.context;
    const { user } = data;

    if (Object.keys(this.state.user).length === 0) {
      this.setState({
        user: user,
      });

      this.visaPassportData(user.id);
    }

    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          <TitleHeader _this={_this} />

          {this.state.user && (
            <>
              <UI.PadView breakpoint={850} _this={_this}>
                {UI.box(150)}
                <View
                  style={{
                    ...styles.sort_text,
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'center' : 'center',
                    padding: 0,
                    boxShadow: '0 2px 3px #3444',
                  }}
                >
                  <View
                    style={{
                      flex: isMobile ? 1 : '0 0 14em',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={`${
                        user.profile
                          ? user.profile
                          : user.photo
                          ? user.photo
                          : UI.PROFILE_PICTURE
                      }`}
                      style={{
                        height: isMobile ? 'auto' : 154,
                        width: 154,
                        objectFit: ImageFit.COVER,
                        position: 'absolute',
                        border: 'solid 5px #fff',
                        borderRadius: '50%',
                        top: -75,
                        left: isMobile ? 0 : 25,
                      }}
                      alt='User Profile'
                    />
                    {isMobile ? (
                      <div style={{ width: '140px', height: '80px' }} />
                    ) : (
                      UI.box(100)
                    )}
                  </View>

                  <View
                    style={{
                      flex: width > 1000 ? '1' : '2',
                      position: 'relative',
                      alignItems: isMobile ? 'center' : 'flex-start',
                      ...styles.SegoeUI,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 25,
                      }}
                    >
                      {`${this.state.user.firstname} ${
                        this.state.user?.middlename || ''
                      } ${this.state.user.lastname}`}
                    </Text>
                    <Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                      <Image width={22} height={22} source={UI.GOOGLE} />
                      {UI.box(5)}
                      Google
                    </Text>
                  </View>
                  {isMobile ? UI.box(30) : ''}

                  <View
                    style={{
                      flex: width > 1000 ? '1' : '0',
                      position: 'relative',
                      ...styles.SegoeUI,
                    }}
                  ></View>

                  <View
                    style={{
                      flex: 1,
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      height: '100%',
                      ...styles.SegoeUI,
                      width: isMobile ? '100%' : '',
                    }}
                  >
                    <TouchableOpacity
                      onClick={this.accountProfile}
                      style={{
                        ...styles.custom_header,
                        width: isMobile ? '100%' : '100%',
                        height: '100%',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: isMobile ? 14 : 18,
                          color: '#434343',
                          textAlign: 'center',
                        }}
                      >
                        My Profile
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        ...styles.custom_header,
                        width: isMobile ? '100%' : '100%',
                        height: '100%',
                        backgroundColor: UI.colors.primary,
                      }}
                    >
                      <Text
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: isMobile ? 14 : 18,
                          fontWeight: 400,
                          color: '#fff',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Image
                          width={22}
                          height={22}
                          source={UI.SETTINGS_WHITE}
                        />
                        {UI.box(5)}
                        Settings
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onClick={() => this.signOut(setData)}
                      style={{
                        ...styles.custom_header,
                        width: isMobile ? '100%' : '100%',
                        height: '100%',
                        color: '#434343',
                      }}
                    >
                      <Text
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: isMobile ? 14 : 18,
                          fontWeight: 400,
                          color: '#434343',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        Sign Out
                        {UI.box(5)}
                        <Image width={22} height={22} source={UI.SIGNOUT} />
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </UI.PadView>

              <AccountInformation
                _this={_this}
                styles={styles}
                isMobile={isMobile}
                width={width}
                state={this.state}
                onStateUpdate={this.onStateUpdate}
              />

              <AccountSecurity
                _this={_this}
                styles={styles}
                isMobile={isMobile}
                width={width}
                state={this.state}
                onStateUpdate={this.onStateUpdate}
              />

              <UI.PadView
                breakpoint={850}
                _this={_this}
                style={{ ...styles.SegoeUI }}
              >
                {UI.box(50)}
                <View
                  style={{
                    backgroundColor: '#fff',
                    padding: isMobile ? 15 : 30,
                    boxShadow: '0 2px 3px #3444',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      justifyContent: 'space-between',
                      fontSize: isMobile ? 20 : 25,
                      fontWeight: 'bold',
                    }}
                  >
                    <Text>Passport and Visa</Text>
                    <TouchableOpacity onClick={this.handlePassportVisa}>
                      <Text style={{ color: '#006FB9' }}>+ Add</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </UI.PadView>

              <UI.PadView
                breakpoint={850}
                _this={_this}
                style={{ ...styles.SegoeUI }}
              >
                {UI.box(5)}

                {this.state.VisaPassportList &&
                  this.state.VisaPassportList.map((item) => {
                    return (
                      <View
                        style={{
                          backgroundColor: '#fff',
                          padding: isMobile ? 15 : 30,
                          boxShadow: '0 2px 3px #3444',
                          marginBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'space-between',
                            fontSize: isMobile ? 20 : 25,
                            fontWeight: 'bold',
                          }}
                        >
                          <Text>
                            {item.firstname +
                              ' ' +
                              item.middlename +
                              ' ' +
                              item.lastname}
                          </Text>
                          <TouchableOpacity>
                            <Link
                              style={{
                                color: '#006FB9',
                                textDecoration: 'none',
                              }}
                              to={`/passport-visa/${item.assistanceId}`}
                            >
                              Edit
                            </Link>
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  })}
              </UI.PadView>
            </>
          )}

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(AccountSettings);

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: '1011',
    backgroundColor: '#B3B3B3',
    marginTop: 5,
    marginBottom: 10,
  },
  SegoeUI: {
    fontFamily: 'Segoe UI',
  },
  filter_checkbox_text: {
    fontSize: 16,
    color: '#8f8f8f',
    fontWeight: 'bold',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: 'none',
  },
  checkbox_container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingLeft: 0,
    paddingRight: 30,
    marginBottom: 5,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  form_input: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
  },
  form_input_password: {
    height: 50,
    flex: 1,
    borderRadius: 5,
    border: '1px solid #d9d9d9',
    padding: 8,
    letterSpacing: '0.125em',
  },
  hotel_title: {
    fontSize: 36,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  custom_btn_outline: {
    padding: 12,
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_header: {
    padding: 12,
    // backgroundColor: UI.colors.primary,
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn: {
    padding: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_outline_text: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: 'bold',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  main_text: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
  },
  submain_text: {
    color: 'black',
    fontWeight: 'bold',
  },
  label: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  span_text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span_text_primary: {
    color: UI.colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
  },
  sort_select: {
    width: 200,
    borderWidth: 0.5,
    borderColor: '#c0c0c0',
    borderRadius: 10,
    borderStyle: 'solid',
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 10,
    backgroundColor: 'transparent',
  },
  fl_ai: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sort_text: {
    alignSelf: 'stretch',
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  selectOption: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 50,
    width: '100%',
    paddingLeft: 10,
  },
});
