// Packages
import React, { Component } from 'react';
import IconTint from 'react-icon-tint';

// Components
import UI from '../../../shared/Components/UI/js';

// Assets / CSS
import './HotelItems.scss';

export default class HotelItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const hotelList = this.props.hotelList;

    return (
      <div className='hotel-cont'>
        {hotelList &&
          hotelList.length > 0 &&
          hotelList.map((destination) => {
            return <HotelItem _this={_this} destination={destination} />;
          })}
        {!hotelList ||
          (!hotelList.length > 0 && (
            <div>
              <h2 style={{ fontSize: 16 }}>
                No Hotel found. Try to search for something else.
              </h2>
            </div>
          ))}
      </div>
    );
  }
}

const HotelItem = ({ _this, destination }) => {
  const onClick = () => {
    const search_params = {
      check_in: _this.state.search_check_in,
      check_out: _this.state.search_check_out,
      guest: _this.state.search_guest,
    };

    localStorage.setItem('search_params', JSON.stringify(search_params));
    _this.props.history.push(`/hotel-details/${destination?.slug}`);
  };

  return (
    <div onClick={onClick} className='hotel-item'>
      <img
        className='hotel-image'
        src={destination?.primaryImageURL}
        alt={destination?.name}
      />

      <div className='hotel-details'>
        <div className='first-row'>
          <h3 className='primary-text'>{destination?.title}</h3>

          <div className='icons'>
            <img width={20} src={UI.STAR} alt='star' className='star' />

            <p className='rating'>{destination?.rating}</p>

            <div
              className='best-value'
              style={{
                display: destination?.isBestValue ? 'flex' : 'none',
              }}
            >
              <IconTint
                src={UI.VERIFIED}
                maxWidth={20}
                maxHeight={20}
                color={'#006fb9'}
              />

              <p className='verified-text'>Best Value</p>
            </div>
          </div>
        </div>

        <div className='line'></div>

        <div className='second-row'>
          <div className='first-line'>
            <div className='location'>
              <IconTint
                src={UI.LOCATION}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-location'>{destination?.location}</p>
            </div>

            <div className='calendar'>
              <IconTint
                src={UI.CALENDAR}
                maxWidth={20}
                maxHeight={20}
                color={'#646464'}
              />

              <p className='hotel-date'>{destination?.date_opened}</p>
            </div>
          </div>

          <div className='second-line'>
            <IconTint
              src={UI.STAR}
              maxWidth={20}
              maxHeight={20}
              color={'#646464'}
            />

            <p className='hotel-address'>{destination?.places}</p>
          </div>
        </div>

        <div className='third-row'>
          <div className='price'>
            <p className='text-header'>Php {destination?.price}</p>

            {destination?.isTaxInclusive && (
              <span className='tax-inclusive'>Inclusive of Taxes</span>
            )}
          </div>

          <p className={`pax-left`}>
            {parseInt(destination?.room_left) > 0
              ? `${destination?.room_left} Room Left!`
              : `Fully Booked`}
          </p>
        </div>
      </div>
    </div>
  );
};
