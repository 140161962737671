import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import { Modal, Button, Form } from 'react-bootstrap';
import Notification from '../../../shared/Components/Notification/Notification';

import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './css.css';
import SortComponent from './SortComponent';
import TitleHeader from '../../components/TitleHeader';
import ItemList from './ItemList';
import SearchContainer from './SearchContainer';
import Recommendations from '../../components/Recommendations';
import StarRating from './StarRating';
import TabsUI from './Tabs';
import PaginationUI from './Pagination';

// Context
import AllContext from '../../../shared/Context/AllContext';

// API
import {
  submitBookingReview,
  getTourPastBooking,
  getHotelPastBooking,
  getFerryPastBooking,
} from '../../../shared/API/request';

class BookingHistory extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props) {
    super(props);

    UI.initiateView(this, {
      active_link: '',
      filteredDestinations: null,
      activeTab: 'Tour',
      tourList: null,
      hotelList: null,
      ferryList: null,
      tourMeta: null,
      hotelMeta: null,
      ferryMeta: null,
      search_sortby: null,
      location: '',
      sortBy: 'price',
      dateOfBooking: '',
      show: false,
      historyID: '',
      type: '',
      comment: '',
      rating: 0,
    });
  }

  componentWillMount() {
    // Use Context
    const { data, setData } = this.context;

    this.bookingHistory(data.user.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, setData } = this.context;

    if (prevState !== this.state) {
      console.log(this.state);
    }

    // Function for Filter By Price API
    if (prevState.sortBy !== this.state.sortBy) {
      this.bookingHistory(data.user.id);
    }
  }

  prevPage = (type) => {
    const { data } = this.context;

    if (type === 'tour') {
      const prevPage = parseInt(this.state.tourMeta.current_page) - 1;
      const params = `?location=${this.state.location}&dateOfBooking=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}&page=${prevPage}`;

      getTourPastBooking(data.user.id, params, this.tourCallback);
    } else if (type === 'hotel') {
      const prevPage = parseInt(this.state.hotelMeta.current_page) - 1;
      const params = `?location=${this.state.location}&dateOfBooking=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}&page=${prevPage}`;

      getHotelPastBooking(data.user.id, params, this.hotelCallback);
    } else {
      const prevPage = parseInt(this.state.ferryMeta.current_page) - 1;
      const params = `?origin=${this.state.location}&departure_date=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}&page=${prevPage}`;

      getFerryPastBooking(data.user.id, params, this.ferryTourback);
    }
  };

  nextPage = (type) => {
    const { data } = this.context;

    if (type === 'tour') {
      const nextPage = parseInt(this.state.tourMeta.current_page) + 1;
      const params = `?location=${this.state.location}&dateOfBooking=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}&page=${nextPage}`;

      getTourPastBooking(data.user.id, params, this.tourCallback);
    } else if (type === 'hotel') {
      const nextPage = parseInt(this.state.hotelMeta.current_page) + 1;
      const params = `?location=${this.state.location}&dateOfBooking=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}&page=${nextPage}`;

      getHotelPastBooking(data.user.id, params, this.hotelCallback);
    } else {
      const prevPage = parseInt(this.state.ferryMeta.current_page) + 1;
      const params = `?origin=${this.state.location}&departure_date=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}&page=${prevPage}`;

      getFerryPastBooking(data.user.id, params, this.ferryTourback);
    }
  };

  bookingHistory = (id) => {
    const tourHotelParams = `?location=${this.state.location}&dateOfBooking=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}`;
    const ferryParams = `?origin=${this.state.location}&departure_date=${this.state.dateOfBooking}&sortBy=${this.state.sortBy}`;

    getHotelPastBooking(id, tourHotelParams, this.hotelCallback);
    getTourPastBooking(id, tourHotelParams, this.tourCallback);
    getFerryPastBooking(id, ferryParams, this.ferryTourback);
  };

  // Sidebar Search Function
  search = () => {
    const { data, setData } = this.context;

    this.bookingHistory(data.user.id);
  };

  // Callback after get api for Tours
  tourCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          tourList: data.data,
        });
      }

      this.setState({
        tourMeta: data.meta,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  // Callback after get api for Hotel
  hotelCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          hotelList: data.data,
        });
      }

      this.setState({
        hotelMeta: data.meta,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  // Callback after get api for Hotel
  ferryTourback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          ferryList: data.data,
        });
      }

      this.setState({
        ferryMeta: data.meta,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  openReviewModal = (data, type) => {
    this.setState({
      show: true,
      historyID: data,
      type: type,
      comment: '',
      rating: 0,
    });
  };

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;

    // Use Context
    const { data, setData } = this.context;
    const userID = data?.user?.id;

    const submitReview = () => {
      if (this.state.comment === '' || this.state.rating === 0) {
        Notification('Please fill in the empty fields', 'danger');
        return;
      }

      const params = {
        type: this.state.type,
        bookingRefId: this.state.historyID,
        rate: this.state.rating,
        comment: this.state.comment,
        guest_id: userID,
      };

      submitBookingReview(params, ratingCallback);
    };

    const ratingCallback = async (response) => {
      const { status, data } = await response;
      console.log(response);
      if (status === 201 || status === 200 || status === 202) {
        Notification('Review rating successfully submitted', 'success');
        this.bookingHistory(userID);
        this.setState({
          show: false,
          historyID: '',
          comment: '',
          rating: 0,
        });
      } else if (status === 400) {
        Notification('An Error Occured', 'danger');
      } else {
        Notification('An Error Occured', 'danger');
      }
    };

    return (
      <>
        {/* Main Content */}
        <View style={styles.main_container}>
          <ScrollView>
            <Header _this={this} />
            {/* Contents Here */}
            <TitleHeader
              _this={_this}
              title={'BOOKING HISTORY'}
              sub_title={'HOTEL / PROFILE / BOOKING HISTORY'}
            />
            <SortComponent _this={_this} />

            <UI.PadView _this={_this}>
              {UI.box(30)}
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                {/* Left Content */}
                <TabsUI _this={_this} openReviewModal={this.openReviewModal} />

                {UI.box(30)}

                {/* Right Content */}
                <View>
                  <SearchContainer _this={_this} />
                  {UI.box(30)}
                  {/* <Recommendations _this={_this} /> */}
                  {UI.box(30)}
                </View>
              </View>

              {/* Pagination */}
              <View
                style={{
                  flexDirection: 'row',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <PaginationUI _this={this} />
              </View>
            </UI.PadView>

            {/* End Contents Here */}

            <Footer _this={_this} />
          </ScrollView>
        </View>

        {/* Modal */}
        <Modal
          className='form-modal'
          size='lg'
          show={this.state.show}
          onHide={() => {
            this.setState({ show: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3' controlId='formComment'>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder='Enter your thoughts'
                  value={this.state.comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
              </Form.Group>

              <StarRating _this={this} />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                this.setState({ show: false });
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitReview();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(BookingHistory);

const styles = StyleSheet.create({
  maps_bg: {
    height: 250,
    justifyContent: 'center',
    alignItems: 'center',
  },
  show_map_btn_text: {
    color: 'white',
    fontSize: 14,
  },
  show_map_btn: {
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: UI.colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  pagination_btn_text: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 'bold',
  },
  pagination_btn: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    alignSelf: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  main_container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
});
