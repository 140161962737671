import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

export default class Checkboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          ...styles.right_container,
          width: isMobile ? "100%" : 300 + paddingX * 2,
          margin: '0 auto',
        }}
      >
        <Text style={styles.filter_title}>Filter by</Text>
        {UI.box(10)}
        <View style={styles.line_divider}></View>
        {UI.box(10)}
        <View style={styles.filter_text_container}>
          <Text style={styles.filter_text_left}>Your Budget (per night)</Text>
          {/* <Text style={styles.filter_text_right}>Results</Text> */}
        </View>
        {UI.box(10)}

        <FilterCheckbox
          _this={_this}
          id={"9"}
          text={"P 0 - P 3,000"}
          // results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"10"}
          text={"P 3,001 - P 6,000"}
          // results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"11"}
          text={"P 6,001 - P 9,000"}
          // results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"12"}
          text={"P 9,001 - P 12,000"}
          // results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"13"}
          text={"P 12,001 - P 15,000"}
          // results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"14"}
          text={"P 15,001 - P 18,000"}
          // results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"15"}
          text={"P 18,000 +"}
          // results={"500"}
        />
        {UI.box(20)}
        {/* <View style={styles.line_divider}></View>
        {UI.box(20)}
        <View style={styles.filter_text_container}>
          <Text style={styles.filter_text_left}>Popular Filters</Text>
          <Text style={styles.filter_text_right}>Results</Text>
        </View>
        {UI.box(10)}
        <FilterCheckbox
          _this={_this}
          id={"1"}
          text={"Hotels"}
          results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"2"}
          text={"Free Cancelation"}
          results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"3"}
          text={"Breakfast Included"}
          results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"4"}
          text={"Free WiFi"}
          results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"5"}
          text={"4 Stars"}
          results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"6"}
          text={"Private Bathroom"}
          results={"500"}
        />
        <FilterCheckbox
          _this={_this}
          id={"7"}
          text={"Central Tokyo"}
          results={"500"}
        />
        {UI.box(30)} */}
      </View>
    );
  }
}

const FilterCheckbox = (
  props = { _this: null, id: "", text: "", results: "" }
) => {
  const id = "filter_" + props.id;
  let state_checked = props._this.state["checked_" + props.id];

  const onClick = () => {
    let value = document.getElementById(id).checked;
    document.getElementById(id).checked = !value;
    props._this.setState({
      ["checked_" + props.id]: !value,
    });
  };

  return (
    <View style={styles.filter_checkbox}>
      <TouchableOpacity onClick={onClick} style={styles.checkbox_container}>
        <input id={id} type={"checkbox"} style={styles.checkbox} />
        <View style={{ marginLeft: -17, marginTop: 0.5 }}>
          <Image height={14} width={14} tintColor="white" source={UI.CHECK} />
        </View>
        {UI.box(10)}
        <Text style={styles.filter_checkbox_text}>{props.text}</Text>
      </TouchableOpacity>
      <Text style={styles.filter_checkbox_text}>{props.results}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  filter_checkbox_text: {
    fontSize: 16,
    color: "#8f8f8f",
    fontWeight: "bold",
  },
  checkbox: {
    height: 20,
    width: 20,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRadius: 3,
    pointerEvents: "none",
  },
  checkbox_container: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  filter_checkbox: {
    flexDirection: "row",
    alignSelf: "stretch",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 5,
  },
  filter_text_right: {
    fontWeight: "bold",
    fontSize: 16,
    color: UI.colors.primary,
    alignSelf: "flex-start",
    paddingLeft: 30,
    paddingRight: 30,
  },
  filter_text_left: {
    fontWeight: "bold",
    fontSize: 16,
    color: UI.colors.primary,
    alignSelf: "flex-start",
    paddingLeft: 30,
    paddingRight: 30,
    flex: 1,
  },
  filter_text_container: {
    flexDirection: "row",
    alignSelf: "stretch",
  },
  line_divider: {
    height: 0.5,
    alignSelf: "stretch",
    backgroundColor: "#c0c0c0",
  },
  filter_title: {
    fontWeight: "bold",
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: "flex-start",
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 15,
  },
  right_container: {
    backgroundColor: "white",
    alignItems: "center",
    alignSelf: "flex-start",
  },
});
