const myData = [
  'Jakarta',
  'Delhi',
  'Manila',
  'Seoul-Incheon',
  'Shanghai',
  'Karachi',
  'Beijing',
  'New York',
  'Guangzhou-Foshan',
  'Sao Paulo',
  'Mexico City',
  'Mumbai',
  'Osaka-Kobe-Kyoto',
  'Moscow',
  'Dhaka',
  'Cairo',
  'Los Angeles',
  'Bangkok',
  'Kolkata',
  'Buenos Aires',
  'Tehran',
  'Istanbul',
  'Lagos',
  'Shenzhen',
  'Rio de Janeiro',
  'Kinshasa',
  'Tianjin',
  'Paris',
  'Lima',
  'Chengdu',
  'London',
  'Nagoya',
  'Lahore',
  'Bangalore',
  'Chennai',
  'Chicago',
  'Bogota',
  'Ho Chi Minh City',
  'Hyderabad',
  'Dongguan',
  'Johannesburg-East Rand',
  'Wuhan',
  'Taipei',
  'Hangzhou',
  'Hong Kong',
  'Chongqing',
  'Ahmadabad',
  'Kuala Lumpur',
  'Quanzhou',
  'Essen-Dusseldorf',
  'Baghdad',
  'Toronto',
  'Santiago',
  'Dallas-Fort Worth',
  'Madrid',
  'Nanjing',
  'Shenyang',
  `Xi'an`,
  'San Francisco-San Jose',
  'Luanda',
  'Qingdao',
  'Houston',
  'Miami',
  'Bandung',
  'Riyadh',
  'Pune',
  'Singapore',
  'Philadelphia',
  'Surat',
  'Milan',
  'Suzhou',
  'St. Petersburg',
  'Khartoum',
  'Atlanta',
  'Zhengzhou',
  'Washington',
  'Surabaya',
  'Harbin',
  'Abidjan',
  'Yangon',
  'Nairobi',
  'Barcelona',
  'Alexandria',
  'Kabul',
  'Guadalajara',
  'Ankara',
  'Belo Horizonte',
  'Boston',
  'Xiamen',
  'Kuwait',
  'Dar es Salaam',
  'Phoenix',
  'Dalian',
  'Accra',
  'Monterrey',
  'Berlin',
  'Sydney',
  'Fuzhou',
  'Medan',
  'Dubai',
  'Melbourne',
  'Rome',
  'Busan',
  'Cape Town',
  'Jinan',
  'Ningbo',
  'Hanoi',
  'Naples',
  'Taiyuan',
  'Jiddah',
  'Detroit',
  'Hefei',
  'Changsha',
  'Kunming',
  'Wuxi',
  'Medellín',
  'Faisalabad',
  'Aleppo',
  'Kano',
  'Montréal',
  'Dakar',
  'Athens',
];
const CityList = myData
  .sort((a, b) => a.localeCompare(b))
  .map((item, i) => {
    return (
      <option
        key={i}
        value={item}
      >
        {item}
      </option>
    );
  });

export { CityList };
