import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

import "./css.css";

export default class GuestInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      guest: "1",
      room: "1",
    };
  }

  onSubmit = () => {
    this.setState({
      guest: "1",
      room: "1",
      hidden: true,
    });

    this.props.onChange({ guest: this.state.guest, room: this.state.room });
  };

  render() {
    const props = this.props;
    const _this = props._this;
    const isActive = _this.state.active_input === props.title ? true : false;
    const { value, disabled } = props;

    return (
      <View
        style={{ height: 45, width: "100%" }}
        className={this.props.className}
      >
        <View
          style={{
            ...styles.filter_input,
            borderColor: isActive ? UI.colors.primary : "#c7c7c7",
          }}
        >
          <Text style={styles.filter_title}>{props.title}</Text>
          <View style={styles.filter_input_container}>
            {UI.box(8)}
            <View style={styles.filter_icon}>
              <View
                style={{
                  alignSelf: "center",
                  display: isActive ? "flex" : "none",
                }}
              >
                <Image
                  width={22}
                  height={22}
                  source={props.icon}
                  tintColor={UI.colors.primary}
                />
              </View>
              <View
                style={{
                  alignSelf: "center",
                  display: !isActive ? "flex" : "none",
                }}
              >
                <Image
                  width={22}
                  height={22}
                  source={props.icon}
                  tintColor={value ? "#646464" : "#c7c7c7"}
                />
              </View>
            </View>
            <input
              onFocus={() => {
                _this.setState({
                  active_input: props.title,
                });
              }}
              onBlur={() => {
                _this.setState({
                  active_input: "",
                });
              }}
              style={{
                ...styles.filter_input_tag,
                pointerEvents: disabled ? "none" : "all",
              }}
              placeholder={props.placeholder}
              defaultValue={value}
              value={value}
              type={props.type}
              min={props.min}
              readOnly
              onClick={() => {
                this.setState({ hidden: false });
              }}
            />
            <div className={`guest-room ${this.state.hidden ? "hidden" : ""}`}>
              <div className="guest-list">
                <label>Guest</label>
                <input
                  type="text"
                  value={this.state.guest}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');

                    if(value > 100) {
                      return;
                    }

                    this.setState({ guest: value });
                  }}
                ></input>
              </div>

              <div className="room-list">
                <label>Room</label>
                <input
                  type="text"
                  value={this.state.room}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');

                    if(value > 100) {
                      return;
                    }
                    
                    this.setState({ room: value });
                  }}
                ></input>
              </div>

              <div className="buttons">
                <button className="submit" onClick={() => this.onSubmit()}>
                  Submit
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    this.setState({ hidden: true });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  filter_input_tag: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 10,
    fontSize: 12,
    backgroundColor: "transparent",
  },
  filter_icon: {
    alignSelf: "center",
  },
  filter_input_container: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    marginTop: -3,
    position: "relative",
  },
  filter_title: {
    backgroundColor: "white",
    height: 14,
    fontSize: 12,
    fontWeight: "bolder",
    color: UI.colors.primary,
    paddingLeft: 3,
    paddingRight: 3,
    display: "flex",
    marginLeft: 7,
    marginTop: -10,
    whiteSpace: "nowrap",
  },
  filter_input: {
    flex: 1,
    height: 45,
    borderStyle: "solid",
    borderWidth: 1.5,
    borderRadius: 5,
    alignItems: "flex-start",
  },
});
