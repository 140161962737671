import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import FilterInputs from "./FilterInputs";

export default class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          ...styles.right_container,
          width: isMobile ? "100%" : 300 + paddingX * 2,
        }}
      >
        <Text style={styles.primary_text}>Search</Text>
        {UI.box(30)}
        <FilterInputs _this={_this} />

        <TouchableOpacity
          onClick={_this.search}
          style={styles.search_hotels_btn}
        >
          <View style={{ alignSelf: "center" }}>
            <Image
              width={22}
              height={22}
              source={UI.MAGNIFYING_2}
              tintColor={"white"}
            />
          </View>
          {UI.box(5)}
          <Text style={styles.search_hotel_text}>Search</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  primary_text: {
    fontWeight: "bold",
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: "flex-start",
  },
  right_container: {
    backgroundColor: "white",
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: "center",
    alignSelf: "flex-start"
  },
  search_hotel_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  search_hotels_btn: {
    height: 45,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    width: "100%",
    justifyContent: "center",
    marginTop: 20,
  },
});
