import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ItemList from './ItemList';
import TourHotelList from './TourHotelList';
import FerryList from './FerryList';
import './Tabs.scss';

const TabsUI = ({ _this, openReviewModal }) => {
  const [activeKey, setActiveKey] = useState('Tour');

  return (
    <div className='tabs-ui'>
      <Tabs
        activeKey={activeKey}
        onSelect={(e) => {
          setActiveKey(e);
          _this.setState({
            activeTab: e,
          });
        }}
        className='mb-3'
      >
        <Tab eventKey='Tour' title='Tour'>
          <TourHotelList
            type={'tour'}
            _this={_this}
            openReviewModal={openReviewModal}
          />
        </Tab>
        <Tab eventKey='Hotel' title='Hotel'>
          <TourHotelList
            type={'hotel'}
            _this={_this}
            openReviewModal={openReviewModal}
          />
        </Tab>
        <Tab eventKey='Ferry' title='Ferry'>
          <FerryList
            type={'ferry'}
            _this={_this}
            openReviewModal={openReviewModal}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default TabsUI;
