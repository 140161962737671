import axios from 'axios';

let apiURL = null;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev url
    apiURL = process.env.REACT_APP_BASE_URL;
} else {
    // production url
    apiURL = process.env.REACT_APP_BASE_URL_HTTPS;
}

export default axios.create({
    baseURL: apiURL,
});