const plantype = [
  'ST ASEAN PLUS',
  'ST DOMESTIC',
  'ST WORLDWIDE 1.5M',
  'ST WORLDWIDE 2.5M',
  'ST WORLDWIDE 1.5M C19',
  'ST WORLDWIDE 2.5M C19',
];

export { plantype };
