import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { AiFillStar } from 'react-icons/ai';
//css
import './YourSelection.scss';

function YourSelection({ _this }) {
  const width = window.innerWidth;
  const paddingX = width * 0.05;
  const { booking, ferry } = _this.state;

  console.log('State', _this.state);
  console.log(ferry)
  const getDate = (date) => {
    if (date) {
      const dateArr = date.split('|');
      const newDate = new Date(dateArr[0]);

      return newDate.toDateString();
    }
  };

  const getTime = (date) => {
    if (date) {
      const dateArr = date.split('|');
      const newDate = new Date(dateArr[0]);

      return newDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  return (
    <div className=' your-selection'>
      <hr />
      <Row className='mt-5'>
        {/* column 1 */}
        <div className='col-lg-6'>
          <p className='h5 fw-bold'>Your Booking Details</p>
          {/* Origin Destination */}
          <Row className='mb-3'>
            <div className='col'>
              <p className='p1'>Origin</p>
              <p className='fw-bold h5'>{booking.origin}</p>
              {/* <p className='p1-highlight'>North Harbor Pier 4</p> */}
            </div>
            <div className='col '>
              <p className='p1 '>Destination</p>
              <p className='fw-bold h5 '>{booking.destination}</p>
              {/* <p className='p1-highlight '>Cebu Pier 1</p> */}
            </div>
            <div className='col '>
              <p>{/* <u className='p1 '>Edit</u> */}</p>
            </div>
          </Row>
          {/* Departure Arrival */}
          <Row className='mb-3'>
            <div className='col'>
              <p className='p1'>Departure</p>
              <p className='fw-bold h5'>{getDate(booking.departureDate)}</p>
              <p className='p1-highlight'>{getTime(booking.departureDate)}</p>
            </div>
            {booking.tripType === 'twoway' && (
              <div className='col'>
                <p className='p1'>Arrival</p>
                <p className='fw-bold h5'>{getDate(booking.returnDate)}</p>
                <p className='p1-highlight'>{getTime(booking.returnDate)}</p>
              </div>
            )}
          </Row>
          {/* Passengers */}
          <Row className='mb-3'>
            <div className='col'>
              <p className='p1'>Passengers</p>
              <p className='fw-bold h5'>{booking.passenger}</p>
            </div>
            {/* <div className='col offset-4'>
              <u className='p1'>Edit</u>
            </div> */}
          </Row>
          {/* Accomodation Type */}
          <Row className='mb-3'>
            <div className='col '>
              <p className='p1'>Accomodation Type</p>
              <p className='fw-bold h5'>{booking.accomodation} Class</p>
            </div>
            {/* <div className='col offset-4'>
              <u className='p1'>Edit</u>
            </div> */}
          </Row>
          <p className='p1'>Vessel</p>
          <p className='fw-bold h5'>{` ${ferry.name}`}</p>
          {/* <hr />
          <p className='fw-bold h5'>Do you have a promo code?</p>
          <p>Enter your promo code</p>
          <Form.Group className='promoField'>
            <Form.Control type='text' placeholder='Enter Promo Code Here' />
          </Form.Group>
          <button className='py-3 px-5 mb-5 btn-apply'>Apply</button> */}
        </div>
        {/* column 2 */}
        <div className='col-lg-6'>
          <Row>
            <div className='col-lg-6 mb-5'>
              <img src={ferry.images[0].image_url} alt='img' />
            </div>
            <div className='col-lg-6'>
              <p className='h5 fw-bold'>Travel to {booking.destination}</p>
              <p className='h5 fw-bold'>{` ${ferry.name}`}</p>
              <p className='p1-highlight'>
                {booking.accomodation} <span>&#8226;</span>
                {` ${booking.passenger}`} Passengers
              </p>
              <span className='d-flex fw-bold' style={{ alignItems: 'center' }}>
                <AiFillStar color='red' /> {ferry?.others?.rating}{' '}
                {`(${ferry?.others?.totalReviews} Review${
                  ferry?.others?.totalReviews === 0 ? '' : 's'
                })`}
              </span>

              <p className='p1-highlight'>{ferry?.amenities}</p>
            </div>
          </Row>
          <hr />

          <p className='fw-bold h5 mt-5'>Price Details</p>
          <Row>
            <Col sm={6}>
              <p className='p1'>{booking.accomodation} Class</p>
              <p className='p1'>No. of Passenger</p>
              <p className='p1 highlight'>Total Price</p>
              <br />
              <p className='p1'>Insurance Fee (per passenger)</p>
              <p className='p1'>No. of Passenger</p>
              <p className='p1 highlight'>Total Insurance Fee</p>
              <br />
              <p className='p1'>Service Fee</p>
              <br />
              <p className='p1 highlight bold'>Total</p>
            </Col>
            <Col sm={2}>
              <p className='p1 align-right'>
                ₱ {booking.price.toLocaleString()}
              </p>
              <p className='p1 align-right'>x {booking.passenger}</p>
              <p className='p1 align-right highlight'>
                ₱ {booking.subTotal.toLocaleString()}
              </p>
              <br />
              <p className='p1 align-right'>
                ₱ {booking.insuranceFee.toLocaleString()}
              </p>
              <p className='p1 align-right'>x {booking.passenger}</p>
              <p className='p1 align-right highlight'>
                ₱ {booking.insuranceTotal.toLocaleString()}
              </p>
              <br />
              <p className='p1 align-right highlight'>
                ₱ {booking.serviceFee.toLocaleString()}
              </p>
              <br />
              <p className='p1 align-right bold highlight'>
                ₱ {booking.total.toLocaleString()}
              </p>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default YourSelection;
