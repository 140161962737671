import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import FilterInput from "../../components/FilterInput";

export default class HotelReserve extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    const { history_data } = _this.state;
      
    console.log(_this.state);

    return (
      <View
        style={{ ...styles.main_container, width: width <= 830 ? "100%" : 340 }}
      >
        <View style={styles.row}>
          <View style={styles.header_container}>
            <Text style={styles.header_price}>Payment Details</Text>
          </View>
        </View>

        {UI.box(20)}
    
        <Text style={styles.normal_text}>Here is the summary of your payment</Text>
        <View style={styles.price_container}>
          <View style={{ flex: 1 }}>
            <Text style={styles.price_left}>Price per {history_data.type === 'ferry' ? 'passenger' : history_data.type === 'tour' ? 'person' : 'night'}</Text>
          </View>
          <Text style={styles.price_right}>₱ {parseFloat(history_data?.paymentDetails?.price).toLocaleString()}</Text>
        </View>
        {/* <View style={styles.price_container}>
          <View style={{ flex: 1 }}>
            <Text style={styles.price_left}>x 3 nights</Text>
          </View>
          <Text style={styles.price_right}>₱14,988</Text>
        </View> */}
        <View style={styles.price_container}>
          <View style={{ flex: 1 }}>
            <Text style={styles.price_left}>Service Fee</Text>
          </View>
          <Text style={styles.price_right}>₱ {history_data?.paymentDetails?.serviceFee.toLocaleString()}</Text>
        </View>
        <View style={styles.price_container}>
          <View style={{ flex: 1 }}>
            <Text style={{...styles.price_left, color: "black"}}>Total</Text>
          </View>
          <Text style={{...styles.price_right, color: "black"}}>{`₱ ${history_data?.paymentDetails?.grandtotal.toLocaleString()}`}</Text>
        </View>
        {UI.box(20)}
        <View style={{...styles.price_container, alignSelf: "center", flexDirection: "column"}}>
          <Text style={{...styles.price_left, color: "#8E8E8E"}}>Payment Date</Text>
        </View>
        <View style={{...styles.price_container, alignSelf: "center", flexDirection: "row"}}>
          <Image
            height={20}
            width={20}
            tintColor={"#646464"}
            source={UI.CALENDAR}
          />
          <Text style={{...styles.price_right, color: "#646464", marginLeft: 5}}>April 5, 2021</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  price_right: {
    fontSize: 14,
    color: "#8E8E8E",
    fontWeight: "bold"
  },
  price_left: {
    fontSize: 14,
    color: "#8E8E8E",
    fontWeight: "bold"
  },
  price_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  normal_text: {
    fontSize: 12,
    color: "#8E8E8E",
    alignSelf: "center",
  },
  custom_btn_text: {
    color: "white",
    fontSize: 13,
    fontWeight: "bold",
  },
  custom_btn: {
    height: 40,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  review_text: {
    fontSize: 12,
    color: "#8E8E8E",
  },
  rating_text: {
    color: "black",
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: 10,
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  end: {
    alignItems: "flex-end",
  },
  header_night: {
    fontSize: 16,
    color: UI.colors.primary,
    marginLeft: 10,
  },
  header_price: {
    fontSize: 20,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  row: {
    flexDirection: "row",
  },
  header_container: {
    flexDirection: "row",
    alignItems: "flex-end",
    flex: 1,
    alignSelf: "flex-start",
  },

  main_container: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    height: 300
  },
});
