// Packages
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// Components
import UI from '../../../shared/Components/UI/js';
import Notification from '../../../shared/Components/Notification/Notification';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';

// Contexts
import AllContext from '../../../shared/Context/AllContext';

// API
import {
  getVisaPassportUser,
  submitVisaPassport,
  updateVisaPassport,
} from '../../../shared/API/request';

// Main Component
const PasswordVisaForm = ({
  _this,
  styles,
  isMobile,
  width,
  state,
  onStateUpdate,
  ...rest
}) => {
  // useForm
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // Context Values
  const { data, setData } = useContext(AllContext);

  // State
  const [userData, setUserData] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    birthdate: '',
    age: '',
    gender: '',
    contact: '',
    email: '',
    address: '',
    remarks: '',
    documents: [],
  });

  const [birthdate, setBirthdate] = useState({
    month: '',
    day: '',
    year: '',
  });

  const { pathname } = useLocation();
  let { id } = useParams();
  let history = useHistory();

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (pathname === `/passport-visa/${id}`) {
      visaPassportUserData();
    }
  }, []);

  const visaPassportUserData = async () => {
    const userData = await getVisaPassportUser(id);

    const newDate = new Date(userData?.birthdate);

    const month = newDate.toLocaleString('default', { month: 'short' });
    const day = newDate.getDate();
    const year = newDate.getFullYear();

    const dateofbirth = `${month} ${day}, ${year}`;

    setUserData({
      ...userData,
      birthdate: dateofbirth,
    });

    setBirthdate({
      month: month,
      day: parseInt(day + 1),
      year: year,
    });
  };

  // useEffect will fire on every userData state update
  useEffect(() => {
    if (userData && userData.documents) {
      // console.log(userData.documents);
    }

    if (userData && userData.birthdate) {
      setValue('birthdate', userData.birthdate);
    }

    reset({}, { keepDirty: true });

    console.log(userData);
  }, [userData]);

  // useEffect will fire on every birthdate state update
  useEffect(() => {
    const { month, day, year } = birthdate;
    console.log(month);
    if (month && day && year) {
      const newDate = new Date(`${month} ${day}, ${year}`)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');

      const age = getAge(`${month} ${day}, ${year}`);

      setUserData({
        ...userData,
        age: age,
      });

      // If new selected date is not the same  as current selected date
      if (newDate.toString() !== userData.birthdate) {
        setUserData((prevState) => {
          return { ...prevState, birthdate: newDate.toString() };
        });
      }
    }
  }, [birthdate]);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  // Will fire on Submit Button
  const onSubmit = (values) => {
    if (userData.documents === null || userData.documents.length === 0) {
      setError('documents', {
        type: 'custom',
        message: 'Please Select File/s',
      });
      return;
    } else {
      let formData = new FormData();

      if (pathname === `/passport-visa/${id}`) {
        formData.append('assistanceId', id);
      }

      formData.append('userID', data.user.id);
      formData.append('firstname', values.firstname);
      formData.append('middlename', values.middlename);
      formData.append('lastname', values.lastname);
      formData.append('gender', values.gender);
      formData.append('age', values.age);
      formData.append('birthdate', values.birthdate);
      formData.append('address', values.address);
      formData.append('contact', values.contact);
      formData.append('email', values.email);
      formData.append('address', values.address);
      formData.append('remarks', values.remarks);

      if (!userData.documents[0].id) {
        for (let i = 0; i < userData.documents.length; i++) {
          formData.append(`documents[${i}]`, userData.documents[i]);
        }
      }

      if (pathname === '/passport-visa') submitVisaPassport(formData, callback);
      if (pathname === `/passport-visa/${id}`) {
        updateVisaPassport(formData, callback);
      }
    }
  };

  const callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (pathname === '/passport-visa') {
        history.push('/account-settings');
        Notification('Successfully Added', 'success');
      }

      if (pathname === `/passport-visa/${id}`) {
        Notification('Successfully Updated', 'success');
      }
    } else if (status === 400 || status === 422) {
      Notification(data.message, 'danger');

      if (data.errors) {
        const { errors } = data;
        if (errors['documents.0']) {
          Notification('Maximum filesize limit is 1MB', 'danger');
        }
      }
    } else {
      Notification(data.message, 'danger');
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <>
      {userData && (
        <form
          className='form-profile'
          onSubmit={handleSubmit(onSubmit)}
          style={{
            flexDirection: isMobile ? 'column' : 'column',
            display: 'flex',
          }}
          encType='multipart/form-data'
        >
          <input
            type='hidden'
            {...register('birthdate', {
              required: '(Required)',
              value: userData.birthdate,
              onChange: (e) => {
                setUserData((prevState) => {
                  return { ...prevState, birthdate: e.target.value };
                });
              },
            })}
          />
          <View
            style={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              gap: 20,
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                flex: '1',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                First Name{' '}
                {errors.firstname ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.firstname.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <input
                  type='text'
                  style={{
                    ...styles.form_input,
                    width: isMobile ? '100%' : '100%',
                  }}
                  placeholder='First Name'
                  {...register('firstname', {
                    required: '(Required)',
                    pattern: {
                      value: /^[a-zA-Z ]*$/g,
                      message: 'Numbers and Special Characters not Allowed',
                    },
                    value: userData.firstname,
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, firstname: e.target.value };
                      });
                    },
                  })}
                />
              </View>
            </View>

            <View
              style={{
                flex: '1',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Middle Name{' '}
                {errors.middlename ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.middlename.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <input
                  {...register('middlename', {
                    value: userData.middlename,
                    pattern: {
                      value: /^[a-zA-Z ]*$/g,
                      message: 'Numbers and Special Characters not Allowed',
                    },
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, middlename: e.target.value };
                      });
                    },
                  })}
                  type='text'
                  name='middlename'
                  style={{
                    ...styles.form_input,
                    width: isMobile ? '100%' : '100%',
                  }}
                  placeholder='Middle Name'
                />
              </View>
            </View>

            <View
              style={{
                flex: '1',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Last Name{' '}
                {errors.lastname ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.lastname.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <input
                  {...register('lastname', {
                    required: '(Required)',
                    pattern: {
                      value: /^[a-zA-Z ]*$/g,
                      message: 'Numbers and Special Characters not Allowed',
                    },
                    value: userData.lastname,
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, lastname: e.target.value };
                      });
                    },
                  })}
                  type='text'
                  name='lastname'
                  style={{
                    ...styles.form_input,
                    width: isMobile ? '100%' : '100%',
                  }}
                  placeholder='Last Name'
                />
              </View>
            </View>
          </View>

          {UI.box(20)}

          <View
            style={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              gap: 20,
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                flex: width > 900 ? '1' : '1.5',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Birthdate{' '}
                {errors.birthdate ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.birthdate.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'stretch',
                  alignItems: isMobile ? 'center' : 'flex-start',
                  gap: width > 900 ? 20 : 10,
                }}
              >
                <select
                  className='option-select'
                  id='month'
                  style={{
                    ...styles.selectOption,
                    height: isMobile ? 37 : 50,
                    padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                    color: '#8E8E8E',
                  }}
                  value={birthdate.month ? birthdate.month : ''}
                  onChange={(e) => {
                    setBirthdate((prevState) => {
                      return { ...prevState, month: e.target.value };
                    });
                  }}
                >
                  <option hidden value=''>---</option>
                  <option value='Jan'>Jan</option>
                  <option value='Feb'>Feb</option>
                  <option value='Mar'>Mar</option>
                  <option value='Apr'>Apr</option>
                  <option value='May'>May</option>
                  <option value='Jun'>Jun</option>
                  <option value='Jul'>Jul</option>
                  <option value='Aug'>Aug</option>
                  <option value='Sep'>Sep</option>
                  <option value='Oct'>Oct</option>
                  <option value='Nov'>Nov</option>
                  <option value='Dec'>Dec</option>
                </select>

                <select
                  className='option-select'
                  id='day'
                  style={{
                    ...styles.selectOption,
                    height: isMobile ? 37 : 50,
                    padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                    color: '#8E8E8E',
                  }}
                  value={birthdate.day ? birthdate.day : ''}
                  onChange={(e) => {
                    setBirthdate((prevState) => {
                      return { ...prevState, day: e.target.value };
                    });
                  }}
                >
                  <option hidden value=''>---</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                  <option value='23'>23</option>
                  <option value='24'>24</option>
                  <option value='25'>25</option>
                  <option value='26'>26</option>
                  <option value='27'>27</option>
                  <option value='28'>28</option>
                  <option value='29'>29</option>
                  <option value='30'>30</option>
                  <option value='31'>31</option>
                </select>

                <select
                  className='option-select'
                  id='year'
                  style={{
                    ...styles.selectOption,
                    height: isMobile ? 37 : 50,
                    padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                    color: '#8E8E8E',
                  }}
                  value={birthdate.year ? birthdate.year : ''}
                  onChange={(e) => {
                    setBirthdate((prevState) => {
                      return { ...prevState, year: e.target.value };
                    });
                  }}
                >
                  <option hidden value=''>---</option>
                  {years()}
                </select>
              </View>
            </View>

            <View
              style={{
                flex: width > 900 ? '1' : '1.5',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'stretch',
                  alignItems: isMobile ? 'center' : 'center',
                  gap: width > 900 ? 10 : 5,
                }}
              >
                Age{' '}
                {errors.age ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.age.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'stretch',
                  alignItems: isMobile ? 'center' : 'flex-start',
                  gap: width > 900 ? 10 : 10,
                }}
              >
                <input
                  maxLength={3}
                  type='text'
                  {...register('age', {
                    required: '(Required)',
                    value: userData.age,
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, age: e.target.value };
                      });
                    },
                  })}
                  name='age'
                  id='age'
                  style={{
                    ...styles.selectOption,
                    height: isMobile ? 37 : 50,
                    padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                    color: '#8E8E8E',
                  }}
                />
              </View>
            </View>

            <View
              style={{
                flex: '0.5',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Gender{' '}
                {errors.gender ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.gender.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <select
                  {...register('gender', {
                    required: '(Required)',
                    value: userData.gender,
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, gender: e.target.value };
                      });
                    },
                  })}
                  name='gender'
                  id='gender'
                  style={{
                    ...styles.selectOption,
                    height: isMobile ? 37 : 50,
                    padding: isMobile ? '0 0 0 8px' : '0 0 0 11px',
                    color: '#8E8E8E',
                  }}
                >
                  <option value=''>Select Gender</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </select>
              </View>
            </View>

            <View
              style={{
                flex: '1',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Contact Number{' '}
                {errors.contact ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.contact.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <input
                  {...register('contact', {
                    required: '(Required)',
                    minLength: {
                      value: 11,
                      message: 'This field must be 11 digits',
                    },
                    value: userData.contact,
                    onChange: (event) => {
                      const value = event.target.value.replace(/\D/g, '');

                      if (value.length <= 11) {
                        setUserData((prevState) => {
                          return { ...prevState, contact: event.target.value };
                        });

                        setValue('contact', value);
                      } else {
                        setValue('contact', userData.contact);
                      }
                    },
                  })}
                  type='text'
                  name='contact'
                  style={{
                    ...styles.form_input,
                    width: isMobile ? '100%' : '100%',
                  }}
                  placeholder={'Enter Contact Number Here'}
                />
              </View>
            </View>
          </View>

          {UI.box(20)}

          <View
            style={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              gap: 20,
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                flex: '1',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Email Address{' '}
                {errors.email ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.email.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <input
                  {...register('email', {
                    required: '(Required)',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                    value: userData.email,
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, email: e.target.value };
                      });
                    },
                  })}
                  type='email'
                  name='email'
                  style={{
                    ...styles.form_input,
                    width: isMobile ? '100%' : '100%',
                  }}
                  placeholder='Enter Email Address Here'
                />
              </View>
            </View>

            <View
              style={{
                flex: '1',
              }}
            >
              <Text
                style={{
                  ...styles.label,
                  textAlign: isMobile ? 'flex-start' : 'flex-start',
                }}
              >
                Address{' '}
                {errors.address ? (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'normal',
                      fontSize: 14,
                    }}
                  >
                    {errors.address.message}
                  </span>
                ) : (
                  ''
                )}
              </Text>
              <View
                style={{
                  alignSelf: 'stretch',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'center' : 'flex-start',
                }}
              >
                <input
                  {...register('address', {
                    required: '(Required)',
                    value: userData.address,
                    onChange: (e) => {
                      setUserData((prevState) => {
                        return { ...prevState, address: e.target.value };
                      });
                    },
                  })}
                  type='address'
                  name='address'
                  style={{
                    ...styles.form_input,
                    width: isMobile ? '100%' : '100%',
                  }}
                  placeholder='Enter Address Here'
                />
              </View>
            </View>
          </View>

          {UI.box(20)}

          <View
            style={{
              flex: '1',
            }}
          >
            <Text
              style={{
                ...styles.label,
                textAlign: isMobile ? 'flex-start' : 'flex-start',
              }}
            >
              About me{' '}
              {errors.remarks ? (
                <span
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    fontSize: 14,
                  }}
                >
                  {errors.remarks.message}
                </span>
              ) : (
                ''
              )}
            </Text>
            <View
              style={{
                display: 'block',
              }}
            >
              <textarea
                {...register('remarks')}
                value={userData.remarks}
                onChange={(e) => {
                  setUserData((prevState) => {
                    return { ...prevState, remarks: e.target.value };
                  });
                }}
                type='text'
                name='about'
                style={{
                  ...styles.form_input,
                  width: isMobile ? '100%' : '100%',
                  height: 150,
                }}
                className={errors.remarks ? 'red' : ''}
                placeholder='Tell us about yourself'
              />
            </View>
          </View>

          {UI.box(20)}

          <View
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              fontSize: isMobile ? 20 : 25,
              fontWeight: 'bold',
            }}
          >
            <Text>
              Upload Required Documents{' '}
              {errors.documents ? (
                <span
                  style={{
                    color: 'red',
                    fontWeight: 'normal',
                    fontSize: 14,
                  }}
                >
                  {errors.documents.message}
                </span>
              ) : (
                ''
              )}
            </Text>
          </View>

          <View
            style={{
              alignSelf: 'stretch',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1.25rem 0',
            }}
          >
            <div className='mr-5'>
              <label
                for='file'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '340px',
                  height: '170px',
                  borderRadius: '16px',
                  background: '#E1E1E1',
                  cursor: 'pointer',
                }}
              >
                <svg
                  style={{ marginRight: '0.5rem' }}
                  id='edit_plus'
                  data-name='edit / plus'
                  width='30'
                  height='30'
                  viewBox='0 0 34 34'
                >
                  <defs>
                    <clipPath id='clip-path'>
                      <rect
                        id='edit_plus_Background_Mask_'
                        data-name='edit / plus (Background/Mask)'
                        width='34'
                        height='34'
                        fill='#006fb9'
                      />
                    </clipPath>
                  </defs>
                  <rect
                    id='edit_plus_Background_Mask_2'
                    data-name='edit / plus (Background/Mask)'
                    width='34'
                    height='34'
                    fill='none'
                  />
                  <g
                    id='edit_plus-2'
                    data-name='edit / plus'
                    clip-path='url(#clip-path)'
                  >
                    <path
                      id='coolicon'
                      d='M11.333,19.833H8.5v-8.5H0V8.5H8.5V0h2.833V8.5h8.5v2.833h-8.5Z'
                      transform='translate(7.083 7.083)'
                      fill='#006fb9'
                    />
                  </g>
                </svg>
                <span
                  style={{
                    color: '#006FB9',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  {userData.documents && (
                    <>
                      {userData.documents.length > 0 && (
                        <>
                          {userData.documents.length} File
                          {userData.documents.length === 1 ? '' : 's'} Selected
                        </>
                      )}
                      {(userData.documents.length === 0 ||
                        userData.documents === null) && <>Upload file</>}
                    </>
                  )}
                  {!userData.documents && <>Upload file</>}
                </span>
              </label>
              <input
                id='file'
                type='file'
                style={{ visibility: 'hidden' }}
                {...register('documents', {
                  value: userData.documents,
                  onChange: (e) => {
                    let docuArr = [];

                    Array.from(e.target.files).forEach((file) => {
                      docuArr.push(file);
                    });

                    setUserData((prevState) => {
                      return { ...prevState, documents: docuArr };
                    });
                  },
                })}
                multiple
              />
            </div>
          </View>

          {/* {pathname !== `/passport-visa/${id}` && (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  fontSize: isMobile ? 20 : 25,
                  fontWeight: "bold",
                }}
              >
                <Text>
                  Upload Required Documents{" "}
                  {errors.documents ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "normal",
                        fontSize: 14,
                      }}
                    >
                      {errors.documents.message}
                    </span>
                  ) : (
                    ""
                  )}
                </Text>
              </View>

              <View
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1.25rem 0",
                }}
              >
                <div className="mr-5">
                  <label
                    for="file"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "340px",
                      height: "170px",
                      borderRadius: "16px",
                      background: "#E1E1E1",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      style={{ marginRight: "0.5rem" }}
                      id="edit_plus"
                      data-name="edit / plus"
                      width="30"
                      height="30"
                      viewBox="0 0 34 34"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="edit_plus_Background_Mask_"
                            data-name="edit / plus (Background/Mask)"
                            width="34"
                            height="34"
                            fill="#006fb9"
                          />
                        </clipPath>
                      </defs>
                      <rect
                        id="edit_plus_Background_Mask_2"
                        data-name="edit / plus (Background/Mask)"
                        width="34"
                        height="34"
                        fill="none"
                      />
                      <g
                        id="edit_plus-2"
                        data-name="edit / plus"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          id="coolicon"
                          d="M11.333,19.833H8.5v-8.5H0V8.5H8.5V0h2.833V8.5h8.5v2.833h-8.5Z"
                          transform="translate(7.083 7.083)"
                          fill="#006fb9"
                        />
                      </g>
                    </svg>
                    <span
                      style={{
                        color: "#006FB9",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {userData.documents && (
                        <>
                          {userData.documents.length > 0 && (
                            <>
                              {userData.documents.length} File
                              {userData.documents.length === 1 ? "" : "s"} Selected
                            </>
                          )}
                          {(userData.documents.length === 0 ||
                            userData.documents === null) && <>Upload file</>}
                        </>
                      )}
                      {!userData.documents && <>Upload file</>}
                    </span>
                  </label>
                  <input
                    id="file"
                    type="file"
                    style={{ visibility: "hidden" }}
                    {...register("documents", {
                      value: userData.documents,
                      onChange: (e) => {
                        let docuArr = [];

                        Array.from(e.target.files).forEach((file) => {
                          docuArr.push(file);
                        });

                        setUserData((prevState) => {
                          return { ...prevState, documents: docuArr };
                        });
                      },
                    })}
                    multiple
                  />
                </div>
              </View>
            </>
          )} */}

          <View
            style={{
              flex: '1',
            }}
          >
            <TouchableOpacity
              style={{
                display: 'block',
                textAlign: 'center',
                width: 'min-content',
                margin: '0 auto',
              }}
            >
              <input
                type='submit'
                name='submit'
                style={{
                  ...styles.form_input,
                  cursor: 'pointer',
                  backgroundColor: '#0BC175',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: isMobile ? 'auto' : 150,
                  padding: isMobile ? '0 8px' : '',
                  height: isMobile ? 37 : 50,
                }}
                value={'Submit'}
              />
            </TouchableOpacity>
          </View>
        </form>
      )}
    </>
  );
};

export default PasswordVisaForm;

const years = () => {
  let selectYears = [];

  let endDate = 1950;
  let currentYear = new Date().getFullYear();

  while (currentYear >= endDate) {
    selectYears.push(setOption(currentYear));
    currentYear--;
  }

  return <>{selectYears}</>;
};

const age = () => {
  let age = [];
  for (var i = 1; i <= 100; i++) {
    age.push(setOption(i));
  }

  return <>{age}</>;
};

const setOption = (param) => {
  return <option value={`${param}`}>{param}</option>;
};
