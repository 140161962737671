// Packages
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Dropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  Text,
  StyleSheet,
} from '../../shared/custom-react-native';

// Components
import Notification from '../../shared/Components/Notification/Notification';
import UI from '../../shared/Components/UI/js';

// CSS
import './SubHeader.scss';

// Context
import AllContext from '../../shared/Context/AllContext';

const active_tab_color = '#2081C2';
const inactive_tab_color = 'black';

export default class SubHeaderWeb extends Component {
  // Static Context
  static contextType = AllContext;

  search = () => {
    const _this = this.props._this;

    const dateToday = new Date();
    let tomorrow = new Date(dateToday);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (
      _this.state.search_query === '' ||
      _this.state.search_query === undefined
    ) {
      Notification('Please insert query before searching', 'danger');
      return;
    }

    UI.search('/search-results', {
      city: _this.state.search_query,
      check_in: dateToday.toISOString().split('T')[0],
      check_out: tomorrow.toISOString().split('T')[0],
      guest: '1',
      room: '1',
      type: 'HOTELS',
      sort_by: 'price',
    });
  };

  index = () => {
    UI.goTo('/');
  };

  tours = () => {
    UI.goTo('/tours');
  };

  hotels = () => {
    UI.goTo('/hotels');
  };

  mybooking_details = () => {
    UI.goTo('/my-booking');
  };

  contact_us = () => {
    UI.goTo('/contact-us');
  };
  mybookmark = () => {
    UI.goTo('/bookmarks');
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;

    // Use Context
    const { data, setData } = this.context;

    return (
      <div
        className='nav navbar'
        style={{
          paddingLeft: width * 0.05,
          paddingRight: width * 0.05,
        }}
      >
        <div className='search-menu'>
          <TouchableOpacity onClick={this.index} className='traverse-logo'>
            <img src={UI.LOGO} alt={'Traverse Logo'} />
          </TouchableOpacity>

          <div className='divider'></div>

          <div className='search-input'>
            <input
              onChange={(e) => {
                _this.setState({
                  search_query: e.target.value,
                });
              }}
              className='search_input'
              placeholder={'What are you looking for?'}
            />
            {UI.box(10)}
            <TouchableOpacity onClick={this.search}>
              <Image
                width={22}
                height={22}
                source={UI.MAGNIFYING}
                tintColor={'#9B9B9B'}
              />
            </TouchableOpacity>
          </div>
        </div>

        <div className='navbar-menu'>
          <TabLink onClick={this.index} text={'HOME'} _this={_this} />
          <TabLink onClick={this.tours} text={'TOURS'} _this={_this} />
          <TabLink onClick={this.hotels} text={'HOTELS'} _this={_this} />

          <Dropdown
            text={'FERRY PORTAL'}
            className={`nav-dropdown ${
              _this.state.active_link === 'FERRY PORTAL' ? 'active' : ''
            }`}
            onMouseEnter={() =>
              this.props.headerThis.setState({ showDropDown: true })
            }
            onMouseLeave={() =>
              this.props.headerThis.setState({ showDropDown: false })
            }
            show={this.props.headerThis.state.showDropDown}
          >
            <Dropdown.Toggle variant='success' id='ferry-dropdown'>
              <Link to='/ferry-portal'>Ferry Portal</Link>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <NavDropdown.Item as={Link} to='/ferry-portal-2go'>
                2GO
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/ferry-portal-OceanJet'>
                Ocean Jet
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/ferry-portal/insurance'>
                Insurance
              </NavDropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Will only show if the user is logged in */}
          {data.user && data.isAuth && (
            <>
              <TabLink
                onClick={this.mybooking_details}
                text={'MY BOOKINGS'}
                _this={_this}
              />

              <TabLink
                text={'BOOKMARKS'}
                _this={_this}
                onClick={this.mybookmark}
              />
            </>
          )}
          <TabLink onClick={this.contact_us} text={'ABOUT'} _this={_this} />
        </div>
      </div>
    );
  }
}

const TabLink = (props = { text: '', onClick: () => {}, _this: null }) => {
  const isActive = props._this.state.active_link === props.text;
  return (
    <TouchableOpacity onClick={props.onClick} className='nav-link'>
      <Text
        style={{
          color: isActive ? active_tab_color : inactive_tab_color,
        }}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};
