// Packages
import React, { Component } from 'react';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImageUpload from '../../components/ImageUpload';
import Notification from '../../../shared/Components/Notification/Notification';

// API
import { submitFlightInquiry } from '../../../shared/API/request';
// css
import './FlightInquiry.scss';
// Main Components
export default class HotelDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      active_link: '',
      firstname: '',
      lastname: '',
      email: '',
      contact: '',
      noOfChildren: 0,
      noOfAdults: 0,
      noOfSeniors: 0,
      passengerInfo: '',
      departureDate: '',
      returningDate: '',
      documents: '',
      additionalInfo: '',
      disabledBtn: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== prevState) {
      console.log(this.state);
    }
  }

  submitFlight = () => {
    const {
      firstname,
      lastname,
      email,
      contact,
      noOfChildren,
      noOfAdults,
      noOfSeniors,
      passengerInfo,
      departureDate,
      returningDate,
      documents,
      additionalInfo,
    } = this.state;

    // Return of these is empty state
    if (
      !firstname ||
      !lastname ||
      !email ||
      !contact ||
      !passengerInfo ||
      !departureDate ||
      !returningDate ||
      !documents
    ) {
      Notification('Please fill in the empty inputs', 'danger');
      return;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      Notification('Please Enter a valid Email', 'danger');
      return;
    }

    if (
      /[0-9!@#\$%\^\&*\(\)\/\\+=._-]/g.test(firstname) ||
      /[0-9!@#\$%\^\&*\(\)\/\\+=._-]/g.test(lastname)
    ) {
      Notification('Special Characters are not allowed on name', 'danger');
      return;
    }
    if (contact.length < 11) {
      Notification('Contact Number field must be 11 digits', 'danger');
      return;
    }

    if (noOfChildren > 20 || noOfAdults > 20 || noOfSeniors > 20) {
      Notification(
        'Maximum passenger limit reached (maximum of 20 allowed)',
        'danger',
      );
      return;
    }
    if (
      this.state.noOfChildren === 0 &&
      this.state.noOfAdults === 0 &&
      this.state.noOfSeniors === 0
    ) {
      Notification(
        'Please input number of passenger that will board',
        'danger',
      );
      return;
    }

    // Proceed if theres value in all state
    let formData = new FormData();
    // formData.append("userID", data.user.id);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('contact', contact);
    formData.append('noOfChildren', noOfChildren);
    formData.append('noOfAdults', noOfAdults);
    formData.append('noOfSeniors', noOfSeniors);
    formData.append('passengerInfo', passengerInfo);
    formData.append('departureDate', departureDate);
    formData.append('returningDate', returningDate);
    formData.append('additionalInfo', additionalInfo);

    for (let i = 0; i < documents.length; i++) {
      formData.append(`documents[${i}]`, documents[i]);
    }
    this.setState({ disabledBtn: true });
    submitFlightInquiry(formData, this.callback);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
  };

  callback = async (response) => {
    const { status, data } = await response;

    console.log(status);
    console.log(data);

    if (status === 201 || status === 200) {
      this.setState({ disabledBtn: false });
      Notification('Flight Inquiry Successfully sent', 'success');

      this.setState({
        firstname: '',
        lastname: '',
        email: '',
        contact: '',
        noOfChildren: '',
        noOfAdults: '',
        noOfSeniors: '',
        passengerInfo: '',
        departureDate: '',
        returningDate: '',
        documents: '',
        additionalInfo: '',
      });
    } else if (status === 400) {
      this.setState({ disabledBtn: false });
      Notification(data.message, 'danger');
    } else if (status === 422) {
      this.setState({ disabledBtn: false });
      if (data.errors) {
        const { errors } = data;

        if (errors.departureDate) {
          console.log(errors.departureDate);
          errors.departureDate.forEach((error) => {
            Notification(error, 'danger');
          });
        }

        if (errors.returningDate) {
          console.log(errors.returningDate);
          errors.returningDate.forEach((error) => {
            Notification(error, 'danger');
          });
        }

        if (errors['documents.0']) {
          Notification(errors['documents.0'], 'danger');
        }
        if (errors['documents.1']) {
          Notification(errors['documents.1'], 'danger');
        }
        if (errors['documents.2']) {
          Notification(errors['documents.2'], 'danger');
        }

        if (errors['documents.3']) {
          Notification(errors['documents.3'], 'danger');
        }

        if (errors['documents.4']) {
          Notification(errors['documents.4'], 'danger');
        }
      }
    } else {
      this.setState({ disabledBtn: false });
      Notification('An Error Occured', 'danger');
    }
  };

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;

    return (
      <View className='main_container'>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}

          <UI.Row
            breakpoint={850}
            _this={this}
            style={{
              width: '100%',
              paddingRight: paddingX,
              paddingLeft: paddingX,
              paddingTop: 10,
              marginTop: 0,
            }}
          >
            <span className='page_title'>Flight Inquiry</span>
          </UI.Row>

          <View
            style={{
              marginTop: 20,
              paddingLeft: paddingX,
              paddingRight: paddingX,
              width: '100%',
            }}
          >
            <ColoredLine color='#D3D3D3' />
          </View>

          <View
            style={{
              flexDirection: width <= 830 ? 'column' : 'row',
              width: '100%',
            }}
          >
            {/* First Column */}
            <View style={{ width: '100%' }}>
              <span
                className='sub_title'
                style={{
                  marginTop: 20,
                  paddingLeft: paddingX,
                }}
              >
                Enter required details
              </span>
              {UI.box(20)}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                }}
              >
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View style={{ width: '100%', height: 50, paddingRight: 10 }}>
                    <span className='label'>
                      First Name <Text style={{ color: 'red' }}>*</Text>
                    </span>
                    <input
                      type='text'
                      name='firstName'
                      className='form_input'
                      placeholder={'Enter First Name Here'}
                      value={this.state.firstname}
                      onChange={(e) =>
                        this.setState({ firstname: e.target.value })
                      }
                    />
                  </View>

                  {UI.box(20)}
                  <View style={{ width: '100%', height: 50, paddingRight: 10 }}>
                    <span className='label'>
                      Last Name <Text style={{ color: 'red' }}>*</Text>
                    </span>
                    <input
                      type='text'
                      name='lastName'
                      className='form_input'
                      placeholder={'Enter Last Name Here'}
                      value={this.state.lastname}
                      onChange={(e) =>
                        this.setState({ lastname: e.target.value })
                      }
                    />
                  </View>
                  {UI.box(20)}
                </View>
              </UI.Row>

              {UI.box(20)}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                }}
              >
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View style={{ width: '100%', height: 50, paddingRight: 10 }}>
                    <span className='label'>
                      Email Address <Text style={{ color: 'red' }}>*</Text>
                    </span>
                    <input
                      type='email'
                      name='email'
                      className='form_input'
                      placeholder={'Enter Email Address Here'}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </View>

                  {UI.box(20)}
                  <View style={{ width: '100%', height: 50, paddingRight: 10 }}>
                    <span className='label'>
                      Contact Number <Text style={{ color: 'red' }}>*</Text>
                    </span>
                    <input
                      type='text'
                      maxLength={11}
                      name='contactNo'
                      className='form_input'
                      placeholder={'Enter Contact Number Here'}
                      value={this.state.contact}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          this.setState({ contact: e.target.value });
                        }
                      }}
                    />
                  </View>
                  {UI.box(20)}
                </View>
              </UI.Row>

              {UI.box(20)}
              <span
                className='sub_title'
                style={{
                  marginTop: 20,
                  paddingLeft: paddingX,
                }}
              >
                Enter required details
              </span>

              {UI.box(20)}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                }}
              >
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View style={{ width: '100%', height: 40, paddingRight: 10 }}>
                    <span className='label'>No. of Children</span>
                    <input
                      type='text'
                      maxLength={2}
                      name='childAge'
                      className='person_num_input'
                      placeholder={'Enter No. of Children Here'}
                      value={this.state.noOfChildren}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;

                        if (e.target.value === '00') {
                          return;
                        }

                        if (e.target.value === '' || re.test(e.target.value)) {
                          this.setState({ noOfChildren: e.target.value });
                        }
                      }}
                    />
                  </View>

                  {UI.box(20)}
                  <View style={{ width: '100%', height: 40, paddingRight: 10 }}>
                    <span className='label'>No. of Adults</span>
                    <input
                      type='text'
                      maxLength={2}
                      name='adultAge'
                      className='person_num_input'
                      placeholder={'Enter No. of Adults Here'}
                      value={this.state.noOfAdults}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;

                        if (e.target.value === '00') {
                          return;
                        }

                        if (e.target.value === '' || re.test(e.target.value)) {
                          this.setState({ noOfAdults: e.target.value });
                        }
                      }}
                    />
                  </View>
                  {UI.box(20)}
                  <View style={{ width: '100%', height: 40, paddingRight: 10 }}>
                    <span className='label'>No. of Seniors</span>
                    <input
                      type='text'
                      maxLength={2}
                      name='seniorAge'
                      className='person_num_input'
                      placeholder={'Enter No. of Seniors Here'}
                      value={this.state.noOfSeniors}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;

                        if (e.target.value === '00') {
                          return;
                        }

                        if (e.target.value === '' || re.test(e.target.value)) {
                          this.setState({ noOfSeniors: e.target.value });
                        }
                      }}
                    />
                  </View>
                  {UI.box(20)}
                </View>
              </UI.Row>

              {UI.box(20)}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  height: 200,
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 20,
                }}
              >
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View
                    style={{ width: '100%', height: 200, paddingRight: 10 }}
                  >
                    <span className='label'>
                      Passengers information (For Children &amp; Senior)
                    </span>
                    <textarea
                      name='seniorAge'
                      cols={4}
                      className='form_input'
                      placeholder={'Enter the Name, Birthdate and Age here'}
                      value={this.state.passengerInfo}
                      onChange={(e) =>
                        this.setState({ passengerInfo: e.target.value })
                      }
                    />
                  </View>
                </View>
              </UI.Row>

              {UI.box(20)}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                }}
              >
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View style={{ width: '100%', height: 40, paddingRight: 10 }}>
                    <span className='label'>Departure Date</span>
                    <input
                      type='date'
                      name='departureDate'
                      className='person_num_input'
                      style={{ color: '#000' }}
                      value={this.state.departureDate}
                      onChange={(e) =>
                        this.setState({ departureDate: e.target.value })
                      }
                    />
                  </View>

                  {UI.box(20)}
                  <View style={{ width: '100%', height: 40, paddingRight: 10 }}>
                    <span className='label'>Returning Date</span>
                    <input
                      type='date'
                      name='returnDate'
                      className='person_num_input'
                      style={{ color: '#000' }}
                      value={this.state.returningDate}
                      onChange={(e) =>
                        this.setState({ returningDate: e.target.value })
                      }
                    />
                  </View>
                  {UI.box(20)}
                </View>
              </UI.Row>

              {UI.box(20)}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 30,
                }}
              >
                <View style={{ display: 'flex', width: '100%' }}>
                  <View style={{ width: '100%', height: 50 }}>
                    <TouchableOpacity
                      onClick={() => {
                        _this.props.history.goBack();
                      }}
                      className='custom_btn_bottom'
                    >
                      <span className='custom_btn_text'>Back</span>
                    </TouchableOpacity>
                    {UI.box(20)}
                  </View>
                </View>
              </UI.Row>
            </View>
            {/* End First Column */}

            {/* Second Column */}
            <View style={{ width: '100%' }}>
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingRight: paddingX,
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                }}
              >
                <span
                  className='sub_title'
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                >
                  Attach valid ID and needed documents here
                </span>
              </UI.Row>
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingRight: paddingX,
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                }}
              >
                {' '}
                <h6
                  className='text-danger'
                  style={{ fontSize: '12px', padding: '0 96px' }}
                >
                  Note: Multiple attachments can be uploaded
                </h6>
              </UI.Row>
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingTop: 10,
                  marginTop: 0,
                  marginBottom: 120,
                }}
              >
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View
                    style={{
                      width: '100%',
                      height: 50,
                      paddingRight: 10,
                      paddingLeft: paddingX,
                    }}
                  >
                    <ul>
                      <li className='li'>Senior Citizen ID</li>
                      <li className='li'>Vaccination Card</li>
                      <li className='li'>etc.</li>
                    </ul>
                  </View>

                  {UI.box(20)}
                  <View style={{ width: '100%', height: 50 }}>
                    <ImageUpload _this={_this} />
                  </View>
                  {UI.box(20)}
                </View>
              </UI.Row>

              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  marginTop: 20,
                  padding: '0 96px',
                }}
              >
                <View
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    backgroundColor: '#E1E1E1',
                    padding: '20px 40px',
                  }}
                >
                  <span className='label'>File Name:</span>
                  <View
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      backgroundColor: '#E1E1E1',
                    }}
                  >
                    {this.state.documents && this.state.documents.length > 0 && (
                      <>
                        {UI.box(10)}
                        {this.state.documents.map((docu) => {
                          console.log(docu);
                          return (
                            <Text
                              className='label'
                              style={{
                                marginTop: 10,
                              }}
                            >
                              {docu.name}
                            </Text>
                          );
                        })}
                      </>
                    )}
                  </View>
                </View>
              </UI.Row>

              <View style={{ width: '100%' }}>
                <UI.Row
                  breakpoint={850}
                  _this={this}
                  style={{
                    width: '100%',
                    height: 200,
                    paddingLeft: paddingX,
                    paddingRight: paddingX,
                    paddingTop: 10,
                    marginTop: 20,
                  }}
                >
                  <View style={{ flexDirection: 'row', width: '100%' }}>
                    <View
                      style={{ width: '100%', height: 200, paddingRight: 10 }}
                    >
                      <span className='label'>
                        For Additional information (Add baggage, for assistance)
                      </span>
                      <textarea
                        name='seniorAge'
                        cols={4}
                        className='form_input'
                        placeholder={'Enter the information here'}
                        value={this.state.additionalInfo}
                        onChange={(e) =>
                          this.setState({ additionalInfo: e.target.value })
                        }
                      />
                    </View>
                  </View>
                </UI.Row>
              </View>

              <View style={{ width: '100%' }}>
                <UI.Row
                  breakpoint={850}
                  _this={this}
                  style={{
                    width: '100%',
                    height: 200,
                    paddingLeft: paddingX,
                    paddingTop: 10,
                    marginTop: 110,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      transform: 'translateX(55%)',
                    }}
                  >
                    <View
                      style={{ width: '100%', height: 50, paddingRight: 10 }}
                    >
                      <button
                        className='custom_btn_text custom_btn_bottom'
                        disabled={this.state.disabledBtn}
                        onClick={this.submitFlight}
                      >
                        Submit
                      </button>

                      {UI.box(20)}
                    </View>
                  </View>
                </UI.Row>
              </View>
            </View>
            {/* End Second Column */}
          </View>

          {/* End Contents Here */}
          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);
