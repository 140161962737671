import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";

export default class Recommendations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  find_more = () => {
    UI.goTo('/tours')
  }

  render() {
    const _this = this.props._this;
    const image = _this.props.image;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          ...styles.right_container,
          width: isMobile ? "100%" : 300 + paddingX * 2,
        }}
      >
        <Text style={{...styles.recommend_title, marginBottom: 10}}>Recommendations</Text>
        {UI.box(10)}
        
        <RecommendItem
          _this={_this}
          image={UI.SHINJUKU_HOTEL}
          main={"4D/3N Holidays in Tokyo"}
          title={"Shinjuku Prince Hotel"}
          sub={"Already included in package"}
          rating={"4.5"}
          status={"This property is in good location."}
          inclusion={"Free Buffet Breakfast"}
        />
        {UI.box(10)}
        <RecommendItem
          _this={_this}
          image={UI.SHINJUKU_HOTEL}
          main={"4D/3N Holidays in Tokyo"}
          title={"Shinjuku Prince Hotel"}
          sub={"Already included in package"}
          rating={"4.5"}
          status={"This property is in good location."}
          inclusion={"Free Buffet Breakfast"}
        />
        {UI.box(10)}
        <RecommendItem
          _this={_this}
          image={UI.SHINJUKU_HOTEL}
          main={"4D/3N Holidays in Tokyo"}
          title={"Shinjuku Prince Hotel"}
          sub={"Already included in package"}
          rating={"4.5"}
          status={"This property is in good location."}
          inclusion={"Free Buffet Breakfast"}
        />
        {UI.box(10)}
        <div style={{...styles.find_more_container}}>
          <TouchableOpacity
            onClick={this.find_more}
            style={styles.find_more_btn}
          >
            <View style={{ alignSelf: "center" }}>
              <Image
                width={22}
                height={22}
                source={UI.MAGNIFYING_2}
                tintColor={"white"}
              />
            </View>
            {UI.box(5)}
            <Text style={styles.find_more_text}>FIND MORE</Text>
          </TouchableOpacity>
        </div>
      </View>
    );
  }
}


const RecommendItem = (
  props = { 
    _this: null, 
    image: "", 
    main: "", 
    title: "", 
    sub: "", 
    rating: "", 
    status: "", 
    inclusion: ""
  }
) => {
  // const id = props.id;
  // let state_checked = props._this.state["checked"];
  const onClick = () => {
    UI.goTo("/account");
  };

  return (
    <TouchableOpacity onClick = {onClick} style = {{marginBottom: 30}}>
      <View style={styles.recommend_item}>
        <ImageBackground style={styles.recommend_image} source={props.image} />
        {UI.box(15)}
        <View style={{ flex: 1 }}>
          <Text style={styles.recommend_item_text}>{props.main}</Text>
          <Text style={styles.recommend_item_text}>{props.title}</Text>
          <Text style={styles.recommend_item_text}>{props.sub}</Text>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Image
                height={20}
                width={20}
                tintColor={UI.colors.yellow}
                source={UI.STAR}
              />
            <Text style={{...styles.included_text, paddingLeft: 10}}>{props.rating}</Text>
          </View>
          <Text style={{...styles.included_text}}>{props.status}</Text>
          <Text style={{...styles.included_text}}>{props.inclusion}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};


const styles = StyleSheet.create({
  recommend_image: {
    height: 120,
    width: 160,
    borderRadius: 5
  },
  recommend_item: {
    flexDirection: "row",
    height: 100,
    width: "100%",
    marginBottom: 25,
    alignSelf: "flex-start",
    paddingLeft: 20,
    paddingRight: 20,
  },
  recommend_item_text: {
    fontSize: 12,
    color: "black",
    fontWeight: "bold",
  },
  included_text: {
    fontSize: 12,
    color: "#707070",
    fontWeight: "bold",
  },
  details_text: {
    fontSize: 12,
    color: "black",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  recommend_text: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#3D3D3D",
  },
  recommend_text_right: {
    fontWeight: "bold",
    fontSize: 16,
    color: UI.colors.primary,
    alignSelf: "center",
  },
  recommend_text_left: {
    fontWeight: "bold",
    fontSize: 16,
    color: UI.colors.primary,
    alignSelf: "flex-start",
    flex: 1,
  },
  recommend_text_container: {
    flexDirection: "row",
    alignSelf: "stretch",
  },
  line_divider: {
    height: 0.5,
    alignSelf: "stretch",
    backgroundColor: "#c0c0c0",
  },
  recommend_title: {
    fontWeight: "bold",
    fontSize: 22,
    color: UI.colors.primary,
    alignSelf: "flex-start",
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 15,
  },
  right_container: {
    backgroundColor: "white",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  find_more_container: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 0,
    paddingBottom: 20,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  find_more_btn: {
    height: 45,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    width: "100%",
    justifyContent: "center",
  },
  find_more_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
});
