// Packages
import React, { Component, useEffect } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

// Components
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImageUpload from '../../components/ImageUpload';
import TitleHeader from '../../components/TitleHeader';
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Notification from '../../../shared/Components/Notification/Notification';
import { aboutUsSettings, submitContact } from '../../../shared/API/request';
import { BsTelephoneFill } from 'react-icons/bs';
import { FaRegEnvelope } from 'react-icons/fa';
// CSS
import './ContactUs.scss';

export default class HotelDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      active_link: 'ABOUT',
      concernType: '',
      firstname: '',
      lastname: '',
      email: '',
      contact: '',
      message: '',
      contact_email: '--',
      contact_no: '--',
    });
    this.contact_us();
  }
  contact_us = () => {
    console.log('wa');
    aboutUsSettings(this.callbacks);
  };
  callbacks = async (response) => {
    const { status, data } = await response;

    console.log(status);
    console.log(data);
    this.setState({
      contact_email: data.data.contact_emails,
      contact_no: data.data.contact_no,
    });

    if (status === 201 || status === 200) {
    } else if (status === 400 || status === 422) {
      Notification(data.message, 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  handleSubmit = () => {
    const { concernType, firstname, lastname, email, contact, message } =
      this.state;

    // Return of these is empty state
    if (
      !concernType ||
      !firstname ||
      !lastname ||
      !email ||
      !contact ||
      !message
    ) {
      Notification('Please fill in the empty inputs', 'danger');
      return;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      Notification('Please Enter a valid Email', 'danger');
      return;
    }
    console.log(contact.length)
    if (contact.length < 11) {
      Notification('Minimum of 11 digit', 'danger');
      return;
    }

    if (
      /[0-9!@#\$%\^\&*\(\)\/\\+=._-]/g.test(firstname) ||
      /[0-9!@#\$%\^\&*\(\)\/\\+=._-]/g.test(lastname) ||
      /[!@#\$%\^\&*\(\)\/\\+=._-]/g.test(message) ||
      /[!@#\$%\^\&*\(\)\/\\+=._-]/g.test(contact)
    ) {
      Notification('Numbers and special characters are not allowed in names', 'danger');
      return;
    }

    // Proceed if theres value in all state
    let formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('contact_no', contact);
    formData.append('message', message);
    formData.append('concern', concernType);

    submitContact(formData, this.callback);
  };

  callback = async (response) => {
    const { status, data } = await response;

    console.log(status);
    console.log(data);

    if (status === 201 || status === 200) {
      Notification(data.msg, 'success');

      this.setState({
        firstname: '',
        lastname: '',
        email: '',
        contact: '',
        message: '',
      });
    } else if (status === 400 || status === 422) {
      Notification(data.message, 'danger');

      if (data.errors) {
        const { errors } = data;
        if (errors['documents.1']) {
          Notification(errors['documents.1'], 'danger');
        }
      }
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;
    const image_width = width <= 830 ? width : width / 3;
    // Runs after onSubmit

    return (
      <div className='main_container'>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}
          <TitleHeader
            _this={_this}
            title={'Contact Us'}
            sub_title={'HOME / ABOUT / CONTACT US'}
          />

          <div className='contact-us'>
            <Container
              fluid
              style={{ paddingLeft: isMobile ? 0 : paddingX }}
            >
              <Row>
                <Col
                  lg={6}
                  sm={12}
                >
                  <div className='contact-us-form'>
                    <Form className='form'>
                      <h2 className='contact-header'>Get in touch with us</h2>

                      <hr className='border-line' />

                      <Form.Group
                        className='mb-4'
                        controlId='formConcern'
                      >
                        <Form.Label className='label'>
                          My concern is related to:
                        </Form.Label>
                        <Form.Select
                          className='form_input'
                          value={this.state.concernType}
                          onChange={(e) =>
                            this.setState({ concernType: e.target.value })
                          }
                        >
                          <option
                            className='option'
                            value=''
                            hidden
                          >
                            Select
                          </option>
                          <option
                            className='option'
                            value='Flight inquiry'
                          >
                            Flight inquiry
                          </option>
                          <option
                            className='option'
                            value='Tour inquiry'
                          >
                            Tour inquiry
                          </option>
                          <option
                            className='option'
                            value='Booking inquiry'
                          >
                            Booking inquiry
                          </option>
                          <option
                            className='option'
                            value='Cancellation/Refund'
                          >
                            Cancellation/Refund
                          </option>
                          <option
                            className='option'
                            value='Ferry Inquiry'
                          >
                            Ferry Inquiry
                          </option>
                          <option
                            className='option'
                            value='Hotel inquiry'
                          >
                            Hotel inquiry
                          </option>
                          <option
                            className='option'
                            value='Passport and Visa Inquiry'
                          >
                            Passport and Visa Inquiry
                          </option>
                          <option
                            className='option'
                            value='Others'
                          >
                            Others
                          </option>
                        </Form.Select>
                      </Form.Group>

                      <Row>
                        <Col>
                          <Form.Group
                            className='mb-4'
                            controlId='formFirstname'
                          >
                            <Form.Label className='label'>
                              First Name
                            </Form.Label>
                            <Form.Control
                              className='form_input'
                              type='text'
                              placeholder='Enter First Name'
                              value={this.state.firstname}
                              onChange={(e) =>
                                this.setState({ firstname: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className='mb-4'
                            controlId='formLastname'
                          >
                            <Form.Label className='label'>Last Name</Form.Label>
                            <Form.Control
                              className='form_input'
                              type='text'
                              placeholder='Enter Last Name'
                              value={this.state.lastname}
                              onChange={(e) =>
                                this.setState({ lastname: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group
                            className='mb-4'
                            controlId='formEmail'
                          >
                            <Form.Label className='label'>
                              Email Address
                            </Form.Label>
                            <Form.Control
                              className='form_input'
                              type='email'
                              placeholder='Enter Email'
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className='mb-4'
                            controlId='formContact'
                          >
                            <Form.Label className='label'>
                              Contact Number
                            </Form.Label>
                            <Form.Control
                              className='form_input'
                              type='text'
                              maxLength={11}
                              placeholder='Enter Contact Number'
                              value={this.state.contact}
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({ contact: e.target.value });
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group
                            className='mb-4'
                            controlId='formMessage'
                          >
                            <Form.Label className='label'>Message</Form.Label>
                            <Form.Control
                              className='form_input message'
                              as='textarea'
                              rows={5}
                              placeholder='Enter Message'
                              value={this.state.message}
                              onChange={(e) =>
                                this.setState({ message: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group className='mb-2'>
                        <Button
                          className='custom_btn'
                          onClick={() => this.handleSubmit()}
                        >
                          Send Message
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col
                  lg={6}
                  sm={12}
                >
                  <div
                    className='direction-cont'
                    style={{ width: '100%' }}
                  >
                    <Row>
                      <Col>
                        <div
                          style={{
                            paddingRight: paddingX,
                            paddingLeft: paddingX,
                          }}
                          className='header'
                        >
                          <span className='sub_title'>
                            Traverse contact details
                          </span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div
                          className='map'
                          style={{
                            paddingLeft: paddingX,
                            paddingRight: paddingX,
                          }}
                        >
                          <iframe
                            title='Traverse Map'
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.3403936571326!2d121.05039473046195!3d14.579669558581276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8474d36bd71%3A0x54a37d8878981e2b!2sThe%20Royal%20Plaza%20Commercial%20Center!5e0!3m2!1sen!2sph!4v1659576946305!5m2!1sen!2sph'
                            height='300'
                            frameBorder='0'
                            allowFullScreen=''
                            aria-hidden='false'
                            tabIndex='0'
                            referrerpolicy='no-referrer-when-downgrade'
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div
                          className='contact-details'
                          style={{
                            paddingRight: paddingX,
                            paddingLeft: paddingX,
                          }}
                        >
                          <div className='cont'>
                            {this.state.contact_email
                              .split(',')
                              .map((item, index) => {
                                return (
                                  <span
                                    className='label  d-flex'
                                    key={index}
                                  >
                                    <div
                                      className='me-2'
                                      style={{
                                        display: 'flex',
                                        alignSelf: 'center',
                                      }}
                                    >
                                      <FaRegEnvelope />
                                    </div>
                                    <span>{item}</span>
                                  </span>
                                );
                              })}

                            <span className='label d-flex'>
                              <div
                                className='me-2'
                                style={{ display: 'flex', alignSelf: 'center' }}
                              >
                                <BsTelephoneFill />
                              </div>
                              {this.state.contact_no}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* End Contents Here */}
          <Footer _this={_this} />
        </ScrollView>
      </div>
    );
  }
}

// export default GoogleApiWrapper({
//   apiKey: ''
// })(MapContainer);

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);
