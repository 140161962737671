import React, { useState } from 'react';
import { Row, Form } from 'react-bootstrap';
import { Switch } from 'antd';

import { AiFillStar } from 'react-icons/ai';
// scss
import './YourDetails.scss';
function YourDetails({ _this }) {
  const { booking, ferry } = _this.state;

  return (
    <div className='your-details '>
      <hr />
      <Row className='mt-5'>
        {/* column 1 */}
        <div className='col-lg-6'>
          <p className='fw-bold h5'>Enter your Details</p>
          <Row>
            <div className='col-lg-6'>
              <Form.Group>
                <Form.Label>
                  First Name <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  value={_this.state.firstname}
                  onChange={(e) =>
                    _this.setState({ firstname: e.target.value })
                  }
                  type='text'
                  placeholder='Enter First Name Here'
                />
              </Form.Group>
            </div>
            <div className='col-lg-6'>
              <Form.Group>
                <Form.Label>
                  Last Name <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  value={_this.state.lastname}
                  onChange={(e) => _this.setState({ lastname: e.target.value })}
                  type='text'
                  placeholder='Enter Last Name Here'
                />
              </Form.Group>
            </div>
          </Row>
          <Row className='mb-5'>
            <div className='col-lg-6'>
              <Form.Group>
                <Form.Label>
                  Contact Number <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  value={_this.state.mobile}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    if (value.length <= 11) {
                      _this.setState({ mobile: value });
                    }
                  }}
                  type='text'
                  placeholder='Enter Number Here'
                />
              </Form.Group>
            </div>
            <div className='col-lg-6'>
              <Form.Group>
                <Form.Label>
                  Email Address <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  value={_this.state.email}
                  onChange={(e) => _this.setState({ email: e.target.value })}
                  type='email'
                  placeholder='Enter Email Address Here'
                />
              </Form.Group>
            </div>
          </Row>
          <hr />
          {/* <Row className='my-5'>
            <div className='col-6'>
              <p className='fw-bold h5'>Is this a work trip?</p>
            </div>
            <div className='col-6 text-end'>
              <label>
                <Switch
                  onChange={() =>
                    _this.setState({ workTrip: !_this.state.workTrip })
                  }
                  checked={_this.state.workTrip}
                />
              </label>
            </div>
          </Row>
          <hr /> */}
          <p className='fw-bold h5'>Your Arrival Time</p>
          <p className='p1 my-3'>Add your estimate arrival time. (Optional)</p>
          <Form.Group style={{ maxWidth: '250px' }}>
            <Form.Control
              type='datetime-local'
              onChange={(e) => _this.setState({ arrivalTime: e.target.value })}
              value={_this.state.arrivalTime}
              placeholder='Enter Email Address Here'
            />
          </Form.Group>
          <hr />
        </div>
        {/* column 2 */}
        <div className='col-lg-6'>
          <Row>
            <div className='col-lg-6 mb-5'>
              <img
                src={ferry.images[0].image_url}
                alt='img'
              />
            </div>
            <div className='col-lg-6'>
              <p className='h5 fw-bold'>Travel to {booking.destination}</p>
              <p className='h5 fw-bold'>{`${ferry.name}`}</p>
              <p className='p1-highlight'>
                {booking.accomodation} <span>&#8226;</span>
                {` ${booking.passenger}`} Passengers
              </p>
              <span
                className='d-flex fw-bold'
                style={{ alignItems: 'center' }}
              >
                <AiFillStar color='red' /> {ferry?.others?.rating}{' '}
                {`(${ferry?.others?.totalReviews} Review${
                  ferry?.others?.totalReviews === 0 ? '' : 's'
                })`}
              </span>

              <p className='p1-highlight'>{ferry.amenities}</p>
            </div>
          </Row>

          <p className='mt-5 fw-bold h5'>Special Request</p>
          <p className='p1-highlight fst-italic'>
            It can't be guaranteed, however the property with do it's best to
            meet your needs. You can always make a special request after your
            booking is complete.
          </p>
          <Form.Group>
            <Form.Control
              type='textarea'
              onChange={(e) =>
                _this.setState({ specialRequest: e.target.value })
              }
              value={_this.state.specialRequest}
              placeholder='Please write your request (Optional)'
              autoComplete='off'
            />
          </Form.Group>
        </div>
      </Row>
    </div>
  );
}

export default YourDetails;
