// Packages
import React, { Component } from 'react';

// Components
import Notification from '../../../shared/Components/Notification/Notification';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';

// Context
import AllContext from '../../../shared/Context/AllContext';

// Main Component
export default class Rooms extends Component {
  static contextType = AllContext;

  state = {
    rooms: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const _this = this.props._this;
    const { rooms } = _this.state.filteredList[0];

    if (rooms) {
      this.setState({
        rooms: rooms,
      });
    }

    // console.log("Parent State", _this.state)
    // console.log("Parent State", _this.state.filteredList[0].rooms)
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state);
    // const _this = this.props._this;
    // console.log("Parent State", _this.state.all_selected_room)
  }

  selectRoom = (room) => {
    const _this = this.props._this;
    const { data, setData } = this.context;

    // console.log("Parent State", _this.state.all_selected_room);
    // console.log(room);

    // Filter all selected Rooms by room ID
    const filteredSelectedRooms = _this.state.all_selected_room.filter(
      (arrRoom) => {
        return arrRoom.id === room.id;
      },
    );

    // console.log("Filtered", filteredSelectedRooms);

    if (room.total_available_rooms > filteredSelectedRooms.length) {
      if (room) {
        _this.setState({
          selected_room: room,
        });
      }

      _this.gotoTop();
    } else {
      Notification(
        'Max available rooms reached for the selected room',
        'danger',
      );
    }
  };

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;

    return (
      <View
        style={{
          ...styles.main_container,
          alignItems: width <= 650 ? 'center' : 'flex-start',
        }}
      >
        {this.state.rooms && this.state.rooms.length > 0 && (
          <>
            <Header text={'AVAILABLE ROOMS'} />

            <View style={{ width: '100%', gap: '2em' }}>
              {this.state.rooms.map((room) => {
                console.log('RROM', room);

                if (
                  !room.total_available_rooms ||
                  room.total_available_rooms === 0
                ) {
                  return <></>;
                }

                return (
                  <View
                    key={room.id}
                    style={{
                      flexDirection: width <= 425.98 ? 'column' : 'row',
                    }}
                  >
                    <View style={{ ...styles.room_image }}>
                      <img
                        style={{
                          width: 'auto',
                          height: '250px',
                          objectFit: 'cover',
                        }}
                        src={room.primaryImageURL}
                        alt={`${room.room_type} Room Main`}
                      />
                    </View>
                    <View
                      style={{
                        flex: '1',
                        backgroundColor: '#fff',
                        borderRadius: '0 7px 7px 0',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* For Room Header */}
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          borderBottom: 'solid 1px #E2E2E2',
                          padding: '2em 2em 1em',
                          alignItems: 'center',
                        }}
                      >
                        <Text
                          style={{
                            color: '#006FB9',
                            fontSize: 20,
                            fontWeight: 'bolder',
                          }}
                        >
                          {room.room_type} Room
                        </Text>

                        <View style={{ flexDirection: 'row', gap: '1em' }}>
                          <TouchableOpacity
                            style={{ ...styles.room_select }}
                            onClick={() => this.selectRoom(room)}
                          >
                            <Text>Select Room</Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      {/* For Room Content */}
                      <View
                        style={{
                          flexDirection: width <= 425.98 ? 'column' : 'row',
                          padding: '0 2em',
                          alignItems: 'center',
                          gap: width <= 425.98 ? '1em' : '2em',
                          margin: '1em 0',
                        }}
                      >
                        {/* Guest */}
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1em',
                          }}
                        >
                          <img
                            style={{
                              width: 17,
                              height: 20,
                            }}
                            src={UI.ROOM_USER}
                            alt='Guest Logo'
                          />

                          <Text
                            style={{ fontWeight: 'bold', color: '#646464' }}
                          >{`${room.total_guests} Guest`}</Text>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1em',
                          }}
                        >
                          <img
                            style={{
                              width: 20,
                              height: 20,
                            }}
                            src={UI.BEDROOMS}
                            alt='Bedrooms Logo'
                          />

                          <Text
                            style={{ fontWeight: 'bold', color: '#646464' }}
                          >{`${room.total_bed ? room.total_bed : 0} Bedroom${
                            room.total_bed > 1 ? 's' : ''
                          }`}</Text>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1em',
                          }}
                        >
                          <img
                            style={{
                              width: 20,
                              height: 20,
                            }}
                            src={UI.BATHROOMS}
                            alt='Bathrooms Logo'
                          />

                          <Text
                            style={{ fontWeight: 'bold', color: '#646464' }}
                          >{`${
                            room.total_bathroom ? room.total_bathroom : 0
                          } Bathroom${
                            room.total_bathroom > 1 ? 's' : ''
                          }`}</Text>
                        </View>
                      </View>

                      {/* For Price Footer */}
                      <View
                        style={{
                          flexDirection: width <= 425.98 ? 'column' : 'row',
                          borderRadius: '0 0 7px 0',
                          justifyContent: 'space-between',
                          borderBottom: 'solid 1px #E2E2E2',
                          padding: '1em 2em 1em',
                          alignItems: 'center',
                          backgroundColor: '#43980F',
                          color: '#fff',
                          fontWeight: 'bold',
                        }}
                      >
                        <Text style={{ fontSize: 16 }}>
                          PHP {room.price}{' '}
                          <Text style={{ marginLeft: 20, fontSize: 14 }}>
                            {room.isTaxInclusive ? 'Inclusive of Taxes' : ''}
                          </Text>
                        </Text>
                        <Text>
                          {room.total_available_rooms
                            ? room.total_available_rooms
                            : '0'}{' '}
                          {`Room${
                            room.total_available_rooms > 1 ? 's' : ''
                          } Available`}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </>
        )}
      </View>
    );
  }
}

const BedItem = (props = { _this: null, main: '', sub: '' }) => {
  return (
    <TouchableOpacity>
      <View style={styles.bed_item}>
        <Image
          height={100}
          width={100}
          tintColor={'#787878'}
          source={UI.BED}
        />
        <Text style={styles.header_bed}>{props.main}</Text>
        <Text style={styles.header_sub}>{props.sub}</Text>
      </View>
    </TouchableOpacity>
  );
};

const Header = (props = { text: '' }) => {
  return (
    <View style={{ width: '100%' }}>
      <Text style={styles.header_text}>{props.text}</Text>
      <View style={styles.line}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  room_select: {
    color: '#fff',
    cursor: 'pointer',
    display: 'inline-flex',
    userSelect: 'none',
    flexDirection: 'row',
    height: '40px',
    width: '130px',
    backgroundColor: 'rgb(0, 111, 185)',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  room: {
    width: '100%',
  },
  room_image: {
    borderRadius: '7px 0 0 7px',
    overflow: 'hidden',
    flexBasis: '250px',
  },
  bed_item: {
    height: 180,
    width: 180,
    backgroundColor: 'white',
    borderRadius: 5,
    marginRight: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: '#B3B3B3',
    marginTop: 5,
    marginBottom: 20,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
    marginTop: 20,
  },
  header_bed: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  header_sub: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#7E7E7E',
  },
  main_container: {
    width: '100%',
  },
});
