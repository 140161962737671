// Packages
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import MainLanding from './modules/main/containers/MainLanding';
import SearchItemsContainer from './modules/search_results/containers/SearchItemsContainer';
import Tours from './modules/tours/containers/TourContainer';
import Hotels from './modules/hotels/containers/HotelContainer';
import HotelDetails from './modules/hotel_details/containers/HotelDetails';
import TourDetails from './modules/tour_details/containers/TourDetails';
import TourBookingDetails from './modules/tour_booking_details/containers/TourBookingDetails';
import HotelBookingDetails from './modules/hotel_booking_details/containers/HotelBookingDetails';
import Login from './modules/login/containers/Login';
import Signup from './modules/signup/containers/Signup';
import FlightInquiry from './modules/flight_inquiry/containers/FlightInquiry';
import ForgotPass from './modules/forgotpass/containers/ForgotPass';
import TermsOfUse from './modules/termsofuse/containers/TermsOfUse';
import PrivacyPolicy from './modules/privacypolicy/containers/PrivacyPolicy';
import MyAccount from './modules/myaccount/container/MyAccount';
import ContactUs from './modules/contact_us/containers/ContactUs';
import AccountSettings from './modules/accountsettings/container/AccountSettings';
import BookingHistory from './modules/booking_history/containers/BookingHistory';
import HistoryDetails from './modules/history_details/containers/HistoryDetails';
import PageNotFound from './modules/page_404/containers/Page404';
import PassportVisa from './modules/passport_visa/container/PassportVisa';
import MyBooking from './modules/my-booking/containers/mainContainer';
import FerryPortal from './modules/Ferry_Portal/FerryPortalPage';
import Insurance from './modules/insurance/container/Insurance';
import Inquiries from './modules/inquiries/container/Inquiries';
import RemovePass from './modules/forgotpass/containers/ForgotPass';
import ResetPass from './modules/resetpass/containers/ResetPass';
// JSON (sample api)
import hotelList from './dummy_api/hotel.json';
import roomList from './dummy_api/rooms.json';
import toursList from './dummy_api/tours.json';

// Context
import AllContext from './shared/Context/AllContext';

// Protected Route
import ProtectedRoute from './shared/Components/ProtectedRoute/ProtectedRoute';
import FerryPortalPage_2GO from './modules/Ferry_Portal_2go/FerryPortalPage_2GO';
import FerryPortalInformation from './modules/FerryPortalInformation/FerryPortalInformation';
import FerryPortalPageOceanJet from './modules/Ferry_Portal_OceanJet/FerryPortalPageOceanJet';
import FerryBookingDetailsPage from './modules/Ferry_booking_details/FerryBookingDetailsPage';
import FerryPortal_OceanJetInformation from './modules/FerryPortal_OceanJetInformation/FerryPortal_OceanJetInformation';
import bookmarks from './modules/bookmarks/bookmarks';

// Main Component
function AppRouter() {
  // State
  const [data, setData] = useState({
    tours: toursList,
    hotels: hotelList,
    rooms: roomList,
    isAuth: false,
    user: {},
  });

  // Function to get and set isAuth and User state data from local storage
  if (!data.isAuth) {
    const localAuth = JSON.parse(localStorage.getItem('isAuth'));
    const user = JSON.parse(localStorage.getItem('user'));

    if (localAuth && user) {
      setData((prevState) => {
        return { ...prevState, isAuth: true, user: user };
      });
    } else {
      localStorage.clear();
    }
  }
  
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  // Routes
  return (
    <AllContext.Provider value={{ data, setData }}>
      <Switch>
        <Route
          exact
          path='/'
          component={MainLanding}
        />
        <Route
          exact
          path='/login'
          component={Login}
        />
        <Route
          exact
          path='/signup'
          component={Signup}
        />
        <Route
          exact
          path='/forgotpass'
          component={ForgotPass}
        />
        <Route
          exact
          path='/terms-of-use'
          component={TermsOfUse}
        />
        <Route
          exact
          path='/privacy-policy'
          component={PrivacyPolicy}
        />
        <Route
          exact
          path='/search-results'
          component={SearchItemsContainer}
        />
        <Route
          exact
          path='/hotel-details/:slug'
          component={HotelDetails}
        />
        <Route
          exact
          path='/tour-details/:slug'
          component={TourDetails}
        />
        <Route
          exact
          path='/tour-booking-details'
          component={TourBookingDetails}
        />
        <Route
          exact
          path='/remove-password'
          component={RemovePass}
        />
        <Route
          exact
          path='/hotel-booking-details'
          component={HotelBookingDetails}
        />
        <Route
          exact
          path='/tours'
          component={Tours}
        />
        <Route
          exact
          path='/hotels'
          component={Hotels}
        />
        <Route
          exact
          path='/ferry-portal'
          component={FerryPortal}
        />
        <Route
          exact
          path='/ferry-portal-2go'
          component={FerryPortalPage_2GO}
        />
        <Route
          exact
          path='/ferry-portal-OceanJet'
          component={FerryPortalPageOceanJet}
        />
        <Route
          exact
          path='/ferry-portal/2go-information'
          component={FerryPortalInformation}
        />
        <Route
          exact
          path='/ferry-portal/Oceanjet-information'
          component={FerryPortal_OceanJetInformation}
        />
        <Route
          exact
          path='/ferry-portal/ferry-booking-details'
          component={FerryBookingDetailsPage}
        />
        <Route
          exact
          path='/flight-inquiry'
          component={FlightInquiry}
        />
        <Route
          exact
          path='/contact-us'
          component={ContactUs}
        />
        <Route
          exact
          path='/inquiries'
          component={Inquiries}
        />
        <ProtectedRoute
          exact
          path='/booking-history'
          component={BookingHistory}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/bookmarks'
          component={bookmarks}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/ferry-portal/insurance'
          component={Insurance}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/booking-history/:id'
          component={HistoryDetails}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/passport-visa'
          component={PassportVisa}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/passport-visa/:id'
          component={PassportVisa}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/my-booking'
          component={MyBooking}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/account'
          component={MyAccount}
          isAuth={data.isAuth}
        />
        <ProtectedRoute
          exact
          path='/account-settings'
          component={AccountSettings}
          isAuth={data.isAuth}
        />
        <Route
          exact
          path='/reset-password'
          component={ResetPass}
          isAuth={data.isAuth}
        />
        <Route
          exact
          path='/404'
          component={PageNotFound}
        />
        <Route component={PageNotFound} />
      </Switch>
    </AllContext.Provider>
  );
}

export default AppRouter;
