// Packages
import React, { useState, useEffect } from 'react';

// Components
import {
  TouchableOpacity,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import FilterInput from '../../components/FilterInput';
import GuestInput from './GuestInput';

// CSS
import './SplashPage.css';
import './SplashPage.scss';

// Variable for setting height of the banner
const splash_height = 550;

// Main Component
export default function SplashPage(props) {
  // Get this value from Mainlanding as _this
  const _this = props._this;

  // Variable for getting current screen width
  const width = _this.state.width;

  // States
  const [thisData, setThisData] = useState();

  useEffect(() => {
    if (props._this) {
      setThisData(props._this);
    }
  }, [props]);
  console.log(_this);

  // To render on screen

  return (
    <ImageBackground
      className='splash-container'
      style={{
        height: splash_height + (_this.state.isMobile ? 100 : 0),
      }}
      source={UI.BANNER}
      imageFit={ImageFit.COVER}
    >
      <View
        className='splash-container'
        style={{
          zIndex: 99,
          height: splash_height + (_this.state.isMobile ? 100 : 0),
        }}
      >
        <span className='main-title'>
          Traverse Through Memorable Experiences
        </span>
        {UI.box(20)}
        <span className='subtitle'>
          Looking for a Hotel? or Tie in you Hotel stay with your Flight, let's
          get started below.
        </span>
        {UI.box(60)}

        <View
          className='filter-container'
          style={{
            paddingLeft: width * 0.05,
            paddingRight: width * 0.05,
          }}
        >
          <div className='tab-buttons-container'>
            <TabButton
              _this={_this}
              text={'HOTELS'}
              icon={UI.HOTEL_2}
            />
            {UI.box(15)}
            <TabButton
              _this={_this}
              text={'TOURS'}
              icon={UI.AIRPLANE}
            />
          </div>

          <View className='booking-search filter-input-container'>
            <FilterInput
              onChange={(e) => {
                _this.setState({
                  search_city: e.target.value,
                });
              }}
              _this={_this}
              title={'City, Destination or Hotel Name'}
              placeholder={'Destination Name'}
              icon={UI.DESTINATION}
              className='destination'
              type='text'
            />

            <FilterInput
              onChange={(e) => {
                _this.setState({
                  search_check_in: e.target.value,
                });
              }}
              _this={_this}
              title={'Check In'}
              icon={UI.CALENDAR}
              className='checkIn'
              type='date'
              min={new Date().toISOString().split('T')[0]}
              max={_this.state.search_check_out}
            />

            <FilterInput
              onChange={(e) => {
                _this.setState({
                  search_check_out: e.target.value,
                });
              }}
              _this={_this}
              title={'Check Out'}
              icon={UI.CALENDAR}
              className='checkOut'
              type='date'
              min={_this.state.search_check_in}
            />

            <GuestInput
              onChange={(val) => {
                _this.setState({
                  search_guest: val.guest,
                  search_room: val.room,
                });
              }}
              _this={_this}
              title={'Guest & Rooms'}
              placeholder={'1 Guest, 1 Room'}
              icon={UI.PROFILE}
              className='guest'
              type='text'
              value={
                thisData
                  ? `${thisData.state.search_guest} Guest, ${thisData.state.search_room} Room`
                  : ''
              }
            />

            <TouchableOpacity
              onClick={_this.search}
              style={{ ...styles.search_hotels_btn, width: 650 }}
              className='hotelSearch search-hotel-btn'
            >
              <View style={{ alignSelf: 'center' }}>
                <Image
                  width={22}
                  height={22}
                  source={UI.MAGNIFYING_2}
                  tintColor={'white'}
                />
              </View>
              {UI.box(5)}
              <Text className='search-hotel-text'>SEARCH</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      <div
        className='tint-box'
        style={{
          height: splash_height + (_this.state.isMobile ? 100 : 0),
          marginTop: -(splash_height + (_this.state.isMobile ? 100 : 0)),
        }}
      ></div>
    </ImageBackground>
  );
}

// Code for the tab of Hotels and Tours
const TabButton = (props = { _this: null, text: '', icon: UI.HOTEL_2 }) => {
  const _this = props._this;
  let isActive = _this.state.selected_tab === props.text ? true : false;
  let search = _this.state.selected_tab === '';
  return (
    <TouchableOpacity
      onClick={(e) => {
        _this.setState({
          selected_tab: props.text,
          type: props.text,
        });
      }}
      className='tab-button-container'
      style={{
        backgroundColor: isActive ? UI.colors.primary : '#C4C4C4',
      }}
    >
      <View
        style={{ alignSelf: 'center', display: isActive ? 'flex' : 'none' }}
      >
        <Image
          source={props.icon}
          tintColor={'white'}
          height={24}
          width={24}
        />
      </View>
      <View
        style={{ alignSelf: 'center', display: !isActive ? 'flex' : 'none' }}
      >
        <Image
          source={props.icon}
          tintColor={'#888888'}
          height={24}
          width={24}
        />
      </View>
      {UI.box(10)}
      <Text
        style={{
          color: isActive ? 'white' : '#888888',
          fontSize: 13,
          fontWeight: 'bold',
        }}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

// CSS Styles
const styles = StyleSheet.create({});
