import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Row } from 'react-bootstrap';

//components
import YourSelection from './Step1/YourSelection';
import YourDetails from './Step2/YourDetails';
import FinalStep from './Step3/FinalStep';
import { submitFerryBooking } from '../../shared/API/request';
import Notification from '../../shared/Components/Notification/Notification';

// css
import './StepperPortal.scss';

function StepperPortal({ paddingX, _this }) {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const nextStep = () => {
    if (activeStep === 2) {
      if (_this.state.mobile === '' || _this.state.mobile === null) {
        Notification('Please fill in the required fields', 'danger');
      } else if (_this.state.mobile.length < 11) {
        Notification('This field must be 11 digits', 'danger');
      } else {
        handleSubmit();
      }
    } else if (activeStep === 1) {
      if (
        _this.state.mobile === '' ||
        _this.state.mobile === null ||
        _this.state.firstname === '' ||
        _this.state.firstname === null ||
        _this.state.lastname === '' ||
        _this.state.lastname === null ||
        _this.state.email === '' ||
        _this.state.email === null
      ) {
        Notification('Please fill in the required fields', 'danger');
      } else if (
        containsSpecialChars(_this.state.firstname) ||
        containsSpecialChars(_this.state.lastname)
      ) {
        Notification(
          'Numbers and special characters are not allowed in names',
          'danger',
        );
      } else if (_this.state.mobile.length < 11) {
        Notification('This field must be 11 digits', 'danger');
      } else {
        setActiveStep((currentStep) => {
          return currentStep + 1;
        });
      }
    } else {
      setActiveStep((currentStep) => {
        return currentStep + 1;
      });
    }
  };

  const backStep = () => {
    if (activeStep === 0) {
      history.push('/ferry-portal');
    } else {
      setActiveStep((currentStep) => {
        return currentStep + -1;
      });
    }
  };

  const handleSubmit = () => {
    const departArr = _this.state?.booking?.departureDate.split('|');
    const returnArr = _this.state?.booking?.returnDate.split('|');

    if (_this.state.paymentType === '') {
      Notification('Select Payment Type', 'danger');
      return;
    }
    console.log(_this.state.paymentType);
    if (_this.state.paymentType === 'paymaya') {
      // Notification('Select Payment Type', 'danger');
      return;
    }

    const params = {
      guestId: _this.state?.userId,
      ferryId: _this.state?.ferry?.id,
      origin: _this.state?.booking?.origin,
      destination: _this.state?.booking?.destination,
      departureDate: departArr[1],
      returnDate: returnArr[1] ? returnArr[1] : '',
      totalPassenger: _this.state?.booking?.passenger,
      accomodationType: _this.state?.booking?.accomodation,
      rate: _this.state?.booking?.price,
      subTotal: _this.state?.booking?.subTotal,
      grandTotal: _this.state?.booking?.total,
      insurance: _this.state?.booking?.insuranceFee,
      serviceFee: _this.state?.booking?.serviceFee,
      insuranceTotal: _this.state?.booking?.insuranceTotal,
      paymentType: _this.state.paymentType,
    };
    setDisabledBtn(true);
    submitFerryBooking(params, callback);
  };

  const containsSpecialChars = (str) => {
    const specialChars = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  };

  // Callback after get api for searchhoteltours
  const callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      setDisabledBtn(false);
      localStorage.removeItem('booking-details');
      localStorage.removeItem('search_params');

      if (_this.state.paymentType === 'paymaya') {
        window.location.replace(data.data.redirectUrl);
      } else {
        Notification('Book successfully', 'success');
        history.push('/my-booking');
      }
    } else if (status === 400) {
      setDisabledBtn(false);
      Notification('An Error Occured', 'danger');
    } else {
      setDisabledBtn(false);
      Notification('An Error Occured', 'danger');
    }
  };

  return (
    <div style={{ padding: `0 ${paddingX}px` }}>
      <Stepper
        className='container my-5'
        activeStep={activeStep}
      >
        <Step>
          <StepLabel>Your Selection</StepLabel>
        </Step>
        <Step>
          <StepLabel>Your Details</StepLabel>
        </Step>
        <Step>
          <StepLabel>Final Step</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 ? (
        <YourSelection _this={_this} />
      ) : activeStep === 1 ? (
        <YourDetails _this={_this} />
      ) : activeStep === 2 ? (
        <FinalStep _this={_this} />
      ) : (
        <div className='text-center'>
          <p>Finish</p>{' '}
        </div>
      )}
      <Row className='mt-5'>
        <div className='col-6'>
          <button
            className='btn  py-3 px-5 text-white'
            onClick={backStep}
            style={{ backgroundColor: '#006FB9' }}
          >
            Back
          </button>
        </div>
        <div className='col-6 text-end '>
          {/* <input
            type='button'
            value={activeStep === 2 ? 'Finish' : 'Next'}
            onClick={nextStep}
            className='btn py-3 px-5 text-white'
            style={{ backgroundColor: '#006FB9' }}
          /> */}
          <button
            onClick={nextStep}
            className='btn py-3 px-5 text-white'
            style={{ backgroundColor: '#006FB9' }}
            disabled={disabledBtn}
          >
            {activeStep === 2 ? 'Finish' : 'Next'}
          </button>
        </div>
      </Row>
    </div>
  );
}

export default StepperPortal;
