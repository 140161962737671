import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";
import FilterInput from "../../components/FilterInput";

export default class FilterInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;

    return (
      <View style={styles.filter_inputs_container}>
        <FilterInput
          onChange={(e) => {
            _this.setState({
              location: e.target.value,
            });
          }}
          _this={_this}
          title={"Location / Origin"}
          placeholder={"Enter here"}
          icon={UI.DESTINATION}
        />
        {UI.box(15)}
        <FilterInput
          onChange={(e) => {
            _this.setState({
              dateOfBooking: e.target.value,
            });
          }}
          _this={_this}
          title={"Date of Booking / Departure Date"}
          placeholder={"Apr 7, 2021, Wed"}
          icon={UI.CALENDAR}
          type={'date'}
        />
        {UI.box(15)}


      </View>
    );
  }
}


const styles = StyleSheet.create({
  filter_inputs_container: {
    borderRadius: 10,
    backgroundColor: "white",
    alignSelf: "stretch",
  },
  filter_input: {
    flex: 1,
    height: 45,
    borderStyle: "solid",
    borderWidth: 1.5,
    borderRadius: 5,
    alignItems: "flex-start",
  },
});
