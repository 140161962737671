import React from 'react';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import ConditionalRender from '../../components/ConditionalRender';

const PaginationUI = ({ _this }) => {
  const isTour = _this.state.activeTab === 'Tour';
  const isHotel = _this.state.activeTab === 'Hotel';
  const isFerry = _this.state.activeTab === 'Ferry';

  return (
    <>
      <ConditionalRender
        condition={isTour}
        renderIf={
          <>
            {_this.state.tourMeta && (
              <>
                {_this.state.tourMeta.current_page !==
                  _this.state.tourMeta.from &&
                  _this.state.tourMeta.from !== null && (
                    <TouchableOpacity
                      style={styles.pagination_btn}
                      onClick={() => _this.prevPage('tour')}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={'#646464'}
                        source={UI.CHEVRON_LEFT}
                      />
                    </TouchableOpacity>
                  )}
                <View style={styles.pagination_btn}>
                  <Text style={styles.pagination_btn_text}>
                    Showing Page{' '}
                    {_this.state.tourMeta
                      ? _this.state.tourMeta.current_page
                      : '0'}{' '}
                    of{' '}
                    {_this.state.tourMeta
                      ? _this.state.tourMeta.last_page
                      : '0'}
                  </Text>
                  {UI.box(10)}
                </View>
                {_this.state.tourMeta.current_page !==
                  _this.state.tourMeta.last_page && (
                  <TouchableOpacity
                    style={styles.pagination_btn}
                    onClick={() => _this.nextPage('tour')}
                  >
                    <Image
                      height={15}
                      width={15}
                      tintColor={'#646464'}
                      source={UI.CHEVRON_RIGHT}
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
          </>
        }
      />

      <ConditionalRender
        condition={isHotel}
        renderIf={
          <>
            {_this.state.hotelMeta && (
              <>
                {_this.state.hotelMeta.current_page !==
                  _this.state.hotelMeta.from &&
                  _this.state.hotelMeta.from !== null && (
                    <TouchableOpacity
                      style={styles.pagination_btn}
                      onClick={() => _this.prevPage('hotel')}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={'#646464'}
                        source={UI.CHEVRON_LEFT}
                      />
                    </TouchableOpacity>
                  )}
                <View style={styles.pagination_btn}>
                  <Text style={styles.pagination_btn_text}>
                    Showing Page{' '}
                    {_this.state.hotelMeta
                      ? _this.state.hotelMeta.current_page
                      : '0'}{' '}
                    of{' '}
                    {_this.state.hotelMeta
                      ? _this.state.hotelMeta.last_page
                      : '0'}
                  </Text>
                  {UI.box(10)}
                </View>
                {_this.state.hotelMeta.current_page !==
                  _this.state.hotelMeta.last_page && (
                  <TouchableOpacity
                    style={styles.pagination_btn}
                    onClick={() => _this.nextPage('hotel')}
                  >
                    <Image
                      height={15}
                      width={15}
                      tintColor={'#646464'}
                      source={UI.CHEVRON_RIGHT}
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
          </>
        }
      />

      <ConditionalRender
        condition={isFerry}
        renderIf={
          <>
            {_this.state.ferryMeta && (
              <>
                {_this.state.ferryMeta.current_page !==
                  _this.state.ferryMeta.from &&
                  _this.state.ferryMeta.from !== null && (
                    <TouchableOpacity
                      style={styles.pagination_btn}
                      onClick={() => _this.prevPage('ferry')}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={'#646464'}
                        source={UI.CHEVRON_LEFT}
                      />
                    </TouchableOpacity>
                  )}
                <View style={styles.pagination_btn}>
                  <Text style={styles.pagination_btn_text}>
                    Showing Page{' '}
                    {_this.state.ferryMeta
                      ? _this.state.ferryMeta.current_page
                      : '0'}{' '}
                    of{' '}
                    {_this.state.ferryMeta
                      ? _this.state.ferryMeta.last_page
                      : '0'}
                  </Text>
                  {UI.box(10)}
                </View>
                {_this.state.ferryMeta.current_page !==
                  _this.state.ferryMeta.last_page && (
                  <TouchableOpacity
                    style={styles.pagination_btn}
                    onClick={() => _this.nextPage('ferry')}
                  >
                    <Image
                      height={15}
                      width={15}
                      tintColor={'#646464'}
                      source={UI.CHEVRON_RIGHT}
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default PaginationUI;

const styles = StyleSheet.create({
  pagination_btn_text: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 'bold',
  },
  pagination_btn: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    alignSelf: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
});
