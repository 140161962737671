// Packages
import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Components
import {
  ScrollView,
  View,
  ImageFit,
} from '../../../shared/custom-react-native';
import TourMap from './TourMap';
import TourInfo from './TourInfo';
import Inclusions from './Inclusions';
import Exclusions from './Exclusions';
import TourReserve from './TourReserve';
import Information from './Information';
import Availability from './Availability';
import TourServices from './TourServices';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import TourDescription from './TourDescription';
import UI from '../../../shared/Components/UI/js';
import Notification from '../../../shared/Components/Notification/Notification';

// API

import { tourDetail, getBookmark } from '../../../shared/API/request';

// CSS
import './TourDetails.scss';

// Context
import AllContext from '../../../shared/Context/AllContext';

// Main Component
class TourDetails extends Component {
  // Static Context
  static contextType = AllContext;

  constructor(props, context) {
    super(props);
    // Get id from URL
    const tourSlug = this.props.match.params.slug;
    UI.initiateView(this, {
      active_link: 'TOURS',
      filteredList: [],
      loading: true,
      selected_room: null,
      notFound: false,
      tourSlug: tourSlug,
    });

    // Run function to request api
    this.tourDetailBySlug(tourSlug);
    this.getBookmarkStatus(context.data.user.id, tourSlug);
  }

  // Function to get Bookmark Status
  getBookmarkStatus = (id, slug) => {
    getBookmark(id, slug, this.bookmarkCallback);
  };

  // Function to run api request
  tourDetailBySlug = (slug) => {
    tourDetail(slug, this.callback);
  };

  // Callback after get api for searchhoteltours
  callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          filteredList: [data.data],
          loading: false,
        });
      }

      this.setState({
        meta: data.meta,
      });
    } else if (status === 404) {
      this.setState({
        loading: false,
        notFound: true,
      });
    } else if (status === 400) {
      Notification('An Error Occured', 'danger');
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  bookmarkCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      this.setState({
        bookmarkId: data.bookmarkId,
        isBookMarked: data.bookmarkId,
      });
    } else {
      Notification('An Error Occured', 'danger');
    }
  };

  bookmarkHandler() {
    console.log('test');
  }

  render() {
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;

    const image_width = width <= 830 ? width : width / 3;

    // Use Context
    const { data, setData } = this.context;

    return (
      <View className='main_container'>
        <ScrollView>
          <Header _this={this} />
          {/* Loading... */}
          {this.state.loading && (
            <>
              <View style={{ width: width }}>
                <UI.Row
                  breakpoint={850}
                  _this={this}
                  style={{
                    width: '100%',
                    paddingLeft: paddingX,
                    paddingRight: paddingX,
                    marginTop: 50,
                    display: 'flex',
                    height: '15em',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <h2
                      style={{
                        fontSize: 36,
                        fontWeight: 'bold',
                        color: '#006fb9',
                      }}
                    >
                      Loading...
                    </h2>
                  </div>
                </UI.Row>
              </View>
            </>
          )}

          {/* Tour Not Found */}
          {this.state.notFound && !this.state.loading && (
            <>
              <View style={{ width: width }}>
                <UI.Row
                  breakpoint={850}
                  _this={this}
                  style={{
                    width: '100%',
                    paddingLeft: paddingX,
                    paddingRight: paddingX,
                    marginTop: 50,
                    display: 'flex',
                    height: '15em',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <h2
                      style={{
                        fontSize: 36,
                        fontWeight: 'bold',
                        color: '#006fb9',
                      }}
                    >
                      Tour not found.
                    </h2>
                  </div>
                </UI.Row>
              </View>
            </>
          )}

          {/* Tour Found */}
          {this.state.filteredList && this.state.filteredList.length > 0 && (
            <>
              {/* Banner Images */}
              <View>
                <ScrollView style={{ width: width }} horizontal={true}>
                  <View
                    style={{ flexDirection: 'row', width: width, height: 300 }}
                  >
                    {this.state.filteredList[0].images.map((image) => (
                      <img
                        src={image.file_url}
                        style={{
                          height: 300,
                          width: image_width,
                          objectFit: ImageFit.COVER,
                        }}
                        alt={image.filename}
                      />
                    ))}
                  </View>
                </ScrollView>
              </View>

              {/* Main Content */}
              <UI.Row
                breakpoint={850}
                _this={this}
                style={{
                  width: '100%',
                  paddingLeft: paddingX,
                  paddingRight: paddingX,
                  marginTop: 50,
                }}
              >
                {/* Left Content */}
                <View style={{ flexDirection: 'column', flex: 1 }}>
                  <TourInfo
                    _this={this}
                    filteredList={this.state.filteredList}
                    data={data}
                    tourDetailBySlug={this.tourDetailBySlug}
                  />
                  <TourDescription
                    _this={_this}
                    filteredList={this.state.filteredList}
                  />
                  {/* {UI.box(width <= 830 ? 30 : 50)}
                  <Availability
                    _this={this}
                    filteredList={this.state.filteredList}
                  /> */}
                  {UI.box(width <= 830 ? 30 : 50)}
                  <Inclusions
                    _this={_this}
                    filteredList={this.state.filteredList}
                  />
                  {UI.box(width <= 830 ? 30 : 40)}
                  <Exclusions
                    _this={_this}
                    filteredList={this.state.filteredList}
                  />
                </View>
                {UI.box(width <= 830 ? 30 : 100)}

                {/* Right Content */}
                <View style={{ flexDirection: 'column' }}>
                  <TourReserve
                    _this={this}
                    filteredList={this.state.filteredList}
                  />
                  {/* {UI.box(width <= 830 ? 30 : 50)} */}
                  {/* <Information _this={this} /> */}
                  {UI.box(width <= 830 ? 30 : 40)}
                  {/* <TourServices _this={this} />
                  {UI.box(width <= 830 ? 30 : 50)} */}
                  <TourMap
                    _this={this}
                    filteredList={this.state.filteredList}
                  />
                </View>
              </UI.Row>
            </>
          )}

          {/* End Contents Here */}
          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(TourDetails);
