import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/Components/UI/js';
import { DatePicker, TimePicker, InputNumber, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Option } = Select;

export default class BookingDetailsInfo extends Component {
  constructor(props) {
    super(props);
    // this.state = { edit1: false, edit2: false, edit3: false };
    // this.handleEdit1 = this.handleEdit1.bind(this);
    // this.handleEdit2 = this.handleEdit2.bind(this);
    // this.handleEdit3 = this.handleEdit3.bind(this);
  }

  // handleEdit1() {
  //     this.setState(prevState => ({
  //         edit1: !prevState.edit1,
  //     }));

  //     console.log(this.state.edit1);

  // }

  // handleEdit2() {
  //     this.setState(prevState => ({
  //         edit2: !prevState.edit2
  //     }));

  //     console.log(this.state.edit2);
  // }

  // handleEdit3() {
  //     this.setState(prevState => ({
  //         edit3: !prevState.edit3
  //     }));

  //     console.log(this.state.edit3);
  // }

  seePopularHotel() {
    UI.goTo('/hotel-details');
  }

  render() {
    const _this = this.props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;
    const isMobile = _this.state.isMobile;
    // let edit1 = this.state.edit1;
    // let edit2 = this.state.edit2;
    // let edit3 = this.state.edit3;

    const dateConverter = (date) => {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

      const convertedDate = new Date(date);
      const fullDate =
        days[convertedDate.getDay()] +
        ', ' +
        months[convertedDate.getMonth()] +
        ' ' +
        convertedDate.getDate() +
        ', ' +
        convertedDate.getFullYear();
      return fullDate;
    };

    // booking dates
    const booking_check_in = dateConverter(_this.state.booking.check_in);
    const booking_check_out = dateConverter(_this.state.booking.check_out);

    // vat calculation
    const vatCalculator = (price) => {
      const vat = parseFloat((price / 1.12) * 0.12).toFixed(2);

      return vat;
    };

    // vat calculation
    const roomNoVatCalculator = (price) => {
      const roomPrice = parseFloat(price / 1.12).toFixed(2);

      return roomPrice;
    };

    return (
      <View
        styles={{
          ...styles.main_container,
          width: width <= 830 ? '100%' : 400,
        }}
      >
        <UI.Row
          breakpoint={850}
          _this={_this}
        >
          <View style={{ flexDirection: 'row', width: width }}>
            <View
              style={{
                marginTop: 20,
                paddingRight: paddingX,
                width: width <= 830 ? '100%' : '40%',
              }}
            >
              <Text style={{ ...styles.h3 }}>Your Booking Details</Text>

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '18%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>Check-in</Text>
                  <Text style={{ ...styles.h3 }}>{booking_check_in}</Text>
                  {/* <Text style={{ ...styles.h5, color: '#8E8E8E' }}>From 2:00 PM</Text> */}
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '18%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>Check-out</Text>
                  <Text style={{ ...styles.h3 }}>{booking_check_out}</Text>
                  {/* {edit1 === false ?
                                        <>
                                            <Text style={{ ...styles.h5 }}>Check-out</Text>
                                            <Text style={{ ...styles.h3 }}>{booking_check_out}</Text>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E' }}>Until 11:00 AM</Text>
                                        </>
                                        :
                                        <>
                                            <Text style={{ ...styles.h5 }}>Check-in</Text>
                                            <DatePicker style={{ marginBottom: 10 }} />
                                            <TimePicker use12Hours format="h:mm a" />
                                        </>
                                    } */}
                </View>

                {/* <View style={{
                                    marginTop: 20,
                                    paddingRight: paddingX,
                                    width: width <= 830 ? '100%' : '5%',
                                    alignItems: 'right'
                                }}>
                                    {edit1 === false ?
                                        <TouchableOpacity name="edit1" onClick={this.handleEdit1}>
                                            <Text style={styles.h5}>Edit</Text>
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity name="update1" onClick={this.handleEdit1}>
                                            <Text style={styles.h5}>Save</Text>
                                        </TouchableOpacity>
                                    }
                                </View> */}
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 40,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '18%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>Guest</Text>
                  <Text style={{ ...styles.h3 }}>
                    {_this.state.booking.guest}
                  </Text>
                  {/* {edit2 === false ?
                                        <>
                                            <Text style={{ ...styles.h5 }}>Guest</Text>
                                            <Text style={{ ...styles.h3 }}>1 Adult</Text>
                                        </>
                                        :
                                        <>
                                            <Text style={{ ...styles.h5 }}>Guest</Text>
                                            <Text style={{ ...styles.h3 }}>Number of Adult</Text>
                                            <InputNumber prefix={<UserOutlined />} style={{ width: '100%' }} defaultValue={1} min={0} />
                                        </>
                                    } */}
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '18%',
                  }}
                ></View>

                {/* <View style={{
                                    marginTop: 20,
                                    paddingRight: paddingX,
                                    width: width <= 830 ? '100%' : '5%',
                                    alignItems: 'right'
                                }}>
                                    {edit2 === false ?
                                        <TouchableOpacity name="edit2" onClick={this.handleEdit2}>
                                            <Text style={styles.h5}>Edit</Text>
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity name="update2" onClick={this.handleEdit2}>
                                            <Text style={styles.h5}>Save</Text>
                                        </TouchableOpacity>
                                    }
                                </View> */}
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  minHeight: 50,
                  marginTop: 20,
                  marginBottom: 50,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '30%',
                  }}
                >
                  <Text style={{ ...styles.h5 }}>Room Selected</Text>
                  {_this.state.booking &&
                    _this.state.booking.all_selected_room.map((item) => {
                      return (
                        <View
                          style={{
                            marginTop: 15,
                            width: '100%',
                          }}
                        >
                          <Text style={{ ...styles.h3 }}>
                            {item.name} - ₱ {item.price}
                          </Text>
                          <ul
                            style={{
                              width: '100%',
                              margin: '0 0 0.25rem 0.35rem',
                              color: '#707070',
                            }}
                          >
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                {item.bed_type}
                              </Text>
                            </li>
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                {item.total_available_rooms} Room(s)
                              </Text>
                            </li>
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                {item.total_bed} Bed(s)
                              </Text>
                            </li>
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                {item.total_bathroom} Bathroom(s)
                              </Text>
                            </li>
                          </ul>
                        </View>
                      );
                    })}
                  {/* {edit3 === false ?
                                        <>
                                            <Text style={{ ...styles.h5 }}>Room Selected</Text>
                                            <Text style={{ ...styles.h3 }}>Room Name Here - Non Smoking</Text>
                                        </>
                                        :
                                        <>
                                            <Text style={{ ...styles.h5 }}>Promo Selected</Text>
                                            <Select defaultValue="valentines_day" style={{ width: 120 }}>
                                                <Option value="valentines_day">Valentines Day</Option>
                                            </Select>
                                        </>
                                    } */}
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '6%',
                  }}
                ></View>

                {/* <View style={{
                                    marginTop: 20,
                                    paddingRight: paddingX,
                                    width: width <= 830 ? '100%' : '5%',
                                    alignItems: 'right'
                                }}>
                                    {edit3 === false ?
                                        <TouchableOpacity name="edit3" onClick={this.handleEdit3}>
                                            <Text style={styles.h5}>Edit</Text>
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity name="update3" onClick={this.handleEdit3}>
                                            <Text style={styles.h5}>Save</Text>
                                        </TouchableOpacity>
                                    }
                                </View> */}
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    width: '38%',
                  }}
                >
                  <ColoredLine color='#D3D3D3' />
                </View>
              </View>

              {/* <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '33%',
                  }}
                >
                  <Text style={{ ...styles.h3 }}>Popular in the area</Text>
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '10%',
                    alignItems: 'right',
                  }}
                >
                  <TouchableOpacity onClick={this.seePopularHotel}>
                    <Text style={{ ...styles.h5 }}>See more</Text>
                  </TouchableOpacity>
                </View>
              </View> */}

              {/* <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '13%',
                  }}
                >
                  <Image
                    height={700}
                    width={200}
                    source={UI.SHINJUKU_HOTEL}
                  />
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '13%',
                  }}
                >
                  <Image
                    height={700}
                    width={200}
                    source={UI.SHINJUKU_HOTEL}
                  />
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '13%',
                  }}
                >
                  <Image
                    height={700}
                    width={200}
                    source={UI.SHINJUKU_HOTEL}
                  />
                </View>
              </View> */}
            </View>

            {width > 830 && ( // Large Screens
              <View
                style={{
                  marginTop: 20,
                  paddingLeft: paddingX,
                  width: width <= 830 ? '100%' : '50%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: 'auto',
                    minHeight: 200,
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginTop: 20,
                      width: width <= 830 ? '100%' : '20%',
                    }}
                  >
                    <img
                      className='img-fluid'
                      src={_this.state.booking.hotelArr.primaryImageURL}
                      alt='img'
                      style={{ borderRadius: '10px' }}
                    />
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '50%',
                      paddingLeft: 10,
                    }}
                  >
                    <Text style={{ ...styles.h3 }}>
                      Room Hotel in {_this.state.booking.hotelArr.location}
                    </Text>
                    <Text style={{ ...styles.h3 }}>
                      {_this.state.booking.hotelArr.title}
                    </Text>
                    {/* <View style={{ flexDirection: "row" }}>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E', marginRight: 20 }}>Queen Bed: </Text>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E' }}>1 Bath</Text>
                                        </View> */}
                    <View style={{ flexDirection: 'row' }}>
                      <Image
                        height={20}
                        width={20}
                        tintColor={'#FF0000'}
                        source={UI.STAR}
                      />
                      <Text style={{ ...styles.h5, marginLeft: 20 }}>
                        {_this.state.booking.hotelArr.rating} (
                        {_this.state.booking.hotelArr.totalReviews} reviews)
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...styles.h5,
                        marginTop: '20px',
                        color: '#8E8E8E',
                      }}
                    >
                      This property is in{' '}
                      {_this.state.booking.hotelArr.location}.
                    </Text>
                    <Text style={{ ...styles.h5, marginTop: 20 }}>
                      Hotel Amenities
                    </Text>
                    {_this.state.booking &&
                      _this.state.booking.hotelArr.aminities.map((item) => {
                        return (
                          <Text
                            style={{
                              ...styles.h5,
                              color: '#8E8E8E',
                              marginTop: 5,
                            }}
                          >
                            {item.desc}
                          </Text>
                        );
                      })}
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '5%',
                      alignItems: 'right',
                    }}
                  ></View>
                </View>

                {/* Line Break */}
                {/* <View
                  style={{
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <ColoredLine color='#D3D3D3' />
                </View> */}

                {/* Promo Code Start */}
                {/* <View
                  style={{
                    flexDirection: 'row',
                    width: width,
                    height: 50,
                    marginTop: 5,
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '30%',
                    }}
                  >
                    <Text style={{ ...styles.h3 }}>
                      Do you have a promo code?
                    </Text>
                    <Text style={{ ...styles.h5, color: '#8E8E8E' }}>
                      Enter your promo code
                    </Text>
                  </View>
                </View> */}

                {/* <View
                  style={{
                    flexDirection: 'row',
                    width: width,
                    height: 50,
                    marginTop: 5,
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      marginRight: 40,
                      height: 40,
                      width: width <= 830 ? '100%' : '22%',
                    }}
                  >
                    <input
                      type='text'
                      id='cardNum'
                      onChange={(e) => _this.promoCodeHandler(e.target.value)}
                      value={_this.state.promoCode}
                      placeholder='Enter Promo Code Here'
                      autoComplete='off'
                      style={{ ...styles.inputText }}
                    />
                  </View>
                </View> */}

                {/* <View
                  style={{
                    flexDirection: 'row',
                    width: width,
                    height: 50,
                    marginTop: 5,
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginTop: 5,
                      paddingRight: paddingX,
                      marginRight: 40,
                      height: 40,
                      width: width <= 830 ? '100%' : '22%',
                    }}
                  >
                    <TouchableOpacity style={styles.promo_custom_btn}>
                      <Text style={styles.promo_custom_btn_text}>Apply</Text>
                    </TouchableOpacity>
                  </View>
                </View> */}
                {/* Promo Code End */}

                {/* Line Break */}
                <View
                  style={{
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  <ColoredLine color='#D3D3D3' />
                </View>

                <Text style={{ ...styles.h3, marginTop: 30 }}>
                  Price Details
                </Text>

                {_this.state.booking &&
                  _this.state.booking.all_selected_room.map((item) => {
                    return (
                      <View
                        style={{
                          flexDirection: 'row',
                          width: width,
                          minHeight: 50,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            marginTop: 20,
                            paddingRight: paddingX,
                            width: width <= 830 ? '100%' : '20%',
                          }}
                        >
                          <Text style={{ ...styles.h3 }}>{item.name}</Text>
                          <ul
                            style={{
                              margin: '0 0 0.25rem 0.35rem',
                              color: '#707070',
                            }}
                          >
                            <li>
                              <Text style={{ ...styles.h5 }}>Room Rate</Text>
                            </li>
                            <li>
                              <Text style={{ ...styles.h5 }}>12% vat</Text>
                            </li>
                          </ul>
                          <Text style={{ ...styles.h3 }}>Service Charge</Text>
                          <ul
                            style={{
                              margin: '0 0 0.25rem 0.35rem',
                              color: '#707070',
                            }}
                          >
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                Fee × Duration
                              </Text>
                            </li>
                          </ul>
                        </View>

                        <View
                          style={{
                            marginTop: 20,
                            paddingRight: paddingX,
                            width: width <= 830 ? '100%' : '20%',
                            alignItems: 'right',
                          }}
                        >
                          <Text style={{ ...styles.h3 }}>₱ {item.price}</Text>
                          <ul
                            style={{
                              margin: '0 0 0.25rem 0.35rem',
                              color: '#707070',
                            }}
                          >
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                ₱ {roomNoVatCalculator(item.price)}
                              </Text>
                            </li>
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                ₱ {vatCalculator(item.price)}
                              </Text>
                            </li>
                          </ul>
                          <Text style={{ ...styles.h3 }}>
                            ₱{' '}
                            {_this.state.booking.service_fee *
                              _this.state.booking.night_duration}
                          </Text>
                          <ul
                            style={{
                              margin: '0 0 0.25rem 0.35rem',
                              color: '#707070',
                            }}
                          >
                            <li>
                              <Text style={{ ...styles.h5 }}>
                                ₱ {_this.state.booking.service_fee} ×{' '}
                                {_this.state.booking.night_duration} night(s)
                              </Text>
                            </li>
                          </ul>
                        </View>

                        <View
                          style={{
                            marginTop: 20,
                            paddingRight: paddingX,
                            width: width <= 830 ? '100%' : '6%',
                          }}
                        ></View>
                      </View>
                    );
                  })}

                <View
                  style={{
                    flexDirection: 'row',
                    width: width,
                    height: 50,
                    marginTop: 5,
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '20%',
                    }}
                  >
                    <Text style={{ ...styles.h3 }}>Total Price</Text>
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '20%',
                      alignItems: 'right',
                    }}
                  >
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                      ₱ {_this.state.booking.total}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '6%',
                    }}
                  ></View>
                </View>
              </View>
            )}
          </View>

          {width <= 830 && ( // Large Screens
            <View
              style={{
                marginTop: 20,
                paddingLeft: paddingX,
                width: width <= 830 ? '100%' : '50%',
              }}
            >
              <View
                style={{
                  marginTop: 20,
                  paddingLeft: paddingX,
                  width: width <= 830 ? '100%' : '50%',
                }}
              >
                <View
                  style={{
                    flexDirection: width <= 425.98 ? 'column' : 'row',
                    width: width <= 425.98 ? 'auto' : width,
                    minHeight: 200,
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      marginTop: 20,
                      width: width <= 830 ? '100%' : '20%',
                    }}
                  >
                    <Image
                      height={200}
                      width={200}
                      source={_this.state.booking.hotelArr.primaryImageURL}
                    />
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '50%',
                    }}
                  >
                    <Text style={{ ...styles.h3 }}>
                      Room Hotel in {_this.state.booking.hotelArr.location}
                    </Text>
                    <Text style={{ ...styles.h3 }}>
                      {_this.state.booking.hotelArr.title}
                    </Text>
                    {/* <View style={{ flexDirection: "row" }}>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E', marginRight: 20 }}>Queen Bed: </Text>
                                            <Text style={{ ...styles.h5, color: '#8E8E8E' }}>1 Bath</Text>
                                        </View> */}
                    <View style={{ flexDirection: 'row' }}>
                      <Image
                        height={20}
                        width={20}
                        tintColor={'#FF0000'}
                        source={UI.STAR}
                      />
                      <Text style={{ ...styles.h5, marginLeft: 20 }}>
                        {_this?.state?.booking?.hotelArr?.rating} (
                        {_this?.state?.booking?.hotelArr?.reviews?.length}{' '}
                        reviews)
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...styles.h5,
                        marginTop: '20px',
                        color: '#8E8E8E',
                      }}
                    >
                      This property is in{' '}
                      {_this.state.booking.hotelArr.location}.
                    </Text>
                    <Text style={{ ...styles.h5, marginTop: 20 }}>
                      Hotel Amenities
                    </Text>
                    {_this.state.booking &&
                      _this.state.booking.hotelArr.aminities.map((item) => {
                        return (
                          <Text
                            style={{
                              ...styles.h5,
                              color: '#8E8E8E',
                              marginTop: 5,
                            }}
                          >
                            {item.desc}
                          </Text>
                        );
                      })}
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      paddingRight: paddingX,
                      width: width <= 830 ? '100%' : '5%',
                      alignItems: 'right',
                    }}
                  ></View>
                </View>
              </View>

              <View
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
              >
                <ColoredLine color='#D3D3D3' />
              </View>

              <Text style={{ ...styles.h3, marginTop: 30 }}>Price Details</Text>

              {_this.state.booking &&
                _this.state.booking.all_selected_room.map((item) => {
                  return (
                    <View
                      style={{
                        flexDirection: 'row',
                        width: width,
                        minHeight: 50,
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <View
                        style={{
                          marginTop: 20,
                          paddingRight: paddingX,
                          width: width <= 830 ? '100%' : '20%',
                        }}
                      >
                        <Text style={{ ...styles.h3 }}>{item.name}</Text>
                        <ul
                          style={{
                            margin: '0 0 0.25rem 0.35rem',
                            color: '#707070',
                          }}
                        >
                          <li>
                            <Text style={{ ...styles.h5 }}>Rate</Text>
                          </li>
                          <li>
                            <Text style={{ ...styles.h5 }}>12% vat</Text>
                          </li>
                        </ul>
                        <Text style={{ ...styles.h3 }}>Service Fee</Text>
                        <ul
                          style={{
                            margin: '0 0 0.25rem 0.35rem',
                            color: '#707070',
                          }}
                        >
                          <li>
                            <Text style={{ ...styles.h5 }}>
                              × {_this.state.booking.night_duration} Night(s)
                            </Text>
                          </li>
                        </ul>
                      </View>

                      <View
                        style={{
                          marginTop: 20,
                          paddingRight: paddingX,
                          width: width <= 830 ? '100%' : '20%',
                          alignItems: 'right',
                        }}
                      >
                        <Text style={{ ...styles.h3 }}>₱ {item.price}</Text>
                        <ul
                          style={{
                            margin: '0 0 0.25rem 0.35rem',
                            color: '#707070',
                          }}
                        >
                          <li>
                            <Text style={{ ...styles.h5 }}>
                              ₱ {roomNoVatCalculator(item.price)}
                            </Text>
                          </li>
                          <li>
                            <Text style={{ ...styles.h5 }}>
                              ₱ {vatCalculator(item.price)}
                            </Text>
                          </li>
                        </ul>
                        <Text style={{ ...styles.h3 }}>
                          ₱{' '}
                          {_this.state.booking.service_fee *
                            _this.state.booking.night_duration}
                        </Text>
                        <ul
                          style={{
                            margin: '0 0 0.25rem 0.35rem',
                            color: '#707070',
                          }}
                        >
                          <li>
                            <Text style={{ ...styles.h5 }}>
                              ₱ {_this.state.booking.service_fee}
                            </Text>
                          </li>
                        </ul>
                      </View>

                      <View
                        style={{
                          marginTop: 20,
                          paddingRight: paddingX,
                          width: width <= 830 ? '100%' : '6%',
                        }}
                      ></View>
                    </View>
                  );
                })}

              <View
                style={{
                  flexDirection: 'row',
                  width: width,
                  height: 50,
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '20%',
                  }}
                >
                  <Text style={{ ...styles.h3 }}>Total Price</Text>
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '20%',
                    alignItems: 'right',
                  }}
                >
                  <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                    ₱ {_this.state.booking.total}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: 20,
                    paddingRight: paddingX,
                    width: width <= 830 ? '100%' : '6%',
                  }}
                ></View>
              </View>
            </View>
          )}
        </UI.Row>
      </View>
    );
  }
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
    }}
  />
);

const styles = StyleSheet.create({
  inputText: {
    color: '#000',
    fontSize: 13,
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    height: 80,
    width: '100%',
    paddingLeft: 10,
  },
  sub_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: UI.colors.primary,
  },
  header_text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#434343',
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#434343',
  },
  header_container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  main_container: {
    width: '100%',
    marginTop: 30,
  },
  h3: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  h5: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  h3Mobile: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  h5Mobile: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  custom_btn: {
    height: 100,
    width: 165,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  custom_btn_text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  },
  promo_custom_btn: {
    height: 100,
    width: '100%',
    // backgroundColor: UI.colors.primary,
    backgroundColor: '#F2F2F2',
    border: '1px solid #006FB9',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  promo_custom_btn_text: {
    color: '#006FB9',
    fontSize: 13,
    fontWeight: 'bold',
  },
});
