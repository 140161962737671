// Packages
import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";

// Components
import UI from "../../../shared/Components/UI/js";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SortComponent from "./SortComponent";
import TitleHeader from "./TitleHeader";
import SearchItems from "./SearchItems";
import SearchContainer from "./SearchContainer";
import Checkboxes from "./Checkboxes";
import Notification from "../../../shared/Components/Notification/Notification";

// CSS
import "./css.css";

// API
import { hotelOrTourSearch } from "../../../shared/API/request";

// Context
import AllContext from "../../../shared/Context/AllContext";

// Main Class Component
export default class SearchItemsContainer extends Component {
  // Static Context
  static contextType = AllContext;

  // Constructor
  constructor(props) {
    super(props);

    // URL Parameters
    const sp = new URLSearchParams(window.location.search);
    const search_city = sp.get("city");
    const search_check_in = sp.get("check_in");
    const search_check_out = sp.get("check_out");
    const search_guest = sp.get("guest");
    const search_room = sp.get("room");
    const search_type = sp.get("type");
    const search_sortby = sp.get("sort_by");

    // States
    UI.initiateView(this, {
      active_link: "",
      selected_tab: search_type,
      search_city: search_city,
      search_check_in: search_check_in,
      search_check_out: search_check_out,
      search_guest: search_guest,
      search_type: search_type,
      search_room: search_room,
      search_results: null,
      search_sortby: search_sortby,
      filteredDestinations: null,
      filter_by_price: "0|999999",
      meta: null,
      checked_9: false,
      checked_10: false,
      checked_11: false,
      checked_12: false,
      checked_13: false,
      checked_14: false,
      checked_15: false,
    });

    // Fire api query on first render
    this.searchHotelTours(
      search_city,
      search_check_in,
      search_check_out,
      search_guest,
      search_room,
      search_type,
      search_sortby,
      this.state.filter_by_price
    );
  }

  // API get query for search results
  searchHotelTours = (
    city,
    checkIn,
    checkOut,
    guest,
    room,
    type,
    sortby,
    price,
    page = 1
  ) => {
    const params = {
      city: city,
      check_in: checkIn,
      check_out: checkOut,
      guest: guest,
      room: room,
      type: type,
      sortBy: sortby,
      pricerange: price,
      page: page,
    };

    hotelOrTourSearch(params, this.callback);
  };

  // Callback after get api for searchhoteltours
  callback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      if (data.data) {
        this.setState({
          filteredDestinations: data.data,
        });
      }

      this.setState({
        meta: data.meta,
      });
    } else if (status === 400) {
      Notification("An Error Occured", "danger");
    } else {
      Notification("An Error Occured", "danger");
    }
  };

  // Sidebar Search Function
  search = () => {
    const { data, setData } = this.context;

    if (
      !this.state.search_city ||
      !this.state.search_check_in ||
      !this.state.search_check_out ||
      !this.state.search_guest
    ) {
      Notification("Please fill in the required textbox", "danger");
      return false;
    }

    // Set State Data
    setData((prevData) => {
      return {
        ...prevData,
        search_results: {
          city: this.state.search_city,
          check_in: this.state.search_check_in,
          check_out: this.state.search_check_out,
          guest: this.state.search_guest,
          room: this.state.search_room,
          type: this.state.selected_tab,
          sort_by: this.state.search_sortby,
        },
      };
    });

    UI.search("/search-results", {
      city: this.state.search_city,
      check_in: this.state.search_check_in,
      check_out: this.state.search_check_out,
      guest: this.state.search_guest,
      room: this.state.search_room,
      type: this.state.search_type,
      sort_by: this.state.search_sortby,
    });

    this.searchHotelTours(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_type,
      this.state.search_sortby,
      this.state.filter_by_price
    );
  };

  // Will fire on first render
  componentDidMount() {
    // Use Context
    const { data, setData } = this.context;

    const sp = new URLSearchParams(window.location.search);
    const search_city = sp.get("city");
    const search_check_in = sp.get("check_in");
    const search_check_out = sp.get("check_out");
    const search_guest = sp.get("guest");
    const search_type = sp.get("type");

    // Save search params to context
    setData((prevState) => {
      return {
        ...prevState,
        search_params: {
          check_in: search_check_in,
          check_out: search_check_out,
          guest: search_guest,
        },
      };
    });

    // Save search params to localStorage
    localStorage.setItem(
      "search_params",
      JSON.stringify({
        check_in: search_check_in,
        check_out: search_check_out,
        guest: search_guest,
      })
    );
  }

  // Will fire on every state update
  componentDidUpdate(prevProps, prevState) {
    if (prevState.search_sortby !== this.state.search_sortby) {
      this.searchHotelTours(
        this.state.search_city,
        this.state.search_check_in,
        this.state.search_check_out,
        this.state.search_guest,
        this.state.search_room,
        this.state.search_type,
        this.state.search_sortby,
        this.state.filter_by_price
      );
    }

    // Function for Filter By Price
    if (
      prevState.checked_9 !== this.state.checked_9 ||
      prevState.checked_10 !== this.state.checked_10 ||
      prevState.checked_11 !== this.state.checked_11 ||
      prevState.checked_12 !== this.state.checked_12 ||
      prevState.checked_13 !== this.state.checked_13 ||
      prevState.checked_14 !== this.state.checked_14 ||
      prevState.checked_15 !== this.state.checked_15
    ) {
      let ids = [9, 10, 11, 12, 13, 14, 15];
      let reverseIds = [15, 14, 13, 12, 11, 10, 9];
      let lowestPrice = "0";
      let highestPrice = "999999";

      for (let i = 0; i < ids.length; i++) {
        if (this.state[`checked_${ids[i]}`]) {
          switch (ids[i]) {
            case 9:
              lowestPrice = "0";
              break;
            case 10:
              lowestPrice = "3001";
              break;
            case 11:
              lowestPrice = "6001";
              break;
            case 12:
              lowestPrice = "9001";
              break;
            case 13:
              lowestPrice = "12001";
              break;
            case 14:
              lowestPrice = "15001";
              break;
            case 15:
              lowestPrice = "18001";
              break;
            default:
              lowestPrice = "0";
              break;
          }

          break;
        }
      }

      for (let i = 0; i < reverseIds.length; i++) {
        if (this.state[`checked_${reverseIds[i]}`]) {
          switch (reverseIds[i]) {
            case 15:
              highestPrice = "999999";
              break;
            case 14:
              highestPrice = "18000";
              break;
            case 13:
              highestPrice = "15000";
              break;
            case 12:
              highestPrice = "12000";
              break;
            case 11:
              highestPrice = "9000";
              break;
            case 10:
              highestPrice = "6000";
              break;
            case 9:
              highestPrice = "3000";
              break;
            default:
              highestPrice = "999999";
              break;
          }
          break;
        }
      }

      this.setState({
        filter_by_price: `${lowestPrice}|${highestPrice}`,
      });
    }

    // Function for Filter By Price API
    if (prevState.filter_by_price !== this.state.filter_by_price) {
      this.searchHotelTours(
        this.state.search_city,
        this.state.search_check_in,
        this.state.search_check_out,
        this.state.search_guest,
        this.state.search_room,
        this.state.search_type,
        this.state.search_sortby,
        this.state.filter_by_price
      );
    }

    if (prevState != this.state) {
      const sp = new URLSearchParams(window.location.search);
      const search_city = sp.get("city");
      const search_check_in = sp.get("check_in");
      const search_check_out = sp.get("check_out");
      const search_guest = sp.get("guest");
      const search_type = sp.get("type");

      // Save search params to localStorage
      localStorage.setItem(
        "search_params",
        JSON.stringify({
          check_in: search_check_in,
          check_out: search_check_out,
          guest: search_guest,
        })
      );
    }

    console.log(this.state.meta);
  }

  prevPage = () => {
    const prevPage = parseInt(this.state.meta.current_page) - 1;

    this.searchHotelTours(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_type,
      this.state.search_sortby,
      this.state.filter_by_price,
      prevPage
    );
  };

  nextPage = () => {
    const nextPage = parseInt(this.state.meta.current_page) + 1;

    this.searchHotelTours(
      this.state.search_city,
      this.state.search_check_in,
      this.state.search_check_out,
      this.state.search_guest,
      this.state.search_room,
      this.state.search_type,
      this.state.search_sortby,
      this.state.filter_by_price,
      nextPage
    );
  };

  render() {
    // Properties for UI/Responsive
    const width = this.state.width;
    const paddingX = width * 0.05;
    const _this = this;
    const isMobile = _this.state.isMobile;

    // Use Context
    const { data, setData } = this.context;

    const sp = new URLSearchParams(window.location.search);
    const search_city = sp.get("city");
    const search_check_in = sp.get("check_in");
    const search_check_out = sp.get("check_out");
    const search_guest = sp.get("guest");
    const search_type = sp.get("type");

    // To render on screen
    return (
      <View style={styles.main_container}>
        <ScrollView>
          <Header _this={this} />
          {/* Contents Here */}
          <TitleHeader
            _this={_this}
            search_type={search_type}
            search_city={search_city}
          />
          {/* Sort By Price or Rating */}
          <SortComponent _this={_this} />

          <UI.PadView _this={_this}>
            {UI.box(30)}
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              {/* Search Results List */}
              <SearchItems _this={_this} />
              {UI.box(30)}

              <View>
                {/* Sidebar Search Results */}
                <SearchContainer _this={_this} />
                {UI.box(30)}

                {/* Sidebar Checkboxes Filter */}
                <Checkboxes _this={_this} />

                {/* <ImageBackground
                  source={UI.MAPS}
                  style={{
                    width: isMobile ? "100%" : 300 + paddingX * 2,
                    ...styles.maps_bg,
                  }}
                >
                  <TouchableOpacity style={styles.show_map_btn}>
                    <Text style={styles.show_map_btn_text}>Show Map</Text>
                  </TouchableOpacity>
                </ImageBackground> */}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              {this.state.meta && (
                <>
                  {this.state.meta.current_page !== this.state.meta.from && (
                    <TouchableOpacity
                      style={styles.pagination_btn}
                      onClick={() => this.prevPage()}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={"#646464"}
                        source={UI.CHEVRON_LEFT}
                      />
                    </TouchableOpacity>
                  )}
                  <View style={styles.pagination_btn}>
                    <Text style={styles.pagination_btn_text}>
                      Showing Page{" "}
                      {this.state.meta ? this.state.meta.current_page : "0"} of{" "}
                      {this.state.meta ? this.state.meta.last_page : "0"}
                    </Text>
                    {UI.box(10)}
                  </View>
                  {this.state.meta.current_page !==
                    this.state.meta.last_page && (
                    <TouchableOpacity
                      style={styles.pagination_btn}
                      onClick={() => this.nextPage()}
                    >
                      <Image
                        height={15}
                        width={15}
                        tintColor={"#646464"}
                        source={UI.CHEVRON_RIGHT}
                      />
                    </TouchableOpacity>
                  )}
                </>
              )}
            </View>
          </UI.PadView>

          {/* End Contents Here */}

          <Footer _this={_this} />
        </ScrollView>
      </View>
    );
  }
}

// CSS Styles
const styles = StyleSheet.create({
  maps_bg: {
    height: 250,
    justifyContent: "center",
    alignItems: "center",
  },
  show_map_btn_text: {
    color: "white",
    fontSize: 14,
  },
  show_map_btn: {
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: UI.colors.primary,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  pagination_btn_text: {
    color: "#646464",
    fontSize: 14,
    fontWeight: "bold",
  },
  pagination_btn: {
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    alignSelf: "center",
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f3f3f3",
  },
});
