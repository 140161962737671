import React, { Component } from 'react';
import {
    TouchableOpacity,
    ScrollView,
    View,
    Image,
    ImageBackground,
    ImageFit,
    Text,
    StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/Components/UI/js";

export default class FileUpload extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        const _this = props._this;
        const width = _this.state.width;
        const paddingX = width * 0.05;

        let passport_visa_data3 = _this.state.passport_visa_data3;
        let passport_visa_name3 = _this.state.passport_visa_name3;
        let passport_visa_uploaded_at3 = _this.state.passport_visa_uploaded_at3;


        const handleImageUpload = (e) => { //imageHandler
            const reader = new FileReader();
            reader.onload = (e) => {
                if(reader.readyState === 2) {
                    _this.setState({
                        ["passport_visa_data3"]: reader.result
                    });
                }
            }
            reader.readAsDataURL(e.target.files[0])

            _this.setState({
                ["passport_visa_name3"]: e.target.files[0].name
            });

            
            let today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;
            
            _this.setState({
                ["passport_visa_uploaded_at3"]: today
            });
            
        }

        return (
            <div style={styles.image_container}>
               <h1 style={styles.heading}></h1>
               <div style={styles.image_holder}>
                   <img src={passport_visa_data3} alt='' id='img' style={styles.img} />
               </div>
               <input 
                    type="file" 
                    style={styles.input_img} 
                    name="input_img3"
                    id="input_img3" 
                    accept="image/*"
                    onChange={handleImageUpload} 
                />
               <div style={styles.label}>
                   <label htmlFor="input_img3" style={{...styles.image_upload, 
        transform: width <= 830  ? "translate(0,-230%)" : "translate(-48%,-230%)" }}>
                        <i class="fas fa-upload"></i>&nbsp;
                        Upload Photo
                   </label>
               </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    image_container: {
        height: 300,
        width: "100%",
        paddingRight: 50,
        borderRadius: 10,
        borderColor: "#E1E1E1",
    },
    heading: {
        textAlign: "center",
        marginTop: "1rem",
        fontWeight: "bold"
    },
    image_holder: {
        width: 300,
        height: 150,
        border: "3px solid black",
        borderRadius: 5,
        marginTop: "1rem",
        backgroundColor: "#E1E1E1",
    },
    input_img: {
        display: "none",
    },
    img: {
        width: 300,
        height: 144,
        objectFit: "cover"
    },
    label: {
        width: "100%",
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        color: "black",
        fontSize: 16,
        fontWeight: "bold",
    },
    image_upload: {
        paddingTop: 15,
        marginLeft: 0,
        backgroundColor: "#006FB9",
        width: 200,
        height: 50,
        color: "#fff",
        borderRadius: 10,
        textAlign: "center",
        cursor: "pointer",
    }

});